import React from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Alert = ({ children, className, color, ...props }) => {
    return (
        <Box className={['alert', `ime-alert-${color}`, className].join(' ')} role="alert" {...props}>
            {children}
        </Box>
    )
}

Alert.propTypes = {
    children: PropTypes.any.isRequired,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'])
}

Alert.defaultProps = {
    color: 'primary'
}

export default Alert
