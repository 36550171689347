import React, { useState } from 'react'
import Pagination from '../../../../../components/Pagination'
import { Box } from '../../../../../components/vanilla'
import { useGetAllAdmins } from '../../../../../hooks/useAdmin'
import AdminCard from './AdminCard'

function AdminList() {
    // Data vars here
    const [currentPage, setCurrentPage] = useState(1)
    const { data: adminList, isLoading } = useGetAllAdmins({ page: currentPage })

    return (
        <Box>
            {!isLoading && adminList && (
                <>
                    {adminList.admins.map((admin) => (
                        <AdminCard key={admin.id} admin={admin} />
                    ))}

                    <Pagination
                        totalPages={adminList.totalPages}
                        currentPage={currentPage}
                        changePage={(page) => setCurrentPage(page)}
                        itemCount={adminList.count}
                    />
                </>
            )}
        </Box>
    )
}

export default AdminList
