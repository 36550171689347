import React from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Flex = ({ children, className, ...props }) => {
    return (
        <Box className={`d-flex ${className}`} {...props}>
            {children}
        </Box>
    )
}

Flex.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

Flex.defaultProps = {
    className: ''
}

export default Flex
