import { useMutation } from 'react-query'
import { sendBuyServiceContactForm, sendContactForm, sendCRMMailForm, sendSubHrContactForm } from './util'

export const useBuyServiceContactForm = () => {
    return useMutation(sendBuyServiceContactForm)
}

export const useContactForm = () => {
    return useMutation(sendContactForm)
}

export const useCRMMailForm = () => {
    return useMutation(sendCRMMailForm)
}
export const useSubHrContactForm = () => {
    return useMutation(sendSubHrContactForm)
}
