import axios from 'axios'
import Constants from '../helpers/Constants'
import ValidationError from '../errors/ValidationError'
import { getAccessToken } from '../api/Auth'

/** Function to create a new job (Admin + Employer) */
export const addNewJob = (jobData, userType) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            let apiURL
            if (userType == Constants.TYPE_USER_ADMIN) apiURL = Constants.API_ROUTE_ADD_JOB_ADMIN
            else apiURL = Constants.API_ROUTE_ADD_JOB_EMPLOYER

            // Calling the api to add a new job
            await axios.post(apiURL, jobData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            // Calling the api
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to edit a job by job Id */
export const editJob = (jobId, userType, jobData) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling api here
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_EDIT_JOB_ADMIN(jobId)
                    : Constants.API_ROUTE_EDIT_JOB_EMPLOYER(jobId)
            await axios.patch(apiURL, jobData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            resolve()
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNAUTHORIZED ||
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY
            )
                reject(error.response.data.message)
            reject(error)
        }
    })
}

/** Function to get all jobs (Admin + Employer) */
export const getAllJobs = ({ pageNumber, userType, searchParam = '', location = '', salary = '', id = '' }) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the API
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_LIST_JOB_ADMIN
                    : Constants.API_ROUTE_LIST_JOB_EMPLOYER
            const response = await axios.get(
                `${apiURL}?page=${pageNumber}&searchParam=${searchParam}&location=${location}&salary=${salary}&id=${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get job by Id */
export const getJobById = (jobId, userType) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling api here
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_VIEW_JOB_ADMIN(jobId)
                    : Constants.API_ROUTE_VIEW_JOB_EMPLOYER(jobId)
            const response = await axios.get(apiURL, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            resolve(response.data.job)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get job by Id (Public) */
export const getJobByIdPublic = (jobId) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling api here
            const response = await axios.get(Constants.API_ROUTE_GET_PUBLIC_JOB_BY_ID(jobId))
            resolve(response.data.job)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to edit job flags */
export const updateJobFlag = (jobId, flagType, flagValue, userType) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the API
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_EDIT_JOB_FLAG_ADMIN(jobId)
                    : Constants.API_ROUTE_EDIT_JOB_FLAG_EMPLOYER(jobId)
            await axios.patch(
                apiURL,
                {
                    type: flagType,
                    value: flagValue
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }
            )
            resolve()
        } catch (error) {
            console.log(error.response)
            reject(error)
        }
    })
}

/** Function to get all jobs for landing page */
export const getImmediateJobs = ({ page, searchParam = '', location = '', salary = '' }) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.get(
                `${Constants.API_ROUTE_GET_LANDING_JOBS}?page=${page}&searchParam=${searchParam}&location=${location}&salary=${salary}`
            )
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all featured jobs for landing page */
export const getFeaturedJobs = () => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.get(Constants.API_ROUTE_GET_FEATURED_JOBS)
            resolve(response.data.jobs)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to upload resume by job-seeker */
export const uploadResume = (
    resumeData,
    userType = Constants.TYPE_USER_JOB_SEEKER,
    jobSeekerId = '',
    isPublic = false
) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            let accessToken = ''
            if (!isPublic) accessToken = await getAccessToken()

            // Calling the API
            const URL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_UPLOAD_RESUME_ADMIN(jobSeekerId)
                    : `${Constants.API_ROUTE_UPLOAD_RESUME}${jobSeekerId != '' ? `/${jobSeekerId}` : ''}`
            const response = await axios.post(URL, resumeData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            resolve(response.data)
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_BAD_REQUEST
            )
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to upload document by job-seeker */
export const uploadDocument = (docData, jobSeekerId) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            let accessToken = await getAccessToken()

            // Calling the API
            const response = await axios.post(Constants.API_UPLOAD_JOB_SEEKER_DOC(jobSeekerId), docData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            resolve(response.data)
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_BAD_REQUEST
            )
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to add to job by job-seeker */
export const appToJob = (
    jobId,
    jobData,
    userType = Constants.TYPE_USER_JOB_SEEKER,
    jobSeekerId = '',
    isPublic = false,
    isVendorSourced = false
) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            let accessToken
            if (!isPublic) accessToken = await getAccessToken()

            // Calling the API
            const URL =
                userType == Constants.TYPE_USER_JOB_SEEKER
                    ? `${Constants.API_ROUTE_APPLY_JOB(jobId)}${jobSeekerId != '' ? `/${jobSeekerId}` : ''}`
                    : userType == Constants.TYPE_USER_EMPLOYER
                    ? `${Constants.API_ROUTE_VENDOR_APPLY_JOB(jobId)}${jobSeekerId != '' ? `/${jobSeekerId}` : ''}`
                    : Constants.API_ROUTE_APPLY_JOB_ADMIN(jobId, jobSeekerId)
            const response = await axios.patch(
                URL,
                {
                    questionaire: jobData.questionnaireAnswers,
                    resumeId: jobData.resumeId,
                    referralCode: jobData.referralCode,
                    isVendorSourced
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
            resolve(response.data)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to add to job by job-seeker */
export const getResumes = (userType = Constants.TYPE_USER_JOB_SEEKER, jobSeekerId = '', directRegister = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            let headers
            if (!directRegister) {
                const accessToken = await getAccessToken()
                headers = {
                    Authorization: `Bearer ${accessToken}`
                }
            }

            // Calling the API
            const URL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_GET_RESUMES_ADMIN(jobSeekerId)
                    : `${Constants.API_ROUTE_GET_RESUMES}${jobSeekerId != '' ? `/${jobSeekerId}` : ''}`
            const response = await axios.get(URL, {
                headers
            })
            resolve(response.data.resumes)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to get sample job data */
export const getSampleJobData = (employerId, getWithId = false, id = Constants.TYPE_ANON_SAMPLE_JOBS_ARR[0]) => {
    return new Promise((resolve, reject) => {
        try {
            const sampleJobArray = [
                {
                    id: Constants.TYPE_ANON_SAMPLE_JOBS_ARR[0],
                    employer: {
                        id: employerId
                    },
                    positionName: 'Full Stack Developer',
                    minExp: 2.5,
                    maxExp: 5.0,
                    minSal: 500000,
                    maxSal: 800000,
                    qualification: 'B. Tech',
                    city: {
                        CountryName: 'India',
                        id: 3,
                        name: 'Pune'
                    },
                    isRemote: false,
                    isContract: false,
                    techSkill: ['Node Js', 'React Js', 'Mongo DB'],
                    description: '',
                    isActive: true,
                    isAdminApproved: false,
                    isCreatedByAdmin: false,
                    isDeleted: false,
                    isFeatured: false,
                    oldId: '0',
                    questionaire: [
                        {
                            question: 'Do you hold a valid Passport?',
                            mustHave: false,
                            responseType: Constants.TYPE_RESPONSE_YES_NO,
                            idealResponse: 'Yes'
                        }
                    ],
                    validity: '2022-02-18',
                    type: Constants.TYPE_JOB_SAMPLE
                },
                {
                    id: Constants.TYPE_ANON_SAMPLE_JOBS_ARR[1],
                    employer: {
                        id: employerId
                    },
                    positionName: 'React JS Developer',
                    minExp: 3.5,
                    maxExp: 7.0,
                    minSal: 500000,
                    maxSal: 800000,
                    qualification: 'M. Tech',
                    city: {
                        CountryName: 'India',
                        id: 3,
                        name: 'Pune'
                    },
                    isRemote: false,
                    isContract: false,
                    techSkill: ['React Js', 'MySQL'],
                    description: '',
                    isActive: true,
                    isAdminApproved: false,
                    isCreatedByAdmin: false,
                    isDeleted: false,
                    isFeatured: false,
                    oldId: '0',
                    questionaire: [
                        {
                            question: 'Do you hold a valid Passport?',
                            mustHave: false,
                            responseType: Constants.TYPE_RESPONSE_YES_NO,
                            idealResponse: 'Yes'
                        }
                    ],
                    validity: '2022-02-18',
                    type: Constants.TYPE_JOB_SAMPLE
                }
            ]

            if (getWithId) {
                const sampleJob = sampleJobArray.filter((job) => job.id == id)
                resolve(sampleJob[0])
            } else resolve(sampleJobArray)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all jobs (Admin + Employer) */
export const getAllJobApplicants = ({
    pageNumber,
    jobId,
    userType,
    name = '',
    id = '',
    email = '',
    mobileNumber = '',
    minExperience = '',
    maxExperience = '',
    minNoticePeriod = '',
    maxNoticePeriod = '',
    isBestFit = '',
    isServingNoticePeriod = '',
    resetJobSeekerSearch = false
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (resetJobSeekerSearch === true)
                name =
                    email =
                    mobileNumber =
                    minExperience =
                    maxExperience =
                    minNoticePeriod =
                    isBestFit =
                    isServingNoticePeriod =
                    maxNoticePeriod =
                        ''
            // Getting access token
            const accessToken = await getAccessToken()
            // Calling the API
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? resetJobSeekerSearch === false
                        ? `${Constants.API_ROUTE_ADMIN_GET_JOB_SEEKER_POOL(
                              jobId
                          )}?page=${pageNumber}&name=${name}&applicationId=${id}&email=${email}&mobileNumber=${mobileNumber}&minExperience=${minExperience}&maxExperience=${maxExperience}&minNoticePeriod=${minNoticePeriod}&maxNoticePeriod=${maxNoticePeriod}`
                        : `${Constants.API_ROUTE_ADMIN_GET_JOB_APPLICANTS(jobId)}?page=${pageNumber}`
                    : `${Constants.API_ROUTE_EMPLOYER_GET_JOB_APPLICANTS(
                          jobId
                      )}?page=${pageNumber}&name=${name}&applicationId=${id}&email=${email}&mobileNumber=${mobileNumber}&minExperience=${minExperience}&maxExperience=${maxExperience}&minNoticePeriod=${minNoticePeriod}&maxNoticePeriod=${maxNoticePeriod}&isBestFit=${isBestFit}&isServingNoticePeriod=${isServingNoticePeriod}`
            const response = await axios.get(apiURL, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to change job application status */
export const changeJobApplicationStatus = (applicationId, applicantId, updateObj, userType, isRelease = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the API
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_ADMIN_UPDATE_JOB_STATUS(applicationId, applicantId)
                    : Constants.API_ROUTE_EMPLOYER_UPDATE_JOB_STATUS(applicationId, applicantId)

            await axios.patch(`${apiURL}?${isRelease ? 'isRelease=true' : ''}`, updateObj, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            resolve()
        } catch (error) {
            if (
                error.response &&
                (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                    error.response.status == Constants.STATUS_BAD_REQUEST)
            )
                reject(new ValidationError(error.response.data.error.message))
            else reject(error)
        }
    })
}

/** Function to get all job dashboard data */
export const getAllJobStatistics = (pageNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the API
            const response = await axios.get(`${Constants.API_EMPLOYER_DASHBOARD_STATISTICS}?page=${pageNumber}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}
