import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

function Form({ children, className, onSubmit, ...props }) {
    return (
        <Box Element="form" className={className} onSubmit={onSubmit} {...props} noValidate>
            {children}
        </Box>
    )
}

Form.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    onSubmit: PropTypes.func
}

Form.defaultProps = {
    className: '',
    onSubmit: () => {}
}

export default Form
