import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Button = ({ type, label, color, soft, className, to, btnType, icon, isLoading, ...props }) => {
    const Element = type === 'link' ? Link : 'button'
    const iconClass = icon ? 'd-flex align-items-center justify-content-center' : ''

    return (
        <Element
            type={btnType}
            className={['ime-btn', className, `ime-btn-${color}${soft ? '-soft' : ''}`, iconClass].join(' ')}
            to={to}
            {...props}>
            {isLoading ? (
                'Loading...'
            ) : (
                <>
                    {icon} <span className="ms-1"></span> {label}
                </>
            )}
        </Element>
    )
}

Button.propTypes = {
    type: PropTypes.oneOf(['link', 'button']),
    label: PropTypes.any,
    className: PropTypes.string,
    to: PropTypes.string,
    soft: PropTypes.bool,
    btnType: PropTypes.oneOf(['button', 'submit']),
    isLoading: PropTypes.bool,
    icon: PropTypes.any,
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'white',
        'dark',
        'link'
    ])
}

Button.defaultProps = {
    type: 'button',
    label: 'Button',
    className: '',
    color: 'primary',
    isLoading: false,
    soft: true,
    to: '',
    btnType: 'button',
    icon: ''
}

export default Button
