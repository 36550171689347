import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Column, Flex, Heading, Row } from '../../../../../../components/vanilla'
import Pagination from '../../../../../../components/Pagination'
import { useSubHrApplicants } from '../../../../../../hooks/useAdmin'
import Utils from '../../../../../../helpers/Utils'
import Constants from '../../../../../../helpers/Constants'
import JobSeekerCard from '../partials/JobSeekerCard'
import CreatableSelect from '../../../../../../components/elements/CreatableSelect'
import SingleSelect from '../../../../../../components/elements/SingleSelect'

import { getAllTags as getTags } from '../../../../../../api/Admin'
import { setLoadingState } from '../../../../../../features/loader/LoaderSlice'

const ListHrJobSeekers = ({ isPool = false }) => {
    const subHRId = useParams().subHrId

    // Data vars here
    const [currentPage, setCurrentPage] = useState(1)
    const [name, setName] = useState('')
    const [technicalSkills, setTechnicalSkills] = useState([])
    const [skillIsMandatory, setSkillIsMandatory] = useState(false)
    const [tagSearch, setTagSearch] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [currentLocation, setCurrentLocation] = useState('')
    const [minCTC, setMinCTC] = useState('')
    const [maxCTC, setMaxCTC] = useState('')
    const [minNoticePeriod, setMinNoticePeriod] = useState('')
    const [maxNoticePeriod, setMaxNoticePeriod] = useState('')

    const [tags, setTags] = useState([])
    const [optionTags, setOptionTags] = useState([])
    const [skillString, setskillString] = useState('')
    const [advancedSearch, setAdvancedSearch] = useState(false)

    /**
     * Callback to get technical skills from child
     * @param {*} skills
     */
    const updateTechnicalSkills = (skills) => {
        setTechnicalSkills(skills)
        let selectedSkills = ''

        if (skills.length > 0) {
            skills.forEach((skill, index) => {
                selectedSkills += `${skill.value}${index + 1 !== skill.length ? ',' : ''}`
            })
        }

        selectedSkills = selectedSkills.slice(0, -1)
        setskillString(selectedSkills)
    }

    const dispatch = useDispatch()

    const navigate = useNavigate()

    //get page no from URL page
    let search = window.location.search
    let params = new URLSearchParams(search)
    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    //call method on click on browser back and forward arrow button
    window.onpopstate = async () => {
        try {
            setCurrentPage(pageNo)
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        setCurrentPage(pageNo > 0 ? pageNo : 1)
        populateInitData()
    }, [isPool])

    /** Function to populate initial data */
    const populateInitData = async () => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            await getAllTags()
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /**
     * Function to get all the tags
     */
    const getAllTags = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                // Getting all the tags here
                const tagsArr = await getTags()
                setTags(tagsArr)

                // Setting the tags in searchable option
                let newTagsArr = [{ label: 'All Tags', value: '' }]
                tagsArr.forEach((tag) => newTagsArr.push({ label: tag.name, value: tag.id }))
                setOptionTags(newTagsArr)

                resolve()
            } catch (error) {
                reject(error)
            }
        })
    }

    // Styles for react-select
    const selectStyles = {
        control: (styles) => ({ ...styles, fontSize: '1.6rem' }),
        option: (styles) => ({ ...styles, fontSize: '1.6rem' })
    }

    const selectTag = (tag) => {
        setTagSearch(tag.value)
    }

    /** Function to change page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigate({
                // pathname: '/admin/manage/hr/job-seeker/' + subHRId + '/list',
                pathname: isPool
                    ? Constants.ROUTE_ADMIN_MANAGE_SUBHR_POOL_USERS_LIST(subHRId)
                    : Constants.ROUTE_ADMIN_MANAGE_SUBHR_JOBSEEKER_LIST(subHRId),
                search: '?page=' + pageNumber
            })
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    let { isLoading, data, isError } = useSubHrApplicants({
        subHRId,
        page: currentPage,
        name,
        mobileNumber,
        currentLocation,
        minCTC,
        maxCTC,
        minNoticePeriod,
        maxNoticePeriod,
        skillIsMandatory,
        skills: skillString,
        tagId: tagSearch,
        isPool
    })
    if (isError) Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)

    return (
        <Box className="p-4 mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">{isPool ? 'Pool ' : ''}Job Seekers</Heading>
            </Box>

            <Box className="card mb-4 shadow-sm">
                <Box className="card-body">
                    <h4>
                        <b>Search Pane</b>
                    </h4>

                    <Row className="mb-3 ">
                        <Row>
                            <Column className="ps-md-0 col-md-12">
                                <label htmlFor="skills" className="form-label">
                                    Skills
                                </label>
                                <CreatableSelect
                                    styles={selectStyles}
                                    initialValues={technicalSkills}
                                    options={Constants.LIST_DEFAULT_TECH_SKILLS}
                                    callback={updateTechnicalSkills}
                                    placeholder="Search for a Skill and click to add"
                                    createLabel="Search"
                                />
                            </Column>
                        </Row>

                        <Row className="mt-4 mb-4">
                            <Column className="d-flex col-md-6 align-items-center">
                                <label className="col-form-label me-3" htmlFor="feature">
                                    Mandatory Skill Search?
                                </label>
                                <Box className="form-check form-switch">
                                    <input
                                        onChange={(e) => setSkillIsMandatory(e.target.checked)}
                                        checked={skillIsMandatory}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="feature"
                                    />
                                </Box>
                            </Column>
                            <Column className="col-md-6">
                                <span
                                    className="me-4 text-decoration-underline user-select-none"
                                    style={{ cursor: 'pointer', float: 'right' }}
                                    onClick={() => setAdvancedSearch(!advancedSearch)}>
                                    {advancedSearch ? 'Simple Search' : 'Advanced Search'}
                                </span>
                            </Column>
                        </Row>

                        <Box style={{ display: `${advancedSearch ? 'block' : 'none'}` }}>
                            <Row>
                                <Column className="ps-md-0 col-md-3">
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        type="text"
                                        className="form-control"
                                        placeholder="John Doe"
                                        id="name"
                                    />
                                </Column>
                                <Column className="col-md-3">
                                    <label htmlFor="mobileNumber" className="form-label">
                                        Mobile Number
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        value={mobileNumber}
                                        className="form-control"
                                        placeholder="xxxxxxxxxx"
                                        id="mobileNumber"
                                    />
                                </Column>
                                <Column className="col-md-3">
                                    <label htmlFor="currentLocation" className="form-label">
                                        Current Location
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setCurrentLocation(e.target.value)}
                                        value={currentLocation}
                                        className="form-control"
                                        placeholder="Mumbai"
                                        id="currentLocation"
                                    />
                                </Column>
                                <Column className="col-md-3">
                                    <label htmlFor="tag" className="form-label">
                                        Tag
                                    </label>
                                    <SingleSelect
                                        styles={selectStyles}
                                        options={optionTags}
                                        callback={selectTag}
                                        placeholder="Search for a Tag"
                                        noOptionsMessage="No Tag found"
                                    />
                                </Column>
                            </Row>
                            <Row className="mt-3">
                                <Column className="col-md-3 ps-md-0">
                                    <label htmlFor="minCtc" className="form-label">
                                        Expected CTC Range
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMinCTC(e.target.value)}
                                        value={minCTC}
                                        className="form-control"
                                        placeholder="min CTC"
                                        id="minCtc"
                                    />
                                </Column>
                                <Column className="col-md-3">
                                    <label htmlFor="maxCtc" className="form-label">
                                        &nbsp;
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMaxCTC(e.target.value)}
                                        value={maxCTC}
                                        className="form-control"
                                        placeholder="max CTC"
                                        id="maxCtc"
                                    />
                                </Column>
                                <Column className="col-md-3">
                                    <label htmlFor="minNp" className="form-label">
                                        Notice Period Range
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMinNoticePeriod(e.target.value)}
                                        value={minNoticePeriod}
                                        className="form-control"
                                        placeholder="min Notice Period"
                                        id="minNp"
                                    />
                                </Column>
                                <Column className="col-md-3">
                                    <label htmlFor="maxNp" className="form-label">
                                        &nbsp;
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMaxNoticePeriod(e.target.value)}
                                        value={maxNoticePeriod}
                                        className="form-control"
                                        placeholder="max Notice Period"
                                        id="maxNp"
                                    />
                                </Column>
                            </Row>
                        </Box>
                    </Row>
                </Box>
            </Box>
            {!isLoading && data && (
                <>
                    {Array.isArray(data.jobSeekers) && data.jobSeekers.length > 0 ? (
                        <>
                            {data.jobSeekers.map(
                                ({ id, firstName, middleName, lastName, email, phoneNumber, profile }) => (
                                    <JobSeekerCard
                                        firstName={firstName}
                                        middleName={middleName}
                                        lastName={lastName}
                                        email={email}
                                        phoneNumber={phoneNumber}
                                        id={id}
                                        key={id}
                                        profile={profile}
                                    />
                                )
                            )}
                        </>
                    ) : (
                        <Box Element="p">No {isPool ? 'Pool ' : ''}Job-seeker sourced Yet!</Box>
                    )}

                    <Pagination
                        totalPages={data.totalPages}
                        currentPage={currentPage}
                        changePage={(page) => changePage(page)}
                        itemCount={data.count}
                    />
                </>
            )}
        </Box>
    )
}

export default ListHrJobSeekers
