import React from 'react'
import { Box, Flex, Image } from '../../../../components/vanilla'
import Constants from '../../../../helpers/Constants'
import moment from 'moment'

function PostLikes({ like }) {
    return (
        <Flex className={`mb-10 justify-content-start`}>
            <Flex className={`flex-column align-items-start`}>
                <Flex className="mb-2 align-items-center">
                    <Box className="avatar avatar-xl">
                        <Image
                            src={`${
                                like.user.avatarPath != null ? like.user.avatarPath : Constants.DEFAULT_PATH_NO_IMAGE
                            }`}
                            className="avatar-img rounded-circle"
                            alt="Avatar Image"
                        />
                    </Box>
                    <Box className="ms-3">
                        <span className="fs-5 fw-bold text-gray-900 me-2">{`${like.user.firstName} ${like.user.lastName}`}</span>
                        <span className="text-muted mb-1">
                            {moment(like.createdAt).format('ddd MMM Do YYYY, h:mm:ss a')}
                        </span>
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default PostLikes
