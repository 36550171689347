import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getJobSeekersForTag } from '../../../../api/Admin'
import JobSeekerCard from '../../../../components/elements/user/job-seeker'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'

function TagJobSeekers() {
    const { tagId } = useParams()
    const [jobSeekers, setJobSeekers] = useState([])

    useEffect(() => {
        getTagJobSeekers()
    }, [])

    /**
     * Function to get all the job Seekers in tag
     */
    const getTagJobSeekers = async () => {
        try {
            const jobSeekersArr = await getJobSeekersForTag({ tagId })
            setJobSeekers(jobSeekersArr)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <div className="p-4 mb-4">
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <h3>JobSeekers for the List</h3>
            </div>

            <div className="row">
                {jobSeekers.map((jobSeeker, index) => (
                    <div className="col-md-3" key={jobSeeker.id}>
                        <JobSeekerCard index={index} jobSeeker={jobSeeker} showUtils={false} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TagJobSeekers
