import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import FeaturedJobs from '../components/carousels/FeaturedJobs'
import { setLoadingState } from '../features/loader/LoaderSlice'
import { getImmediateJobs, getFeaturedJobs } from '../api/Jobs'
import Constants from '../helpers/Constants'
import SearchForm from '../components/elements/Job/SearchForm'
import Utils from '../helpers/Utils'
import { Box, Button, Heading, Image } from '../components/vanilla'
import Pagination from '../components/Pagination'
import { useNavigate } from 'react-router-dom'

function Index() {
    // Init dispatch here
    const dispatch = useDispatch()

    // Data Vars
    const [immediateJobs, setImmediateJobs] = useState([])
    const [featuredJobs, setFeaturedJobs] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    // Search vars
    const [searchParams, setSearchParams] = useState([])
    const [location, setLocation] = useState('')
    const [salaryRange, setSalaryRange] = useState('')

    const navigate = useNavigate()

    //get page no from URL page
    let search = window.location.search
    let params = new URLSearchParams(search)
    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    //call method on click on browser back and forward arrow button
    window.onpopstate = async () => {
        try {
            setCurrentPage(pageNo)
            await getJobByPage(pageNo, searchParams, location, salaryRange)
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        populateInitData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo, currentPage])

    /** Function to populate initial data on load */
    const populateInitData = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            setCurrentPage(pageNo > 0 ? pageNo : 1)
            // Getting all the featured jobs
            const featuredJobsResponse = await getFeaturedJobs()
            setFeaturedJobs(featuredJobsResponse)

            await getJobByPage(pageNo > 0 ? pageNo : currentPage, searchParams, location, salaryRange)
        } catch (error) {
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get all the jobs */
    const getJobByPage = async (pageNumber, searchParams = '', location = '', salaryRange = '') => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Getting all the immediate jobs
            const immediateJobsResponse = await getImmediateJobs({
                page: pageNumber,
                location: location,
                salary: salaryRange,
                searchParam: searchParams
            })
            setImmediateJobs(immediateJobsResponse.jobs)
            setTotalPages(immediateJobsResponse.totalPages)
            setTotalCount(immediateJobsResponse.count)
            setCurrentPage(immediateJobsResponse.currentPage)
        } catch (error) {
            return error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change Job Page */
    const changeJobPage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigate({
                pathname: Constants.ROUTE_CLIENT_HOME,
                search: '?page=' + pageNumber
            })
            await getJobByPage(pageNumber, searchParams, location, salaryRange)
        } catch (error) {
            throw error
        }
    }

    /** Callback to handle job search */
    const searchJobCallback = async (searchParamsArr, location, salary) => {
        try {
            // Refining the searchParams
            let searchParams = ''
            if (searchParamsArr.length > 0) {
                searchParamsArr.forEach((param, index) => {
                    searchParams += `${param.value}${index + 1 !== searchParamsArr.length ? ',' : ''}`
                })
            }

            navigate({
                pathname: Constants.ROUTE_CLIENT_HOME,
                search: '?page=1'
            })

            // Page should always be 1 to re-initiate a search
            await getJobByPage(1, searchParams, location, salary)

            setSearchParams(searchParams)
            setLocation(location)
            setSalaryRange(salary)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <Box>
            {/* Highlighted Job Carousel */}
            <Box Element="section" className="py-5 text-center hero" id="featured-jobs-section">
                <FeaturedJobs jobs={featuredJobs} />
            </Box>

            {/* Search */}
            <Box Element="section">
                <Box className="container">
                    <Box className="row">
                        <Box className="col-sm-12">
                            <Box className="search-con-company">
                                <Box className="search-con">
                                    <Heading size="xl">Find Immediate Job Openings</Heading>

                                    <SearchForm callback={searchJobCallback} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Bottom */}
            <Box Element="section" className="division">
                <Box className="container-fluid">
                    <Box className="row ">
                        <Box className="col-md-8 ">
                            <Box className="left-panel ">
                                <Heading size="xl">Newest Job Openings</Heading>
                                <Box className="row">
                                    <Box className="col-sm-12">
                                        {immediateJobs.length > 0 ? (
                                            <>
                                                {immediateJobs.map((job) => (
                                                    <Box className="oppening" key={job.id}>
                                                        {job.employer.avatarPath == '' ? (
                                                            <Box className="company-initials">
                                                                {job.employer.companyName
                                                                    .split(' ')
                                                                    .map((str) => (str ? str[0].toUpperCase() : ''))
                                                                    .join('')}
                                                            </Box>
                                                        ) : (
                                                            <Image
                                                                src={job.employer.avatarPath}
                                                                className="com-logo company-logo"
                                                                alt="Company Logo"
                                                            />
                                                        )}

                                                        <Box className="oppening-con ">
                                                            <Heading size="xl">{job.positionName}</Heading>
                                                            <Heading size="md">{job.employer.companyName}</Heading>
                                                            <Box Element="p">
                                                                Location:{' '}
                                                                {`${job.city.name} ${job.isRemote ? '(Remote)' : ''} ${
                                                                    job.isContract ? '(Contract)' : ''
                                                                }`}
                                                            </Box>
                                                        </Box>
                                                        <Button
                                                            type="link"
                                                            color="success"
                                                            style={{ fontSize: '15px', padding: '1.2rem 4.3rem' }}
                                                            to={Constants.ROUTE_CLIENT_JOB_SEEKER_APPLY_JOB(job.id)}
                                                            label="Apply"
                                                        />
                                                    </Box>
                                                ))}
                                            </>
                                        ) : (
                                            <>No Jobs Found!</>
                                        )}
                                    </Box>
                                </Box>

                                {/* Pagination */}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    changePage={(page) => changeJobPage(page)}
                                    itemCount={totalCount}
                                />
                            </Box>
                        </Box>
                        <Box className="col-md-4 ">
                            <Box className="right-panel">
                                <Box className="row">
                                    <Box className="col-sm-12">
                                        <Box className="right">
                                            <Box className="card" id="set-job-alert">
                                                <i className="fas fa-business-time"></i>
                                                <Heading size="xl">SET JOB ALERT</Heading>
                                                <Box Element="p">
                                                    Set Email Notifications for jobs matching your Choice No
                                                    registration required
                                                </Box>
                                                <Button color="success" className="w-100" label="JOB ALERT" />
                                            </Box>

                                            <Box className="card" id="get-hr-support">
                                                <i className="fas fa-headset"></i>
                                                <Heading size="xl">GET HR SUPPORT</Heading>
                                                <Box Element="p">
                                                    Get Remote HR Support for your recruitment needs. Registration
                                                    required
                                                </Box>
                                                <a
                                                    target="_blank"
                                                    className="p-0 w-100"
                                                    href={
                                                        Constants.WHATSAPP_URL_CLIENT_SUPPORT +
                                                        process.env.REACT_APP_WHATSAPP_NUMBER
                                                    }
                                                    rel="noreferrer">
                                                    <Button color="success" className="w-100" label="HIRE HR" />
                                                </a>
                                            </Box>

                                            <Box className="card" id="post-free-job">
                                                <i className="fas fa-briefcase"></i>
                                                <Heading size="xl">POST FREE JOB</Heading>
                                                <Box Element="p">
                                                    Post a Free Job and Quickly get Response. Registration required{' '}
                                                </Box>
                                                <Button
                                                    type="link"
                                                    to={Constants.ROUTE_CLIENT_EMPLOYER_POST_JOB}
                                                    color="success"
                                                    className="w-100 text-center"
                                                    label="POST JOB"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Index
