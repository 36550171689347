/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateUserRating } from '../../../../api/Admin'
import { getJobSeekerProfile, updateUserProfile } from '../../../../api/Auth'
import { getResumes, uploadResume } from '../../../../api/Jobs'
import ValidationError from '../../../../errors/ValidationError'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import { getAdminType, getIsSuperAdmin } from '../../../../features/user/userSlice'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import CreatableSelect from '../../CreatableSelect'
import ReactTooltip from 'react-tooltip'
import { FaBell } from 'react-icons/fa'
import { Button } from '../../../vanilla'
import { FaKey } from 'react-icons/fa'
import DatePicker from 'react-datepicker'
import MappedSubHR from './partials/MappedSubHR'

function JobSeekerProfile({ userType }) {
    // Getting adminType
    const adminType = useSelector(getAdminType)
    const isSuperAdmin = useSelector(getIsSuperAdmin)
    // Init dispatch here
    const dispatch = useDispatch()

    // Getting the job-seeker id
    const jobSeekerId = useParams().jobSeekerId

    // Data vars here
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [tagDesignation, setTagDesignation] = useState('')
    const [tagLocation, setTagLocation] = useState('')
    const [tagExperience, setTagExperience] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [cvHeadline, setCvHeadline] = useState('')
    const [majorSkills, setMajorSkills] = useState([])
    const [minorSkills, setMinorSkills] = useState([])
    const [workHistory, setWorkHistory] = useState([])
    const [projects, setProjects] = useState([])
    const [projectLinks, setProjectLinks] = useState([])
    const [githubLinks, setGithubLinks] = useState([])
    const [education, setEducation] = useState([])
    const [certifications, setCertifications] = useState([])
    const [socialLinks, setSocialLinks] = useState([])
    const [dob, setDob] = useState('')
    const [permanentAddress, setPermanentAddress] = useState('')
    const [gender, setGender] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [category, setCategory] = useState('')
    const [languages, setLanguages] = useState([])
    const [avatarPath, setAvatarPath] = useState('')
    const [resumes, setResumes] = useState([])
    const [currentCTC, setCurrentCTC] = useState(0)
    const [expectedCTC, setExpectedCTC] = useState(0)
    const [noticePeriod, setNoticePeriod] = useState(0)
    const [preferredInterviewTime, setPreferredInterviewTime] = useState(0)
    const [rating, setRating] = useState([])
    const [ratingNum, setRatingNum] = useState(1)
    const [lastWorkDay, setLastWorkDay] = useState('')
    const [subHR, setSubHR] = useState('')

    // Work history & Projects form data
    const [companyDesignation, setCompanyDesignation] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [workingFromYear, setWorkingFromYear] = useState('')
    const [workingFromMonth, setWorkingFromMonth] = useState('')
    const [isCurrentJob, setIsCurrentJob] = useState(false)
    const [workedTillYear, setWorkedTillYear] = useState('')
    const [workedTillMonth, setWorkedTillMonth] = useState('')
    const [responsibilities, setResponsibilities] = useState('')
    const [editWorkIndex, setEditWorkIndex] = useState(0)
    // Project Link Form data
    const [projectName, setProjectName] = useState('')
    const [projectLink, setProjectLink] = useState('')
    // Education Form Data
    const [educationType, setEducationType] = useState('')
    const [course, setCourse] = useState('')
    const [specialization, setSpecialization] = useState('')
    const [universityName, setUniversityName] = useState('')
    const [passOutYear, setPassOutYear] = useState('')
    // Certification Form Data
    const [certificationName, setCertificationName] = useState('')
    // Social Link Form Data
    const [socialLink, setSocialLink] = useState('')
    // Resume upload Form Data
    const [resumeTitle, setResumeTitle] = useState('')
    const [resumeFile, setResumeFile] = useState('')
    // Misc vars
    const [editRating, setEditRating] = useState(false)

    // Loader vars
    const [updateLoading, setUpdateLoading] = useState(false)
    const [uploadResumeLoading, setUploadResumeLoading] = useState(false)

    const [editType, setEditType] = useState(Constants.TYPE_UPDATE_BASIC_DETAILS)

    const btnStyle = { fontSize: '13px' }

    useEffect(() => {
        populateInitData()
    }, [])

    /** Function to populate initial data */
    const populateInitData = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // get and set profile data
            await getUserProfile()
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get user profile and set */
    const getUserProfile = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling api to get user profile
            const response = await getJobSeekerProfile(userType, jobSeekerId)

            // Getting user resumes
            const resumeResponse = await getResumes(userType, jobSeekerId)
            setResumes(resumeResponse)

            // Setting the data here
            setFirstName(response.firstName)
            setMiddleName(response.middleName)
            setLastName(response.lastName)
            setSubHR(response.subHR)
            setTagDesignation(response.profile.tagDesignation)
            setTagLocation(response.profile.tagLocation)
            setTagExperience(response.profile.tagExperience)
            setCurrentCTC(response.profile.currentCTC)
            setExpectedCTC(response.profile.ExpectedCTC)
            setNoticePeriod(response.profile.noticePeriod)
            setPreferredInterviewTime(response.profile.preferredInterviewTime)
            let lastWorkingDate = ''
            if (response.profile.lastWorkDay !== '') {
                const parsedDate = Date.parse(response.profile.lastWorkDay)
                lastWorkingDate = new Date(parsedDate)
            }
            setLastWorkDay(lastWorkingDate)
            setPhoneNumber(response.phoneNumber)
            setEmail(response.email)
            setCvHeadline(response.profile.cvHeadline)
            let majorSkillArr = []
            response.profile.majorSkills.forEach((skill) => {
                majorSkillArr.push({ label: skill, value: skill })
            })
            setMajorSkills(majorSkillArr)
            let minorSkillArr = []
            response.profile.minorSkills.forEach((skill) => {
                minorSkillArr.push({ label: skill, value: skill })
            })
            setMinorSkills(minorSkillArr)
            setWorkHistory(response.profile.workHistory)
            setProjects(response.profile.projects)
            setProjectLinks(response.profile.projectLinks)
            setGithubLinks(response.profile.githubLinks)
            setEducation(response.profile.education)
            setCertifications(response.profile.certifications)
            setSocialLinks(response.profile.socialLinks)
            setDob(response.profile.dob)
            setPermanentAddress(response.profile.permanentAddress)
            setGender(response.profile.gender)
            setMaritalStatus(response.profile.maritalStatus)
            setCategory(response.profile.category)
            let languagesArr = []
            response.profile.languages.forEach((language) => {
                languagesArr.push({ label: language, value: language })
            })
            setLanguages(languagesArr)
            setAvatarPath(response.avatarPath)
            setRatingNum(response.profile.rating)

            const ratingNumber = response.profile.rating
            let ratingArr = []
            // Based on rating number, setting the rating array
            for (let i = 0; i < ratingNumber; i++) ratingArr.push(1)
            let remainingRating = 5 - ratingNumber
            for (let j = 0; j < remainingRating; j++) ratingArr.push(0)

            setRating(ratingArr)

            // Resetting the work history/projects form
            setCompanyDesignation('')
            setCompanyName('')
            setWorkingFromYear('')
            setWorkingFromMonth('')
            setWorkedTillYear('')
            setWorkedTillMonth('')
            setResponsibilities('')
            setIsCurrentJob(false)
            setEditWorkIndex(0)
            // Resetting the project link form
            setProjectName('')
            setProjectLink('')
            // Resetting Education here
            setEducationType('')
            setCourse('')
            setSpecialization('')
            setUniversityName('')
            setPassOutYear('')
            // Resetting resume form
            setResumeFile('')
            setResumeTitle('')
        } catch (error) {
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to reset user profile */
    const resetUserProfile = async () => {
        try {
            await getUserProfile()
        } catch (error) {}
    }

    /** Function to update basic details */
    const updateProfileDetails = async () => {
        try {
            // Setting the loading here
            setUpdateLoading(true)
            // Based on the type validating if required
            let workHistoryArr = [...workHistory]
            let projectsArr = [...projects]
            let projectLinksArr = [...projectLinks]
            let educationArr = [...education]
            let certificationsArr = [...certifications]
            let socialLinksArr = [...socialLinks]
            switch (editType) {
                case Constants.TYPE_UPDATE_BASIC_DETAILS:
                    break
                case Constants.TYPE_UPDATE_CV_HEADLINE:
                    break
                case Constants.TYPE_UPDATE_MAJOR_SKILLS:
                    break
                case Constants.TYPE_UPDATE_MINOR_SKILLS:
                    break
                case Constants.TYPE_ADD_WORK_HISTORY:
                    validateWorkHistory()

                    // creating the object and pushing
                    workHistoryArr.push({
                        companyDesignation,
                        companyName,
                        workingFromYear,
                        workingFromMonth,
                        workedTillYear,
                        workedTillMonth,
                        isCurrentJob,
                        responsibilities
                    })

                    break
                case Constants.TYPE_EDIT_WORK_HISTORY:
                    // Validating the data first
                    validateWorkHistory()

                    // Setting new data
                    workHistoryArr[editWorkIndex].companyDesignation = companyDesignation
                    workHistoryArr[editWorkIndex].companyName = companyName
                    workHistoryArr[editWorkIndex].workingFromYear = workingFromYear
                    workHistoryArr[editWorkIndex].workingFromMonth = workingFromMonth
                    workHistoryArr[editWorkIndex].workedTillYear = workedTillYear
                    workHistoryArr[editWorkIndex].workedTillMonth = workedTillMonth
                    workHistoryArr[editWorkIndex].isCurrentJob = isCurrentJob
                    workHistoryArr[editWorkIndex].responsibilities = responsibilities
                    break
                case Constants.TYPE_ADD_PROJECTS:
                    validateWorkHistory(Constants.TYPE_ADD_PROJECTS)

                    projectsArr.unshift({
                        projectTitle: companyDesignation,
                        associationName: companyName,
                        workingFromYear,
                        workingFromMonth,
                        workedTillYear,
                        workedTillMonth,
                        isCurrentlyInProgress: isCurrentJob,
                        details: responsibilities
                    })
                    break
                case Constants.TYPE_EDIT_PROJECTS:
                    validateWorkHistory(Constants.TYPE_ADD_PROJECTS)

                    projectsArr[editWorkIndex].projectTitle = companyDesignation
                    projectsArr[editWorkIndex].associationName = companyName
                    projectsArr[editWorkIndex].workingFromYear = workingFromYear
                    projectsArr[editWorkIndex].workingFromMonth = workingFromMonth
                    projectsArr[editWorkIndex].workedTillYear = workedTillYear
                    projectsArr[editWorkIndex].workedTillMonth = workedTillMonth
                    projectsArr[editWorkIndex].isCurrentlyInProgress = isCurrentJob
                    projectsArr[editWorkIndex].details = responsibilities
                    break
                case Constants.TYPE_ADD_PROJECT_LINKS:
                    validateProjectLinks()

                    projectLinksArr.unshift({
                        projectName,
                        projectLink
                    })
                    break
                case Constants.TYPE_EDIT_PROJECT_LINKS:
                    validateProjectLinks()

                    projectLinksArr[editWorkIndex].projectName = projectName
                    projectLinksArr[editWorkIndex].projectLink = projectLink
                    break
                case Constants.TYPE_ADD_EDUCATION:
                    validateEducation()

                    educationArr.push({
                        educationType,
                        course,
                        specialization,
                        universityName,
                        passOutYear
                    })
                    break
                case Constants.TYPE_EDIT_EDUCATION:
                    validateEducation()
                    educationArr[editWorkIndex].educationType = educationType
                    educationArr[editWorkIndex].course = course
                    educationArr[editWorkIndex].specialization = specialization
                    educationArr[editWorkIndex].universityName = universityName
                    educationArr[editWorkIndex].passOutYear = passOutYear
                    break
                case Constants.TYPE_ADD_CERTIFICATION:
                    // Validating data
                    if (certificationName == '') throw new ValidationError(Constants.MSG_EMPTY_CERTIFICATION_NAME)

                    certificationsArr.push({
                        name: certificationName
                    })
                    break
                case Constants.TYPE_EDIT_CERTIFICATION:
                    // Validating data
                    if (certificationName == '') throw new ValidationError(Constants.MSG_EMPTY_CERTIFICATION_NAME)
                    certificationsArr[editWorkIndex].name = certificationName
                    break
                case Constants.TYPE_ADD_SOCIAL_LINK:
                    // Validating data
                    if (socialLink == '') throw new ValidationError(Constants.MSG_EMPTY_SOCIAL_LINK)

                    socialLinksArr.push(socialLink)
                    break
                case Constants.TYPE_EDIT_SOCIAL_LINK:
                    // Validating data
                    if (socialLink == '') throw new ValidationError(Constants.MSG_EMPTY_SOCIAL_LINK)
                    socialLinksArr[editWorkIndex] = socialLink
                    break
            }

            // Re-transform the array
            let majorSkillArr = []
            let minorSkillsArr = []
            let languagesArr = []
            majorSkills.forEach((skill) => {
                majorSkillArr.push(skill.value)
            })
            minorSkills.forEach((skill) => {
                minorSkillsArr.push(skill.value)
            })
            languages.forEach((language) => {
                languagesArr.push(language.value)
            })

            // Updating the Profile here
            await updateUserProfile(
                userType,
                {
                    tagDesignation,
                    tagLocation,
                    tagExperience,
                    cvHeadline,
                    majorSkills: majorSkillArr,
                    minorSkills: minorSkillsArr,
                    workHistory: workHistoryArr,
                    projects: projectsArr,
                    projectLinks: projectLinksArr,
                    githubLinks,
                    education: educationArr,
                    certifications: certificationsArr,
                    socialLinks: socialLinksArr,
                    dob,
                    permanentAddress,
                    gender,
                    maritalStatus,
                    category,
                    languages: languagesArr,
                    currentCTC: currentCTC.toString(),
                    ExpectedCTC: expectedCTC.toString(),
                    noticePeriod: noticePeriod.toString(),
                    preferredInterviewTime: preferredInterviewTime.toString(),
                    lastWorkDay:
                        lastWorkDay !== '' && lastWorkDay !== null ? moment(lastWorkDay).format('MM-DD-YYYY') : ''
                },
                jobSeekerId
            )

            Utils.showToast(Constants.MSG_PROFILE_UPDATE_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
            // Closing the modal
            Utils.closeItem('btn-close-edit')
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            setUpdateLoading(false)
        }
    }

    /** Function to validate work history form (add/edit) */
    const validateWorkHistory = (type = Constants.TYPE_ADD_WORK_HISTORY) => {
        try {
            // Validating the data
            if (companyDesignation == '') {
                if (type == Constants.TYPE_ADD_WORK_HISTORY)
                    throw new ValidationError(Constants.MSG_EMPTY_COMPANY_DESIGNATION)
                else if (type == Constants.TYPE_ADD_PROJECTS)
                    throw new ValidationError(Constants.MSG_EMPTY_PROJECT_TITLE)
            }
            if (companyName == '') throw new ValidationError(Constants.MSG_EMPTY_COMPANY_NAME)
            if (workingFromYear == '') throw new ValidationError(Constants.MSG_EMPTY_WORKING_FROM_YEAR)
            if (workingFromMonth == '') throw new ValidationError(Constants.MSG_EMPTY_WORKING_FROM_MONTH)
            if (!isCurrentJob) {
                if (workedTillYear == '') throw new ValidationError(Constants.MSG_EMPTY_WORKING_TO_YEAR)
                if (workedTillMonth == '') throw new ValidationError(Constants.MSG_EMPTY_WORKING_TO_MONTH)
                const fromDate = moment(`${workingFromYear}-${workingFromMonth}-01`, 'YYYY-MM-DD')
                const toDate = moment(`${workedTillYear}-${workedTillMonth}-01`, 'YYYY-MM-DD')
                if (fromDate > toDate) throw new ValidationError(Constants.MSG_FROM_DATE_BIGGER)
            }
            if (responsibilities == '') {
                if (type == Constants.TYPE_ADD_WORK_HISTORY)
                    throw new ValidationError(Constants.MSG_EMPTY_RESPONSIBILITIES)
                else if (type == Constants.TYPE_ADD_PROJECTS)
                    throw new ValidationError(Constants.MSG_EMPTY_PROJ_DETAILS)
            }
        } catch (error) {
            throw error
        }
    }

    /** Function to validate project Links */
    const validateProjectLinks = () => {
        try {
            if (projectName == '') throw new ValidationError(Constants.MSG_EMPTY_PROJECT_TITLE)
            if (projectLink == '') throw new ValidationError(Constants.MSG_EMPTY_PROJ_LINK)
        } catch (error) {
            throw error
        }
    }

    /** Function to validate education details */
    const validateEducation = () => {
        try {
            if (educationType == '') throw new ValidationError(Constants.MSG_EMPTY_EDUCATION_TYPE)
            if (course == '') throw new ValidationError(Constants.MSG_EMPTY_COURSE)
            if (specialization == '') throw new ValidationError(Constants.MSG_EMPTY_SPECIALIZATION)
            if (universityName == '') throw new ValidationError(Constants.MSG_EMPTY_UNIV_NAME)
            if (passOutYear == '') throw new ValidationError(Constants.MSG_EMPTY_PASS_YEAR)
        } catch (error) {
            throw error
        }
    }

    /** Function to handle major skills callback */
    const majorSkillsCallback = (skills) => {
        setMajorSkills(skills)
    }

    /** Function to handle minor skills callback */
    const minorSkillsCallback = (skills) => {
        setMinorSkills(skills)
    }

    /** Function to handle languages callback */
    const languagesCallback = (language) => {
        setLanguages(language)
    }

    /** Function to set edit work history */
    const setEditWorkHistory = (index, type = Constants.TYPE_EDIT_WORK_HISTORY) => {
        setEditType(type)
        setEditWorkIndex(index)

        // Getting the data of work
        let values
        switch (type) {
            case Constants.TYPE_EDIT_WORK_HISTORY:
                values = [...workHistory][index]
                setCompanyDesignation(values.companyDesignation)
                setCompanyName(values.companyName)
                setWorkingFromYear(values.workingFromYear)
                setWorkingFromMonth(values.workingFromMonth)
                setIsCurrentJob(values.isCurrentJob)
                setResponsibilities(values.responsibilities)
                if (!values.isCurrentJob) {
                    setWorkedTillYear(values.workedTillYear)
                    setWorkedTillMonth(values.workedTillMonth)
                }
                break

            case Constants.TYPE_EDIT_PROJECTS:
                values = [...projects][index]
                setCompanyDesignation(values.projectTitle)
                setCompanyName(values.associationName)
                setWorkingFromYear(values.workingFromYear)
                setWorkingFromMonth(values.workingFromMonth)
                setIsCurrentJob(values.isCurrentlyInProgress)
                setResponsibilities(values.details)
                if (!values.isCurrentlyInProgress) {
                    setWorkedTillYear(values.workedTillYear)
                    setWorkedTillMonth(values.workedTillMonth)
                }
                break

            case Constants.TYPE_EDIT_PROJECT_LINKS:
                values = [...projectLinks][index]
                setProjectName(values.projectName)
                setProjectLink(values.projectLink)
                break

            case Constants.TYPE_EDIT_EDUCATION:
                values = [...education][index]
                setEducationType(values.educationType)
                setCourse(values.course)
                setSpecialization(values.specialization)
                setUniversityName(values.universityName)
                setPassOutYear(values.passOutYear)
                break

            case Constants.TYPE_EDIT_CERTIFICATION:
                values = [...certifications][index]
                setCertificationName(values.name)
                break

            case Constants.TYPE_EDIT_SOCIAL_LINK:
                values = [...socialLinks][index]
                setSocialLink(values)
                break
        }
    }

    /** Function to handle resume Upload */
    const handleUploadResume = async (e) => {
        // Setting the loading here
        setUploadResumeLoading(true)
        try {
            e.preventDefault()
            // Validating
            if (resumeTitle == '') throw new ValidationError(Constants.MSG_EMPTY_RESUME_TITLE)
            if (resumeFile == '') throw new ValidationError(Constants.MSG_EMPTY_RESUME)

            // Create multipart/formData
            const data = new FormData()
            data.append('resumeTitle', resumeTitle)
            data.append('resumeFile', resumeFile)

            // Uploading the resume
            await uploadResume(data, userType, jobSeekerId)

            await getUserProfile()
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            setUploadResumeLoading(false)
        }
    }

    /** Function to update user profile rating */
    const updateUserProfileRating = async (e) => {
        try {
            e.preventDefault()

            // Validating Data
            if (ratingNum == '') throw new ValidationError(Constants.MSG_INVALID_RATING)

            await updateUserRating(jobSeekerId, ratingNum)
            await getUserProfile()
            setEditRating(false)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
        }
    }

    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="card unick-box py-4 px-4 fs-16  text-dark br-5 mt-1-5">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="p-abso">
                                                    <div className="p-abso-edit">
                                                        <a href="#">Edit</a>
                                                    </div>
                                                    <img
                                                        src={`${
                                                            avatarPath != ''
                                                                ? avatarPath
                                                                : Constants.DEFAULT_PATH_JOB_SEEKER_AVATAR
                                                        }`}
                                                        alt=""
                                                        className="img-fluid img-thumbnail "
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="card p-3 pr-mi-hi">
                                                    <h3 className="fw-bold d-flex align-items-center justify-content-between">
                                                        {`${firstName} ${middleName} ${lastName}`}
                                                        <span className="fs-16">
                                                            <a
                                                                href="#"
                                                                onClick={() =>
                                                                    setEditType(Constants.TYPE_UPDATE_BASIC_DETAILS)
                                                                }
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#tagModal"
                                                                className="text-decoration-none">
                                                                Edit
                                                            </a>
                                                        </span>
                                                    </h3>
                                                    <p className="pb-0 mb-0">
                                                        <b>Designation:</b>{' '}
                                                        {tagDesignation != '' ? tagDesignation : 'No Designation'}
                                                        <br />
                                                        <b>Location:</b>{' '}
                                                        {tagLocation != '' ? tagLocation : 'No Location'}
                                                        <br />
                                                        <b>Experinece:</b>{' '}
                                                        {tagExperience != ''
                                                            ? tagExperience + ' Year(s)'
                                                            : 'No Experience'}
                                                        <br />
                                                        <b>Preferred Date/Time:</b>{' '}
                                                        {preferredInterviewTime != '' ? preferredInterviewTime : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="card p-3 pr-mi-hi">
                                                    <h3 className="fw-bold d-flex align-items-center justify-content-between">
                                                        {phoneNumber != '' ? phoneNumber : 'No Phone Number'}
                                                    </h3>
                                                    <p>
                                                        <a href={`mailto:${email}`} className="text-decoration-none">
                                                            {email != '' ? email : 'No Email'}
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <b>Current / Expected CTC:</b>{' '}
                                                        {`${currentCTC ? currentCTC : 0} / ${
                                                            expectedCTC ? expectedCTC : 0
                                                        }`}{' '}
                                                        LPA
                                                        <br />
                                                        <b>Notice Period:</b> {noticePeriod ? noticePeriod : 0} days
                                                        <br />
                                                        <b>Last Working Day:</b>{' '}
                                                        {lastWorkDay !== ''
                                                            ? moment(lastWorkDay).format('DD-MM-YYYY')
                                                            : 'NA'}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 ">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="card p-3 pr-mi-hi-sm">
                                                            <p className="mb-0">
                                                                <b>Profile Rating</b> <br />
                                                                {rating.map((num, index) => (
                                                                    <span
                                                                        key={index}
                                                                        className={`fa fa-star ${
                                                                            num == 1 ? 'text-warning' : ''
                                                                        }`}></span>
                                                                ))}{' '}
                                                                {adminType == Constants.TYPE_USER_ADMIN_MAIN && (
                                                                    <>
                                                                        {!editRating ? (
                                                                            <a
                                                                                href="#"
                                                                                onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    setEditRating(true)
                                                                                }}
                                                                                className="text-decoration-none">
                                                                                <i className="fas fa-edit"></i> Edit
                                                                            </a>
                                                                        ) : (
                                                                            <>
                                                                                <br />
                                                                                <select
                                                                                    className="me-2"
                                                                                    value={ratingNum}
                                                                                    onChange={(e) =>
                                                                                        setRatingNum(e.target.value)
                                                                                    }>
                                                                                    <option value="">
                                                                                        Select Rating
                                                                                    </option>
                                                                                    <option>1</option>
                                                                                    <option>2</option>
                                                                                    <option>3</option>
                                                                                    <option>4</option>
                                                                                    <option>5</option>
                                                                                </select>
                                                                                <button
                                                                                    onClick={(e) =>
                                                                                        updateUserProfileRating(e)
                                                                                    }>
                                                                                    Update Rating
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    {adminType === Constants.TYPE_USER_ADMIN_MAIN && isSuperAdmin && (
                                                        <MappedSubHR
                                                            subHR={subHR}
                                                            jobSeekerId={jobSeekerId}
                                                            onMap={() => getUserProfile()}
                                                        />
                                                    )}

                                                    <div className="col-sm-12">
                                                        {userType === Constants.TYPE_USER_JOB_SEEKER && (
                                                            <Button
                                                                type="link"
                                                                className="mt-3"
                                                                to={Constants.ROUTE_CLIENT_JOB_SEEKER_CHANGE_PWD}
                                                                icon={<FaKey style={{ fontSize: '17px' }} />}
                                                                label="Change Password"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="card p-3 mt-1-5 pro-card-shadow">
                                <h3 className="fw-bold d-flex align-items-center justify-content-between pro-head">
                                    Quick Access
                                </h3>

                                <ul className="list-unstyled mt-1-5">
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Attach CV </p>
                                        <span>
                                            <a href="#" className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>CV Headline </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_UPDATE_CV_HEADLINE)}
                                                className="link-primary text-decoration-none">
                                                UPDATE
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Major Skills </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_UPDATE_MAJOR_SKILLS)}
                                                className="link-primary text-decoration-none">
                                                UPDATE
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Minor Skills </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_UPDATE_MINOR_SKILLS)}
                                                className="link-primary text-decoration-none">
                                                UPDATE
                                            </a>
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-unstyled mt-1-5">
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Work History </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_ADD_WORK_HISTORY)}
                                                className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Projects </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_ADD_PROJECTS)}
                                                className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Project Links </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_ADD_PROJECT_LINKS)}
                                                className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-unstyled mt-1-5">
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Education </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_ADD_EDUCATION)}
                                                className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Certifications </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_ADD_CERTIFICATION)}
                                                className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                </ul>

                                <ul className="list-unstyled mt-1-5">
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Personal Details </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_EDIT_PERSONAL_DETAILS)}
                                                className="link-primary text-decoration-none">
                                                UPDATE
                                            </a>
                                        </span>
                                    </li>
                                    <li className=" d-flex align-items-center justify-content-between">
                                        <p>Social Links </p>
                                        <span>
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#tagModal"
                                                onClick={() => setEditType(Constants.TYPE_ADD_SOCIAL_LINK)}
                                                className="link-primary text-decoration-none">
                                                ADD
                                            </a>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-9">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">ATTACH CV :</p>
                                        </div>
                                        {/* Resume Item */}
                                        {resumes.length != 0 && (
                                            <>
                                                {resumes.map((resume, index) => (
                                                    <div
                                                        className="border p-3 d-flex justify-content-between align-items-center mb-3"
                                                        key={resume.id}>
                                                        <div>{resume.resumeTitle}</div>
                                                        <div>
                                                            <a href={resume.resumePath} target="_blank">
                                                                <i className="fas fa-cloud-download-alt"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}

                                                <hr />
                                            </>
                                        )}

                                        {/* Upload Resume */}
                                        <label className="form-label mt-3">Add Resume</label>
                                        <div className="row">
                                            <div className="col-md-4 ps-0 mt-3 mt-md-0">
                                                <input
                                                    type="text"
                                                    value={resumeTitle}
                                                    onChange={(e) => setResumeTitle(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Enter Resume Title"
                                                />
                                            </div>
                                            <div className="col-md-4 ps-0 mt-3 mt-md-0">
                                                <input
                                                    type="file"
                                                    onChange={(e) => setResumeFile(e.target.files[0])}
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="col-md-4 ps-0 mt-3 mt-md-0">
                                                <Button
                                                    color="warning"
                                                    className="w-100 py-2"
                                                    style={{ fontSize: '16px' }}
                                                    onClick={(e) => handleUploadResume(e)}
                                                    disabled={uploadResumeLoading}
                                                    label={
                                                        uploadResumeLoading ? (
                                                            <>
                                                                <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                                Uploading...
                                                            </>
                                                        ) : (
                                                            <>Upload</>
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">CV HEADLINE : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        href="#"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        onClick={() => setEditType(Constants.TYPE_UPDATE_CV_HEADLINE)}
                                                        className="text-decoration-none ">
                                                        ADD/UPDATE
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>{cvHeadline != '' ? cvHeadline : 'No Headline'}</p>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">MAJOR SKILLS : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        href="#"
                                                        className="text-decoration-none"
                                                        onClick={() => setEditType(Constants.TYPE_UPDATE_MAJOR_SKILLS)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        role="button">
                                                        ADD/UPDATE
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {majorSkills.length != 0 ? (
                                            <>
                                                <ul className="list-inline  mx-2">
                                                    {majorSkills.map((skill, index) => (
                                                        <li className="list-inline-item li-badge" key={index}>
                                                            {skill.value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <>No Skills</>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">MINOR SKILLS : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        className="text-decoration-none"
                                                        onClick={() => setEditType(Constants.TYPE_UPDATE_MINOR_SKILLS)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        href="#"
                                                        role="button">
                                                        ADD/UPDATE
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {minorSkills.length != 0 ? (
                                            <>
                                                <ul className="list-inline  mx-2">
                                                    {minorSkills.map((skill, index) => (
                                                        <li className="list-inline-item li-badge" key={index}>
                                                            {skill.value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <>No Skills</>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">WORK HISTORY : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        className="text-decoration-none"
                                                        onClick={() => setEditType(Constants.TYPE_ADD_WORK_HISTORY)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        href="#"
                                                        role="button">
                                                        ADD
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {workHistory.length != 0 ? (
                                            <>
                                                {workHistory.map((work, index) => (
                                                    <div className="work-history d-block mb-4" key={index}>
                                                        <p>
                                                            <span className="fw-bolder designation">
                                                                {work.companyDesignation}{' '}
                                                                <a
                                                                    href="#"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#tagModal"
                                                                    onClick={() =>
                                                                        setEditWorkHistory(
                                                                            index,
                                                                            Constants.TYPE_EDIT_WORK_HISTORY
                                                                        )
                                                                    }
                                                                    className="text-decoration-none">
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </span>
                                                            <br />
                                                            <span className="fw-bolder">{work.companyName}</span>
                                                            <br />
                                                            <span className="fst-italic text-secondary">{`${
                                                                work.workingFromMonth
                                                            }/${work.workingFromYear} - ${
                                                                work.isCurrentJob
                                                                    ? 'Present'
                                                                    : `${work.workedTillMonth}/${work.workedTillYear}`
                                                            }`}</span>
                                                        </p>
                                                        <p>{work.responsibilities}</p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>No Work History</>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">PROJECTS : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        className="text-decoration-none"
                                                        onClick={() => setEditType(Constants.TYPE_ADD_PROJECTS)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        href="#"
                                                        role="button">
                                                        ADD
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {projects.length != 0 ? (
                                            <>
                                                {projects.map((project, index) => (
                                                    <div className="work-history d-block mb-4" key={index}>
                                                        <ul className="list-inline ms-0 ps-0 mx-2">
                                                            <li className="list-inline-item ">
                                                                {project.projectTitle}{' '}
                                                                <a
                                                                    href="#"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#tagModal"
                                                                    onClick={() =>
                                                                        setEditWorkHistory(
                                                                            index,
                                                                            Constants.TYPE_EDIT_PROJECTS
                                                                        )
                                                                    }
                                                                    className="text-decoration-none">
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            <span className="fw-bolder">{project.associationName}</span>
                                                            <br />
                                                            <span className="fst-italic text-secondary">{`${
                                                                project.workingFromMonth
                                                            }/${project.workingFromYear} - ${
                                                                project.isCurrentlyInProgress
                                                                    ? 'Present'
                                                                    : `${project.workedTillMonth}/${project.workedTillYear}`
                                                            }`}</span>
                                                        </p>
                                                        <p>{project.details}</p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>No Projects</>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">PROJECTS LINKS : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        className="text-decoration-none"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        onClick={() => setEditType(Constants.TYPE_ADD_PROJECT_LINKS)}
                                                        href="#"
                                                        role="button">
                                                        ADD
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {projectLinks.length != 0 ? (
                                            <>
                                                {projectLinks.map((project, index) => (
                                                    <div className="work-history d-block mb-4" key={index}>
                                                        <ul className="list-inline ms-0 ps-0 mx-2">
                                                            <li className="list-inline-item">
                                                                {project.projectName}{' '}
                                                                <a
                                                                    href="#"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#tagModal"
                                                                    onClick={() =>
                                                                        setEditWorkHistory(
                                                                            index,
                                                                            Constants.TYPE_EDIT_PROJECT_LINKS
                                                                        )
                                                                    }
                                                                    className="text-decoration-none">
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <p>
                                                            <a
                                                                href={project.projectLink}
                                                                target="_blank"
                                                                className="text-decoration-none "
                                                                rel="noreferrer">
                                                                {project.projectLink}
                                                            </a>
                                                        </p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>No Project Links</>
                                        )}
                                    </div>
                                </div>

                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="d-flex">
                                            <p className="fw-bold">EDUCATION : </p>
                                            <ul className="list-inline  link-list mx-2">
                                                <li className="list-inline-item border-right">
                                                    <a
                                                        className="text-decoration-none"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        href="#"
                                                        onClick={() => setEditType(Constants.TYPE_ADD_EDUCATION)}
                                                        role="button">
                                                        ADD
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {education.length != 0 ? (
                                            <>
                                                {education.map((educationItem, index) => (
                                                    <div className="work-history d-block" key={index}>
                                                        <p className="fw-bolder">
                                                            <span className="fw-normal">
                                                                {educationItem.educationType}
                                                            </span>{' '}
                                                            <a
                                                                href="#"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#tagModal"
                                                                onClick={() =>
                                                                    setEditWorkHistory(
                                                                        index,
                                                                        Constants.TYPE_EDIT_EDUCATION
                                                                    )
                                                                }
                                                                className="text-decoration-none">
                                                                <i className="far fa-edit"></i>
                                                            </a>
                                                            <br />
                                                            {`${educationItem.course} - ${educationItem.specialization}`}
                                                            <br />
                                                            <span className="fw-normal">
                                                                {educationItem.universityName}
                                                            </span>
                                                        </p>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>No Education details</>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="work-history d-block">
                                            <ul className="list-inline ms-0 ps-0 mx-2">
                                                <li className="list-inline-item fw-bold ">CERTIFICATIONS :</li>
                                                <li className="list-inline-item ">
                                                    <a
                                                        className="text-decoration-none "
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        onClick={() => setEditType(Constants.TYPE_ADD_CERTIFICATION)}
                                                        role="button">
                                                        ADD
                                                    </a>
                                                </li>
                                            </ul>

                                            {certifications.length != 0 ? (
                                                <>
                                                    <ul className="list-unstyled">
                                                        {certifications.map((certification, index) => (
                                                            <li key={index}>
                                                                {certification.name}{' '}
                                                                <a
                                                                    href="#"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#tagModal"
                                                                    onClick={() =>
                                                                        setEditWorkHistory(
                                                                            index,
                                                                            Constants.TYPE_EDIT_CERTIFICATION
                                                                        )
                                                                    }
                                                                    className="text-decoration-none">
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>No Certifications</>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="work-history d-block">
                                            <ul className="list-inline ms-0 ps-0 mx-2">
                                                <li className="list-inline-item fw-bold">PERSONAL DETAILS :</li>
                                                <li className="list-inline-item ">
                                                    <a
                                                        className="text-decoration-none"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        href="#"
                                                        onClick={() =>
                                                            setEditType(Constants.TYPE_EDIT_PERSONAL_DETAILS)
                                                        }
                                                        role="button">
                                                        UPDATE
                                                    </a>
                                                </li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Date of Birth</li>
                                                <li>{dob != '' ? dob : 'NA'}</li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Pemanent Address</li>
                                                <li>{permanentAddress != '' ? permanentAddress : 'NA'}</li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Gender</li>
                                                <li>{gender != '' ? gender : 'NA'}</li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Marital Status</li>
                                                <li>{maritalStatus != '' ? maritalStatus : 'NA'}</li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Category</li>
                                                <li>{category != '' ? category : 'NA'}</li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>
                                                    Languages:{' '}
                                                    {languages.length != 0 ? (
                                                        <>
                                                            {languages.map((language, index) => (
                                                                <span key={language.value}>{`${language.value}${
                                                                    index + 1 != languages.length ? ', ' : ''
                                                                }`}</span>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>NA</>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 pb-5">
                                    <div className="card p-3 mt-1-5 pro-card-shadow fs-16">
                                        <div className="work-history d-block">
                                            <ul className="list-inline ms-0 ps-0 mx-2">
                                                <li className="list-inline-item fw-bold">SOCIAL LINKS :</li>
                                                <li className="list-inline-item ">
                                                    <a
                                                        className="text-decoration-none"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#tagModal"
                                                        onClick={() => setEditType(Constants.TYPE_ADD_SOCIAL_LINK)}
                                                        role="button">
                                                        ADD/UPDATE
                                                    </a>
                                                </li>
                                            </ul>

                                            {socialLinks.length != 0 ? (
                                                <>
                                                    <ul className="list-unstyled">
                                                        {socialLinks.map((link, index) => (
                                                            <li className="pt-2" key={index}>
                                                                <a href={`${link}`} target="_blank">
                                                                    {link}
                                                                </a>{' '}
                                                                <a
                                                                    href="#"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#tagModal"
                                                                    onClick={() =>
                                                                        setEditWorkHistory(
                                                                            index,
                                                                            Constants.TYPE_EDIT_SOCIAL_LINK
                                                                        )
                                                                    }
                                                                    className="text-decoration-none">
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>No social links</>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="back-modal" id="edit-modals">
                {/* Edit Profile Details Modal */}
                <div
                    className="modal fade"
                    id="tagModal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Update Profile</h5>
                                <button
                                    onClick={() => resetUserProfile()}
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {/* Basic Details Portion */}
                                {editType == Constants.TYPE_UPDATE_BASIC_DETAILS && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="tagDesignation" className="form-label">
                                                Designation
                                            </label>
                                            <input
                                                value={tagDesignation}
                                                onChange={(e) => setTagDesignation(e.target.value)}
                                                type="text"
                                                placeholder="Enter Designation"
                                                className="form-control"
                                                id="tagDesignation"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="tagLocation" className="form-label">
                                                Current Location
                                            </label>
                                            <input
                                                value={tagLocation}
                                                onChange={(e) => setTagLocation(e.target.value)}
                                                type="text"
                                                placeholder="Enter Current Location"
                                                className="form-control"
                                                id="tagLocation"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="tagExperience" className="form-label">
                                                Total Experience
                                            </label>
                                            <input
                                                value={tagExperience}
                                                onChange={(e) => setTagExperience(e.target.value)}
                                                type="number"
                                                placeholder="Enter Total experience (in year/s)"
                                                className="form-control"
                                                id="tagExperience"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="currentCTC" className="form-label">
                                                Current CTC
                                            </label>
                                            <input
                                                value={currentCTC ? currentCTC : 0}
                                                onChange={(e) => setCurrentCTC(e.target.value)}
                                                type="number"
                                                placeholder="Enter your current CTC"
                                                className="form-control"
                                                id="currentCTC"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="expectedCTC" className="form-label">
                                                Expected CTC
                                            </label>
                                            <input
                                                value={expectedCTC ? expectedCTC : 0}
                                                onChange={(e) => setExpectedCTC(e.target.value)}
                                                type="number"
                                                placeholder="Enter your expected CTC"
                                                className="form-control"
                                                id="expectedCTC"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="noticePeriod" className="form-label">
                                                Notice Period (in days)
                                            </label>
                                            <input
                                                value={noticePeriod ? noticePeriod : 0}
                                                onChange={(e) => setNoticePeriod(e.target.value)}
                                                type="number"
                                                placeholder="Enter current notice period (in days)"
                                                className="form-control"
                                                id="noticePeriod"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="preferredInterviewTime" className="form-label">
                                                Preferred Date/Time{' '}
                                                <span data-tip data-for="prefDateTooltip">
                                                    <FaBell />
                                                </span>
                                            </label>
                                            <input
                                                value={preferredInterviewTime ? preferredInterviewTime : ''}
                                                onChange={(e) => setPreferredInterviewTime(e.target.value)}
                                                type="text"
                                                placeholder="Enter your Preferred Date/Time"
                                                className="form-control"
                                                id="preferredInterviewTime"
                                                maxLength="15"
                                            />
                                            <ReactTooltip id="prefDateTooltip" place="top" effect="solid">
                                                Maximum 15 character are allowed.
                                            </ReactTooltip>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="lastWorkDay" className="form-label">
                                                Last Working Day
                                            </label>
                                            <DatePicker
                                                selected={lastWorkDay}
                                                className="form-control"
                                                isClearable
                                                withPortal
                                                minDate={new Date()}
                                                placeholderText="Select Last Working Date"
                                                onChange={(date) => setLastWorkDay(date)}
                                            />
                                        </div>
                                    </>
                                )}

                                {/* Edit CV Headline */}
                                {editType == Constants.TYPE_UPDATE_CV_HEADLINE && (
                                    <>
                                        <div className="mb-3">
                                            <label htmlFor="tagDesignation" className="form-label">
                                                CV Headline
                                            </label>
                                            <textarea
                                                placeholder="Enter CV Headline"
                                                value={cvHeadline}
                                                onChange={(e) => setCvHeadline(e.target.value)}
                                                className="form-control"></textarea>
                                        </div>
                                    </>
                                )}

                                {/* Major Skills */}
                                {editType == Constants.TYPE_UPDATE_MAJOR_SKILLS && (
                                    <>
                                        <label htmlFor="majorSkillsUpdate" className="form-label">
                                            Major Skills
                                        </label>
                                        <CreatableSelect
                                            id="majorSkillsUpdate"
                                            callback={majorSkillsCallback}
                                            initialValues={majorSkills}
                                            options={Constants.LIST_DEFAULT_TECH_SKILLS}
                                            placeholder="Please search for a skill and select to add"
                                        />
                                    </>
                                )}

                                {/* Minor Skills */}
                                {editType == Constants.TYPE_UPDATE_MINOR_SKILLS && (
                                    <>
                                        <label htmlFor="minorSkillsUpdate" className="form-label">
                                            Minor Skills
                                        </label>
                                        <CreatableSelect
                                            id="minorSkillsUpdate"
                                            callback={minorSkillsCallback}
                                            initialValues={minorSkills}
                                            options={Constants.LIST_DEFAULT_TECH_SKILLS}
                                            placeholder="Please search for a skill and select to add"
                                        />
                                    </>
                                )}

                                {/* Work History */}
                                {(editType == Constants.TYPE_ADD_WORK_HISTORY ||
                                    editType == Constants.TYPE_EDIT_WORK_HISTORY) && (
                                    <>
                                        <h4>Add Work History</h4>
                                        <div className="mb-3">
                                            <label htmlFor="companyDesignation" className="form-label">
                                                Designation
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setCompanyDesignation(e.target.value)}
                                                value={companyDesignation}
                                                placeholder="Enter Designation"
                                                className="form-control"
                                                id="companyDesignation"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">
                                                Company Name
                                            </label>
                                            <input
                                                type="text"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                placeholder="Enter Company Name"
                                                className="form-control"
                                                id="companyName"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">
                                                Started Working From
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6 ps-0">
                                                    <select
                                                        className="form-select"
                                                        value={workingFromYear}
                                                        onChange={(e) => setWorkingFromYear(e.target.value)}>
                                                        <option>Select Year</option>
                                                        {Utils.getYearsRange(1970).map((year) => (
                                                            <option key={year}>{year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 pe-0">
                                                    <select
                                                        className="form-select"
                                                        value={workingFromMonth}
                                                        onChange={(e) => setWorkingFromMonth(e.target.value)}>
                                                        <option>Select Month</option>
                                                        {Array.from(Array(12), (e, i) => (
                                                            <option key={i}>{i + 1}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">
                                                Worked Till
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6 ps-0">
                                                    <select
                                                        disabled={isCurrentJob}
                                                        className="form-select"
                                                        value={workedTillYear}
                                                        onChange={(e) => setWorkedTillYear(e.target.value)}>
                                                        <option>Select Year</option>
                                                        {Utils.getYearsRange(1970).map((year) => (
                                                            <option key={year}>{year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 pe-0">
                                                    <select
                                                        disabled={isCurrentJob}
                                                        className="form-select"
                                                        value={workedTillMonth}
                                                        onChange={(e) => setWorkedTillMonth(e.target.value)}>
                                                        <option>Select Month</option>
                                                        {Array.from(Array(12), (e, i) => (
                                                            <option key={i}>{i + 1}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            type="checkbox"
                                            id="currentJob"
                                            onChange={(e) => setIsCurrentJob(e.target.checked)}
                                            className="me-3"
                                            checked={isCurrentJob}
                                        />
                                        <label className="form-label" htmlFor="currentJob">
                                            Current Job?
                                        </label>
                                        <div>
                                            <label htmlFor="companyDesignation" className="form-label">
                                                Responsibilities
                                            </label>
                                            <textarea
                                                onChange={(e) => setResponsibilities(e.target.value)}
                                                value={responsibilities}
                                                className="form-control"
                                                placeholder="Enter your responsibilities"></textarea>
                                        </div>
                                    </>
                                )}

                                {/* Projects */}
                                {(editType == Constants.TYPE_ADD_PROJECTS ||
                                    editType == Constants.TYPE_EDIT_PROJECTS) && (
                                    <>
                                        <h4>Add Project</h4>
                                        <div className="mb-3">
                                            <label htmlFor="projectTitle" className="form-label">
                                                Project Title
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setCompanyDesignation(e.target.value)}
                                                value={companyDesignation}
                                                placeholder="Enter Project Title"
                                                className="form-control"
                                                id="projectTitle"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="projectAssociation" className="form-label">
                                                Company/Institution/Organization Name
                                            </label>
                                            <input
                                                type="text"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                placeholder="Enter Company/Institution/Organization Name"
                                                className="form-control"
                                                id="projectAssociation"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">
                                                Started Working From
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6 ps-0">
                                                    <select
                                                        className="form-select"
                                                        value={workingFromYear}
                                                        onChange={(e) => setWorkingFromYear(e.target.value)}>
                                                        <option>Select Year</option>
                                                        {Utils.getYearsRange(1970).map((year) => (
                                                            <option key={year}>{year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 pe-0">
                                                    <select
                                                        className="form-select"
                                                        value={workingFromMonth}
                                                        onChange={(e) => setWorkingFromMonth(e.target.value)}>
                                                        <option>Select Month</option>
                                                        {Array.from(Array(12), (e, i) => (
                                                            <option key={i}>{i + 1}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">
                                                Worked Till
                                            </label>
                                            <div className="row">
                                                <div className="col-md-6 ps-0">
                                                    <select
                                                        disabled={isCurrentJob}
                                                        className="form-select"
                                                        value={workedTillYear}
                                                        onChange={(e) => setWorkedTillYear(e.target.value)}>
                                                        <option>Select Year</option>
                                                        {Utils.getYearsRange(1970).map((year) => (
                                                            <option key={year}>{year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-md-6 pe-0">
                                                    <select
                                                        disabled={isCurrentJob}
                                                        className="form-select"
                                                        value={workedTillMonth}
                                                        onChange={(e) => setWorkedTillMonth(e.target.value)}>
                                                        <option>Select Month</option>
                                                        {Array.from(Array(12), (e, i) => (
                                                            <option key={i}>{i + 1}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            type="checkbox"
                                            id="currentlyInProgress"
                                            onChange={(e) => setIsCurrentJob(e.target.checked)}
                                            className="me-3"
                                            checked={isCurrentJob}
                                        />
                                        <label className="form-label" htmlFor="currentlyInProgress">
                                            Currently In Progress?
                                        </label>
                                        <div>
                                            <label htmlFor="projectDetails" className="form-label">
                                                Details of Project
                                            </label>
                                            <textarea
                                                onChange={(e) => setResponsibilities(e.target.value)}
                                                value={responsibilities}
                                                className="form-control"
                                                placeholder="Enter the details of the project"
                                                id="projectDetails"></textarea>
                                        </div>
                                    </>
                                )}

                                {/* Project Links */}
                                {(editType == Constants.TYPE_ADD_PROJECT_LINKS ||
                                    editType == Constants.TYPE_EDIT_PROJECT_LINKS) && (
                                    <>
                                        <h4>Add Project Links</h4>
                                        <div className="mb-3">
                                            <label htmlFor="projectTitle" className="form-label">
                                                Project Title
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setProjectName(e.target.value)}
                                                value={projectName}
                                                placeholder="Enter Project Title"
                                                className="form-control"
                                                id="projectTitle"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="projectLink" className="form-label">
                                                Project Link
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setProjectLink(e.target.value)}
                                                value={projectLink}
                                                placeholder="Enter Project Link"
                                                className="form-control"
                                                id="projectLink"
                                            />
                                        </div>
                                    </>
                                )}

                                {(editType == Constants.TYPE_ADD_EDUCATION ||
                                    editType == Constants.TYPE_EDIT_EDUCATION) && (
                                    <>
                                        <h4>Add Education Details</h4>
                                        <div className="mb-3">
                                            <label htmlFor="educationType" className="form-label">
                                                Education Type
                                            </label>
                                            <select
                                                value={educationType}
                                                onChange={(e) => setEducationType(e.target.value)}
                                                className="form-select"
                                                id="educationType">
                                                <option>Select Education Type</option>
                                                <option>PhD</option>
                                                <option>Bachelors</option>
                                                <option>Masters</option>
                                                <option>12th</option>
                                                <option>10th</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="course" className="form-label">
                                                Course
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setCourse(e.target.value)}
                                                value={course}
                                                placeholder="Enter Course Name"
                                                className="form-control"
                                                id="course"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="specialization" className="form-label">
                                                Specialization
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setSpecialization(e.target.value)}
                                                value={specialization}
                                                placeholder="Enter Specialization"
                                                className="form-control"
                                                id="specialization"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="universityName" className="form-label">
                                                University/Institute Name
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setUniversityName(e.target.value)}
                                                value={universityName}
                                                placeholder="Enter University/Institute Name"
                                                className="form-control"
                                                id="universityName"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="passOutYear" className="form-label">
                                                Pass-out Year
                                            </label>
                                            <select
                                                className="form-select"
                                                value={passOutYear}
                                                onChange={(e) => setPassOutYear(e.target.value)}
                                                id="passOutYear">
                                                <option>Select Pass out year</option>
                                                {Utils.getYearsRange(1970).map((year) => (
                                                    <option key={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                )}

                                {editType == Constants.TYPE_EDIT_PERSONAL_DETAILS && (
                                    <>
                                        <h4>Edit Personal Details</h4>
                                        <div className="mb-3">
                                            <label htmlFor="dob" className="form-label">
                                                Date of Birth
                                            </label>
                                            <input
                                                type="date"
                                                onChange={(e) => setDob(e.target.value)}
                                                value={dob}
                                                className="form-control"
                                                id="dob"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="permanentAddress" className="form-label">
                                                Permanent Address
                                            </label>
                                            <textarea
                                                onChange={(e) => setPermanentAddress(e.target.value)}
                                                value={permanentAddress}
                                                placeholder="Enter your full permanent address"
                                                className="form-control"
                                                id="permanentAddress"></textarea>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label">
                                                Gender
                                            </label>
                                            <select
                                                className="form-select"
                                                id="gender"
                                                value={gender}
                                                onChange={(e) => setGender(e.target.value)}>
                                                <option value={'male'}>Male</option>
                                                <option value={'female'}>Female</option>
                                                <option value={'other'}>Other</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="maritalStatus" className="form-label">
                                                Marital Status
                                            </label>
                                            <select
                                                className="form-select"
                                                id="maritalStatus"
                                                value={maritalStatus}
                                                onChange={(e) => setMaritalStatus(e.target.value)}>
                                                <option value="">Select Marital Status</option>
                                                <option>Single</option>
                                                <option>Married</option>
                                                <option>Divorced</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="category" className="form-label">
                                                Category
                                            </label>
                                            <select
                                                className="form-select"
                                                id="category"
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}>
                                                <option value="">Select Category</option>
                                                <option>General</option>
                                                <option>SC/ST</option>
                                                <option>OBC</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="languages" className="form-label">
                                                Languages
                                            </label>
                                            <CreatableSelect
                                                id="languages"
                                                callback={languagesCallback}
                                                initialValues={languages}
                                                placeholder="Please search for a Language and select to add"
                                            />
                                        </div>
                                    </>
                                )}

                                {(editType == Constants.TYPE_ADD_CERTIFICATION ||
                                    editType == Constants.TYPE_EDIT_CERTIFICATION) && (
                                    <>
                                        <h4>Add Certifications</h4>
                                        <div className="mb-3">
                                            <label htmlFor="certificationName" className="form-label">
                                                Certification Name
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setCertificationName(e.target.value)}
                                                value={certificationName}
                                                placeholder="Enter Certification Name"
                                                className="form-control"
                                                id="certificationName"
                                            />
                                        </div>
                                    </>
                                )}

                                {(editType == Constants.TYPE_ADD_SOCIAL_LINK ||
                                    editType == Constants.TYPE_EDIT_SOCIAL_LINK) && (
                                    <>
                                        <h4>Add Social Links</h4>
                                        <div className="mb-3">
                                            <label htmlFor="socialLink" className="form-label">
                                                Social Link
                                            </label>
                                            <input
                                                type="text"
                                                onChange={(e) => setSocialLink(e.target.value)}
                                                value={socialLink}
                                                placeholder="Enter Social Link to add"
                                                className="form-control"
                                                id="socialLink"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <Button
                                    id="btn-close-edit"
                                    color="warning"
                                    className="px-5"
                                    style={btnStyle}
                                    btnType="button"
                                    data-bs-dismiss="modal"
                                    onClick={() => resetUserProfile()}
                                    label="Close"
                                />
                                <Button
                                    disabled={updateLoading}
                                    className="px-5"
                                    btnType="button"
                                    style={btnStyle}
                                    onClick={() => updateProfileDetails()}
                                    label={
                                        updateLoading ? (
                                            <>
                                                <i className="fas fa-circle-notch fa-spin"></i> Updating...
                                            </>
                                        ) : (
                                            <>Save Changes</>
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobSeekerProfile
