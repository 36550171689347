import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getAllCampusJobSeekerPaginated } from '../../../../api/Admin'
import CampusJobSeekerCard from '../../../../components/elements/user/job-seeker/CampusJobSeekerCard'
import Pagination from '../../../../components/Pagination'
import { Box, Button, Column, Row, Heading } from '../../../../components/vanilla'
import ValidationError from '../../../../errors/ValidationError'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'

function JobSeekerList() {
    const navigate = useNavigate()

    let search = window.location.search
    let params = new URLSearchParams(search)

    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    // Data Vars
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const [jobSeekers, setJobSeekers] = useState([])

    // Search parameters
    const [name, setName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [currentLocation, setCurrentLocation] = useState('')

    // Initialize dispatch here
    const dispatch = useDispatch()

    window.onpopstate = async () => {
        try {
            setCurrentPage(pageNo)
            await getJobSeekerByPage(pageNo)
        } catch (error) {
            throw error
        }
    }

    useEffect(async () => {
        await populateInitData()
    }, [])

    /** Function to populate initial data */
    const populateInitData = async () => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        setCurrentPage(pageNo > 0 ? pageNo : 1)
        try {
            // Getting job-seekers
            await getJobSeekerByPage(pageNo > 0 ? pageNo : currentPage)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get all the job-seekers by page number */
    const getJobSeekerByPage = async (pageNumber) => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            const response = await getAllCampusJobSeekerPaginated(pageNumber, {
                name,
                mobileNumber,
                currentLocation
            })

            // Setting the data here
            setCurrentPage(response.currentPage)
            setTotalPages(response.totalPages)
            setTotalCount(response.count)
            setJobSeekers([...response.jobSeekers])
        } catch (error) {
            throw error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to search job seeker by name */
    const searchJobSeeker = async () => {
        try {
            navigate({
                pathname: Constants.ROUTE_ADMIN_MANAGE_LIST_CAMPUS_USERS,
                search: '?page=1'
            })
            await getJobSeekerByPage(1) // Should start a new search
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigate({
                pathname: Constants.ROUTE_ADMIN_MANAGE_LIST_CAMPUS_USERS,
                search: '?page=' + pageNumber
            })

            await getJobSeekerByPage(pageNumber)
        } catch (error) {
            throw error
        }
    }
    // Column width class based on admin type
    return (
        <Box className="p-4 mb-4">
            <Box className="card mb-4 shadow-sm">
                <Box className="card-body">
                    <Heading size="l">
                        <b>Search Campus Job Seekers</b>
                    </Heading>
                    <form onSubmit={() => {}}>
                        <Row className="row mb-3 ">
                            <Column className="col-sm-12 p-0">
                                <Box className="input-group">
                                    <input
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by name"
                                        id="name"
                                    />
                                    <input
                                        type="number"
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        value={mobileNumber}
                                        className="form-control"
                                        placeholder="Search by phone"
                                        id="mobileNumber"
                                    />
                                    <input
                                        type="text"
                                        onChange={(e) => setCurrentLocation(e.target.value)}
                                        value={currentLocation}
                                        className="form-control"
                                        placeholder="Search by location"
                                        id="currentLocation"
                                    />

                                    <Button
                                        type="button"
                                        label="Search"
                                        className="btn btn-search btn-primary radius-none"
                                        onClick={(e) => searchJobSeeker(e)}></Button>
                                </Box>
                            </Column>
                        </Row>
                    </form>
                </Box>
            </Box>
            {jobSeekers.length != 0 ? (
                <>
                    <Row>
                        {jobSeekers.map((jobSeeker, index) => (
                            <div className="col-md-3" key={jobSeeker.id}>
                                <CampusJobSeekerCard index={index} jobSeeker={jobSeeker} />
                            </div>
                        ))}
                    </Row>
                </>
            ) : (
                <>No Job-Seekers Found</>
            )}

            {/* Pagination */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={(page) => changePage(page)}
                itemCount={totalCount}
            />
        </Box>
    )
}

export default JobSeekerList
