import { useQuery } from 'react-query'
import { getSiteMap, getSlugPageData } from './util'

export const useSiteMap = ({ isFooterURL }) => {
    return useQuery(['getSiteMapData', isFooterURL], () => getSiteMap({ isFooterURL }), {
        refetchOnWindowFocus: false,
        keepPreviousData: true
    })
}

export const useSlugPageData = ({ slug }) => {
    return useQuery(['getSlugPageData', slug], () => getSlugPageData({ slug }), {
        refetchOnWindowFocus: false,
        keepPreviousData: true
    })
}
