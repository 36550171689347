import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import AddFooterForm from '../../../components/forms/footer/Form'

function AddFooter() {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Add New Footer Blog</Heading>
            <AddFooterForm />
        </Box>
    )
}
export default AddFooter
