import React from 'react'
import { Box, Column, Input, Row } from '../../vanilla'
import useFormFields from './useFormFields'

function FormFields({ form, adsData }) {
    let adsFormType = adsData ? adsData.type : 'sidebar'
    if (form.watch('adsType') !== undefined) {
        adsFormType = form.watch('adsType')
    }
    const { adsType, companyName, media, isActive, description, logo, actionUrl } = useFormFields({
        form
    })
    return (
        <Box className="mb-3">
            <Row className="mt-4">
                <Column className="col-md-6 mt-4">
                    <Input {...adsType} />
                </Column>

                <Row className="mt-4">
                    <Column className="col-md-6">
                        <Input {...isActive} />
                    </Column>
                </Row>
                <Column className="col-md-6 mt-4">
                    <Input {...media} />
                </Column>

                <Column className="col-md-6 mt-4">
                    <Input {...companyName} />
                </Column>
                <Column className="col-md-6 mt-4">
                    <Input {...actionUrl} />
                </Column>

                {adsFormType === 'post' && (
                    <>
                        <Column className="col-md-6 mt-4">
                            <Input {...logo} />
                        </Column>

                        <Column className="col-md-12 mt-4">
                            <Input {...description} defaultValue={adsData?.description} />
                        </Column>
                    </>
                )}
            </Row>
        </Box>
    )
}

export default FormFields
