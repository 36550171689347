import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import LeftBar from '../LeftBar'
import { sendForgotPasswordRequest, changePasswordFP } from '../../../api/Auth'
import Utils from '../../../helpers/Utils'
import VerifyOTP from '../register/VerifyOTP'
import ValidationError from '../../../errors/ValidationError'
import NotFoundError from '../../../errors/NotFoundError'

function ForgotForm({ type }) {
    const formType = type

    // Data vars
    const [email, setEmail] = useState('')
    const [childOtp, setChildOtp] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const [step, setStep] = useState(1)

    // Loader vars here
    const [sendRequestLoading, setSendRequestLoading] = useState(false)
    const [changePwdLoading, setChangePwdLoading] = useState(false)

    // Init navigate here
    const navigate = useNavigate()

    /** Function to handle child callback */
    const sendToParent = (stepValue, otpValue) => {
        setStep(stepValue)
        setChildOtp(otpValue)
    }

    /** Function to handle send forgot link */
    const handleForgotPassword = async (e) => {
        try {
            e.preventDefault()
            // Setting the loading here
            setSendRequestLoading(true)

            // Validations here
            if (email === '' || !Utils.validateEmail(email)) throw new ValidationError(Constants.MSG_INVALID_EMAIL)

            // Calling the api
            await sendForgotPasswordRequest({
                email,
                type
            })

            // Showing success toast
            Utils.showToast(Constants.MSG_FORGOT_PASSWORD_REQ_SENT, Constants.TYPE_TOAST_SUCCESS)

            // Changing step
            setStep(2)
        } catch (error) {
            if (error instanceof ValidationError || error instanceof NotFoundError)
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            setSendRequestLoading(false)
        }
    }

    /** Function to handle Change password */
    const handleChangePwd = async (e) => {
        try {
            e.preventDefault()
            // Setting the loading here
            setChangePwdLoading(true)

            // Validations here
            if (newPassword === '' || !Utils.validatePassword(newPassword))
                throw new ValidationError(Constants.MSG_INVALID_PASSWORD)

            // Calling the api
            await changePasswordFP({
                newPassword,
                email,
                otp: childOtp,
                type: formType
            })

            // Showing success toast
            Utils.showToast(Constants.MSG_PWD_UPDATE_SUCCESS_FP, Constants.TYPE_TOAST_SUCCESS)

            // Setting the redirect route
            const redirectRoute = getLoginURL()
            navigate(redirectRoute)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            setChangePwdLoading(false)
        }
    }

    /**
     * Function to get url to login
     */
    const getLoginURL = () => {
        if (formType === Constants.TYPE_USER_JOB_SEEKER) return Constants.ROUTE_CLIENT_LOGIN_JOB_SEEKER
        if (formType === Constants.TYPE_USER_EMPLOYER) return Constants.ROUTE_CLIENT_LOGIN_EMPLOYER
        if (formType === Constants.TYPE_USER_ADMIN) return Constants.ROUTE_ADMIN_LOGIN
    }

    return (
        <div>
            <section className="py-5 text-center hero-inner">
                <div className="row mrl-0 py-lg-5 pt-0">
                    <div className="col-lg-12 col-md-12 mx-auto">
                        <h1>{formType === Constants.TYPE_USER_JOB_SEEKER ? 'Job-Seeker' : 'Employer'} Login</h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="registration unick-box-sha">
                                <LeftBar />

                                <div className="reg-formcon">
                                    {step === 1 && (
                                        <>
                                            <h2 className="text-center mb-3">Forgot Password !</h2>
                                            <p className="text-center mb-4 col-sm-10 offset-sm-1">
                                                Don't worry it happens to the best of us! Enter your registered email
                                                address and we will get you back on track.
                                            </p>
                                            <form onSubmit={(e) => handleForgotPassword(e)}>
                                                <div className="row mb-4">
                                                    <div className="col-sm-8 offset-sm-2">
                                                        <input
                                                            type="email"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            className="form-control col-sm-8"
                                                            id="email"
                                                            placeholder="johndoe@example.com"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="text-center mb-2">
                                                    <button className="btn btn-primary" disabled={sendRequestLoading}>
                                                        {sendRequestLoading ? (
                                                            <>
                                                                <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                                Processing...
                                                            </>
                                                        ) : (
                                                            <>Submit</>
                                                        )}
                                                    </button>
                                                </div>

                                                <div className="text-center">
                                                    <Link to={getLoginURL()} className="back-link">
                                                        <i className="fas fa-caret-left"></i> Back to Login
                                                    </Link>
                                                </div>
                                            </form>
                                        </>
                                    )}

                                    {step === 2 && (
                                        <>
                                            <h2 className="text-center mb-3">OTP Verification !</h2>
                                            <p className="text-center mb-4 col-sm-10 offset-sm-1">
                                                We sent a verification code to your email. Enter the code from the email
                                                in the field below.
                                            </p>
                                            <VerifyOTP
                                                type={formType}
                                                otpType={Constants.TYPE_VERIFY_OTP_EMAIL}
                                                callback={sendToParent}
                                                email={email}
                                                userType={formType}
                                            />
                                        </>
                                    )}

                                    {step === 3 && (
                                        <>
                                            <h2 className="text-center mb-3">Change Password !</h2>
                                            <p className="text-center mb-4 col-sm-10 offset-sm-1">
                                                Now you can change your password by filling the below form.
                                            </p>
                                            <form onSubmit={(e) => handleChangePwd(e)}>
                                                <div className="row mb-4">
                                                    <div className="col-sm-8 offset-sm-2">
                                                        <label htmlFor="password" className="d-block">
                                                            New Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            className="form-control col-sm-8"
                                                            id="password"
                                                            placeholder="***********"
                                                        />
                                                        <div className="password-helper">
                                                            Your password should be between 6-16 characters long and
                                                            contain atleast a number and a special character.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-center mb-2">
                                                    <button className="btn btn-primary" disabled={changePwdLoading}>
                                                        {changePwdLoading ? (
                                                            <>
                                                                <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                                Processing...
                                                            </>
                                                        ) : (
                                                            <>Submit</>
                                                        )}
                                                    </button>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ForgotForm
