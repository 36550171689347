import React from 'react'
import { Card, CardBody } from '../vanilla'
import PropTypes from 'prop-types'

const CardBasic = ({ shadow, cardClass, bodyClass, children, ...props }) => {
    const shadowClass = shadow ? 'shadow' : ''

    return (
        <Card className={[shadowClass, cardClass].join(' ')} {...props}>
            <CardBody className={bodyClass}>{children}</CardBody>
        </Card>
    )
}

CardBasic.propTypes = {
    shadow: PropTypes.bool,
    cardClass: PropTypes.string,
    bodyClass: PropTypes.string,
    children: PropTypes.any.isRequired
}

CardBasic.defaultProps = {
    shadow: true,
    cardClass: '',
    bodyClass: ''
}

export default CardBasic
