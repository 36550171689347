import React from 'react'
import { useSelector } from 'react-redux'
import JobSeekerProfile from '../../../../components/elements/user/job-seeker/Profile'
import { getUserType } from '../../../../features/user/userSlice'

function JobSeekerProfileAdmin() {
    // Getting current user type
    const userType = useSelector(getUserType)

    return <JobSeekerProfile userType={userType} />
}

export default JobSeekerProfileAdmin
