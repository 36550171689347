import React, { useState, useEffect } from 'react'
import { Box, Flex, Row, Heading, Column } from '../../../components/vanilla'
import { useBlogList } from '../../../hooks/useAdmin'
import Pagination from '../../../components/Pagination'
import { useNavigate, Link } from 'react-router-dom'
import UnAuthError from '../../../errors/UnAuthError'
import SearchBlogForm from '../../../components/forms/blog/SearchBlogForm'
import SimpleLoader from '../../../components/vanilla/loaders/SimpleLoader/SImpleLoader'
import Constants from '../../../helpers/Constants'

function BlogList() {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchData, setSearchData] = useState({
        title: ''
    })
    const navigate = useNavigate()
    let search = window.location.search
    let params = new URLSearchParams(search)

    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    useEffect(() => {
        setCurrentPage(pageNo > 0 ? pageNo : 1)
    }, [pageNo])

    const {
        data: blogsData,
        isLoading: isInitLoading,
        isError: isInitError
    } = useBlogList({ ...searchData, page: currentPage })

    const navigateUrl = (pageNumber) => {
        navigate({
            pathname: '/admin/blog/list',
            search: '?page=' + pageNumber
        })
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigateUrl(pageNumber)
        } catch (error) {
            throw error
        }
    }

    /**
     * Function to set search form data
     */
    const setSearchFormData = (modifiedSearchData) => {
        setSearchData(modifiedSearchData)
        navigateUrl(1)
    }

    if (isInitError) {
        return <UnAuthError />
    }

    return (
        <Box className="admin-container mb-4">
            <Row className="p-4 mb-4">
                <Box className="mb-4 d-flex align-items-center justify-content-between">
                    <Heading size="lg">Manage Blogs</Heading>
                    <Link to={Constants.ROUTE_ADMIN_ADD_BLOG} className="btn-job btn-primary">
                        <i className="fas fa-user-plus"></i> Add New
                    </Link>
                </Box>
                <SearchBlogForm
                    isInitLoading={isInitLoading}
                    oldSearchData={searchData}
                    setSearchData={setSearchFormData}
                />
                {!isInitLoading ? (
                    <>
                        {blogsData.data.length !== 0 ? (
                            <>
                                {blogsData.data.map((blog, index) => (
                                    <Box className="card mb-4" key={index}>
                                        <Row className="card-body align-items-center">
                                            <Column className="col-md-1 mb-2 mb-md-0 text-center text-md-start">
                                                <img
                                                    src={`${
                                                        blog.thumbnailURL != null
                                                            ? blog.thumbnailURL
                                                            : Constants.DEFAULT_BLOG_IMAGE
                                                    }`}
                                                    className="avatar-img rounded-circle"
                                                />
                                            </Column>
                                            <Column className="col-md-7 text-md-start">
                                                <Heading size="md">{blog.title}</Heading>
                                            </Column>

                                            <Column className="col-md-4 text-md-end">
                                                <Link
                                                    to={Constants.ROUTE_ADMIN_BLOG_EDIT(blog.id)}
                                                    className="btn-job btn-primary">
                                                    <i className="fas fa-edit"></i> Edit
                                                </Link>
                                            </Column>
                                        </Row>
                                    </Box>
                                ))}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={blogsData.meta.pagination.pageCount}
                                    changePage={(page) => changePage(page)}
                                    itemCount={blogsData.meta.pagination.total}
                                    pageSize={blogsData.meta.pagination.pageSize}
                                />
                            </>
                        ) : (
                            <>No Blog Found</>
                        )}
                    </>
                ) : (
                    <Flex className="align-items-center justify-content-center flex-column">
                        <SimpleLoader size="50px" />
                        <Box className="mt-3">
                            Please wait, we are processing your data. This might take a few moments!
                        </Box>
                    </Flex>
                )}
            </Row>
        </Box>
    )
}
export default BlogList
