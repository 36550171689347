import React, { useEffect, useState } from 'react'
import { getCities } from '../../../api/Common'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import { Box, Button } from '../../vanilla'
import CreatableSelect from '../CreatableSelect'

function SearchForm({ callback }) {
    // Search vars
    const [searchParams, setSearchParams] = useState([])
    const [location, setLocation] = useState('')
    const [salaryRange, setSalaryRange] = useState('')
    const [cities, setCities] = useState([])

    // Callback to get search params from child
    const updateSearchParams = (params) => {
        setSearchParams(params)
    }

    // Styles for react-select
    const selectStyles = {
        control: (styles) => ({ ...styles, border: 'none', boxShadow: 'none', fontSize: '1.6rem' }),
        option: (styles) => ({ ...styles, fontSize: '1.6rem' })
    }

    useEffect(() => {
        const getCitiesArray = async () => {
            try {
                // Getting all the cities
                const citiesResponse = await getCities('', '')
                setCities(citiesResponse)
            } catch (error) {
                Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
            }
        }

        getCitiesArray()
    }, [])

    return (
        <Box Element="form">
            <Box className="input-group">
                <Box className="search-row">
                    <Box className="skill-area">
                        <CreatableSelect
                            initialValues={searchParams}
                            options={Constants.LIST_DEFAULT_TECH_SKILLS}
                            callback={updateSearchParams}
                            placeholder="Search Company / skills / Job description"
                            styles={selectStyles}
                            createLabel="Search"
                        />
                    </Box>
                    <Box className="location-area">
                        <select
                            className="form-select form-select-lg location"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}>
                            <option value="">All Locations</option>
                            {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </Box>
                    <Box className="salary-area">
                        <select
                            className="form-select form-select-lg location"
                            value={salaryRange}
                            onChange={(e) => setSalaryRange(e.target.value)}>
                            <option value="">All Salary Ranges (INR)</option>
                            <option>0-300000</option>
                            <option>300000-600000</option>
                            <option>600000-1000000</option>
                            <option>1000000-1500000</option>
                            <option>1500000-2500000</option>
                            <option>2500000-4000000</option>
                            <option value="4000000-NO_LIMIT">4000000+</option>
                        </select>
                    </Box>

                    <Box className="search-area">
                        <Button
                            label="Search"
                            color="primary"
                            className="w-100"
                            style={{ padding: '1.5rem 4.5rem', fontSize: '15px' }}
                            onClick={() => callback(searchParams, location, salaryRange)}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SearchForm
