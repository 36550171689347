import React from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Column = ({ children, className, ...props }) => {
    return (
        <Box className={className} {...props}>
            {children}
        </Box>
    )
}

Column.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

Column.defaultProps = {
    className: ''
}

export default Column
