export default function useFormFields({
    form: {
        control,
        formState: { errors }
    }
}) {
    const fields = {
        companyName: {
            name: 'companyName',
            id: 'companyName',
            label: 'Company Name',
            placeholder: 'Enter Company Name',
            defaultValue: '',
            type: 'text',
            error: errors.companyName,
            isRequired: true,
            rules: {
                required: 'Please enter the Company Name'
            },
            control
        },
        media: {
            name: 'media',
            id: 'media',
            label: 'Media Image',
            placeholder: 'Select Media Image',
            defaultValue: '',
            type: 'file',
            error: errors.media,
            control
        },
        logo: {
            name: 'logo',
            id: 'logo',
            label: 'Company Logo',
            placeholder: 'Select Logo',
            defaultValue: '',
            type: 'file',
            error: errors.logo,
            control
        },
        description: {
            name: 'description',
            id: 'description',
            label: 'Post Description',
            placeholder: 'Enter Ads Description',
            type: 'ckeditor',
            isRequired: true,
            defaultValue: '',
            floating: true,
            rules: {
                required: 'Please enter the Ads Description'
            },
            error: errors.description,
            control
        },
        actionUrl: {
            name: 'actionUrl',
            id: 'actionUrl',
            label: 'Action URL',
            placeholder: 'Enter Action URL',
            defaultValue: '',
            type: 'text',
            error: errors.actionUrl,
            isRequired: true,
            control,
            rules: {
                required: 'Please enter the Action URL'
            }
        },
        adsType: {
            type: 'select',
            name: 'adsType',
            id: 'adsType',
            label: 'Select Type',
            placeholder: 'Select Type',
            options: [
                {
                    value: `post`,
                    label: `Post`
                },
                {
                    value: `sidebar`,
                    label: `Sidebar`
                }
            ],
            error: errors.adsType,
            control
        },
        isActive: {
            name: 'isActive',
            id: 'isActive',
            label: 'Is Active?',
            defaultValue: true,
            type: 'switch',
            error: errors.isActive,
            control
        }
    }

    return fields
}
