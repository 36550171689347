import React, { useEffect, useState } from 'react'
import Creatable from 'react-select/creatable'
import { components } from 'react-select'
import Constants from '../../helpers/Constants'
import { Box } from '../vanilla'

function CreatableSelect({
    callback,
    placeholder = '',
    initialValues = [],
    options = [],
    styles = {},
    createLabel = 'Create',
    maxSelectCount = Constants.MAX_ITEMS_SELECTED,
    isDisable = false
}) {
    /** Default values */
    const optionsArr = []
    const [value, setValue] = useState([])

    /** Function to handle value change */
    const handleChange = (inputValue) => {
        // Setting input value and updating parent
        setValue(inputValue)
        callback(inputValue)
    }

    /** Changing the array of options if the array elements exists */
    if (options.length !== 0) {
        options = options.map((option) => {
            optionsArr.push({
                value: option,
                label: option
            })
        })
    }

    const Menu = (props) => {
        const optionSelectedLength = props.getValue().length || 0
        return (
            <components.Menu {...props}>
                {optionSelectedLength < maxSelectCount ? (
                    props.children
                ) : (
                    <Box className="status-font p-4 text-center text-muted">
                        {Constants.MAJOR_SKILLS_EXCEED_WARNING_MESSAGE}
                    </Box>
                )}
            </components.Menu>
        )
    }

    useEffect(() => {
        setValue(initialValues)
        handleChange(initialValues)
    }, [initialValues])

    return (
        <div>
            <Creatable
                components={{ Menu }}
                styles={styles}
                isClearable
                isDisabled={isDisable}
                isMulti
                onChange={(value) => handleChange(value)}
                options={optionsArr}
                value={value}
                isOptionDisabled={() => value.length >= maxSelectCount}
                placeholder={placeholder}
                formatCreateLabel={(userInput) => `${createLabel} for ${userInput}`}
            />
        </div>
    )
}

export default CreatableSelect
