import React from 'react'
import Constants from '../../../helpers/Constants'
import { useGetCommunityPost } from '../../../hooks/useAdmin'
import { Box, Column, Input, Row } from '../../vanilla'
import useFormFields from './useFormFields'

function FormFields({ form, postId, formType = Constants.TYPE_ADD }) {
    const { data: communityPostData } = useGetCommunityPost({ postId, enabled: postId !== undefined })
    const { title, description, media, isActive } = useFormFields({
        form,
        communityPost: communityPostData?.data,
        formType
    })

    return (
        <Box className="mb-3">
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...title} />
                </Column>
                <Column className="col-md-6">
                    <Input {...media} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...isActive} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-12">
                    <Input {...description} defaultValue={communityPostData?.data?.description} />
                </Column>
            </Row>
        </Box>
    )
}

export default FormFields
