import React from 'react'
import { useSelector } from 'react-redux'
import { getIsLoading } from '../features/loader/LoaderSlice'

function Loader() {
    const showLoader = useSelector(getIsLoading)

    return showLoader ? (
        <div className="page-preloader d-flex align-items-center justify-content-center">
            <div className="loader"></div>
        </div>
    ) : null
}

export default Loader
