import Constants from '../../../helpers/Constants'

export default function useFormFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    footer,
    formType = Constants.TYPE_ADD
}) {
    const fields = {
        title: {
            name: 'title',
            id: 'title',
            label: 'Title',
            placeholder: 'Enter Title',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter Footer title'
            },
            error: errors.title,
            control
        },
        isActive: {
            name: 'isActive',
            id: 'isActive',
            label: 'Is Active?',
            defaultValue: false,
            type: 'switch',
            error: errors.isActive,
            control
        }
    }

    // In case of edit setting all the default fields
    if (formType === Constants.TYPE_EDIT && footer) {
        setValue('title', footer.title)
        setValue('isActive', footer.isActive)
    }

    return fields
}
