import React from 'react'
import { Container, Heading, Image } from '../components/vanilla'
import accessError from '../assets/img/access.svg'

function UnAuthError() {
    return (
        <Container style={{ textAlign: 'center' }} className="position-relative z-index-5 pt-5 pb-5">
            <Heading size="xl" className="alert alert-danger alert-dismissible fade show">
                You are not authorized to access this page !
            </Heading>
            <Image src={accessError} alt="success_image" />
        </Container>
    )
}

export default UnAuthError
