import React from 'react'
import styles from './SimpleLoader.module.css'
import { Box } from '../../../vanilla'

const SimpleLoader = ({
    size = '50px',
    className = '',
    style = {},
    borderColor = '#f3f3f3',
    borderTopColor = '#555'
}) => (
    <Box
        className={[styles.loader, className].join(' ')}
        style={{ ...style, height: size, width: size, borderColor, borderTopColor }}
    />
)

export default SimpleLoader
