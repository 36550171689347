import React, { useState } from 'react'
import Pagination from '../../../components/Pagination'
import { Box, Heading } from '../../../components/vanilla'
import { useEmailLogs } from '../../../hooks/useAdmin'
import { FaEye } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Constants from '../../../helpers/Constants'

const EmailLogList = () => {
    // Data vars here
    const [currentPage, setCurrentPage] = useState(1)
    const { data: emailLogsResponse } = useEmailLogs({ page: currentPage })

    return (
        <Box className="p-4 mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">Email Logs</Heading>
            </Box>

            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Recipient</th>
                        <th scope="col">Sent From</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Timestamp</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {emailLogsResponse && emailLogsResponse.data.length > 0 && (
                        <>
                            {emailLogsResponse.data.map((emailLog) => (
                                <tr key={emailLog.id}>
                                    <td>{emailLog.to}</td>
                                    <td>{emailLog.from}</td>
                                    <td>{emailLog.subject}</td>
                                    <td>{emailLog.createdAt}</td>
                                    <td>
                                        <Link to={Constants.ROUTE_ADMIN_EMAIL_LOG({ id: emailLog.id })}>
                                            <FaEye />
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                </tbody>
            </table>

            {emailLogsResponse && emailLogsResponse.data.length > 0 && (
                <Pagination
                    totalPages={emailLogsResponse.meta.pagination.pageCount}
                    currentPage={currentPage}
                    nextPage={() => setCurrentPage((_currentPage) => _currentPage + 1)}
                    prevPage={() => setCurrentPage((_currentPage) => _currentPage - 1)}
                    changePage={(page) => setCurrentPage(page)}
                    itemCount={emailLogsResponse.meta.pagination.total}
                    pageSize={emailLogsResponse.meta.pagination.pageSize}
                />
            )}
        </Box>
    )
}

export default EmailLogList
