import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { addNewPanelist } from '../../../api/Auth'
import ValidationError from '../../../errors/ValidationError'
import { getUserType } from '../../../features/user/userSlice'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import { Button } from '../../vanilla'

function AddPanelistForm({ employerId = '' }) {
    // Getting current user type
    const userType = useSelector(getUserType)
    // Init navigate here
    const navigate = useNavigate()

    // Data Vars
    const [type, setType] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phPrefix, setPhPrefix] = useState('+91')
    const [phNumber, setPhNumber] = useState('')

    // Loader vars
    const [addLoading, setAddLoading] = useState(false)

    /** Function to handle add/edit form */
    const handleSubmit = async (e) => {
        // Setting the loading here
        setAddLoading(true)
        try {
            e.preventDefault()

            // Validating data first
            if (type == '') throw new ValidationError(Constants.MSG_EMPTY_PANELIST_TYPE)
            if (firstName == '') throw new ValidationError(Constants.MSG_INVALID_PANELIST_FIRST_NAME)
            if (lastName == '') throw new ValidationError(Constants.MSG_INVALID_PANELIST_LAST_NAME)
            if (email == '' || !Utils.validateEmail(email))
                throw new ValidationError(Constants.MSG_INVALID_PANELIST_EMAIL)
            if (phNumber == '' || !Utils.validateMobileNumber(phNumber))
                throw new ValidationError(Constants.MSG_INVALID_PANELIST_PH_NUMBER)

            // Calling the API
            await addNewPanelist(
                userType,
                {
                    type,
                    firstName,
                    middleName,
                    lastName,
                    email,
                    phoneNumber: `${phPrefix}${phNumber}`
                },
                employerId
            )

            // Showing success message
            Utils.showToast(Constants.MSG_PANELIST_ADDED, Constants.TYPE_TOAST_SUCCESS)

            // Setting the loading here
            setAddLoading(false)

            const redirectURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.ROUTE_ADMIN_MANAGE_EMPLOYER_MEMBER_LIST(employerId)
                    : Constants.ROUTE_CLIENT_EMPLOYER_PANEL_LIST
            navigate(redirectURL)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)

            // Setting the loading here
            setAddLoading(false)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="registration">
                        <div className="reg-formcon unick-box">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                                        Select Type <span className="red-text">*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <div className="form-check form-check-inline">
                                            <input
                                                value={'interviewer'}
                                                onChange={(e) => setType(e.target.value)}
                                                className="form-check-input"
                                                type="radio"
                                                id="inlineCheckbox1"
                                                name="type"
                                            />
                                            <label className="form-check-label ms-3 mt-2" htmlFor="inlineCheckbox1">
                                                TECH
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input
                                                value={'hr'}
                                                onChange={(e) => setType(e.target.value)}
                                                className="form-check-input"
                                                type="radio"
                                                id="inlineCheckbox2"
                                                name="type"
                                            />
                                            <label className="form-check-label ms-3 mt-2" htmlFor="inlineCheckbox2">
                                                HR
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                                        Name <span className="red-text">*</span>
                                    </label>
                                    <div className="col-sm-3">
                                        <input
                                            type="text"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className="form-control"
                                            placeholder="First name"
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <input
                                            type="text"
                                            onChange={(e) => setMiddleName(e.target.value)}
                                            className="form-control"
                                            placeholder="Middle name (Optional)"
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <input
                                            type="text"
                                            onChange={(e) => setLastName(e.target.value)}
                                            className="form-control"
                                            placeholder="Last name"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="email" className="col-sm-3 col-form-label">
                                        Email <span className="red-text">*</span>
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="form-control"
                                            id="email"
                                            placeholder="name@company.com"
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                                        Phone <span className="red-text">*</span>
                                    </label>
                                    <div className="col-sm-1">
                                        <select onChange={(e) => setPhPrefix(e.target.value)} className="form-control">
                                            <option>+ 91</option>
                                            <option>+ 92</option>
                                            <option>+ 93</option>
                                            <option>+ 94</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="text"
                                            onChange={(e) => setPhNumber(e.target.value)}
                                            className="form-control"
                                            placeholder="xxxxxxxxxx"
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-sm-9 offset-sm-3">
                                        <Button
                                            disabled={addLoading}
                                            btnType="submit"
                                            className="px-4"
                                            style={{ fontSize: '15px' }}
                                            label={
                                                addLoading ? (
                                                    <>
                                                        <i className="fas fa-circle-notch fa-spin"></i> Adding...
                                                    </>
                                                ) : (
                                                    <>Add Panel Member</>
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPanelistForm
