import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Row, Column, Input, Box } from '../../vanilla'
import useSearchFields from './useSearchFields'

function SearchFooterForm({ setSearchData, oldSearchData, isInitLoading }) {
    const form = useForm()
    const onSubmit = async (data) => {
        setSearchData((oldSearchData) => ({
            ...oldSearchData,
            title: data.title
        }))
    }

    const { title } = useSearchFields({
        form
    })
    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Row className="row mb-3 ">
                <Column className="col-sm-12 p-0">
                    <Box className="input-group">
                        <Input {...title} />
                        <Button
                            isLoading={isInitLoading}
                            btnType="submit"
                            label="Search"
                            color="primary"
                            soft
                            className="btn btn-search btn-primary radius-none"
                        />
                    </Box>
                </Column>
            </Row>
        </form>
    )
}

export default SearchFooterForm
