import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { appToJob, changeJobApplicationStatus, getAllJobApplicants, getAllJobs, getResumes } from '../../../api/Jobs'
import { sendTestLink, updateIsBestFitFlag } from '../../../api/Admin'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
import { getUserType } from '../../../features/user/userSlice'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import DateTimePicker from 'react-datetime-picker'
import ValidationError from '../../../errors/ValidationError'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { confirmAlert } from 'react-confirm-alert'
import { getDocuments, updateEmployerDocAccess } from '../../../api/Auth'
import Applicant from './Applicant'
import { Box, Button, Column, Row } from '../../vanilla'
import Toolbar from './partials/Toolbar'
import { useToggleServeNoticePeriod } from '../../../hooks/useAdmin'
import Pagination from '../../Pagination'
import { useParams } from 'react-router-dom'

function AssignmentList() {
    // Getting the user type
    const userType = useSelector(getUserType)
    const params = useParams()

    // Init Dispatch here
    const dispatch = useDispatch()
    const { mutateAsync } = useToggleServeNoticePeriod()

    const [jobs, setJobs] = useState([])
    const [applicants, setApplicants] = useState([])
    const [panelists, setPanelists] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectJobId, setSelectJobId] = useState('')
    const [totalPagesApplicant, setTotalPagesApplicant] = useState(1)
    const [totalCountApplicant, setTotalCountApplicant] = useState(0)
    const [currentPageApplicant, setCurrentPageApplicant] = useState(1)

    const [interviewerId, setInterviewerId] = useState('')
    const [interviewDateTime, setInterviewDateTime] = useState(null)
    const [testLink, setTestLink] = useState('')
    const [meetingLink, setMeetingLink] = useState('')
    const [interviewMsg, setInterviewMsg] = useState('')
    const [interviewType, setInterviewType] = useState(Constants.TYPE_INTERVIEW_TECHNICAL)
    const [otherPanelists, setOtherPanelists] = useState('')

    const [ctc, setCtc] = useState(0)
    const [joiningDate, setJoiningDate] = useState('')
    const [joiningLocation, setJoiningLocation] = useState('')
    const [offerLetter, setOfferLetter] = useState('')

    const [rejectReason, setRejectReason] = useState([])
    const [searchParam, setSearchParam] = useState('')

    const [applicantName, setApplicantName] = useState('')
    const [applicantEmail, setApplicantEmail] = useState('')
    const [applicantMobile, setApplicantMobile] = useState('')
    const [applicantMinExperience, setApplicantMinExperience] = useState('')
    const [applicantMaxExperience, setApplicantMaxExperience] = useState('')
    const [applicantBestFit, setApplicantBestFit] = useState(false)
    const [applicantServingNotice, setApplicantServingNotice] = useState(false)
    const [applicantMinNoticePeriod, setApplicantMinNoticePeriod] = useState('')
    const [applicantMaxNoticePeriod, setApplicantMaxNoticePeriod] = useState('')

    const [documents, setDocuments] = useState([])

    // User make application Vars
    const [resumes, setResumes] = useState([])
    const [resumeId, setResumeId] = useState('')
    const [questionnaireAnswers, setQuestionnaireAnswers] = useState([])

    // Loader vars
    const [interviewLoading, setInterviewLoading] = useState(false)
    const [offerLoading, setOfferLoading] = useState(false)
    const [rejectLoading, setRejectLoading] = useState(false)
    const [sendTestLoading, setSendTestLoading] = useState(false)
    const [postJobLoading, setPostJobLoading] = useState(false)
    const [fetchResumesLoading, setFetchResumesLoading] = useState(false)

    const [paramInit, setParamInit] = useState(true)

    const textStyle = {
        fontSize: '1.6rem',
        paddingTop: '0.7rem',
        paddingLeft: '5rem'
    }

    useEffect(() => {
        populateInitData()
    }, [])

    /** Function to Populate initial data */
    const populateInitData = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Checking if the route params contains jobId
            // then force filtering only the job with job id
            let id = ''
            if (params.jobId) id = params.jobId

            /** Getting all the jobs here */
            await getJobByPage(currentPage, id)

            // if jobId is present in params, we need to set the
            // job automatically
            if (params.jobId) {
                // Clicking job automatically
                document.getElementsByName('selectedJob')[0].click()
            }
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get all the jobs */
    const getJobByPage = async (pageNumber, id = '') => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            const response = await getAllJobs({ pageNumber, userType, searchParam, id })
            // Setting data's here
            setTotalPages(response.totalPages)
            setTotalCount(response.count)
            // TODO: Later Pass params in API get to only receive jobs with active and approved
            setJobs([...response.jobs].filter((job) => job.isActive && job.isAdminApproved))
            setCurrentPage(response.currentPage)
            setSelectJobId('') // Defaulting to null so that nothing will be selected by default

            // Clearing applicant data
            setTotalPagesApplicant(1)
            setTotalCountApplicant(0)
            setApplicants([])
            setCurrentPageApplicant(1)
            setPanelists([])
            // Scrolling to Top of page
            window.scrollTo(0, 0)
        } catch (error) {
            return error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get all the applicants for Job */
    const getJobApplicantsByPage = async (pageNumber, jobId, resetJobSeekerSearch = false) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            let applicationId = ''
            if (params.applicationId && paramInit) applicationId = params.applicationId

            const response = await getAllJobApplicants({
                pageNumber,
                jobId,
                userType,
                name: applicantName,
                email: applicantEmail,
                mobileNumber: applicantMobile,
                minExperience: applicantMinExperience,
                maxExperience: applicantMaxExperience,
                minNoticePeriod: applicantMinNoticePeriod,
                maxNoticePeriod: applicantMaxNoticePeriod,
                isBestFit: applicantBestFit ? 1 : 0,
                isServingNoticePeriod: applicantServingNotice ? 1 : 0,
                id: applicationId,
                resetJobSeekerSearch
            })
            // Setting data's here
            setTotalPagesApplicant(response.totalPages)
            setTotalCountApplicant(response.count)
            setApplicants([...response.applicants])
            setCurrentPageApplicant(response.currentPage)
            setPanelists([...response.panelists])

            // Reset data's here
            setRejectReason([])
            setCtc(0)
            setJoiningDate('')
            setJoiningLocation('')
            setOfferLetter('')
        } catch (error) {
            return error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to handle job select change */
    const selectJob = async (jobId, index) => {
        setSelectJobId(jobId)
        setApplicantName('')
        setApplicantEmail('')
        setApplicantMobile('')
        setApplicantMinNoticePeriod('')
        setApplicantMaxNoticePeriod('')
        setApplicantMinExperience('')
        setApplicantMaxExperience('')
        setApplicantBestFit(false)
        setApplicantServingNotice(false)
        // Setting the questionnaires
        const jobData = jobs[index]
        let questionnaireArr = []
        for (const questionnaire of jobData.questionaire) {
            questionnaireArr.push({
                question: questionnaire.question,
                answer: questionnaire.responseType == Constants.TYPE_RESPONSE_YES_NO ? 'Yes' : '0',
                responseType: questionnaire.responseType,
                idealResponse: questionnaire.idealResponse,
                mustHave: questionnaire.mustHave
            })
        }
        setQuestionnaireAnswers(questionnaireArr)
        // Calling API here to get all the job applicants
        await getJobApplicantsByPage(currentPageApplicant, jobId, true)
        // Setting param init to false here
        // so that the rest functionality works correctly
        setParamInit(false)
        Utils.scrollToTop()
    }

    /** Function to change Job Page */
    const changeJobPage = async (pageNumber) => {
        try {
            await getJobByPage(pageNumber)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Function to change Applicant page */
    const changeApplicantPage = async (pageNumber) => {
        try {
            await getJobApplicantsByPage(pageNumber, selectJobId)
            Utils.scrollToTop()
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Function to change status of candidate */
    const changeCandidateStatus = async (applicationId, applicantId, status, isRelease = false, index = 0) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // If we are initiating a release after HOLD
            if (isRelease) {
                await changeJobApplicationStatus(
                    applicationId,
                    applicantId,
                    {
                        status
                    },
                    userType,
                    true // Sending isRelease as true
                )

                // Populating the applicants again
                return await getJobApplicantsByPage(currentPageApplicant, selectJobId)
            }

            // Based on status creating update Object & updating the status
            switch (status) {
                case Constants.STATUS_JOB_APP_ALLOTED:
                case Constants.STATUS_JOB_APP_APPLIED:
                case Constants.STATUS_JOB_APP_JOINED:
                case Constants.STATUS_JOB_APP_HOLD:
                    await changeJobApplicationStatus(
                        applicationId,
                        applicantId,
                        {
                            status
                        },
                        userType
                    )
                    break

                case Constants.STATUS_JOB_APP_SHORTLISTED:
                    await changeJobApplicationStatus(
                        applicationId,
                        applicantId,
                        {
                            status
                        },
                        userType
                    )
                    Utils.showToast(Constants.MSG_CANDIDATE_SHORTLISTED, Constants.TYPE_TOAST_SUCCESS)
                    break

                case Constants.STATUS_JOB_APP_INTERVIEW:
                case Constants.STATUS_JOB_APP_HR:
                    // Setting loading here
                    setInterviewLoading(true)

                    // Validating the entered data here
                    if (interviewerId == '') throw new ValidationError(Constants.MSG_EMPTY_PANELIST)
                    if (otherPanelists !== '') {
                        let otherPanelistsArr = otherPanelists.split(',')
                        for (const otherPanelist of otherPanelistsArr) {
                            if (!Utils.validateEmail(otherPanelist)) {
                                throw new ValidationError(Constants.MSG_INVALID_OTHER_PANEL_EMAIL)
                            }
                        }
                    }
                    if (interviewDateTime == '' || interviewDateTime == null)
                        throw new ValidationError(Constants.MSG_EMPTY_INTERVIEW_DATE)

                    await changeJobApplicationStatus(
                        applicationId,
                        applicantId,
                        {
                            status,
                            interviewerId,
                            type:
                                status == Constants.STATUS_JOB_APP_INTERVIEW
                                    ? Constants.TYPE_INTERVIEW_TECHNICAL
                                    : Constants.TYPE_INTERVIEW_HR,
                            scheduleDateTime: moment(interviewDateTime).format('YYYY-MM-DD HH:mm:ss'),
                            testLink,
                            meetingLink,
                            interviewStatus: Constants.STATUS_JOB_INTERVIEW_SCHEDULED,
                            interviewMsg,
                            otherPanelists
                        },
                        userType
                    )

                    Utils.closeItem('close-interview-modal' + index)
                    Utils.showToast(Constants.MSG_INTERVIEW_SCHEDULED, Constants.TYPE_TOAST_SUCCESS)
                    setInterviewMsg('')
                    break

                case Constants.STATUS_JOB_APP_OFFERED:
                    // Setting the loading here
                    setOfferLoading(true)

                    // Validating data here
                    if (ctc == 0 || ctc == '') throw new ValidationError(Constants.MSG_EMPTY_CTC_OFFERED)
                    if (joiningDate == '') throw new ValidationError(Constants.MSG_EMPTY_JOINING_DATE)
                    if (joiningLocation == '') throw new ValidationError(Constants.MSG_EMPTY_JOINING_LOCATION)
                    if (offerLetter == '') throw new ValidationError(Constants.MSG_EMPTY_OFFER_LETTER)

                    // Preparing the data
                    const data = new FormData()
                    data.append('offerLetter', offerLetter)
                    data.append('status', status)
                    data.append('ctc', ctc)
                    data.append('joiningDate', joiningDate)
                    data.append('joiningLocation', joiningLocation)

                    await changeJobApplicationStatus(applicationId, applicantId, data, userType)

                    Utils.closeItem('close-offer-modal')
                    // Showing success toast
                    Utils.showToast(Constants.MSG_OFFER_SENT_TO_CANDIDATE, Constants.TYPE_TOAST_SUCCESS)
                    break

                case Constants.STATUS_JOB_APP_REJECT:
                    // Setting the loading here
                    setRejectLoading(true)
                    // Validating the reject reasons
                    if (rejectReason.length == 0) throw new ValidationError(Constants.MSG_EMPTY_REJECT_REASON)

                    await changeJobApplicationStatus(
                        applicationId,
                        applicantId,
                        {
                            status,
                            rejectReason
                        },
                        userType
                    )

                    Utils.closeItem(`reject-modal${index}`)
                    break

                default:
                    throw new ValidationError(Constants.MSG_UNEXPECTED_ERROR)
            }

            // Populating the applicants again
            await getJobApplicantsByPage(currentPageApplicant, selectJobId)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
            setInterviewLoading(false)
            setOfferLoading(false)
            setRejectLoading(false)
        }
    }

    /** Function to handle reject reason change */
    const changeRejectReason = (event) => {
        let values = [...rejectReason]
        // If the checkbox is checked
        if (event.target.checked) values.push(event.target.value)
        else {
            const index = rejectReason.indexOf(event.target.value)
            values.splice(index, 1)
        }

        setRejectReason(values)
    }

    /** Function to change adminTestLink */
    const handleAdminTestLinkChange = (event, index) => {
        let values = [...applicants]
        values[index].updatedTestLink = event.target.value
        setApplicants(values)
    }

    /** Function to change admin Link subject */
    const handleAdminTestSubjectChange = (event, index) => {
        let values = [...applicants]
        values[index].updatedSubject = event.target.value
        setApplicants(values)
    }

    /** Function to handle admin test link submit */
    const handleAdminTestLinkSubmit = async (event, index) => {
        event.preventDefault()
        try {
            // Setting the loading
            setSendTestLoading(true)
            let applicant = [...applicants][index]

            // Validating the data
            if (applicant.updatedSubject == undefined || applicant.updatedSubject == '')
                throw new ValidationError(Constants.MSG_EMPTY_ADMIN_TEST_SUBJECT)
            if (applicant.updatedTestLink == undefined || applicant.updatedTestLink == '')
                throw new ValidationError(Constants.MSG_EMPTY_ADMIN_TEST_LINK)

            // Updating and sending email
            await sendTestLink(
                applicant.updatedTestLink,
                applicant.id,
                applicant.jobSeeker.id,
                applicant.updatedSubject
            )
            // Getting the applicants again
            await getJobApplicantsByPage(currentPageApplicant, selectJobId)

            // Showing success toast
            Utils.showToast(Constants.MSG_TEST_LINK_SENT, Constants.TYPE_TOAST_SUCCESS)
            Utils.closeItem(`close-test-link-modal${index}`)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading
            setSendTestLoading(false)
        }
    }

    /** Function to get Job Seeker Resumes */
    const getJobSeekerResumes = async (jobSeekerId) => {
        try {
            setResumes([])
            // Setting the loading
            setFetchResumesLoading(true)

            // Getting user resumes
            const resumeResponse = await getResumes(userType, jobSeekerId)
            setResumes(resumeResponse)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading
            setFetchResumesLoading(false)
        }
    }

    /** Function to handle questionnaire answers */
    const handleQuestionnaireAnsChange = (index, val) => {
        let values = [...questionnaireAnswers]
        values[index]['answer'] = val
        setQuestionnaireAnswers(values)
    }

    /** Function to handle resume change */
    const handleResumeChange = (event) => {
        setResumeId(event.target.value)
    }

    /** Function to handle job application */
    const handleApplyJob = async (e, jobSeekerId, index) => {
        e.preventDefault()
        try {
            // Setting loading here
            setPostJobLoading(true)

            // Validating
            if (resumeId == '') throw new ValidationError(Constants.MSG_EMPTY_RESUME_UPLOAD)
            if (questionnaireAnswers.length > 0) {
                // Validating Questionnaires here
                const valid = questionnaireAnswers.every((questionnaire) => questionnaire.answer != '')
                if (!valid) throw new ValidationError(Constants.MSG_JOB_QUESTIONNAIRE_ANS_INVALID)
            }

            // Calling API to add to job
            await appToJob(
                selectJobId,
                {
                    questionnaireAnswers,
                    resumeId: resumeId,
                    referralCode: ''
                },
                userType,
                jobSeekerId
            )

            // Setting loading here
            setPostJobLoading(false)
            // Showing success toast
            Utils.showToast(Constants.MSG_JOB_APPLICATION_SENT, Constants.TYPE_TOAST_SUCCESS)
            Utils.closeItem(`apply-close-btn${index}`)

            // Calling get jobs again
            await getJobByPage(currentPage)
        } catch (error) {
            // Setting loading here
            setPostJobLoading(false)

            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Function to handle access for user docs change */
    const toggleAccessUserDocs = async (e, applicationId, index) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the API to update the access
            const checkValue = e.target.checked
            await updateEmployerDocAccess(applicationId, checkValue)

            let values = [...applicants]
            values[index].canAccessUserDocs = checkValue
            setApplicants(values)

            Utils.showToast(Constants.MSG_EMPLOYER_ACCESS_DOC_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change status of jobSeeker serving notice period */
    const toggleIsJobSeekerServeNoticePeriod = async (e, applicationId, index) => {
        try {
            dispatch(setLoadingState(true))
            const checkValue = e.target.checked
            await mutateAsync({
                data: {
                    applicationId: applicationId,
                    servingNoticePeriod: checkValue
                }
            })
            let values = [...applicants]
            values[index].servingNoticePeriod = checkValue
            setApplicants(values)
            Utils.showToast(Constants.MSG_SERVE_NOTICE_PERIOD_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to fetch docs of job-seeker */
    const fetchUserDocs = async (jobSeekerUuId, applicationId) => {
        try {
            // Clearing the docs first
            setDocuments([])

            // Calling the api to get user documents
            const docs = await getDocuments(jobSeekerUuId, userType, applicationId)
            setDocuments([...docs.documents])
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /**
     * Function to toggle applicant best fit flag
     */
    const toggleBestFitFlag = async (e, applicationId, index) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the API to update the access
            const checkValue = e.target.checked
            await updateIsBestFitFlag({ applicationId, isBestFit: checkValue })

            let values = [...applicants]
            values[index].isBestFit = checkValue
            setApplicants(values)

            Utils.showToast(Constants.MSG_USER_BIT_FIT_TOGGLE, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    const btnStyle = { fontSize: '15px' }

    return (
        <div>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="employer-pros-list-content">
                                <div className="employer-pros-list-content-con">
                                    <div className="employer-pros-list-content-left ">
                                        <h2 className="text-secondary text-center">Job List</h2>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                value={searchParam}
                                                onChange={(e) => setSearchParam(e.target.value)}
                                                className="form-control"
                                                placeholder="Search Job"
                                            />
                                            <Button
                                                onClick={() => getJobByPage(1)}
                                                btnType="submit"
                                                className="radius-none px-4"
                                                style={btnStyle}
                                                label="Search"
                                            />
                                        </div>

                                        <div className="col-sm-12">
                                            {/* Job Item */}
                                            {jobs.map((job, index) => (
                                                <div className="jobvacancy-con" key={job.id}>
                                                    <div
                                                        className={`jobvacancy-box ${
                                                            job.id == selectJobId ? 'job-active' : ''
                                                        }`}>
                                                        <h4>
                                                            Job: {job.positionName}
                                                            <span>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) => selectJob(e.target.value, index)}
                                                                    value={job.id}
                                                                    name="selectedJob"
                                                                    checked={job.id == selectJobId}
                                                                    className="form-check-input"
                                                                />
                                                            </span>
                                                        </h4>
                                                        <ul>
                                                            <li>
                                                                <strong>Code :</strong> {job.jobCode}
                                                            </li>
                                                            <li>
                                                                <strong>Company:</strong> {job.employer.companyName}
                                                            </li>
                                                            <li>
                                                                <strong>Exp:</strong>{' '}
                                                                {`${Math.round(job.minExp * 100) / 100} - ${
                                                                    Math.round(job.maxExp * 100) / 100
                                                                } yrs`}
                                                            </li>
                                                            <li>
                                                                <strong>JOB Skills : </strong>
                                                                {job.techSkill.map((skill, index) => (
                                                                    <span key={skill}>{`${skill}${
                                                                        index + 1 != job.techSkill.length ? ', ' : ''
                                                                    }`}</span>
                                                                ))}
                                                            </li>
                                                            <li>
                                                                <strong>Location: </strong>
                                                                {job.city.name}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            ))}

                                            {jobs.length === 0 && <p>No Jobs</p>}

                                            {/* Pagination */}
                                            <Pagination
                                                totalPages={totalPages}
                                                currentPage={currentPage}
                                                changePage={(page) => changeJobPage(page)}
                                                itemCount={totalCount}
                                            />
                                        </div>
                                    </div>

                                    {/* Job Seekers Applied list */}
                                    <div className="employer-pros-list-content-right ml-15">
                                        <h2 className="text-secondary text-center">Job Seeker List</h2>
                                        <Row className="mb-4">
                                            <Column className="col-3">
                                                <input
                                                    type="text"
                                                    onChange={(e) => setApplicantName(e.target.value)}
                                                    value={applicantName}
                                                    className="form-control"
                                                    placeholder="Job Seeker Name"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>
                                            <Column className="col-3">
                                                <input
                                                    type="text"
                                                    onChange={(e) => setApplicantEmail(e.target.value)}
                                                    value={applicantEmail}
                                                    className="form-control"
                                                    placeholder="Job seeker email"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>
                                            <Column className="col-2">
                                                <input
                                                    type="number"
                                                    onChange={(e) => setApplicantMobile(e.target.value)}
                                                    value={applicantMobile}
                                                    className="form-control"
                                                    placeholder="Job seeker mobile"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>
                                            <Column className="col-2">
                                                <input
                                                    type="number"
                                                    onChange={(e) => setApplicantMinExperience(e.target.value)}
                                                    value={applicantMinExperience}
                                                    className="form-control"
                                                    placeholder="Min experience"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>
                                            <Column className="col-2">
                                                <input
                                                    type="number"
                                                    onChange={(e) => setApplicantMaxExperience(e.target.value)}
                                                    value={applicantMaxExperience}
                                                    className="form-control"
                                                    placeholder="Max experience"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>
                                        </Row>

                                        <Row className="pb-4">
                                            <Column className="col-3">
                                                <input
                                                    type="number"
                                                    onChange={(e) => setApplicantMinNoticePeriod(e.target.value)}
                                                    value={applicantMinNoticePeriod}
                                                    className="form-control"
                                                    placeholder="Min Notice period"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>
                                            <Column className="col-3">
                                                <input
                                                    type="number"
                                                    onChange={(e) => setApplicantMaxNoticePeriod(e.target.value)}
                                                    value={applicantMaxNoticePeriod}
                                                    className="form-control"
                                                    placeholder="Max Notice period"
                                                    disabled={selectJobId === ''}
                                                />
                                            </Column>

                                            {userType == Constants.TYPE_USER_EMPLOYER && (
                                                <>
                                                    <Column className="col-2 employer-assignment-search">
                                                        <Box className="form-check form-switch">
                                                            <input
                                                                className="form-check-search-input form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                disabled={selectJobId === ''}
                                                                checked={applicantBestFit}
                                                                onChange={(e) => setApplicantBestFit(e.target.checked)}
                                                            />
                                                            <Box
                                                                Element="label"
                                                                className="form-check"
                                                                style={{ ...textStyle }}
                                                                htmlFor={`is-best-fit`}>
                                                                Best Fit?
                                                            </Box>
                                                        </Box>
                                                    </Column>
                                                    <Column className="col-3 employer-assignment-search">
                                                        <Box className="form-check form-switch">
                                                            <input
                                                                className="form-check-search-input form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                checked={applicantServingNotice}
                                                                disabled={selectJobId === ''}
                                                                onChange={(e) =>
                                                                    setApplicantServingNotice(e.target.checked)
                                                                }
                                                            />
                                                            <Box
                                                                Element="label"
                                                                className="form-check"
                                                                style={{ ...textStyle }}
                                                                htmlFor={`is-best-fit`}>
                                                                Serving Notice Period ?
                                                            </Box>
                                                        </Box>
                                                    </Column>
                                                </>
                                            )}

                                            <Column className="col-1">
                                                <Button
                                                    onClick={() => getJobApplicantsByPage(1, selectJobId)}
                                                    btnType="submit"
                                                    className="radius-none px-4"
                                                    disabled={selectJobId === ''}
                                                    style={btnStyle}
                                                    label="Search"
                                                />
                                            </Column>
                                        </Row>
                                        <Row className="mb-4">
                                            <Toolbar applicants={applicants} />
                                            {/* Item */}
                                            {applicants.map((applicant, index) => (
                                                <Applicant
                                                    applicant={applicant}
                                                    index={index}
                                                    key={`${applicant.jobSeeker.id}-${index}`}
                                                    onToggleBestFit={(e) => toggleBestFitFlag(e, applicant.id, index)}
                                                    onAssign={() =>
                                                        changeCandidateStatus(
                                                            applicant.id,
                                                            applicant.jobSeeker.id,
                                                            Constants.STATUS_JOB_APP_ALLOTED,
                                                            false,
                                                            index
                                                        )
                                                    }
                                                    onUnassign={() =>
                                                        confirmAlert({
                                                            title: Constants.MSG_GEN_ALERT_UNASSIGN,
                                                            message: Constants.MSG_GEN_ALERT_UNASSIGN_DESC,
                                                            buttons: [
                                                                {
                                                                    label: 'Confirm',
                                                                    onClick: () =>
                                                                        changeCandidateStatus(
                                                                            applicant.id,
                                                                            applicant.jobSeeker.id,
                                                                            Constants.STATUS_JOB_APP_APPLIED,
                                                                            false,
                                                                            index
                                                                        )
                                                                },
                                                                {
                                                                    label: 'No'
                                                                }
                                                            ]
                                                        })
                                                    }
                                                    onShortlist={() =>
                                                        changeCandidateStatus(
                                                            applicant.id,
                                                            applicant.jobSeeker.id,
                                                            Constants.STATUS_JOB_APP_SHORTLISTED,
                                                            false,
                                                            index
                                                        )
                                                    }
                                                    onInterview={() =>
                                                        setInterviewType(Constants.STATUS_JOB_APP_INTERVIEW)
                                                    }
                                                    onHR={() => setInterviewType(Constants.STATUS_JOB_APP_HR)}
                                                    onJoined={() =>
                                                        confirmAlert({
                                                            title: Constants.MSG_GEN_ALERT_UNASSIGN,
                                                            message: Constants.MSG_GEN_ALERT_CAN_JOINED,
                                                            buttons: [
                                                                {
                                                                    label: 'Confirm',
                                                                    onClick: () =>
                                                                        changeCandidateStatus(
                                                                            applicant.id,
                                                                            applicant.jobSeeker.id,
                                                                            Constants.STATUS_JOB_APP_JOINED,
                                                                            false,
                                                                            index
                                                                        )
                                                                },
                                                                {
                                                                    label: 'Go Back'
                                                                }
                                                            ]
                                                        })
                                                    }
                                                    onApplication={() => getJobSeekerResumes(applicant.jobSeeker.id)}
                                                    onDocs={() => fetchUserDocs(applicant.jobSeeker.id, applicant.id)}
                                                    onToggleDocAccess={(e) =>
                                                        toggleAccessUserDocs(e, applicant.id, index)
                                                    }
                                                    onToggleServiceNoticePeriodStatus={(e) =>
                                                        toggleIsJobSeekerServeNoticePeriod(e, applicant.id, index)
                                                    }
                                                    onHold={() =>
                                                        changeCandidateStatus(
                                                            applicant.id,
                                                            applicant.jobSeeker.id,
                                                            applicant.status === Constants.STATUS_JOB_APP_HOLD
                                                                ? applicant.applicationLogs[1].status
                                                                : Constants.STATUS_JOB_APP_HOLD,
                                                            applicant.status === Constants.STATUS_JOB_APP_HOLD,
                                                            index
                                                        )
                                                    }
                                                />
                                            ))}

                                            {applicants.length === 0 && <p>No Applicants</p>}
                                        </Row>

                                        <div>
                                            {/* Pagination */}
                                            <Pagination
                                                totalPages={totalPagesApplicant}
                                                currentPage={currentPageApplicant}
                                                changePage={(page) => changeApplicantPage(page)}
                                                itemCount={totalCountApplicant}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {applicants.map((applicant, index) => (
                <div key={applicant.jobSeeker.id}>
                    {/* History Modal */}
                    <div
                        className="modal fade back-modal"
                        id={`historyModal${index}`}
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <table className="table table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Status</th>
                                                <th>User Type</th>
                                                <th>Updated</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applicant.applicationLogs.map((log, index) => (
                                                <tr key={index}>
                                                    <td>{`${log.user.firstName} ${log.user.middleName} ${log.user.lastName}`}</td>
                                                    <td>{log.status}</td>
                                                    <td>{log.userType}</td>
                                                    <td>{log.updated}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <Button
                                        data-bs-dismiss="modal"
                                        color="warning"
                                        className="px-4"
                                        style={btnStyle}
                                        label="Close"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Questionnaires Response mondal */}
                    <div
                        className="modal fade back-modal"
                        id={`questResponsesModal${index}`}
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <table className="table table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Sr No.</th>
                                                <th>Question</th>
                                                <th>Candidate Response</th>
                                                <th>Ideal Response</th>
                                                <th>Must have?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {applicant.questionnaireAnswers.map((question, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{question.question}</td>
                                                    <td>{question.answer}</td>
                                                    <td>{question.idealResponse}</td>
                                                    <td>{question.mustHave ? 'YES' : 'NO'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-footer">
                                    <Button
                                        data-bs-dismiss="modal"
                                        color="warning"
                                        className="px-4"
                                        style={btnStyle}
                                        label="Close"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Schedule Interview Modal */}
                    <div
                        className="modal fade"
                        id={`interviewModal${index}`}
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header"></div>
                                <div className="modal-body">
                                    <div className="p-3">
                                        <h3 className="text-white text-center fw-bold">SCHEDULE INTERVIEW </h3>
                                        <p className=" text-white text-center">
                                            Please Check ‘Preferred Time’ to set-up Interview
                                        </p>
                                        <div className="mb-3 text-center">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label  text-white">
                                                Select Interviewer <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                onChange={(e) => setInterviewerId(e.target.value)}
                                                value={interviewerId}
                                                className="form-select text-center"
                                                aria-label="Default select example">
                                                <option>Select Interviewer</option>
                                                {panelists.map((panelist) => (
                                                    <option value={panelist.id} key={panelist.id}>
                                                        {`${panelist.firstName} ${panelist.middleName} ${panelist.lastName} (${panelist.email}) [${panelist.type}]`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3 text-center">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label  text-white">
                                                Other Panelist emails
                                            </label>
                                            <textarea
                                                className="form-control"
                                                placeholder="Enter email addresses (comma separated and without spaces, Eg: johndoe@test.com,maria@test.com)"
                                                onChange={(e) => setOtherPanelists(e.target.value)}></textarea>
                                        </div>
                                        <div className="mb-3 text-center">
                                            <label
                                                htmlFor="exampleFormControlInput1"
                                                className="form-label text-white text-center">
                                                Select Date-Time <span className="text-danger">*</span>
                                            </label>
                                            <DateTimePicker
                                                minDate={new Date()}
                                                className="form-control"
                                                onChange={setInterviewDateTime}
                                                value={interviewDateTime}
                                            />
                                        </div>

                                        <div className="mb-3 text-center">
                                            <label htmlFor="test" className="form-label text-white ">
                                                Test Link
                                            </label>
                                            <input
                                                onChange={(e) => setTestLink(e.target.value)}
                                                value={testLink}
                                                type="text"
                                                className="form-control text-center"
                                                id="test"
                                                placeholder="Enter Test Link here"
                                            />
                                        </div>

                                        <div className="mb-3 text-center">
                                            <label htmlFor="Meeting" className="form-label text-white text-center">
                                                Meeting link
                                            </label>
                                            <input
                                                onChange={(e) => setMeetingLink(e.target.value)}
                                                value={meetingLink}
                                                type="text"
                                                className="form-control text-center"
                                                id="Meeting"
                                                placeholder="Enter Meeting Link here"
                                            />
                                        </div>

                                        <div className="mb-3 text-center">
                                            <label htmlFor="Meeting" className="form-label text-white text-center">
                                                Message
                                            </label>
                                            <textarea
                                                value={interviewMsg}
                                                className="form-control"
                                                onChange={(e) => setInterviewMsg(e.target.value)}
                                                placeholder="Enter message"></textarea>
                                        </div>

                                        <div className="mb-3 d-flex justify-content-center">
                                            <Button
                                                disabled={interviewLoading}
                                                className="px-4 me-2"
                                                style={btnStyle}
                                                label={
                                                    interviewLoading ? (
                                                        <>
                                                            <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                            Processing...
                                                        </>
                                                    ) : (
                                                        <>Schedule Interview</>
                                                    )
                                                }
                                                onClick={() =>
                                                    changeCandidateStatus(
                                                        applicant.id,
                                                        applicant.jobSeeker.id,
                                                        interviewType,
                                                        false,
                                                        index
                                                    )
                                                }
                                            />

                                            <Button
                                                id={`close-interview-modal${index}`}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                color="warning"
                                                className="px-5 ms-2"
                                                style={btnStyle}
                                                label="Cancel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Release Offer letter modal */}
                    <div
                        className="modal fade"
                        id={`offerLetter${index}`}
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header"></div>
                                <div className="modal-body">
                                    <div className="p-3">
                                        <h3 className="text-white text-center fw-bold">RELEASE OFFER LETTER</h3>
                                        <div className="mb-3 text-center">
                                            <label htmlFor="ctcToBeOffered" className="form-label text-white">
                                                CTC to be offered (Annually) <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                onChange={(e) => setCtc(e.target.value)}
                                                value={ctc}
                                                type="text"
                                                placeholder="Enter CTC to be offered to candidate (Annually)"
                                                className="form-control"
                                                id="ctcToBeOffered"
                                            />
                                        </div>

                                        <div className="mb-3 text-center">
                                            <label htmlFor="joiningDate" className="form-label text-white">
                                                Joining Date <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                id="joiningDate"
                                                type="date"
                                                value={joiningDate}
                                                onChange={(e) => setJoiningDate(e.target.value)}
                                                className="form-control"
                                                min={moment().format('YYYY-MM-DD')}
                                            />
                                        </div>

                                        <div className="mb-3 text-center">
                                            <label htmlFor="joiningLocation" className="form-label text-white">
                                                Joining Location <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                onChange={(e) => setJoiningLocation(e.target.value)}
                                                value={joiningLocation}
                                                type="text"
                                                placeholder="Enter the joining location"
                                                className="form-control"
                                                id="joiningLocation"
                                            />
                                        </div>

                                        <div className="mb-3 text-center">
                                            <label htmlFor="offerLetterFile" className="form-label text-white">
                                                Select Offer Letter <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                onChange={(e) => setOfferLetter(e.target.files[0])}
                                                type="file"
                                                className="form-control"
                                                id="offerLetterFile"
                                            />
                                        </div>

                                        <div className="mb-3 d-flex justify-content-center">
                                            <Button
                                                disabled={offerLoading}
                                                color="primary"
                                                className="px-5 me-2"
                                                style={btnStyle}
                                                label={
                                                    offerLoading ? (
                                                        <>
                                                            <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                            Processing...
                                                        </>
                                                    ) : (
                                                        <>Release Offer</>
                                                    )
                                                }
                                                onClick={() =>
                                                    changeCandidateStatus(
                                                        applicant.id,
                                                        applicant.jobSeeker.id,
                                                        Constants.STATUS_JOB_APP_OFFERED,
                                                        false,
                                                        index
                                                    )
                                                }
                                            />
                                            <Button
                                                id="close-offer-modal"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                color="warning"
                                                className="px-5 ms-2"
                                                style={btnStyle}
                                                label="Cancel"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Reject Modal */}
                    <div
                        className="modal fade"
                        id={`reject${index}`}
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="p-3 text-center">
                                        <h3 className="text-white text-center fw-bold">REJECT PROSPECT</h3>
                                        <p className="text-white">
                                            You are going to Reject{' '}
                                            {`${applicant.jobSeeker.firstName} ${applicant.jobSeeker.middleName} ${applicant.jobSeeker.lastName}`}
                                            . Profile will be removed from List
                                        </p>

                                        <div className="p-3 text-center">
                                            <p className=" text-white fw-bold ">PLEASE SELECT A REASON BELOW</p>
                                            <div className="mb-3 text-center">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input form-check-input-modal"
                                                        type="checkbox"
                                                        value="Skills/Project Mismatch"
                                                        id={`skillsMismatch${index}`}
                                                        onChange={(e) => changeRejectReason(e)}
                                                    />
                                                    <label
                                                        className="form-check-label  text-white"
                                                        htmlFor={`skillsMismatch${index}`}>
                                                        Skills/Project Mismatch
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 text-center">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input form-check-input-modal"
                                                        type="checkbox"
                                                        value="Interview Not Cleared"
                                                        id={`interviewNotCleared${index}`}
                                                        onChange={(e) => changeRejectReason(e)}
                                                    />
                                                    <label
                                                        className="form-check-label text-white"
                                                        htmlFor={`interviewNotCleared${index}`}>
                                                        Interview Not Cleared
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="mb-3 text-center">
                                                <div className="form-check ">
                                                    <input
                                                        className="form-check-input form-check-input-modal"
                                                        type="checkbox"
                                                        value="Salary Expectation High"
                                                        id={`salaryHigh${index}`}
                                                        onChange={(e) => changeRejectReason(e)}
                                                    />
                                                    <label
                                                        className="form-check-label text-white"
                                                        htmlFor={`salaryHigh${index}`}>
                                                        Salary Expectation High
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="mb-3 text-center">
                                                <div className="form-check ">
                                                    <input
                                                        className="form-check-input text-center form-check-input-modal"
                                                        type="checkbox"
                                                        value="Appears to be Fake"
                                                        id={`appearsFake${index}`}
                                                        onChange={(e) => changeRejectReason(e)}
                                                    />
                                                    <label
                                                        className="form-check-label text-white"
                                                        htmlFor={`appearsFake${index}`}>
                                                        Appears to be Fake
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="mb-3 text-center">
                                                <div className="form-check ">
                                                    <input
                                                        className="form-check-input text-center form-check-input-modal"
                                                        type="checkbox"
                                                        value="Longer Notice period"
                                                        id={`longerNoticePeriod${index}`}
                                                        onChange={(e) => changeRejectReason(e)}
                                                    />
                                                    <label
                                                        className="form-check-label text-white"
                                                        htmlFor={`longerNoticePeriod${index}`}>
                                                        Longer Notice period
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="mb-3 text-center">
                                                <div className="form-check ">
                                                    <input
                                                        className="form-check-input text-center form-check-input-modal"
                                                        type="checkbox"
                                                        value="Not Interested"
                                                        id={`notInterested${index}`}
                                                        onChange={(e) => changeRejectReason(e)}
                                                    />
                                                    <label
                                                        className="form-check-label text-white"
                                                        htmlFor={`notInterested${index}`}>
                                                        Not Interested
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="mb-3 d-flex justify-content-center">
                                                <Button
                                                    color="primary"
                                                    className="px-5 me-2"
                                                    style={btnStyle}
                                                    label={
                                                        rejectLoading ? (
                                                            <>
                                                                <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                                Processing...
                                                            </>
                                                        ) : (
                                                            <>Reject</>
                                                        )
                                                    }
                                                    disabled={rejectLoading}
                                                    onClick={() =>
                                                        changeCandidateStatus(
                                                            applicant.id,
                                                            applicant.jobSeeker.id,
                                                            Constants.STATUS_JOB_APP_REJECT,
                                                            false,
                                                            index
                                                        )
                                                    }
                                                />
                                                <Button
                                                    id={`reject-modal${index}`}
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    color="warning"
                                                    className="px-5 ms-2"
                                                    style={btnStyle}
                                                    label="Cancel"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Profile Modal */}
                    <div
                        className="modal fade back-modal"
                        id={`profileModal${index}`}
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body">
                                    {/* CV Headline Modal */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">HEADLINE : </p>
                                            <p>
                                                {applicant.jobSeeker.profile.cvHeadline != ''
                                                    ? applicant.jobSeeker.profile.cvHeadline
                                                    : 'No Headline'}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Major Skills */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">MAJOR SKILLS : </p>
                                            {applicant.jobSeeker.profile.majorSkills.length != 0 ? (
                                                <>
                                                    <ul className="list-inline  mx-2">
                                                        {applicant.jobSeeker.profile.majorSkills.map((skill, index) => (
                                                            <li className="list-inline-item li-badge" key={index}>
                                                                {skill}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>No Skills</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Minor Skills */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">MINOR SKILLS : </p>
                                            {applicant.jobSeeker.profile.minorSkills.length != 0 ? (
                                                <>
                                                    <ul className="list-inline  mx-2">
                                                        {applicant.jobSeeker.profile.minorSkills.map((skill, index) => (
                                                            <li className="list-inline-item li-badge" key={index}>
                                                                {skill}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>No Skills</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Work Experience */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">WORK HISTORY : </p>
                                            {applicant.jobSeeker.profile.workHistory.length != 0 ? (
                                                <>
                                                    {applicant.jobSeeker.profile.workHistory.map((work, index) => (
                                                        <div className="work-history d-block mb-4" key={index}>
                                                            <p>
                                                                <span className="fw-bolder designation">
                                                                    {work.companyDesignation}{' '}
                                                                </span>
                                                                <br />
                                                                <span className="fw-bolder">{work.companyName}</span>
                                                                <br />
                                                                <span className="fst-italic text-secondary">{`${
                                                                    work.workingFromMonth
                                                                }/${work.workingFromYear} - ${
                                                                    work.isCurrentJob
                                                                        ? 'Present'
                                                                        : `${work.workedTillMonth}/${work.workedTillYear}`
                                                                }`}</span>
                                                            </p>
                                                            <p>{work.responsibilities}</p>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>No Work History</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Projects */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">PROJECTS : </p>
                                            {applicant.jobSeeker.profile.projects.length != 0 ? (
                                                <>
                                                    {applicant.jobSeeker.profile.projects.map((project, index) => (
                                                        <div className="work-history d-block mb-4" key={index}>
                                                            <ul className="list-inline ms-0 ps-0 mx-2">
                                                                <li className="list-inline-item ">
                                                                    {project.projectTitle}
                                                                </li>
                                                            </ul>
                                                            <p>
                                                                <span className="fw-bolder">
                                                                    {project.associationName}
                                                                </span>
                                                                <br />
                                                                <span className="fst-italic text-secondary">{`${
                                                                    project.workingFromMonth
                                                                }/${project.workingFromYear} - ${
                                                                    project.isCurrentlyInProgress
                                                                        ? 'Present'
                                                                        : `${project.workedTillMonth}/${project.workedTillYear}`
                                                                }`}</span>
                                                            </p>
                                                            <p>{project.details}</p>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>No Projects</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Project Links */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">PROJECT LINKS : </p>
                                            {applicant.jobSeeker.profile.projectLinks.length != 0 ? (
                                                <>
                                                    {applicant.jobSeeker.profile.projectLinks.map((project, index) => (
                                                        <div className="work-history d-block mb-4" key={index}>
                                                            <ul className="list-inline ms-0 ps-0 mx-2">
                                                                <li className="list-inline-item">
                                                                    {project.projectName}
                                                                </li>
                                                            </ul>
                                                            <p>
                                                                <a href="#" className="text-decoration-none ">
                                                                    {project.projectLink}
                                                                </a>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <>No Project Links</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Education */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">EDUCATION : </p>
                                            {applicant.jobSeeker.profile.education.length != 0 ? (
                                                <>
                                                    {applicant.jobSeeker.profile.education.map(
                                                        (educationItem, index) => (
                                                            <div className="work-history d-block" key={index}>
                                                                <p className="fw-bolder">
                                                                    <span className="fw-normal">
                                                                        {educationItem.educationType}
                                                                    </span>
                                                                    <br />
                                                                    {`${educationItem.course} - ${educationItem.specialization}`}
                                                                    <br />
                                                                    <span className="fw-normal">
                                                                        {educationItem.universityName}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <>No Education details</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Certifications */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">CERTIFICATIONS : </p>
                                            {applicant.jobSeeker.profile.certifications.length != 0 ? (
                                                <>
                                                    <ul className="list-unstyled">
                                                        {applicant.jobSeeker.profile.certifications.map(
                                                            (certification, index) => (
                                                                <li key={index}>{certification.name}</li>
                                                            )
                                                        )}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>No Certifications</>
                                            )}
                                        </div>
                                    </div>

                                    {/* Personal Details */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">PERSONAL DETAILS : </p>
                                            <ul className="list-unstyled">
                                                <li>Date of Birth</li>
                                                <li>
                                                    {applicant.jobSeeker.profile.dob != ''
                                                        ? applicant.jobSeeker.profile.dob
                                                        : 'NA'}
                                                </li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Pemanent Address</li>
                                                <li>
                                                    {applicant.jobSeeker.profile.permanentAddress != ''
                                                        ? applicant.jobSeeker.profile.permanentAddress
                                                        : 'NA'}
                                                </li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Gender</li>
                                                <li>
                                                    {applicant.jobSeeker.profile.gender != ''
                                                        ? applicant.jobSeeker.profile.gender
                                                        : 'NA'}
                                                </li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Marital Status</li>
                                                <li>
                                                    {applicant.jobSeeker.profile.maritalStatus != ''
                                                        ? applicant.jobSeeker.profile.maritalStatus
                                                        : 'NA'}
                                                </li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>Category</li>
                                                <li>
                                                    {applicant.jobSeeker.profile.category != ''
                                                        ? applicant.jobSeeker.profile.category
                                                        : 'NA'}
                                                </li>
                                            </ul>

                                            <ul className="list-unstyled">
                                                <li>
                                                    Languages:{' '}
                                                    {applicant.jobSeeker.profile.languages.length != 0 ? (
                                                        <>
                                                            {applicant.jobSeeker.profile.languages.map(
                                                                (language, index) => (
                                                                    <span key={language.value}>
                                                                        {`${language}${
                                                                            index + 1 !=
                                                                            applicant.jobSeeker.profile.languages.length
                                                                                ? ', '
                                                                                : ''
                                                                        }`}
                                                                    </span>
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>NA</>
                                                    )}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* Social Links */}
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <p className="fw-bold">SOCIAL LINKS : </p>
                                            {applicant.jobSeeker.profile.socialLinks.length != 0 ? (
                                                <>
                                                    <ul className="list-unstyled">
                                                        {applicant.jobSeeker.profile.socialLinks.map((link, index) => (
                                                            <li className="pt-2" key={index}>
                                                                <a href={`${link}`} target="_blank" rel="noreferrer">
                                                                    {link}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            ) : (
                                                <>No social links</>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Button
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        color="warning"
                                        className="px-5 ms-2"
                                        style={btnStyle}
                                        label="Close"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* View Job-Seeker Docs Modal */}
                    <div
                        className="modal fade back-modal"
                        id={`userDocsModal${index}`}
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="docs-table table-responsive">
                                        <table className="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Uploaded</th>
                                                    <th scope="col">Category</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documents.length > 0 ? (
                                                    <>
                                                        {documents.map((document, index) => (
                                                            <tr key={document.id}>
                                                                <th>{document.documentFileName}</th>
                                                                <td>
                                                                    {moment(document.createdAt).format('MMM Do YYYY')}
                                                                </td>
                                                                <td>{document.documentCategory}</td>
                                                                <td>
                                                                    <a
                                                                        href={document.documentPath}
                                                                        target="_blank"
                                                                        className="me-3">
                                                                        <i className="fas fa-cloud-download-alt"></i>{' '}
                                                                        Download
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        <tr>
                                                            <td colSpan="4">No Documents Available!</td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Button
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        color="warning"
                                        className="px-5 ms-2"
                                        style={btnStyle}
                                        label="Close"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Make application Modal */}
                    {userType == Constants.TYPE_USER_ADMIN && (
                        <>
                            <div
                                className="modal fade back-modal"
                                id={`applyModal${index}`}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <h3>
                                                Attention! You are applying on behalf of{' '}
                                                {`${applicant.jobSeeker.firstName} ${applicant.jobSeeker.middleName} ${applicant.jobSeeker.lastName}`}
                                            </h3>
                                            <br />

                                            <h5>
                                                1. Select Job Seeker Resume <span className="text-danger">*</span>
                                            </h5>
                                            <div className="mb-3">
                                                {!fetchResumesLoading ? (
                                                    <>
                                                        {resumes.length > 0 ? (
                                                            <>
                                                                <div className="resume-section row">
                                                                    {resumes.map((resume, index) => (
                                                                        <div className="col-md-4 mt-4" key={index}>
                                                                            <div className="card resume-card">
                                                                                <div className="card-body">
                                                                                    <input
                                                                                        checked={resumeId == resume.id}
                                                                                        onChange={(e) =>
                                                                                            handleResumeChange(e)
                                                                                        }
                                                                                        type="radio"
                                                                                        name="resume"
                                                                                        value={resume.id}
                                                                                    />
                                                                                    <label className="resume-label ms-2">
                                                                                        {resume.resumeTitle}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                No Resumes found! Please upload job-seeker resume in Job
                                                                Seeker profile!
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="fas fa-circle-notch fa-spin"></i> Fetching
                                                        Resumes....
                                                    </>
                                                )}
                                            </div>

                                            {/* Question Item */}
                                            {questionnaireAnswers.length > 0 && (
                                                <>
                                                    {questionnaireAnswers.map((questionnaire, index) => (
                                                        <div className="question mb-4" key={index}>
                                                            <h5>
                                                                {`${index + 2}. ${questionnaire.question}`}{' '}
                                                                <span className="text-danger">*</span>
                                                            </h5>

                                                            {questionnaire.responseType ==
                                                            Constants.TYPE_RESPONSE_YES_NO ? (
                                                                <>
                                                                    <select
                                                                        name="answer"
                                                                        className="form-select w-25"
                                                                        onChange={(e) =>
                                                                            handleQuestionnaireAnsChange(
                                                                                index,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        value={questionnaire.answer}>
                                                                        <option>Yes</option>
                                                                        <option>No</option>
                                                                    </select>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <input
                                                                        type="number"
                                                                        name="answer"
                                                                        value={questionnaire.answer}
                                                                        onChange={(e) =>
                                                                            handleQuestionnaireAnsChange(
                                                                                index,
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        className="form-control w-25"
                                                                        placeholder="Enter Details"
                                                                    />
                                                                </>
                                                            )}
                                                        </div>
                                                    ))}
                                                </>
                                            )}

                                            <Button
                                                id={`apply-close-btn${index}`}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                color="warning"
                                                className="px-5 ms-2"
                                                style={btnStyle}
                                                label="Close"
                                            />
                                            {resumes.length > 0 && (
                                                <Button
                                                    color="primary"
                                                    className="px-5 ms-2"
                                                    onClick={(e) => handleApplyJob(e, applicant.jobSeeker.id, index)}
                                                    style={btnStyle}
                                                    label={
                                                        postJobLoading ? (
                                                            <>
                                                                <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                                Applying...
                                                            </>
                                                        ) : (
                                                            <>Apply</>
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Send Admin Link Modal */}
                            <div
                                className="modal fade back-modal"
                                id={`sendTestModal${index}`}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex="-1">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <div className="col-sm-12 mt-3 border p-3 rounded">
                                                <label htmlFor={`messageSubject${index}`}>Message Subject</label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter Subject"
                                                    id={`messageSubject${index}`}
                                                    onChange={(e) => handleAdminTestSubjectChange(e, index)}
                                                    value={applicant.updatedSubject ? applicant.updatedSubject : ''}
                                                    className="form-control"
                                                />
                                                <label htmlFor={`messageBody${index}`}>Message Body</label>
                                                <textarea
                                                    onChange={(e) => handleAdminTestLinkChange(e, index)}
                                                    value={applicant.updatedTestLink ? applicant.updatedTestLink : ''}
                                                    className="form-control"
                                                    id={`messageBody${index}`}
                                                    disabled={!applicant.hasApplication}
                                                    placeholder="Enter message"></textarea>

                                                <div className="mt-4 row">
                                                    <div className="col-md-8 ps-0">
                                                        <Button
                                                            onClick={(e) => handleAdminTestLinkSubmit(e, index)}
                                                            disabled={!applicant.hasApplication || sendTestLoading}
                                                            color="primary"
                                                            className="px-5 w-100"
                                                            style={btnStyle}
                                                            label={
                                                                sendTestLoading ? (
                                                                    <>
                                                                        <i className="fas fa-circle-notch fa-spin"></i>{' '}
                                                                        Processing...
                                                                    </>
                                                                ) : (
                                                                    <>Send message</>
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-4 pe-0">
                                                        <Button
                                                            data-bs-dismiss="modal"
                                                            id={`close-test-link-modal${index}`}
                                                            aria-label="Close"
                                                            color="warning"
                                                            className="px-5 w-100"
                                                            style={btnStyle}
                                                            label="Close"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    )
}

export default AssignmentList
