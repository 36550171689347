import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getUserType } from '../../../features/user/userSlice'
import Constants from '../../../helpers/Constants'
import { Button } from '../../vanilla'

function SearchModal({ onSearch }) {
    const [companyName, setCompanyName] = useState('')
    const [candidateName, setCandidateName] = useState('')
    const [jobTitle, setJobTitle] = useState('')

    const userType = useSelector(getUserType)

    return (
        <div
            className="modal fade"
            id="searchModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog back-modal modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header p-3">
                        <h3 className="modal-title" id="exampleModalLabel">
                            Search Interviews
                        </h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {(userType === Constants.TYPE_USER_ADMIN || userType === Constants.TYPE_USER_JOB_SEEKER) && (
                            <div className="mb-3">
                                <label htmlFor="companyName" className="form-label">
                                    Company Name
                                </label>
                                <input
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    value={companyName}
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    placeholder="Company Name"
                                />
                            </div>
                        )}

                        {userType !== Constants.TYPE_USER_JOB_SEEKER && (
                            <div className="mb-3">
                                <label htmlFor="candidateName" className="form-label">
                                    Job Seeker Name
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) => setCandidateName(e.target.value)}
                                    value={candidateName}
                                    className="form-control"
                                    id="candidateName"
                                    placeholder="Job Seeker Name"
                                />
                            </div>
                        )}

                        <div className="mb-3">
                            <label htmlFor="jobTitle" className="form-label">
                                Job Title
                            </label>
                            <input
                                onChange={(e) => setJobTitle(e.target.value)}
                                value={jobTitle}
                                type="text"
                                className="form-control"
                                id="jobTitle"
                                placeholder="Job Title"
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="warning"
                            className="px-5"
                            data-bs-dismiss="modal"
                            id="searchModal"
                            label="Close"
                        />
                        <Button
                            color="primary"
                            className="px-5"
                            onClick={() => onSearch(companyName, candidateName, jobTitle)}
                            label="Search"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

SearchModal.propTypes = {
    onSearch: PropTypes.func
}

export default SearchModal
