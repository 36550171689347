import React from 'react'
import { Box, Heading, Flex } from '../../../components/vanilla'
import Constants from '../../../helpers/Constants'
import { useParams } from 'react-router-dom'
import AddForm from '../../../components/forms/advertisements/Form'
import { useGetAdvertisement } from '../../../hooks/useAdmin'
import SimpleLoader from '../../../components/vanilla/loaders/SimpleLoader/SImpleLoader'

function EditAdvertisement() {
    const adsId = useParams().adsId
    const { data: advertisementData, isLoading: isInitLoading, refetch: refetchAds } = useGetAdvertisement({ adsId })
    return (
        <>
            {!isInitLoading ? (
                <>
                    {advertisementData?.data !== undefined ? (
                        <Box className="admin-container mb-4">
                            <Heading size="lg">Edit Advertisement</Heading>
                            <AddForm
                                formType={Constants.TYPE_EDIT}
                                adsId={adsId}
                                adsData={advertisementData?.data}
                                refetchAds={refetchAds}
                            />
                        </Box>
                    ) : (
                        <>No Advertisement Found</>
                    )}
                </>
            ) : (
                <Flex className="align-items-center justify-content-center flex-column">
                    <SimpleLoader size="50px" />
                    <Box className="mt-3">Please wait, we are processing your data. This might take a few moments!</Box>
                </Flex>
            )}
        </>
    )
}
export default EditAdvertisement
