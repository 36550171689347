import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getAllEmployerMembers } from '../../../../../api/Admin'
import { changePanelistResultAccess } from '../../../../../api/Common'
import Pagination from '../../../../../components/Pagination'
import ValidationError from '../../../../../errors/ValidationError'
import { setLoadingState } from '../../../../../features/loader/LoaderSlice'
import Constants from '../../../../../helpers/Constants'
import Utils from '../../../../../helpers/Utils'

function ListEmployerMember() {
    // getting the current employerId
    const employerId = useParams().employerId

    // Initialize dispatch here
    const dispatch = useDispatch()

    // Data Vars
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const [panelists, setPanelists] = useState([])
    const [searchParam, setSearchParam] = useState('')

    useEffect(() => {
        populateInitData()
    }, [])

    /** Function to populate initial data */
    const populateInitData = async () => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            await getEmployerMemberByPage(currentPage)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to get employer member by page number */
    const getEmployerMemberByPage = async (pageNumber) => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            const response = await getAllEmployerMembers(searchParam, pageNumber, employerId)

            // Setting the data here
            setCurrentPage(response.currentPage)
            setTotalPages(response.totalPages)
            setTotalCount(response.count)
            setPanelists([...response.panelists])
        } catch (error) {
            throw error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            await getEmployerMemberByPage(pageNumber)
        } catch (error) {
            throw error
        }
    }

    /** Function to search job seeker by name */
    const searchPanelists = async (e) => {
        try {
            await getEmployerMemberByPage(currentPage)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /**
     * Function to handle panelist access change
     */
    const handleAccessChange = async (e, panelistId, index) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            const checkValue = e.target.checked
            await changePanelistResultAccess(panelistId, checkValue ? 1 : 0)

            let values = [...panelists]
            values[index].canDecideInterviewResult = checkValue
            setPanelists(values)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    return (
        <div className="p-4 mb-4">
            <div className="mb-4 d-flex align-items-center justify-content-between">
                <h3>Manage Panelists</h3>
                <Link to={Constants.ROUTE_ADMIN_MANAGE_ADD_EMPLOYER_MEMBER(employerId)} className="btn-job btn-primary">
                    <i className="fas fa-user-plus"></i> Add New
                </Link>
            </div>

            <div className="row mb-3 ">
                <div className="col-sm-12 p-0">
                    <div className="input-group">
                        <input
                            onChange={(e) => setSearchParam(e.target.value)}
                            type="text"
                            className="form-control"
                            placeholder="Search Panelist"
                        />
                        <button
                            type="button"
                            className="btn btn-search btn-primary radius-none"
                            onClick={(e) => searchPanelists(e)}>
                            Search
                        </button>
                    </div>
                </div>
            </div>

            {panelists.map((panelist, index) => (
                <div className="card mb-4" key={panelist.id}>
                    <div className="card-body row align-items-center">
                        <div className="col-md-1 mb-2 mb-md-0 text-center text-md-start">
                            <img
                                src={`${
                                    panelist.avatarPath != ''
                                        ? panelist.avatarPath
                                        : Constants.DEFAULT_PATH_JOB_SEEKER_AVATAR
                                }`}
                                className="avatar-img rounded-circle"
                            />
                        </div>
                        <div className="col-md-4 mb-4 mb-md-0 text-center text-md-start">
                            <h4>{`${panelist.firstName} ${panelist.middleName} ${panelist.lastName}`}</h4>
                            <div>{panelist.email}</div>
                            <div>{panelist.phoneNumber}</div>
                            <div>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input me-2"
                                        type="checkbox"
                                        role="switch"
                                        id={`allowMakeDecision${index}`}
                                        checked={panelist.canDecideInterviewResult}
                                        onChange={(e) => handleAccessChange(e, panelist.id, index)}
                                    />
                                    <label className="form-check-label" htmlFor={`allowMakeDecision${index}`}>
                                        Allow to make decision?
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 text-md-end text-center mb-4 mb-md-0">
                            <a className="btn-job btn-success" onClick={() => alert('Coming Soon!')}>
                                <i className="fas fa-edit"></i> Edit Profile
                            </a>
                        </div>
                    </div>
                </div>
            ))}

            {/* Pagination */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={(page) => changePage(page)}
                itemCount={totalCount}
            />
        </div>
    )
}

export default ListEmployerMember
