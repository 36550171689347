import React from 'react'
import PropTypes from 'prop-types'

const Image = ({ Element, className, src, style, height, width, alt, ...props }) => {
    return <Element src={src} className={className} style={style} height={height} width={width} alt={alt} {...props} />
}

Image.propTypes = {
    Element: PropTypes.string,
    src: PropTypes.elementType.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string
}

Image.defaultProps = {
    Element: 'img',
    className: '',
    alt: 'Image Description'
}

export default Image
