import React from 'react'
import { useSelector } from 'react-redux'
import { getUserType } from '../../../../features/user/userSlice'
import Constants from '../../../../helpers/Constants'
import { Anchor, Box, Button, Column, Flex, Row } from '../../../vanilla'
import { FaCloudDownloadAlt, FaEnvelope } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Actions = ({
    applicant,
    index,
    textStyle,
    onToggleBestFit,
    onAssign,
    onUnassign,
    onShortlist,
    onInterview,
    onHR,
    onJoined,
    onApplication,
    onDocs,
    onToggleDocAccess,
    onToggleServiceNoticePeriodStatus
}) => {
    // Getting the user type
    const userType = useSelector(getUserType)

    /** Function to check if interview can be scheduled */
    const isInterviewScheduleDisabled = ({ application }) => {
        switch (application.status) {
            case Constants.STATUS_JOB_APP_SHORTLISTED:
                return false
            case Constants.STATUS_JOB_APP_INTERVIEW:
                // Getting the technical interviews
                let technicalInterviews = application.jobSchedulesTechnical ? application.jobSchedulesTechnical : []

                if (
                    (Array.isArray(technicalInterviews) &&
                        technicalInterviews.length > 0 &&
                        technicalInterviews[0].isCancelled) ||
                    application.interviewResult !== Constants.TYPE_RESULT_PENDING
                ) {
                    return false
                }

                break
            default:
        }

        return true
    }

    return (
        <>
            <Row>
                {applicant.hasApplication ? (
                    <>
                        <Column className="col-md-3 mb-2">
                            <Button
                                color="white"
                                data-bs-toggle="modal"
                                data-bs-target={`#profileModal${index}`}
                                className="w-100 py-2"
                                label="View Profile"
                            />
                        </Column>
                        {userType === Constants.TYPE_USER_ADMIN &&
                            applicant.status !== Constants.STATUS_JOB_APP_REJECT &&
                            applicant.status !== Constants.STATUS_JOB_APP_JOINED && (
                                <>
                                    {applicant.status === Constants.STATUS_JOB_APP_APPLIED && (
                                        <Column className="col-md-3 mb-2">
                                            <Button
                                                color="white"
                                                className="w-100 py-2"
                                                disabled={applicant.status !== Constants.STATUS_JOB_APP_APPLIED}
                                                onClick={onAssign}
                                                label="Assign"
                                            />
                                        </Column>
                                    )}

                                    {applicant.status !== Constants.STATUS_JOB_APP_APPLIED &&
                                        applicant.status !== Constants.STATUS_JOB_APP_REJECT && (
                                            <Column className="col-md-3 mb-2">
                                                <Button
                                                    color="white"
                                                    className="w-100 py-2"
                                                    onClick={onUnassign}
                                                    label="UnAssign"
                                                />
                                            </Column>
                                        )}
                                </>
                            )}

                        <Column className="col-md-3 mb-2">
                            <Button
                                color="white"
                                className="w-100 py-2"
                                disabled={
                                    applicant.status !== Constants.STATUS_JOB_APP_ALLOTED ||
                                    (applicant.isVendorSourced && userType === Constants.TYPE_USER_ADMIN)
                                }
                                onClick={onShortlist}
                                label="Shortlist"
                            />
                        </Column>
                        <Column className="col-md-3 mb-2">
                            <Button
                                color="white"
                                className="w-100 py-2"
                                data-bs-toggle="modal"
                                data-bs-target={`#interviewModal${index}`}
                                disabled={
                                    isInterviewScheduleDisabled({
                                        application: applicant
                                    }) ||
                                    (applicant.isVendorSourced && userType === Constants.TYPE_USER_ADMIN)
                                }
                                onClick={onInterview}
                                label={`Interview ${applicant.totalTechnicalCount}`}
                            />
                        </Column>
                        <Column className="col-md-3 mb-2">
                            <Button
                                data-bs-toggle="modal"
                                data-bs-target={`#interviewModal${index}`}
                                disabled={
                                    applicant.status !== Constants.STATUS_JOB_APP_INTERVIEW ||
                                    (applicant.isVendorSourced && userType === Constants.TYPE_USER_ADMIN)
                                }
                                color="white"
                                onClick={onHR}
                                className="w-100 py-2"
                                label="HR Round"
                            />
                        </Column>
                        <Column className="col-md-3 mb-2">
                            <Button
                                data-bs-toggle="modal"
                                data-bs-target={`#offerLetter${index}`}
                                disabled={
                                    applicant.status !== Constants.STATUS_JOB_APP_HR ||
                                    (applicant.isVendorSourced && userType === Constants.TYPE_USER_ADMIN)
                                }
                                color="white"
                                className="w-100 py-2"
                                label="Offer"
                            />
                        </Column>
                        {applicant.offerAccepted !== 'REJECTED' && (
                            <Column className="col-md-3 mb-2">
                                <Button
                                    disabled={
                                        applicant.status !== Constants.STATUS_JOB_APP_OFFERED ||
                                        (applicant.isVendorSourced && userType === Constants.TYPE_USER_ADMIN)
                                    }
                                    color="white"
                                    onClick={onJoined}
                                    className="w-100 py-2"
                                    label="Joined"
                                />
                            </Column>
                        )}

                        {userType === Constants.TYPE_USER_ADMIN && (
                            <Column className="col-md-3 mb-2">
                                <Link to={`/admin/interviews/${applicant.id}/chat`}>
                                    <Button color="white" className="w-100 py-2" label="Chat" />
                                </Link>
                            </Column>
                        )}
                    </>
                ) : (
                    <Column className="col-md-12 mb-2">
                        <Button
                            color="white"
                            data-bs-toggle="modal"
                            data-bs-target={`#applyModal${index}`}
                            className="w-100 py-2"
                            onClick={onApplication}
                            label="Make Application"
                        />
                    </Column>
                )}
            </Row>

            {(applicant.status === Constants.STATUS_JOB_APP_SHORTLISTED ||
                applicant.status === Constants.STATUS_JOB_APP_INTERVIEW) && (
                <Box style={textStyle} className="ps-3 my-3">
                    Total Technical Interviews: {applicant.totalTechnicalCount - 1}
                </Box>
            )}

            {applicant.hasApplication && (
                <Flex className="mx-2 mt-2 justify-content-between">
                    {userType === Constants.TYPE_USER_ADMIN ? (
                        <Button
                            data-bs-toggle="modal"
                            data-bs-target={`#userDocsModal${index}`}
                            color="primary"
                            className="px-5"
                            label="Docs"
                            icon={<FaCloudDownloadAlt />}
                        />
                    ) : (
                        <>
                            <ShowDocs hasAccess={applicant.canAccessUserDocs} index={index} onDocs={onDocs} />
                        </>
                    )}

                    <Anchor href={applicant.resume.resumePath} target="_blank">
                        <Button color="warning" className="px-5" label="Resume" icon={<FaCloudDownloadAlt />} />
                    </Anchor>

                    {userType === Constants.TYPE_USER_ADMIN && (
                        <Button
                            data-bs-toggle="modal"
                            data-bs-target={`#sendTestModal${index}`}
                            color="success"
                            className="px-5"
                            label="Send Message"
                            icon={<FaEnvelope />}
                        />
                    )}
                </Flex>
            )}

            {applicant.hasApplication && userType === Constants.TYPE_USER_ADMIN && (
                <>
                    <Flex className="justify-content-between ms-2 mt-3">
                        <Box className="form-check form-switch">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                role="switch"
                                checked={applicant.canAccessUserDocs}
                                onChange={(e) => onToggleDocAccess(e)}
                                id={`allow-employer-doc-check-box-${index}`}
                                style={{ marginTop: 0 }}
                            />
                            <Box
                                Element="label"
                                className="form-check mb-0 ps-0"
                                style={{ width: '225px', ...textStyle }}
                                htmlFor={`allow-employer-doc-check-box-${index}`}>
                                Allow Employer to Download Docs?
                            </Box>
                        </Box>

                        <Box className="form-check form-switch">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                role="switch"
                                id={`is-best-fit-${index}`}
                                style={{ marginTop: 0 }}
                                checked={applicant.isBestFit}
                                onChange={(e) => onToggleBestFit(e)}
                            />
                            <Box
                                Element="label"
                                className="form-check mb-0 ps-0"
                                style={{ width: '70px', ...textStyle }}
                                htmlFor={`is-best-fit-${index}`}>
                                Best Fit?
                            </Box>
                        </Box>
                    </Flex>
                    <Flex className="justify-content-between ms-2 mt-3">
                        <Box className="form-check form-switch">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                role="switch"
                                checked={applicant.servingNoticePeriod}
                                onChange={(e) => onToggleServiceNoticePeriodStatus(e)}
                                id={`is-job-seeker-serve-notice-period-${index}`}
                                style={{ marginTop: 0 }}
                            />
                            <Box
                                Element="label"
                                className="form-check mb-0 ps-0"
                                style={{ width: '300px', ...textStyle }}
                                htmlFor={`is-job-seeker-serve-notice-period-${index}`}>
                                Serving Notice Period ?
                            </Box>
                        </Box>
                    </Flex>
                </>
            )}
        </>
    )
}

const DocsButton = ({ index, onDocs }) => {
    return (
        <Button
            data-bs-toggle="modal"
            data-bs-target={`#userDocsModal${index}`}
            color="primary"
            onClick={onDocs}
            className="px-5"
            label="Docs"
        />
    )
}

const ShowDocs = ({ hasAccess, index, onDocs }) => {
    if (hasAccess) return <DocsButton index={index} onDocs={onDocs} />
    else return null
}

export default Actions
