export default function useFormFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    termsConditions
}) {
    const fields = {
        body: {
            name: 'body',
            id: 'body',
            label: 'Body',
            placeholder: 'Enter Terms and conditions',
            type: 'ckeditor',
            isRequired: true,
            defaultValue: '',
            floating: true,
            rules: {
                required: 'Terms and Conditions is required!'
            },
            error: errors.body,
            control
        }
    }

    // In case of edit setting all the default fields
    if (termsConditions) {
        setValue('body', termsConditions.body)
        setValue('id', termsConditions.id)
    }

    return fields
}
