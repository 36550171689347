import React from 'react'
import Constants from '../../../helpers/Constants'
import { useGetAdmin, useGetAllSidebars, useGetSidebarFromEdit } from '../../../hooks/useAdmin'
import { Box, Column, Heading, Input, Row } from '../../vanilla'
import useFormFields from './useFormFields'

function FormFields({ form, adminId, formType = Constants.TYPE_ANON_ADD }) {
    const { data: adminSidebarURLs } = useGetAllSidebars()
    const { data: adminAccessList } = useGetSidebarFromEdit({
        adminId,
        enabled: adminId !== undefined
    })
    const { data: adminData } = useGetAdmin({ adminId, enabled: adminId !== undefined })
    const {
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber,
        isSuperAdmin,
        canViewDashboard,
        canTogglePool,
        accessList
    } = useFormFields({
        form,
        accessList: adminSidebarURLs?.urls,
        accesses: adminAccessList?.sidebar,
        admin: adminData?.admin,
        formType
    })

    return (
        <Box className="mb-3">
            <Row>
                <Column className="col-md-4">
                    <Input {...firstName} />
                </Column>
                <Column className="col-md-4">
                    <Input {...middleName} />
                </Column>
                <Column className="col-md-4">
                    <Input {...lastName} />
                </Column>
            </Row>
            <Row>
                <Column className="col-md-6">
                    <Input {...email} />
                </Column>
                <Column className="col-md-6">
                    <Input {...phoneNumber} />
                </Column>
                <Column className="col-md-2 mt-2">
                    <Input {...isSuperAdmin} />
                </Column>
                <Column className="col-md-2 mt-2">
                    <Input {...canViewDashboard} />
                </Column>
                <Column className="col-md-2 mt-2">
                    <Input {...canTogglePool} />
                </Column>
            </Row>
            <Heading className="mt-4">Access Controls (For super-admins, the access control doesn't matter)</Heading>
            <Row>
                {Array.isArray(accessList) && accessList.length > 0 && (
                    <>
                        {accessList.map((access) => (
                            <Column className="col-md-2 mb-3" key={access.id}>
                                <Input {...access} />
                                <Box className="text-muted">{access.url}</Box>
                            </Column>
                        ))}
                    </>
                )}
            </Row>
        </Box>
    )
}

export default FormFields
