import React from 'react'
import { useParams } from 'react-router-dom'
import AddForm from '../../../components/jobs/AddForm'
import Constants from '../../../helpers/Constants'

function EditJob() {
    // Getting job Id
    const jobId = useParams().jobId

    return (
        <div>
            <section className="py-5 text-center hero-inner">
                <div className="row mrl-0 py-lg-5 pt-0">
                    <div className="col-lg-12 col-md-12 mx-auto">
                        <h1>Admin Edit Job </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="registration">
                                <div className="reg-formcon unick-box">
                                    {/* Add Job Form with edit */}
                                    <AddForm jobId={jobId} type={Constants.TYPE_ANON_EDIT} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EditJob
