import React from 'react'
import PropTypes from 'prop-types'

const Anchor = ({ Element, className, children, href, ...props }) => {
    return (
        <Element href={href} className={[className, 'text-decoration-none'].join(' ')} {...props}>
            {children}
        </Element>
    )
}

Anchor.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    href: PropTypes.string
}

Anchor.defaultProps = {
    Element: 'a',
    className: '',
    href: '#'
}

export default Anchor
