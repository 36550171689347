import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import AddForm from '../../../components/forms/advertisements/Form'

function AddAdvertisement() {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Add New Advertisement</Heading>
            <AddForm />
        </Box>
    )
}
export default AddAdvertisement
