/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
    isLoggedIn,
    getUserType,
    resetUserProfile,
    getUserFirstName,
    getUserEmail,
    getCompanyName,
    getUserAvatarPath,
    getEmployerType,
    getIsVendor
} from '../features/user/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import Constants from '../helpers/Constants'
import { logoutUser, resetUserDataLocalStorage } from '../api/Auth'
import Utils from '../helpers/Utils'
import { AiOutlineBars } from 'react-icons/ai'
import { Box, Button, Avatar } from './vanilla'
import { setLoadingState } from '../features/loader/LoaderSlice'

function Header() {
    // Checking if user is logged in
    const isAuthenticated = useSelector(isLoggedIn)
    // Getting current user type
    const userType = useSelector(getUserType)
    // Getting current employer type
    const employerType = useSelector(getEmployerType)

    // Getting current isVendor
    const isVendor = useSelector(getIsVendor)

    // Getting the user first name in case user is logged in
    let userFirstName = useSelector(getUserFirstName)
    const userEmail = useSelector(getUserEmail)
    const companyName = useSelector(getCompanyName)
    const userAvatar = useSelector(getUserAvatarPath)
    // Changing the first name to company name in case of employer
    if (userType === Constants.TYPE_USER_EMPLOYER && employerType === Constants.TYPE_USER_EMPLOYER_COMPANY && !isVendor)
        userFirstName = companyName

    // Init location here
    const location = useLocation()

    // Checking if the route is admin and non-authenticated (No need to render the header in case of non-authenticated admin)
    if (Utils.isAdminRoute(location.pathname)) {
        if (!isAuthenticated) return null
        if (userType !== Constants.TYPE_USER_ADMIN) return null
    }

    const btnStyle = { fontSize: '13px' }

    const changePwdURL = () => {
        if (userType === Constants.TYPE_USER_ADMIN) return Constants.ROUTE_ADMIN_CHANGE_PWD
        if (userType === Constants.TYPE_USER_EMPLOYER) return Constants.ROUTE_CLIENT_EMPLOYER_CHANGE_PWD
        return Constants.ROUTE_CLIENT_JOB_SEEKER_CHANGE_PWD
    }
    return (
        <Box Element="header">
            <Box className="navbar navbar-expand-md navbar-light bg-light ">
                <Box className="container-fluid">
                    {Utils.isAdminRoute(location.pathname) && userType === Constants.TYPE_USER_ADMIN && (
                        <AiOutlineBars
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasExample"
                            aria-controls="offcanvasExample"
                            fontSize={30}
                            className="me-3"
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                    <Link
                        to={
                            userType === Constants.TYPE_USER_ADMIN
                                ? Constants.ROUTE_ADMIN_HOME
                                : Constants.ROUTE_CLIENT_HOME
                        }
                        className="navbar-brand">
                        <img src="/assets/img/immediatejoblogo.png" className="img-fluid web-logo" alt="Logo" />
                    </Link>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Box className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {/* Non-Admin Navs */}
                            {!Utils.isAdminRoute(location.pathname) && (
                                <>
                                    {/* Not Authenticated */}
                                    {!isAuthenticated && (
                                        <>
                                            {/* Home route nav items */}
                                            <li className="nav-item">
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_HOME}
                                                    className="nav-link"
                                                    aria-current="page">
                                                    Job Search
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_EMPLOYER_POST_JOB}
                                                    className="nav-link">
                                                    Post Job
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={Constants.ROUTE_CLIENT_ABOUT_US} className="nav-link">
                                                    About Us
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={Constants.ROUTE_CLIENT_CONTACT_US} className="nav-link">
                                                    Contact Us
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={Constants.ROUTE_CLIENT_BUY_SERVICES} className="nav-link">
                                                    Buy Services
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={Constants.ROUTE_CLIENT_HIRING_SOLUTIONS} className="nav-link">
                                                    Hiring solutions
                                                </Link>
                                            </li>
                                        </>
                                    )}

                                    {/* Job Seeker Nav Items */}
                                    {userType === Constants.TYPE_USER_JOB_SEEKER && (
                                        <>
                                            <li className="nav-item">
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_JOB_SEEKER_JOBS}
                                                    className="nav-link"
                                                    aria-current="page">
                                                    Jobs{' '}
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_JOB_SEEKER_INTERVIEWS}
                                                    className="nav-link">
                                                    Interviews &amp; Chat{' '}
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_JOB_SEEKER_DOCUMENTS}
                                                    className="nav-link">
                                                    Documents
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_JOB_SEEKER_APPLICATIONS}
                                                    className="nav-link">
                                                    Applications
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={Constants.ROUTE_CLIENT_JOB_SEEKER_PROFILE}
                                                    className="nav-link">
                                                    Profile
                                                </Link>
                                            </li>
                                        </>
                                    )}

                                    {/* Employer & others Nav Items */}
                                    {userType === Constants.TYPE_USER_EMPLOYER && (
                                        <>
                                            {employerType === Constants.TYPE_USER_EMPLOYER_COMPANY &&
                                                isVendor === false && (
                                                    <>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={Constants.ROUTE_CLIENT_EMPLOYER_DASHBOARD}
                                                                className="nav-link">
                                                                Dashboard{' '}
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item dropdown employer-dropdown">
                                                            <a
                                                                className="nav-link dropdown-toggle"
                                                                href="#"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Jobs
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu employer-dropdown-menu"
                                                                aria-labelledby="navbarDropdown">
                                                                <li>
                                                                    <Link
                                                                        to={Constants.ROUTE_CLIENT_EMPLOYER_JOB_LIST}
                                                                        className="dropdown-item">
                                                                        Job List
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={Constants.ROUTE_CLIENT_EMPLOYER_POST_JOB}
                                                                        className="dropdown-item">
                                                                        New Job
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                        <li className="nav-item dropdown employer-dropdown">
                                                            <a
                                                                className="nav-link dropdown-toggle"
                                                                href="#"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Vendors
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu employer-dropdown-menu"
                                                                aria-labelledby="navbarDropdown">
                                                                <li>
                                                                    <Link
                                                                        to={Constants.ROUTE_CLIENT_EMPLOYER_VENDOR_LIST}
                                                                        className="dropdown-item">
                                                                        Vendor List
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={Constants.ROUTE_CLIENT_EMPLOYER_ADD_VENDOR}
                                                                        className="dropdown-item">
                                                                        Add Vendor
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="nav-item dropdown employer-dropdown">
                                                            <a
                                                                className="nav-link dropdown-toggle"
                                                                href="#"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Prospects
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu employer-dropdown-menu"
                                                                aria-labelledby="navbarDropdown">
                                                                <li>
                                                                    <Link
                                                                        to={
                                                                            Constants.ROUTE_CLIENT_EMPLOYER_PROSPECT_LIST
                                                                        }
                                                                        className="dropdown-item">
                                                                        Prospects List
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="nav-item dropdown employer-dropdown">
                                                            <a
                                                                className="nav-link dropdown-toggle"
                                                                href="#"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Panels
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu employer-dropdown-menu"
                                                                aria-labelledby="navbarDropdown">
                                                                <li>
                                                                    <Link
                                                                        to={Constants.ROUTE_CLIENT_EMPLOYER_PANEL_LIST}
                                                                        className="dropdown-item">
                                                                        Panels List
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={Constants.ROUTE_CLIENT_EMPLOYER_PANEL_ADD}
                                                                        className="dropdown-item">
                                                                        Panels Add
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={Constants.ROUTE_CLIENT_EMPLOYER_JOB_SEEKER}
                                                                className="nav-link">
                                                                Resume Search{' '}
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}

                                            {isVendor === false && (
                                                <>
                                                    <li className="nav-item">
                                                        <Link
                                                            to={Constants.ROUTE_CLIENT_EMPLOYER_INTERVIEWS}
                                                            className="nav-link">
                                                            Interviews{' '}
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link
                                                            to={Constants.ROUTE_CLIENT_EMPLOYER_SCHEDULE}
                                                            className="nav-link">
                                                            Schedule{' '}
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                            {employerType === Constants.TYPE_USER_EMPLOYER_COMPANY &&
                                                isVendor === true && (
                                                    <>
                                                        <li className="nav-item dropdown employer-dropdown">
                                                            <a
                                                                className="nav-link dropdown-toggle"
                                                                href="#"
                                                                id="navbarDropdown"
                                                                role="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Job Seekers
                                                            </a>
                                                            <ul
                                                                className="dropdown-menu employer-dropdown-menu"
                                                                aria-labelledby="navbarDropdown">
                                                                <li>
                                                                    <Link
                                                                        to={
                                                                            Constants.ROUTE_CLIENT_VENDOR_JOB_SEEKER_LIST
                                                                        }
                                                                        className="dropdown-item">
                                                                        Job Seekers List
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link
                                                                        to={
                                                                            Constants.ROUTE_CLIENT_VENDOR_ADD_JOB_SEEKER
                                                                        }
                                                                        className="dropdown-item">
                                                                        Add Job Seekers
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link
                                                                to={Constants.ROUTE_CLIENT_VENDOR_INTERVIEWS}
                                                                className="nav-link">
                                                                Interviews{' '}
                                                            </Link>
                                                        </li>
                                                    </>
                                                )}
                                        </>
                                    )}
                                </>
                            )}
                        </ul>

                        {/* Utility Buttons (non-admin) */}
                        {!Utils.isAdminRoute(location.pathname) && !isAuthenticated && (
                            <>
                                <Button
                                    type="link"
                                    to={Constants.ROUTE_CLIENT_LOGIN_JOB_SEEKER}
                                    className="px-5 py-4 me-0 me-md-3 mb-3 mb-md-0 d-block d-md-inline"
                                    style={btnStyle}
                                    label="JOB SEEKER"
                                />
                                <Button
                                    type="link"
                                    color="warning"
                                    to={Constants.ROUTE_CLIENT_LOGIN_EMPLOYER}
                                    className="px-5 py-4"
                                    style={btnStyle}
                                    label="EMPLOYER"
                                />
                            </>
                        )}

                        {isAuthenticated && (
                            <>
                                <Box Element="ul" className="navbar-nav nav navbar-right">
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img
                                                src={`${
                                                    userAvatar !== ''
                                                        ? userAvatar
                                                        : '/assets/img/user/default-avatar.png'
                                                }`}
                                                className="welcome-avatar welcome-avatar-profile"
                                                alt="Avatar"
                                            />
                                            <Box className="avatar-name">Hi {userFirstName}</Box>
                                        </a>

                                        <Box className="dropdown-menu profile-menu-ul" aria-labelledby="navbarDropdown">
                                            <Box className="row-box">
                                                <Box className="image-box">
                                                    <Avatar
                                                        src={`${
                                                            userAvatar !== ''
                                                                ? userAvatar
                                                                : '/assets/img/user/default-avatar.png'
                                                        }`}
                                                        className="welcome-avatar welcome-avatar-profile"
                                                        alt="Avatar Image"
                                                    />
                                                </Box>
                                                <Box className="name-box">
                                                    <h4>{userFirstName}</h4>
                                                    <span className="email-address">{userEmail}</span>
                                                </Box>
                                            </Box>
                                            <hr className="divider" />
                                            <ul className="profile-sub-menu-ul">
                                                {userType !== Constants.TYPE_USER_ADMIN && (
                                                    <li className="nav-item">
                                                        <Link
                                                            to={
                                                                userType === Constants.TYPE_USER_JOB_SEEKER
                                                                    ? Constants.ROUTE_CLIENT_JOB_SEEKER_PROFILE
                                                                    : Constants.ROUTE_CLIENT_EMPLOYER_PROFILE
                                                            }
                                                            className="profile-link nav-link">
                                                            PROFILE
                                                        </Link>
                                                    </li>
                                                )}

                                                <li className="nav-item">
                                                    <Link to={changePwdURL()} className="profile-link nav-link">
                                                        Change Password
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <LogoutButton userType={userType} />
                                                </li>
                                            </ul>
                                        </Box>
                                    </li>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

function LogoutButton({ userType }) {
    // Init dispatch here
    const dispatch = useDispatch()
    // Init Navigate here
    const navigate = useNavigate()

    /** Function to logout user */
    const handleLogout = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            // Calling the Logout api
            await logoutUser()

            // Setting the loading here
            dispatch(setLoadingState(false))
        } catch (error) {
            // Setting the loading here
            dispatch(setLoadingState(false))
        } finally {
            // Clearing the data from store
            dispatch(resetUserProfile())
            // Clearing data from local storage
            resetUserDataLocalStorage()

            // Redirecting to home
            const redirectRoute =
                userType === Constants.TYPE_USER_ADMIN ? Constants.ROUTE_ADMIN_LOGIN : Constants.ROUTE_CLIENT_HOME
            navigate(redirectRoute)
        }
    }
    return (
        <Box onClick={handleLogout} className="nav-link logout-btn">
            LOG OUT
        </Box>
    )
}

export default Header
