import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ListStyles from './JobSeeker.module.css'

import {
    getAllJobSeekersPaginated,
    getAllTags as getTags,
    addNewTag as addNewAdminTag,
    jobSeekerMapping
} from '../../../../api/Admin'
import CreatableSelect from '../../../../components/elements/CreatableSelect'
import SingleSelect from '../../../../components/elements/SingleSelect'
import JobSeekerCard from '../../../../components/elements/user/job-seeker'
import Pagination from '../../../../components/Pagination'
import { Box, Button, Column, Row } from '../../../../components/vanilla'
import ValidationError from '../../../../errors/ValidationError'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import { getAdminType } from '../../../../features/user/userSlice'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import { useCRMMailForm } from '../../../../hooks/useEmail'
import CRMMailFields from '../admin/crm/CRMMailFields'

function JobSeekerList({ isPool = false }) {
    const form = useForm()
    // Getting current logged in admin type
    const adminType = useSelector(getAdminType)
    const navigate = useNavigate()

    let search = window.location.search
    let params = new URLSearchParams(search)

    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    // Data Vars
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    const [jobSeekers, setJobSeekers] = useState([])

    // Search parameters
    const [name, setName] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [currentLocation, setCurrentLocation] = useState('')
    const [minCTC, setMinCTC] = useState('')
    const [maxCTC, setMaxCTC] = useState('')
    const [minNoticePeriod, setMinNoticePeriod] = useState('')
    const [maxNoticePeriod, setMaxNoticePeriod] = useState('')
    const [technicalSkills, setTechnicalSkills] = useState([])
    const [skillIsMandatory, setSkillIsMandatory] = useState(false)
    const [tagSearch, setTagSearch] = useState('')
    const [totalExperience, setTotalExperience] = useState('')

    const [advancedSearch, setAdvancedSearch] = useState(false)

    const [crmCandidateList, setCrmCandidateList] = useState([])
    const [selectAllCandidate, setSelectAllCandidate] = useState('')
    const [enableEmail, setEnableEmail] = useState(false)

    // Tags var
    const [tags, setTags] = useState([])
    const [optionTags, setOptionTags] = useState([])
    const [isCreateTag, setIsCreateTag] = useState(false)
    const [listName, setListName] = useState('')

    const btnStyle = { fontSize: '15px' }

    // Initialize dispatch here
    const dispatch = useDispatch()

    window.onpopstate = async () => {
        try {
            setCurrentPage(pageNo)
            await getJobSeekerByPage(pageNo)
        } catch (error) {
            throw error
        }
    }

    useEffect(async () => {
        await populateInitData()
    }, [isPool])

    /** Function to populate initial data */
    const populateInitData = async () => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        setCurrentPage(pageNo > 0 ? pageNo : 1)
        try {
            // Getting job-seekers
            await getJobSeekerByPage(pageNo > 0 ? pageNo : currentPage)
            if (adminType === Constants.TYPE_USER_ADMIN_MAIN) {
                // Getting all the tags
                await getAllTags()
            }
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /**
     * Function to get all the tags
     */
    const getAllTags = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                // Getting all the tags here
                const tagsArr = await getTags()
                setTags(tagsArr)

                // Setting the tags in searchable option
                let newTagsArr = [{ label: 'All Tags', value: '' }]
                tagsArr.forEach((tag) => newTagsArr.push({ label: tag.name, value: tag.id }))
                setOptionTags(newTagsArr)

                resolve()
            } catch (error) {
                reject(error)
            }
        })
    }

    /** Function to get all the job-seekers by page number */
    const getJobSeekerByPage = async (pageNumber) => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        try {
            let skills = ''
            /** Refining the skills here */
            if (technicalSkills.length > 0) {
                technicalSkills.forEach((skill, index) => {
                    skills += `${skill.value}${index + 1 !== technicalSkills.length ? ',' : ''}`
                })
            }

            const response = await getAllJobSeekersPaginated(pageNumber, {
                name,
                mobileNumber,
                currentLocation,
                minCTC,
                maxCTC,
                totalExperience,
                minNoticePeriod,
                maxNoticePeriod,
                skillIsMandatory,
                skills,
                tagId: tagSearch,
                isPool
            })

            // Setting the data here
            setCurrentPage(response.currentPage)
            setTotalPages(response.totalPages)
            setTotalCount(response.count)
            setJobSeekers([...response.jobSeekers])
            setSelectAllCandidate(false)
            setCrmCandidateList([])
        } catch (error) {
            throw error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigate({
                pathname: isPool
                    ? Constants.ROUTE_ADMIN_MANAGE_LIST_POOL_USERS
                    : Constants.ROUTE_ADMIN_MANAGE_LIST_JOB_SEEKERS,
                search: '?page=' + pageNumber
            })

            await getJobSeekerByPage(pageNumber)
        } catch (error) {
            throw error
        }
    }

    /** Function to search job seeker by name */
    const searchJobSeeker = async () => {
        try {
            navigate({
                pathname: isPool
                    ? Constants.ROUTE_ADMIN_MANAGE_LIST_POOL_USERS
                    : Constants.ROUTE_ADMIN_MANAGE_LIST_JOB_SEEKERS,
                search: '?page=1'
            })
            await getJobSeekerByPage(1) // Should start a new search
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /**
     * Function to reload jobSeeker list
     */
    const reloadJSList = async () => {
        try {
            await getJobSeekerByPage(currentPage)
        } catch (error) {
            throw error
        }
    }

    /**
     * Callback to get technical skills from child
     * @param {*} skills
     */
    const updateTechnicalSkills = (skills) => {
        setTechnicalSkills(skills)
    }

    /**
     * Callback to get tags from child
     */
    const selectTag = (tag) => {
        setTagSearch(tag.value)
    }

    /**
     * Function to reload callback jobseeker list
     */
    const reloadJobSeekers = (newJobSeekerArr) => {
        setJobSeekers(newJobSeekerArr)
    }

    const selectAllJobSeekers = (isChecked) => {
        setCrmCandidateList([])
        setEnableEmail(false)
        setSelectAllCandidate(isChecked)
        let prevList = crmCandidateList
        // check if checked then assign all job-seekers email if not then make crmCandidate list empty
        if (isChecked) {
            for (const jobSeeker of jobSeekers) {
                prevList.push(jobSeeker.email)
            }
            setCrmCandidateList([...prevList])
            setEnableEmail(true)
        }
    }

    /**
     *
     * @param {*} isChecked
     * @param {*} jobSeekerEmail
     * Update list of jobSeekers once click on checkbox
     */
    const updateEmailList = (isChecked, jobSeekerEmail) => {
        let prevList = crmCandidateList
        setSelectAllCandidate(false)
        if (isChecked === false) {
            let index = prevList.indexOf(jobSeekerEmail)
            if (index > -1) {
                prevList.splice(index, 1)
            }
        } else {
            prevList.push(jobSeekerEmail)
        }
        setCrmCandidateList([...prevList])
        // check if count is greater then 1 then enable else disable
        if (crmCandidateList.length > 0) {
            setEnableEmail(true)
        } else {
            setEnableEmail(false)
        }
    }

    /** Function to close and reset modal and data */
    const closeAndReset = async () => {
        form.reset({})
        Utils.closeItem(`close-send-crm-email-modal`)
    }

    /**
     * Function to call method to send email to multiple selected job-seekers
     */
    const { mutateAsync: sendCRMEmail, isLoading } = useCRMMailForm()
    const onSubmit = async (data) => {
        try {
            const res = await sendCRMEmail({
                ...data,
                toEmails: crmCandidateList
            })
            if (res.status === Constants.STATUS_SUCCESS) {
                //if status is success then reset form data
                closeAndReset()
                // Showing success toast
                Utils.showToast(Constants.MSG_SEND_CRM_EMAIL_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
            }
        } catch (e) {
            Utils.showToast(Constants.MSG_SEND_CRM_EMAIL_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /**
     * Function to add a new tag
     */
    const addNewTag = async () => {
        try {
            // Validating request
            if (listName === '') throw new ValidationError(Constants.MSG_TAG_NAME_EMPTY)
            const tag = await addNewAdminTag({ name: listName })

            // Adding the tag to list
            const listArr = [...tags]
            listArr.push(tag)
            setTags(listArr)
            setIsCreateTag(false)
            setListName('')
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /**
     * Function to change the mapping status of Candidate with tag
     */
    const changeMappingStatus = async ({ e, jobSeekerId, tagId, index }) => {
        try {
            e.preventDefault()
            let checkedValue = e.target.checked
            await jobSeekerMapping({ jobSeekerId, tagId, mapValue: checkedValue })

            // Setting the value locally to reflect in UI
            let jobSeekersArr = [...jobSeekers]

            if (checkedValue) jobSeekersArr[index].tags.push(tagId)
            else {
                let tagIndex = jobSeekersArr[index].tags.indexOf(tagId)
                jobSeekersArr[index].tags.splice(tagIndex, 1)
            }

            setJobSeekers(jobSeekersArr)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    // Column width class based on admin type
    const colClass = adminType === Constants.TYPE_USER_ADMIN_MAIN ? 'col-md-3' : 'col-md-4'

    return (
        <div className="p-4 mb-4">
            <div className="mb-4 d-flex align-items-center justify-content-between">
                <h3>Manage {isPool ? 'Pool' : ''} Job-Seekers</h3>
                <Link
                    to={
                        isPool
                            ? Constants.ROUTE_ADMIN_MANAGE_ADD_POOL_USERS
                            : Constants.ROUTE_ADMIN_MANAGE_ADD_JOB_SEEKER
                    }
                    className="btn-job btn-primary">
                    <i className="fas fa-user-plus"></i> Add New
                </Link>
            </div>

            <div className="card mb-4 shadow-sm">
                <div className="card-body">
                    <h4>
                        <b>Search Pane</b>
                    </h4>
                    <form onSubmit={() => {}}>
                        {/* Primary Search Section */}
                        <div className="mt-3">
                            <label htmlFor="skills" className="form-label">
                                Skills
                            </label>
                            <CreatableSelect
                                initialValues={technicalSkills}
                                options={Constants.LIST_DEFAULT_TECH_SKILLS}
                                callback={updateTechnicalSkills}
                                placeholder="Search for a Skill and click to add"
                                createLabel="Search"
                            />

                            <div className="d-flex align-items-center">
                                <label className="col-form-label me-3" htmlFor="feature">
                                    Mandatory Skill Search?
                                </label>
                                <div className="form-check form-switch">
                                    <input
                                        onChange={(e) => setSkillIsMandatory(e.target.checked)}
                                        checked={skillIsMandatory}
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="feature"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* End Primary Search Section */}

                        {/* Advanced Search Section */}
                        <div style={{ display: `${advancedSearch ? 'block' : 'none'}` }}>
                            <div className="row">
                                <div className={['ps-md-0', colClass].join(' ')}>
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>
                                    <input
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        type="text"
                                        className="form-control"
                                        placeholder="John Doe"
                                        id="name"
                                    />
                                </div>
                                <div className={[colClass].join(' ')}>
                                    <label htmlFor="mobileNumber" className="form-label">
                                        Mobile Number
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMobileNumber(e.target.value)}
                                        value={mobileNumber}
                                        className="form-control"
                                        placeholder="xxxxxxxxxx"
                                        id="mobileNumber"
                                    />
                                </div>
                                <div className={[colClass].join(' ')}>
                                    <label htmlFor="currentLocation" className="form-label">
                                        Current Location
                                    </label>
                                    <input
                                        type="text"
                                        onChange={(e) => setCurrentLocation(e.target.value)}
                                        value={currentLocation}
                                        className="form-control"
                                        placeholder="Mumbai"
                                        id="currentLocation"
                                    />
                                </div>
                                {adminType === Constants.TYPE_USER_ADMIN_MAIN && (
                                    <div className="col-md-3">
                                        <label htmlFor="tag" className="form-label">
                                            Tag
                                        </label>
                                        <SingleSelect
                                            options={optionTags}
                                            callback={selectTag}
                                            placeholder="Search for a Tag"
                                            noOptionsMessage="No Tag found"
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="row mt-3">
                                <div className="col-md-3 ps-md-0">
                                    <label htmlFor="minCtc" className="form-label">
                                        Expected CTC Range
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMinCTC(e.target.value)}
                                        value={minCTC}
                                        className="form-control"
                                        placeholder="min CTC"
                                        id="minCtc"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="maxCtc" className="form-label">
                                        &nbsp;
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMaxCTC(e.target.value)}
                                        value={maxCTC}
                                        className="form-control"
                                        placeholder="max CTC"
                                        id="maxCtc"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="minNp" className="form-label">
                                        Notice Period Range
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMinNoticePeriod(e.target.value)}
                                        value={minNoticePeriod}
                                        className="form-control"
                                        placeholder="min Notice Period"
                                        id="minNp"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="maxNp" className="form-label">
                                        &nbsp;
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setMaxNoticePeriod(e.target.value)}
                                        value={maxNoticePeriod}
                                        className="form-control"
                                        placeholder="max Notice Period"
                                        id="maxNp"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3 ps-md-0">
                                    <label htmlFor="totalExperience" className="form-label">
                                        Total Experience
                                    </label>
                                    <input
                                        type="number"
                                        onChange={(e) => setTotalExperience(e.target.value)}
                                        value={totalExperience}
                                        className="form-control"
                                        placeholder="Total experience"
                                        id="totalExp"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* End Advanced Search Section */}

                        <div className="float-end mt-3">
                            <span
                                className="me-4 text-decoration-underline user-select-none"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setAdvancedSearch(!advancedSearch)}>
                                {advancedSearch ? 'Simple Search' : 'Advanced Search'}
                            </span>
                            <button type="button" className="btn btn-primary" onClick={() => searchJobSeeker()}>
                                Search
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {jobSeekers.length != 0 ? (
                <>
                    <Box className={[ListStyles.crmEmailBox]}>
                        <Box className={[ListStyles.crmEmailInnerBox]}>
                            <Box className="float-end mx-3">
                                <span className="me-4">{'Select all JobSeekers?'}</span>
                                <input
                                    type="checkbox"
                                    onChange={(e) => selectAllJobSeekers(e.target.checked)}
                                    name="selectAllJobSeekers"
                                    checked={selectAllCandidate}
                                    className="me-4"
                                />
                                <Button
                                    className="px-5 fw-bold"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#sendCRMEmailModel`}
                                    disabled={!enableEmail}
                                    label={`Send Email`}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <div className="row">
                        {jobSeekers.map((jobSeeker, index) => (
                            <div className="col-md-3" key={jobSeeker.id}>
                                <JobSeekerCard
                                    index={index}
                                    jobSeeker={jobSeeker}
                                    reloadJSList={reloadJSList}
                                    jobSeekers={jobSeekers}
                                    onChangePoolStatus={reloadJobSeekers}
                                    onCheckUpdateEmailList={updateEmailList}
                                    crmCandidateList={crmCandidateList}
                                />
                            </div>
                        ))}
                    </div>

                    <Box
                        className="modal fade back-modal"
                        id={`sendCRMEmailModel`}
                        aria-hidden="true"
                        aria-labelledby="exampleModalToggleLabel"
                        tabIndex="-1">
                        <Box className="modal-dialog modal-dialog-centered">
                            <Box className="modal-content">
                                <Box Element="form" onSubmit={form.handleSubmit(onSubmit)}>
                                    <Box className="modal-header">
                                        <h5 className="modal-title">Send Email to Selected Job seekers</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </Box>
                                    <Box className="modal-body">
                                        <Row>
                                            <CRMMailFields form={form} />
                                        </Row>
                                    </Box>
                                    <Box className="modal-footer">
                                        <Button
                                            id="close-send-crm-email-modal"
                                            color="warning"
                                            className="px-5"
                                            style={btnStyle}
                                            onClick={(e) => closeAndReset()}
                                            btnType="button"
                                            data-bs-dismiss="modal"
                                            label="Close"
                                        />
                                        <Row>
                                            <Column className="col-md-12">
                                                <Button
                                                    btnType="submit"
                                                    color="primary"
                                                    className="px-5"
                                                    style={{ fontSize: '14px' }}
                                                    label={isLoading ? 'Sending...' : 'Send'}
                                                    disabled={isLoading}
                                                />
                                            </Column>
                                        </Row>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {jobSeekers.map((jobSeeker, index) => (
                        <div
                            key={jobSeeker.id}
                            className="modal fade back-modal"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            id={`tagModal${index}`}
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content p-2">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            &nbsp;
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        {tags.map((tag, tagIndex) => (
                                            <div key={tag.id}>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input me-2"
                                                        type="checkbox"
                                                        onChange={(e) =>
                                                            changeMappingStatus({
                                                                e,
                                                                jobSeekerId: jobSeeker.id,
                                                                tagId: tag.id,
                                                                index
                                                            })
                                                        }
                                                        checked={jobSeeker.tags.includes(tag.id)}
                                                        id={`tagCheckBox${tagIndex}`}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`tagCheckBox${tagIndex}`}>
                                                        {tag.name}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}

                                        {isCreateTag && (
                                            <>
                                                <div className="mt-4 row">
                                                    <div className="col-md-8">
                                                        <input
                                                            type="text"
                                                            value={listName}
                                                            onChange={(e) => setListName(e.target.value)}
                                                            className="form-control h-100"
                                                            placeholder="List Name"
                                                        />
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button
                                                            className="btn btn-success tag-btn w-100"
                                                            onClick={(e) => addNewTag()}
                                                            style={{ minWidth: 0 }}>
                                                            <i className="fas fa-check"></i>
                                                        </button>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button
                                                            className="btn btn-danger tag-btn w-100"
                                                            onClick={() => {
                                                                setIsCreateTag(false)
                                                                setListName('')
                                                            }}
                                                            style={{ minWidth: 0 }}>
                                                            <i className="fas fa-times"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => setIsCreateTag(true)}>
                                            Create List
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <>No Job-Seekers Found</>
            )}

            {/* Pagination */}
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                changePage={(page) => changePage(page)}
                itemCount={totalCount}
            />
        </div>
    )
}

export default JobSeekerList
