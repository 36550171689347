import React from 'react'
import { Box } from '../../../vanilla'
import SimpleLoader from '../SimpleLoader/SImpleLoader'
import { baseStyle, messageStyle } from './styles'

const FullPageLoader = (props) => {
    const { message, showMessage } = props
    return (
        <Box style={baseStyle}>
            <SimpleLoader size="100px" />
            {showMessage && (
                <Box className="mt-3" style={messageStyle}>
                    {message}
                </Box>
            )}
        </Box>
    )
}

export default FullPageLoader
