import React from 'react'
import { Box, Button } from '../../../vanilla'
import { SiMicrosoftexcel } from 'react-icons/si'
import { CSVLink } from 'react-csv'
import Utils from '../../../../helpers/Utils'

const Toolbar = ({ applicants }) => {
    if (applicants.length === 0) return null

    const formatPhone = (phone) => {
        // Removing +
        phone = phone.replace('+', '')
        // Formatting to add - after 91
        phone = phone.substring(0, 2) + '-' + phone.substring(2, phone.length)
        return phone
    }

    // Excel headers
    const headers = [
        { label: 'Candidate Name', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Mobile Number', key: 'mobile' },
        { label: 'Current/Expected CTC', key: 'ctc' },
        { label: 'Notice Period', key: 'noticePeriod' },
        { label: 'Current Location', key: 'currentLocation' },
        { label: 'Skills', key: 'skills' },
        { label: 'Resume', key: 'resume' },
        { label: 'Current Status', key: 'status' },
        { label: 'Source', key: 'source' },
        { label: 'Preferred Date/Time', key: 'preferredDateTime' },
        { label: 'Total Experience', key: 'totalExperience' }
    ]

    // Forming the excel data
    let data = []
    applicants.forEach(
        ({
            jobSeeker: {
                firstName,
                lastName,
                middleName,
                email,
                phoneNumber,
                profile: {
                    ExpectedCTC,
                    currentCTC,
                    noticePeriod,
                    tagLocation,
                    majorSkills,
                    preferredInterviewTime,
                    tagExperience
                }
            },
            resume: { resumePath },
            status,
            interviewResult,
            hrResult,
            jobSchedulesTechnical,
            offerAccepted,
            totalTechnicalCount,
            isVendorSourced,
            vendorDetails: { vendorFirstName, vendorMiddleName, vendorLastName, vendorType }
        }) => {
            data.push({
                name: `${firstName} ${middleName} ${lastName}`,
                email,
                mobile: formatPhone(phoneNumber),
                ctc: `${currentCTC !== '' ? currentCTC : 'NA'}/${ExpectedCTC !== '' ? ExpectedCTC : 'NA'} LPA`,
                noticePeriod: noticePeriod !== '' ? noticePeriod : 'NA',
                currentLocation: tagLocation !== '' ? tagLocation : 'NA',
                skills: majorSkills.length !== 0 ? majorSkills.join(', ') : 'No Skills Available',
                resume: resumePath,
                totalExperience: tagExperience !== '' ? tagExperience : 'NA',
                status: Utils.jobAppDisplayStatusText({
                    status,
                    hrResult,
                    interviewResult,
                    jobSchedulesTechnical,
                    offerAccepted,
                    totalTechnicalCount
                }),
                source:
                    isVendorSourced === true
                        ? `${vendorType}-${vendorFirstName} ${vendorMiddleName} ${vendorLastName}`
                        : 'ManasIO HR',
                preferredDateTime: preferredInterviewTime
            })
        }
    )

    return (
        <Box className="col-md-12 p-0 text-end mt-2">
            <CSVLink data={data} headers={headers} filename="applicants_imejobs">
                <Button
                    color="success"
                    label="Export"
                    icon={<SiMicrosoftexcel />}
                    className="float-end px-5"
                    style={{ fontSize: '1.2rem' }}
                />
            </CSVLink>
        </Box>
    )
}

export default Toolbar
