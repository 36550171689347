import React from 'react'
import ForgotForm from '../../../components/auth/password/ForgotForm'
import { Box } from '../../../components/vanilla'
import Constants from '../../../helpers/Constants'

const ForgotPassword = () => {
    return (
        <Box className="mt-4">
            <ForgotForm type={Constants.TYPE_USER_ADMIN} />
        </Box>
    )
}

export default ForgotPassword
