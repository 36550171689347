import React, { useEffect, useState } from 'react'
import { getAllTags } from '../../../api/Admin'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import Tag from './components/Tag'

function TagsIndex() {
    const [tags, setTags] = useState([])

    useEffect(() => {
        getTags()
    }, [])

    /**
     * Function to get all the tags
     */
    const getTags = async () => {
        try {
            const allTags = await getAllTags()

            setTags(allTags)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /**
     * Function to reload tag data
     */
    const reloadTags = (newTagsArr) => {
        setTags(newTagsArr)
    }

    return (
        <div className="p-4 mb-4">
            <div className="mb-2 d-flex align-items-center justify-content-between">
                <h3>Manage Custom Candidate List</h3>
            </div>
            <div className="mb-4">Click on a list to view candidates</div>

            <div className="row">
                {tags.map((tag, index) => (
                    <div className="col-md-3" key={tag.id}>
                        <Tag tag={tag} tags={tags} index={index} onDelete={reloadTags} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TagsIndex
