import React from 'react'
import { useForm } from 'react-hook-form'
import { useUpdateCompanyHighlights } from '../../../hooks/useAdmin'
import CompanyHighlightFields from '../../forms/employer/CompanyHighlightFields'
import Utils from '../../../helpers/Utils'
import Constants from '../../../helpers/Constants'
import { Box, Button, Column, Row } from '../../vanilla'

const CompanyHighlightModal = ({ selectedEmployer }) => {
    const form = useForm()

    /** Function to close and reset modal and data */
    const closeAndReset = async () => {
        form.reset({})
        Utils.closeItem(`btn-close-update-highlight`)
    }

    const { mutateAsync, isLoading } = useUpdateCompanyHighlights()
    const onSubmit = async (data) => {
        try {
            const res = await mutateAsync({
                employerId: selectedEmployer.id,
                data
            })
            if (res.status === Constants.STATUS_SUCCESS) {
                //if status is success then reset form data, close modal and then show tooltip with success message
                closeAndReset()
                // Showing success toast
                Utils.showToast(Constants.MSG_SUCCESS_UPDATE_HIGHLIGHTS, Constants.TYPE_TOAST_SUCCESS)
            }
        } catch (e) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <Box className="back-modal" id="edit-modals">
            {/* Edit Profile Details Modal */}
            <Box
                className="modal fade"
                id="highlightModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <Box className="modal-dialog modal-dialog-centered">
                    <Box className="modal-content">
                        <Box Element="form" onSubmit={form.handleSubmit(onSubmit)}>
                            <Box className="modal-header">
                                <h5 className="modal-title">
                                    Update Highlight for <b> {selectedEmployer.companyName}</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </Box>
                            <Box className="modal-body">
                                <Row>
                                    <CompanyHighlightFields form={form} selectedEmployer={selectedEmployer} />
                                </Row>
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    id="btn-close-update-highlight"
                                    color="warning"
                                    className="px-5"
                                    style={{ fontSize: '14px' }}
                                    onClick={(e) => closeAndReset()}
                                    btnType="button"
                                    data-bs-dismiss="modal"
                                    label="Close"
                                />
                                <Row>
                                    <Column className="col-md-12">
                                        <Button
                                            btnType="submit"
                                            color="primary"
                                            className="px-5"
                                            style={{ fontSize: '14px' }}
                                            label={isLoading ? 'updating...' : 'Update'}
                                            disabled={isLoading}
                                        />
                                    </Column>
                                </Row>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default CompanyHighlightModal
