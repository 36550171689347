import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import AddSitemapForm from '../../../components/forms/sitemap/Form'
import Constants from '../../../helpers/Constants'
import { useParams } from 'react-router-dom'

function EditSitemap() {
    const sitemapId = useParams().sitemapId

    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Edit Sitemap</Heading>
            <AddSitemapForm formType={Constants.TYPE_EDIT} sitemapId={sitemapId} />
        </Box>
    )
}
export default EditSitemap
