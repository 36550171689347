import React from 'react'
import { FiCopy } from 'react-icons/fi'
import { Box, Button, Column, Row } from '../../../vanilla'
import Utils from '../../../../helpers/Utils'
import Constants from '../../../../helpers/Constants'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const MeetingLinkModal = ({ interview }) => {
    const btnStyle = { fontSize: '12px' }

    const handleCloseMeetingLinkModal = async () => {
        Utils.closeItem(`meetingLinkClose${interview.interviewId}`)
    }

    return (
        <Box
            className="modal fade back-modal"
            id={`meetingLinkModal${interview.interviewId}`}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <Box className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <Box className="modal-content">
                    <Box className="modal-body">
                        <Column className="mt-3 border p-3 rounded">
                            <input
                                type="text"
                                disabled
                                id={`messageSubject`}
                                value={interview.meetingLink ? interview.meetingLink : ''}
                                className="form-control"
                            />

                            <Row className="mt-4">
                                <Column className="col-md-4 ps-0">
                                    <CopyToClipboard
                                        text={interview.meetingLink}
                                        onCopy={() =>
                                            Utils.showToast(
                                                Constants.MSG_LINK_COPIED_CLIPBOARD,
                                                Constants.TYPE_TOAST_INFO,
                                                Constants.POSITION_TOAST_BOTTOM_CENTER
                                            )
                                        }>
                                        <Button color="success" label="Copy" icon={<FiCopy />} className="w-100" />
                                    </CopyToClipboard>
                                </Column>
                                <Column className="col-md-4 ps-0">
                                    <a
                                        href={interview.meetingLink}
                                        target="_blank"
                                        style={btnStyle}
                                        className="ime-btn w-100 ime-btn-primary-soft d-flex align-items-center justify-content-center"
                                        rel="noreferrer">
                                        <i className="fas fa-link" />
                                        &nbsp;Join
                                    </a>
                                </Column>
                                <Column className="col-md-4 pe-0">
                                    <Button
                                        id={`meetingLinkClose${interview.interviewId}`}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        color="warning"
                                        className="px-5 w-100"
                                        style={btnStyle}
                                        label="Close"
                                        onClick={() => handleCloseMeetingLinkModal()}
                                    />
                                </Column>
                            </Row>
                        </Column>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default MeetingLinkModal
