import React from 'react'
import { Box, Flex, Heading, Image } from '../../../../components/vanilla'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'

function PostDesc({ postData }) {
    return (
        <Box className="card h-100">
            <Box className="card-body">
                <Flex className="align-items-center">
                    <Box className="avatar avatar-xxl">
                        <Image
                            src={`${
                                postData.data.media != null ? postData.data.media : Constants.DEFAULT_PATH_NO_IMAGE
                            }`}
                            className="avatar-img rounded-0"
                            alt="Avatar Image"
                        />
                    </Box>
                    <Box className="ms-4">
                        <Box className="mb-0 fs-5">
                            {' '}
                            <b>Total Likes: </b>&nbsp;
                            {postData.data.likes.length}
                        </Box>
                        <Box className="mb-0 fs-5">
                            <b>Total Comments: </b>&nbsp;
                            {postData.data.comments.length}
                        </Box>
                    </Box>
                </Flex>

                <Box>
                    <Heading size="xl" className="mb-0 mt-3">
                        <Heading size="md">{postData.data.title ? postData.data.title : 'NO TITLE'}</Heading>
                    </Heading>
                    <Box className="mt-2 text-muted">
                        <Heading size="md">Description:</Heading>
                        {Utils.stripHTML(postData.data.description).substring(0, 22450)} ...
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PostDesc
