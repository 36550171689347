import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SingleJob from '../../../components/elements/Job/SingleJob'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
import { getAllJobs, getSampleJobData } from '../../../api/Jobs'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import { getUserType } from '../../../features/user/userSlice'
import SearchForm from './SearchForm'
import Pagination from '../../Pagination'
import { useNavigate } from 'react-router-dom'

function JobList() {
    // Data vars here
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [jobs, setJobs] = useState([])

    const [noJobsEmployer, setNoJobsEmployer] = useState(false)

    // Search vars
    const [searchParams, setSearchParams] = useState([])
    const [location, setLocation] = useState('')
    const [salaryRange, setSalaryRange] = useState('')

    // Getting current user type
    const userType = useSelector(getUserType)
    const navigate = useNavigate()

    //get page no from URL page
    let search = window.location.search
    let params = new URLSearchParams(search)
    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    //call method on click on browser back and forward arrow button
    window.onpopstate = async () => {
        try {
            setCurrentPage(pageNo)
            await getJobByPage(pageNo, searchParams, location, salaryRange)
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        populateInitData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Init Dispatch here
    const dispatch = useDispatch()

    /** Function to Populate initial data */
    const populateInitData = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            setCurrentPage(pageNo > 0 ? pageNo : 1)
            // Getting all the jobs
            await getJobByPage(pageNo > 0 ? pageNo : currentPage)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to handle re render parent */
    const handleReRender = (newJobs) => {
        setJobs(newJobs)
    }

    /** Function to get jobs by page number */
    const getJobByPage = async (pageNumber, searchParam = '', location = '', salary = '') => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            const response = await getAllJobs({ pageNumber, userType, searchParam, location, salary })
            if (response.count > 0) {
                // Setting data's here
                setTotalPages(response.totalPages)
                setTotalCount(response.count)
                setJobs([...response.jobs])
                setCurrentPage(response.currentPage)
            } else {
                // for employer setting the sample jobs
                if (userType === Constants.TYPE_USER_EMPLOYER) {
                    const sampleJobs = await getSampleJobData()
                    setJobs(sampleJobs)
                    setNoJobsEmployer(true)
                } else {
                    // Setting data's here
                    setTotalPages(response.totalPages)
                    setTotalCount(response.count)
                    setJobs([...response.jobs])
                    setCurrentPage(response.currentPage)
                }
            }
        } catch (error) {
            return error
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigate({
                pathname: Constants.ROUTE_ADMIN_JOBS_LIST,
                search: '?page=' + pageNumber
            })
            await getJobByPage(pageNumber, searchParams, location, salaryRange)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Callback to handle job search */
    const searchJobCallback = async (searchParamsArr, location, salary) => {
        try {
            // Refining the searchParams
            let searchParams = ''
            if (searchParamsArr.length > 0) {
                searchParamsArr.forEach((param, index) => {
                    searchParams += `${param.value}${index + 1 !== searchParamsArr.length ? ',' : ''}`
                })
            }
            navigate({
                pathname:
                    userType === Constants.TYPE_USER_EMPLOYER
                        ? Constants.ROUTE_CLIENT_EMPLOYER_JOB_LIST
                        : Constants.ROUTE_ADMIN_JOBS_LIST,
                search: '?page=1'
            })

            // Page should always be 1 to re-initiate a search
            await getJobByPage(1, searchParams, location, salary)

            setSearchParams(searchParams)
            setLocation(location)
            setSalaryRange(salary)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <div>
            <div className="mb-4">
                <SearchForm callback={searchJobCallback} />
            </div>
            {/* Jobs Available */}
            {jobs.length > 0 ? (
                <>
                    {noJobsEmployer && (
                        <div className="alert alert-info">
                            There are two sample jobs that are shown below. You can use these job data to post a new
                            job. Alternatively you can also use the Add Job section to start a fresh posting.
                        </div>
                    )}
                    {jobs.length > 0 && (
                        <>
                            {jobs.map((job, index) => (
                                <div key={job.id}>
                                    <SingleJob job={job} index={index} jobs={jobs} reRenderCallback={handleReRender} />
                                </div>
                            ))}
                        </>
                    )}

                    {/* Pagination */}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        changePage={(page) => changePage(page)}
                        itemCount={totalCount}
                    />
                </>
            ) : (
                <>No Jobs Posted Yet!</>
            )}
        </div>
    )
}

export default JobList
