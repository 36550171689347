import React from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Card = ({ children, className, ...props }) => {
    return (
        <Box className={['card', className].join(' ')} {...props}>
            {children}
        </Box>
    )
}

Card.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

Card.defaultProps = {
    className: ''
}

export default Card
