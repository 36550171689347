import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { useAddSitemap, useEditSitemap } from '../../../hooks/useAdmin'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'

function AddSitemapForm({ formType = Constants.TYPE_ADD, sitemapId }) {
    const form = useForm()
    const { mutateAsync: addSitemap, isLoading, isError, error } = useAddSitemap()
    const { mutateAsync: editSitemap, isError: isEditError, error: editError } = useEditSitemap()
    // Init Navigate here
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        if (formType === Constants.TYPE_ADD)
            // Creating/Editing the new Sitemap
            await addSitemap(data)
        else await editSitemap({ sitemapId, data })

        // Navigating to the Sitemap list
        navigate(Constants.ROUTE_ADMIN_SITEMAP_LIST)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            {isEditError && (
                <Alert color="danger">
                    {editError?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}
                </Alert>
            )}
            <FormFields form={form} sitemapId={sitemapId} formType={formType} />

            <Button
                className="mb-0"
                color="primary"
                label={formType === Constants.TYPE_ADD ? 'Add Sitemap' : 'Update Sitemap'}
                btnType="submit"
                isLoading={isLoading}
            />
        </Form>
    )
}

export default AddSitemapForm
