import Constants from '../../../helpers/Constants'

export default function useFormFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    sitemap,
    formType = Constants.TYPE_ADD,
    options
}) {
    const fields = {
        name: {
            name: 'name',
            id: 'name',
            label: 'Name',
            placeholder: 'Enter Name',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter sitemap name'
            },
            error: errors.name,
            control
        },
        url: {
            name: 'url',
            id: 'url',
            label: 'External URL',
            placeholder: 'Enter External URL',
            defaultValue: null,
            type: 'text',
            control
        },
        description: {
            name: 'description',
            id: 'description',
            label: 'Description',
            placeholder: 'Enter Description',
            type: 'ckeditor',
            isRequired: true,
            defaultValue: '',
            floating: true,
            rules: {
                required: 'Please enter sitemap Body'
            },
            error: errors.description,
            control
        },
        isExternal: {
            name: 'isExternal',
            id: 'isExternal',
            label: 'Is External?',
            defaultValue: false,
            type: 'switch',
            error: errors.isExternal,
            control
        },
        isUrl: {
            name: 'isUrl',
            id: 'isUrl',
            label: 'is Url ?',
            defaultValue: false,
            type: 'switch',
            error: errors.isUrl,
            control
        },
        footerGroupId: {
            name: 'footerGroupId',
            id: 'footerGroupId',
            label: 'Footer Group',
            defaultValue: 0,
            type: 'select',
            options,
            error: errors.isUrl,
            control
        }
    }

    // In case of edit setting all the default fields
    if (formType === Constants.TYPE_EDIT && sitemap) {
        setValue('name', sitemap.name)
        setValue('isExternal', sitemap.isExternal)
        setValue('isUrl', sitemap.isUrl)
        setValue('url', sitemap.url)
        setValue('footerGroupId', sitemap.footerGroupId)
    }

    return fields
}
