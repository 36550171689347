import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import Constants from '../../../helpers/Constants'
import { useParams } from 'react-router-dom'
import AddFooterForm from '../../../components/forms/footer/Form'

function EditFooter() {
    const footerId = useParams().footerId
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Edit Footer</Heading>
            <AddFooterForm formType={Constants.TYPE_EDIT} footerId={footerId} />
        </Box>
    )
}
export default EditFooter
