import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { useAddAdvertisement, useEditAdvertisements, useAddMediaFile } from '../../../hooks/useAdmin'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'
import Utils from '../../../helpers/Utils'
import { useDispatch } from 'react-redux'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
function AddForm({ formType = Constants.TYPE_ADD, adsId, adsData = {}, refetchAds }) {
    const defaultValues = {
        adsType: adsData.type ?? 'sidebar',
        companyName: adsData.companyName ?? '',
        description: adsData.description ?? '',
        actionUrl: adsData.actionUrl ?? '',
        isActive: adsData.isActive ?? false
    }
    const form = useForm({ defaultValues })
    const { mutateAsync: addAds, isLoading, isError, error } = useAddAdvertisement()
    const { mutateAsync: editAds, isError: isEditError, error: editError } = useEditAdvertisements()
    const { mutateAsync: addMediaFile } = useAddMediaFile()
    const dispatch = useDispatch()

    // Init Navigate here
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        // Setting the loading here
        try {
            dispatch(setLoadingState(true))
            // Create multipart/formData
            const formData = new FormData()
            if (data.media[0] && Constants.ALLOWED_IMAGE_MIME_TYPE.indexOf(data.media[0].type) < 0) {
                form.setError('media', {
                    type: 'filetype',
                    message: 'Only image file is allowed.'
                })
                return
            }

            if (data.logo && data.logo[0] && Constants.ALLOWED_IMAGE_MIME_TYPE.indexOf(data.logo[0].type) < 0) {
                form.setError('logo', {
                    type: 'filetype',
                    message: 'Only image file is allowed.'
                })
                return
            }

            if (data.actionUrl) {
                var valid = Constants.REGEX__VALIDATE_URL.test(data.actionUrl)
                if (!valid) {
                    form.setError('actionUrl', {
                        type: 'filetype',
                        message: 'Please provide valid action URL!'
                    })
                    return
                }
            }

            // Creating/Editing the new ads
            if (formType === Constants.TYPE_ADD) {
                //check media is required in case of add and logo is required in case of type = post
                if (!data.media) {
                    form.setError('media', {
                        type: 'required',
                        message: 'Media is required !'
                    })
                    return
                }
                if (data.adsType === 'post' && !data.logo) {
                    form.setError('logo', {
                        type: 'required',
                        message: 'Logo is required !'
                    })
                    return
                }
                //upload media image first then check response of success and upload the form data to add ads
                formData.append('file', data.media[0] ?? '')
                formData.append('folder', 'community/advertisements/medias')
                const addMediaResponse = await addMediaFile(formData)
                if (addMediaResponse?.data) {
                    // add post data and call API to add ads
                    formData.delete('file')
                    formData.delete('folder')
                    formData.append('media', addMediaResponse?.data?.name)
                    formData.append('companyName', data.companyName)
                    formData.append('logo', data.logo ? data.logo[0] : '')
                    formData.append('description', data.description ?? '')
                    formData.append('actionUrl', data.actionUrl ?? '')
                    formData.append('isActive', data.isActive)
                    formData.append('type', data.adsType)
                    await addAds(formData)
                } else {
                    Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
                    return
                }
            } else {
                //check if formData.adsType =='post' and logo is empty then trigger error
                if (data.adsType === 'post' && !adsData.logo && !data.logo) {
                    form.setError('logo', {
                        type: 'required',
                        message: 'Logo is required !'
                    })
                    return
                }
                //check if media is selected then call API to upload media else ignore
                let addMediaResponse = ''
                if (data.media) {
                    //upload media image first then check response of success and upload the form data to add ads
                    formData.append('file', data.media[0] ?? '')
                    formData.append('folder', 'community/advertisements/medias')
                    addMediaResponse = await addMediaFile(formData)
                }

                if (addMediaResponse?.data) {
                    formData.delete('file')
                    formData.delete('folder')
                    formData.append('media', addMediaResponse?.data?.name)
                } else {
                    formData.append('media', '')
                }
                formData.append('companyName', data.companyName)
                formData.append('logo', data.logo ? data.logo[0] : '')
                formData.append('description', data.description ?? '')
                formData.append('actionUrl', data.actionUrl ?? '')
                formData.append('isActive', data.isActive)
                formData.append('type', data.adsType)
                await editAds({ adsId, data: formData })
                await refetchAds()
            }
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
        // Navigating to the ads list
        navigate(Constants.ROUTE_ADMIN_ADVERTISEMENT_LIST)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            {isEditError && (
                <Alert color="danger">
                    {editError?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}
                </Alert>
            )}
            <FormFields form={form} adsData={adsData} />

            <Button
                className="mb-0"
                color="primary"
                label={formType === Constants.TYPE_ADD ? 'Add Advertisement' : 'Update Advertisement'}
                btnType="submit"
                isLoading={isLoading}
            />
        </Form>
    )
}

export default AddForm
