import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyUserMobile } from '../../../../api/Admin'
import { getAdminType, getCanTogglePool, getIsSuperAdmin } from '../../../../features/user/userSlice'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import PropTypes from 'prop-types'
import { Row, Column, Button } from '../../../../components/vanilla'
import { FaEdit, FaBriefcase, FaUserCheck, FaSave } from 'react-icons/fa'
import { Avatar, Box, Heading, Flex } from '../../../vanilla'
import CardBasic from '../../../Cards/CardBasic'
import ReactTooltip from 'react-tooltip'
import SubHRTooltip from '../../../SubHRTooltip'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import { useJobSeekerIsPoolStatus, useJobSeekerStatus } from '../../../../hooks/useAdmin'

function JobSeekerCard({
    jobSeeker,
    index,
    reloadJSList,
    jobSeekers,
    showUtils,
    onChangePoolStatus,
    onCheckUpdateEmailList,
    crmCandidateList
}) {
    const dispatch = useDispatch()
    // Getting current logged in admin type
    const adminType = useSelector(getAdminType)
    const isSuperAdmin = useSelector(getIsSuperAdmin)
    const canTogglePool = useSelector(getCanTogglePool)
    const [isPool, setIsPool] = useState(false)
    const [isActive, setIsActive] = useState(false)

    const textStyle = {
        fontSize: '13px'
    }

    const { mutateAsync } = useJobSeekerIsPoolStatus({ id: jobSeeker.id, isPool: !jobSeeker.isPool })
    const { mutateAsync: changeJobSeekerStatus } = useJobSeekerStatus()
    useEffect(() => {
        setIsPool(jobSeeker.isPool)
        setIsActive(jobSeeker.isActive)
    }, [jobSeeker])

    /** Function to verify a job-seeker */
    const verifyUser = async (e, jobSeekerId) => {
        try {
            e.preventDefault()

            await verifyUserMobile(jobSeekerId)
            await reloadJSList()
            Utils.showToast(Constants.MSG_USER_VERIFIED, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    const togglePoolStatus = async (e, jobseekerId) => {
        // Setting the loading here
        dispatch(setLoadingState(true))
        const checkValue = e.target.checked
        await mutateAsync({
            data: {
                id: jobseekerId,
                status: checkValue
            }
        })
        setIsPool(checkValue)
        // Deleting the tag from tags arr
        let values = [...jobSeekers]
        values.splice(index, 1)
        onChangePoolStatus(values)

        const successMessage = isPool ? Constants.MSG_MOVE_FROM_POOL_SUCCESS : Constants.MSG_MOVE_TO_POOL_SUCCESS
        Utils.showToast(successMessage, Constants.TYPE_TOAST_SUCCESS)

        // Setting the loading here
        dispatch(setLoadingState(false))
    }

    /**Change jobSeeker status */
    const toggleIsActive = async (e, jobSeekerId) => {
        const checkValue = e.target.checked
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            await changeJobSeekerStatus({
                id: jobSeekerId,
                isActive: checkValue
            })
            setIsActive(checkValue)
            Utils.showToast(Constants.MSG_STATUS_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            setIsActive(!checkValue)
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
        // Setting the loading here
        dispatch(setLoadingState(false))
    }

    const selectJobSeekerForEmail = async (isChecked, jobSeekerEmail) => {
        onCheckUpdateEmailList(isChecked, jobSeekerEmail)
    }

    const colClass = adminType === Constants.TYPE_USER_ADMIN_MAIN ? 'col-md-4' : 'col-md-6'

    return (
        <CardBasic cardClass="mb-4">
            <Flex className="align-items-center">
                <Avatar
                    size="xxl"
                    src={jobSeeker.avatarPath !== '' ? jobSeeker.avatarPath : Constants.DEFAULT_PATH_JOB_SEEKER_AVATAR}
                />
                <Box className="ms-4">
                    <Heading className="text-purple mb-0 text-uppercase">
                        {`${jobSeeker.firstName} ${jobSeeker.middleName} ${jobSeeker.lastName}`}
                        <SubHRTooltip jobSeeker={jobSeeker} />
                    </Heading>

                    <ReactTooltip id="subhrTooltip" className="tooltip" html={true} />
                    <Box className="mb-0">{jobSeeker.email}</Box>
                    <Box className="mb-0">{jobSeeker.phoneNumber}</Box>
                </Box>
            </Flex>

            <Box className="mb-4 ps-3">
                {showUtils && (
                    <>
                        <Box>
                            <b>Experience:</b>{' '}
                            {`${jobSeeker.profile.tagExperience ? jobSeeker.profile.tagExperience + ' Years' : 'NA'}`}
                        </Box>
                        <Box>
                            <b>Current/Expected CTC:</b>{' '}
                            {`${jobSeeker.profile.currentCTC ? jobSeeker.profile.currentCTC : 'NA'}/${
                                jobSeeker.profile.ExpectedCTC ? jobSeeker.profile.ExpectedCTC : 'NA'
                            } LPA`}
                        </Box>
                        <Box>
                            <b>Location:</b> {jobSeeker.profile.tagLocation ? jobSeeker.profile.tagLocation : 'NA'}
                        </Box>
                        <Box>
                            <b>Notice Period:</b>{' '}
                            {jobSeeker.profile.noticePeriod ? jobSeeker.profile.noticePeriod : 'NA'} days
                        </Box>
                        <Box>
                            <b>Skills: </b>{' '}
                            {jobSeeker.profile.majorSkills.length !== 0 ? (
                                <>{jobSeeker.profile.majorSkills.join(', ')}</>
                            ) : (
                                <>No Skills available</>
                            )}
                        </Box>
                    </>
                )}
            </Box>
            {showUtils && (
                <Row className="mb-4">
                    <Column className={[colClass, 'mb-3'].join(' ')}>
                        <Button
                            type="link"
                            to={Constants.ROUTE_ADMIN_MANAGE_JOB_SEEKER_PROFILE(jobSeeker.id)}
                            className="w-100"
                            label="Edit Profile"
                            icon={<FaEdit />}
                            btnType="button"
                        />
                    </Column>
                    <Column className={[colClass, 'mb-3'].join(' ')}>
                        <Button
                            type="link"
                            to={Constants.ROUTE_ADMIN_MANAGE_JOB_SEEKER_APPLICATIONS(jobSeeker.id)}
                            className="w-100"
                            label="Applications"
                            icon={<FaBriefcase />}
                            color="warning"
                            btnType="button"
                        />
                    </Column>
                    {adminType === Constants.TYPE_USER_ADMIN_MAIN && (
                        <>
                            <Column className={[colClass, 'mb-3'].join(' ')}>
                                <Button
                                    disabled={jobSeeker.isMobileVerified}
                                    onClick={(e) => verifyUser(e, jobSeeker.id)}
                                    className="w-100"
                                    label="Verify User"
                                    icon={<FaUserCheck />}
                                    color={jobSeeker.isMobileVerified ? 'success' : 'danger'}
                                    btnType="button"
                                />
                            </Column>
                            <Column className="col-md-12">
                                <Button
                                    className="w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#tagModal${index}`}
                                    label="Save to List"
                                    icon={<FaSave />}
                                    color="danger"
                                    btnType="button"
                                />
                            </Column>

                            <Flex className="justify-content-between ms-2 mt-3">
                                {(isSuperAdmin || canTogglePool) && (
                                    <Box className="form-check form-switch">
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            role="switch"
                                            checked={isActive}
                                            onChange={(e) => toggleIsActive(e, jobSeeker.id)}
                                            style={{ marginTop: 0 }}
                                        />
                                        <Box
                                            Element="label"
                                            className="form-check mb-0 ps-0"
                                            style={{ width: '300px', ...textStyle }}>
                                            Active ?
                                        </Box>
                                    </Box>
                                )}
                            </Flex>

                            <Flex className="justify-content-between ms-2 mt-3">
                                {(isSuperAdmin || canTogglePool) && (
                                    <Box className="form-check form-switch">
                                        <input
                                            className="form-check-input me-2"
                                            type="checkbox"
                                            role="switch"
                                            checked={isPool}
                                            onChange={(e) => togglePoolStatus(e, jobSeeker.id)}
                                            style={{ marginTop: 0 }}
                                        />
                                        <Box
                                            Element="label"
                                            className="form-check mb-0 ps-0"
                                            style={{ width: '300px', ...textStyle }}>
                                            Is Pool ?
                                        </Box>
                                    </Box>
                                )}
                                <Box className="span">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => selectJobSeekerForEmail(e.target.checked, jobSeeker.email)}
                                        name="selectJobSeeker"
                                        checked={crmCandidateList.indexOf(jobSeeker.email) !== -1 ? true : false}
                                        className="form-check-input"
                                    />
                                </Box>
                            </Flex>
                        </>
                    )}
                </Row>
            )}
        </CardBasic>
    )
}

JobSeekerCard.propTypes = {
    jobSeeker: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    reloadJSList: PropTypes.func,
    showUtils: PropTypes.bool,
    onChangePoolStatus: PropTypes.func.isRequired
}

JobSeekerCard.defaultProps = {
    showUtils: true,
    jobSeeker: {}
}

export default JobSeekerCard
