import React, { useEffect, useState } from 'react'
import Conversation from './conversation/Index'
import './chat.css'
import { getInterviews } from '../../api/Auth'
import { useDispatch } from 'react-redux'
import { setLoadingState } from '../../features/loader/LoaderSlice'
import Utils from '../../helpers/Utils'
import Constants from '../../helpers/Constants'
import SearchModal from './SearchModal'
import { Button, Column, Row } from '../vanilla'
import { FaSearch } from 'react-icons/fa'
import Pagination from '../Pagination'
import { useNavigate } from 'react-router-dom'

function ChatIndex({ userType, employerType, isVendor = false }) {
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)

    // Data Vars
    const [interviews, setInterviews] = useState([])

    // Init dispatch here
    const dispatch = useDispatch()
    const navigate = useNavigate()

    /** useEffect to populate init data */
    useEffect(() => {
        populateInitData()
    }, [])

    /** Function to populate init data */
    const populateInitData = async () => {
        try {
            await getInterviewsByPage({ page: currentPage })
        } catch (error) {}
    }

    /** Function to get all the interviews */
    const getInterviewsByPage = async ({ page, companyName = '', candidateName = '', jobPosition = '' }) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Setting userType
            const APIUserType = userType == Constants.TYPE_USER_EMPLOYER ? employerType : userType
            // Calling API
            const response = await getInterviews({
                userType: APIUserType,
                page,
                positionName: jobPosition,
                companyName,
                jobSeekerName: candidateName,
                isVendor
            })
            setTotalPages(response.totalPages)
            setInterviews([...response.interviewees])
            setCurrentPage(response.currentPage)
            setTotalCount(response.count)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to change interview page */
    const changeInterviewPage = async (page, e) => {
        try {
            await getInterviewsByPage({ page })
        } catch (error) {
            throw error
        }
    }

    /** Function to handle click callback of conversation */
    const handleClickCallback = async (e, interview) => {
        e.preventDefault()
        navigate(`/admin/interviews/${interview.application.id}/chat`)
    }

    /** Function to handle interviews list change */
    const handleInterviewsList = (interviewsArr) => {
        setInterviews(interviewsArr)
    }

    /**
     * Function to handle onSearch from search modal
     */
    const onSearch = async (companyName, candidateName, jobTitle) => {
        try {
            // Closing the modal
            Utils.closeItem('searchModal')
            await getInterviewsByPage({ page: 1, companyName, candidateName, jobPosition: jobTitle })
        } catch (error) {
            throw error
        }
    }

    return (
        <>
            <div className="mt-2">
                <div className="interviews-list">
                    <div className="wrapper h-100">
                        <div className="mb-3">
                            <Button
                                btnType="button"
                                data-bs-toggle="modal"
                                data-bs-target="#searchModal"
                                color="primary"
                                label="Click to Open Search"
                                icon={<FaSearch />}
                                style={{ fontSize: '12px' }}
                            />
                        </div>
                        <div className="interviews-top pb-3">
                            {interviews.length > 0 ? (
                                <Row>
                                    {interviews.map((interview, index) => (
                                        <Column className="col-md-6" key={interview.id}>
                                            <Conversation
                                                key={interview.interviewId}
                                                interview={interview}
                                                clickCallback={handleClickCallback}
                                                setInterviewsCallback={handleInterviewsList}
                                                interviews={interviews}
                                                index={index}
                                            />
                                        </Column>
                                    ))}
                                </Row>
                            ) : (
                                <>No Interviews Scheduled Yet!</>
                            )}
                        </div>
                        <div className="interviews-bottom mt-3">
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                changePage={(page) => changeInterviewPage(page)}
                                itemCount={totalCount}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <SearchModal onSearch={onSearch} />
        </>
    )
}

export default ChatIndex
