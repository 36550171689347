import React from 'react'
import { Box, Input } from '../../../../../vanilla'
import useFormFields from './useFormFields'

const FormFields = ({ form, subHRs }) => {
    const optionSubHRs = []
    if (subHRs && subHRs.length > 0)
        subHRs.map(({ firstName, middleName, lastName, id }) =>
            optionSubHRs.push({
                label: `${firstName} ${middleName} ${lastName}`,
                value: id
            })
        )

    const { hrList } = useFormFields({ form: form, options: optionSubHRs })

    return (
        <Box className="mb-2">
            <Input {...hrList} />
        </Box>
    )
}

export default FormFields
