import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { useAddFooter, useEditFooter } from '../../../hooks/useAdmin'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'

function AddFooterForm({ formType = Constants.TYPE_ADD, footerId }) {
    const form = useForm()
    const { mutateAsync: addFooter, isLoading, isError, error } = useAddFooter()
    const { mutateAsync: editFooter, isError: isEditError, error: editError } = useEditFooter()
    // Init Navigate here
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        if (formType === Constants.TYPE_ADD)
            // Creating/Editing the new Footer
            await addFooter(data)
        else await editFooter({ footerId, data })

        // Navigating to the Footer list
        navigate(Constants.ROUTE_ADMIN_FOOTER_LIST)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            {isEditError && (
                <Alert color="danger">
                    {editError?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}
                </Alert>
            )}
            <FormFields form={form} footerId={footerId} formType={formType} />

            <Button
                className="mb-0"
                color="primary"
                label={formType === Constants.TYPE_ADD ? 'Add Footer Group' : 'Update Footer Group'}
                btnType="submit"
                isLoading={isLoading}
            />
        </Form>
    )
}

export default AddFooterForm
