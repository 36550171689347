import React from 'react'
import { Link } from 'react-router-dom'
import CardBasic from '../../../../components/Cards/CardBasic'
import { Avatar, Badge, Box, Heading } from '../../../../components/vanilla'
import Constants from '../../../../helpers/Constants'

function ApplicantCard({ firstName, middleName, lastName, positionName, companyName, id, jobId, isPool = false }) {
    return (
        <Link
            to={`${Constants.ROUTE_ADMIN_ASSIGN_JOB_CANDIDATE}/${jobId}/application/${id}`}
            style={{ textDecoration: 'none' }}>
            <CardBasic bodyClass="d-flex align-items-center" cardClass="shadow-sm mb-3" key={id}>
                <Box>
                    <Avatar size="xl" />
                </Box>
                <Box style={{ flex: 1 }} className="ps-3 col-8">
                    <Heading className="text-dark">{`${firstName} ${middleName} ${lastName}`}</Heading>
                    <Box className="text-muted">{positionName}</Box>
                </Box>
                <Box className="col-2">
                    <Badge color="success" style={{ fontSize: '1.3rem', textAlign: 'left' }}>
                        {isPool ? 'Pool' : 'Non Pool'}
                    </Badge>
                </Box>
                <Box className="col-2">
                    <Badge color="primary" style={{ fontSize: '1.3rem', textAlign: 'center' }}>
                        {companyName}
                    </Badge>
                </Box>
            </CardBasic>
        </Link>
    )
}

export default ApplicantCard
