import React, { useEffect, useState } from 'react'
import Select from 'react-select'

function SingleSelect({
    placeholder,
    options,
    noOptionsMessage,
    callback,
    isMulti = false,
    styles = {},
    initialValues = []
}) {
    const [value, setValue] = useState([])
    useEffect(() => {
        if (initialValues.length > 0) {
            setValue(initialValues)
            handleChange(initialValues)
        }
    }, [initialValues])

    /** Function to handle change of value */
    const handleChange = (value) => {
        // In case of multiple response - the
        // whole array with label and value
        // is returned
        setValue(value)
        callback(value)
    }
    return (
        <div>
            <Select
                styles={styles}
                isMulti={isMulti}
                value={value}
                onChange={(e) => {
                    handleChange(e)
                }}
                placeholder={placeholder}
                options={options}
                noOptionsMessage={() => noOptionsMessage}
            />
        </div>
    )
}

export default SingleSelect
