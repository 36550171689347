/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { DOTS, usePagination } from '../../hooks/usePagination'
import { Box } from '../vanilla'

const Pagination = ({ totalPages, currentPage, changePage, itemCount, pageSize = 8 }) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount: itemCount,
        siblingCount: 1,
        pageSize
    })
    if (totalPages < 2) return null

    let lastPage = paginationRange[paginationRange.length - 1]
    return (
        <Box className="col-sm-12">
            <Box Element="nav">
                <ul className="pagination">
                    <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                changePage(currentPage - 1)
                            }}>
                            Previous
                        </a>
                    </li>
                    {paginationRange.map((pageNumber) => {
                        // If the range is to be DOT
                        if (pageNumber === DOTS) {
                            return (
                                <li key={pageNumber} className={`page-item disabled`}>
                                    <a className="page-link" href="#">
                                        &#8230;
                                    </a>
                                </li>
                            )
                        }

                        // If the page is last page
                        if (pageNumber === lastPage)
                            return (
                                <li key={pageNumber} className={`page-item disabled`}>
                                    <a className="page-link" href="#">
                                        Last page
                                    </a>
                                </li>
                            )

                        return (
                            <li key={pageNumber} className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        changePage(pageNumber)
                                    }}>
                                    {pageNumber}
                                </a>
                            </li>
                        )
                    })}

                    <li className={`page-item ${lastPage == currentPage ? 'disabled' : ''}`}>
                        <a
                            className="page-link"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault()
                                changePage(currentPage + 1)
                            }}>
                            Next
                        </a>
                    </li>
                </ul>
            </Box>
        </Box>
    )
}

export default Pagination
