import React from 'react'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import { Alert, Box, Button, Flex } from '../../../vanilla'

const Details = ({ applicant, index, textStyle, onHold }) => {
    return (
        <>
            <Alert color="primary" className="text-center" style={textStyle}>
                Current Status:{' '}
                {Utils.jobAppDisplayStatusText({
                    status: applicant.status,
                    hrResult: applicant.hrResult,
                    interviewResult: applicant.interviewResult,
                    jobSchedulesTechnical: applicant.jobSchedulesTechnical,
                    offerAccepted: applicant.offerAccepted,
                    totalTechnicalCount: applicant.totalTechnicalCount
                })}
            </Alert>

            {applicant.hasApplication && (
                <>
                    <Flex className="justify-content-center mb-3">
                        <Box style={textStyle} className="fw-bold">
                            Source:
                            {applicant.isVendorSourced
                                ? ` ${applicant.vendorDetails.vendorType}-${applicant.vendorDetails.vendorFirstName} ${applicant.vendorDetails.vendorMiddleName} ${applicant.vendorDetails.vendorLastName}`
                                : ' ManasIO HR'}
                        </Box>
                    </Flex>

                    <Flex className="justify-content-center mb-3">
                        <Box style={textStyle} className="fw-bold">
                            Preferred Date/Time: {applicant.jobSeeker.profile.preferredInterviewTime}
                        </Box>
                    </Flex>

                    <Flex className="justify-content-center mb-3">
                        <Box style={textStyle} className="fw-bold">
                            Last Working Day: {applicant.jobSeeker.profile.lastWorkDay}
                        </Box>
                    </Flex>

                    <Flex className="justify-content-center">
                        <Button
                            disabled={
                                applicant.status === Constants.STATUS_JOB_APP_REJECT ||
                                applicant.status === Constants.STATUS_JOB_APP_JOINED
                            }
                            className="me-3 col-md-3"
                            color="light"
                            label={applicant.status === Constants.STATUS_JOB_APP_HOLD ? 'Release' : 'On hold'}
                            onClick={onHold}
                        />
                        <Button
                            data-bs-toggle="modal"
                            data-bs-target={`#reject${index}`}
                            disabled={
                                applicant.status === Constants.STATUS_JOB_APP_REJECT ||
                                applicant.status === Constants.STATUS_JOB_APP_JOINED
                            }
                            className="me-3 col-md-3"
                            color="danger"
                            label="Reject"
                        />
                        <Button
                            className="col-md-4"
                            data-bs-toggle="modal"
                            data-bs-target={`#questResponsesModal${index}`}
                            color="warning"
                            label="Questionnaires"
                        />
                    </Flex>
                </>
            )}
        </>
    )
}

export default Details
