import Constants from '../../../helpers/Constants'

export default function useFormFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    blog,
    formType = Constants.TYPE_ADD
}) {
    const fields = {
        title: {
            name: 'title',
            id: 'title',
            label: 'Title',
            placeholder: 'Enter Title',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter blog title'
            },
            error: errors.title,
            control
        },
        thumbnailURL: {
            name: 'thumbnailURL',
            id: 'thumbnailURL',
            label: 'Thumbnail Image',
            placeholder: 'Select Thumbnail Image',
            defaultValue: '',
            type: 'file',
            error: errors.thumbnailURL,
            control
        },
        body: {
            name: 'body',
            id: 'body',
            label: 'Body',
            placeholder: 'Enter Blog Body',
            type: 'ckeditor',
            isRequired: true,
            defaultValue: '',
            floating: true,
            rules: {
                required: 'Please enter the Blog body'
            },
            error: errors.body,
            control
        },
        isActive: {
            name: 'isActive',
            id: 'isActive',
            label: 'Is Active?',
            defaultValue: false,
            type: 'switch',
            error: errors.isActive,
            control
        },
        isFeatured: {
            name: 'isFeatured',
            id: 'isFeatured',
            label: 'Featured ?',
            defaultValue: false,
            type: 'switch',
            error: errors.isFeatured,
            control
        }
    }

    // In case of edit setting all the default fields
    if (formType === Constants.TYPE_EDIT && blog) {
        setValue('title', blog.title)
        setValue('body', blog.body)
        setValue('isActive', blog.isActive)
        setValue('isFeatured', blog.isFeatured)
    }

    return fields
}
