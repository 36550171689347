import React from 'react'
import { Box, Flex, Heading } from '../../../components/vanilla'
import { useParams } from 'react-router-dom'
import { useGetChatMessages } from '../../../hooks/useAdmin'
import SimpleLoader from '../../../components/vanilla/loaders/SimpleLoader/SImpleLoader'
import SingleMessage from './SingleMessage'
import Styles from './Styles.module.css'

function CommunityChatMessages() {
    const chatId = useParams().chatId
    const { data: chatMessages, isLoading: isInitLoading } = useGetChatMessages({ chatId })
    return (
        <>
            {!isInitLoading ? (
                <Flex className="flex-lg-row flex-column mt-5 mx-5">
                    <Box className="flex-lg-row-fluid ms-lg-5 ms-xl-10 w-100">
                        {chatMessages?.data.length > 0 ? (
                            <Box className={['card', Styles.hero].join(' ')}>
                                <Box className="card-body overflow-auto">
                                    {chatMessages.data.map((chat) => (
                                        <SingleMessage
                                            chat={chat}
                                            firstUser={chatMessages.data[0].user.id}
                                            key={chat.id}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        ) : (
                            <Heading>No Chat Message Found</Heading>
                        )}
                    </Box>
                </Flex>
            ) : (
                <Flex className="align-items-center justify-content-center flex-column">
                    <SimpleLoader size="50px" />
                    <Box className="mt-3">Please wait, we are processing your data. This might take a few moments!</Box>
                </Flex>
            )}
        </>
    )
}
export default CommunityChatMessages
