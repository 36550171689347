import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addNewSubHR } from '../../../../../api/Admin'
import BadRequestError from '../../../../../errors/BadRequestError'
import ValidationError from '../../../../../errors/ValidationError'
import Constants from '../../../../../helpers/Constants'
import Utils from '../../../../../helpers/Utils'

function AddHR() {
    // Data vars
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phPrefix, setPhPrefix] = useState('+91')
    const [phNumber, setPhNumber] = useState('')
    const [active, setActive] = useState(false)

    // Loader vars
    const [addLoading, setAddLoading] = useState(false)

    // Initiate navigate here
    const navigate = useNavigate()

    /** Function to add a new sub-hr */
    const handleSubmit = async (event) => {
        event.preventDefault()
        // Setting the loading here
        setAddLoading(true)
        try {
            // Validating data here
            if (firstName == '') throw new ValidationError(Constants.MSG_EMPTY_FIRST_NAME)
            if (lastName == '') throw new ValidationError(Constants.MSG_EMPTY_LAST_NAME)
            if (!Utils.validateName(firstName) || !Utils.validateName(middleName) || !Utils.validateName(lastName))
                throw new ValidationError(Constants.MSG_INVALID_NAME)

            if (email == '' || !Utils.validateEmail(email)) throw new ValidationError(Constants.MSG_INVALID_EMAIL)
            if (phNumber == '' || !Utils.validateMobileNumber(phNumber))
                throw new ValidationError(Constants.MSG_INVALID_MOBILE_NUMBER)

            // Calling the API here
            await addNewSubHR({
                firstName,
                middleName,
                lastName,
                email,
                phoneNumber: `${phPrefix}${phNumber}`,
                isActive: active
            })
            // Setting the loading here
            setAddLoading(false)
            // Success toast here
            Utils.showToast(Constants.MSG_SUB_HR_ADD_SUCCESS_ADMIN, Constants.TYPE_TOAST_SUCCESS)
            // Navigating to employers list page
            navigate(Constants.ROUTE_ADMIN_MANAGE_LIST_HR)
        } catch (error) {
            if (error instanceof ValidationError || error instanceof BadRequestError)
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
            // Setting the loading here
            setAddLoading(false)
        }
    }

    return (
        <div className="admin-container mb-4">
            <h3>Add New Sub-Admin-HR</h3>

            <form onSubmit={(e) => handleSubmit(e)}>
                {/* Full Name */}
                <div className="row mb-3">
                    <label htmlFor="firstName" className="col-sm-4 col-form-label">
                        Full Name <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <div className="row">
                            <div className="col-md-4 ps-0">
                                <input
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="col-md-4">
                                <input
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    value={middleName}
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="Middle Name (Optional)"
                                />
                            </div>
                            <div className="col-md-4 pe-0">
                                <input
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="Last Name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Full Name */}

                {/* Email */}
                <div className="row mb-3">
                    <label htmlFor="companyEmail" className="col-sm-4 col-form-label">
                        Email <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            className="form-control"
                            id="companyEmail"
                            placeholder="xyz@abc.com"
                        />
                    </div>
                </div>
                {/* End Email */}

                {/* Mobile Number */}
                <div className="row mb-3">
                    <label htmlFor="companyMobile" className="col-sm-4 col-form-label">
                        Phone Number <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-3 col-4">
                        <select
                            onChange={(e) => setPhPrefix(e.target.value)}
                            value={phPrefix}
                            className="form-select"
                            id="companyMobilePrefix"
                            aria-label=".form-select example">
                            {Utils.getMobileNumPrefixes().map((prefix, index) => (
                                <option key={index}>{prefix}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-sm-5 col-8 ">
                        <input
                            onChange={(e) => setPhNumber(e.target.value)}
                            value={phNumber}
                            type="text"
                            placeholder="xxxxxxxxxx"
                            className="form-control"
                            id="companyMobile"
                        />
                    </div>
                </div>
                {/* End Mobile Number */}

                {/* Active */}
                <div className="row mb-3">
                    <label className="col-sm-4 col-form-label" htmlFor="active">
                        Active?
                    </label>
                    <div className="col-sm-8 ">
                        <div className="form-check form-switch">
                            <input
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="active"
                            />
                        </div>
                    </div>
                </div>
                {/* End Active */}

                <div className="row mb-3">
                    <div className="col-sm-8 offset-sm-4">
                        <button type="submit" className="btn btn-primary" disabled={addLoading}>
                            {addLoading ? (
                                <>
                                    <i className="fas fa-circle-notch fa-spin"></i> Adding...
                                </>
                            ) : (
                                <>Add Sub-HR</>
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddHR
