import React from 'react'
import { Navigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { isLoggedIn } from '../../../features/user/userSlice'
import { useSelector } from 'react-redux'

function AdminAuthRedirectRoute({ children }) {
    // Checking if user is logged in
    const isAuthenticated = useSelector(isLoggedIn)

    // If Unauthenticated, go to child
    return !isAuthenticated ? children : <Navigate to={Constants.ROUTE_ADMIN_HOME} />
}

export default AdminAuthRedirectRoute
