import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { useAddBlog, useEditBlog } from '../../../hooks/useAdmin'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'
function AddBlogForm({ formType = Constants.TYPE_ADD, blogId }) {
    const form = useForm()
    const { mutateAsync: addBlog, isLoading, isError, error } = useAddBlog()
    const { mutateAsync: editBlog, isError: isEditError, error: editError } = useEditBlog()
    // Init Navigate here
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        // Create multipart/formData
        const formData = new FormData()
        if (data.thumbnailURL[0] && Constants.ALLOWED_IMAGE_MIME_TYPE.indexOf(data.thumbnailURL[0].type) < 0) {
            form.setError('thumbnailURL', {
                type: 'filetype',
                message: 'Only image file is allowed.'
            })
            return
        }
        formData.append('body', data.body)
        formData.append('isActive', data.isActive)
        formData.append('isFeatured', data.isFeatured)
        formData.append('thumbnailURL', data.thumbnailURL[0] ?? '')
        formData.append('title', data.title)

        // Creating/Editing the new blog
        if (formType === Constants.TYPE_ADD) await addBlog(formData)
        else await editBlog({ blogId, data: formData })

        // Navigating to the blog list
        navigate(Constants.ROUTE_ADMIN_BLOG_LIST)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            {isEditError && (
                <Alert color="danger">
                    {editError?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}
                </Alert>
            )}
            <FormFields form={form} blogId={blogId} formType={formType} />

            <Button
                className="mb-0"
                color="primary"
                label={formType === Constants.TYPE_ADD ? 'Add Blog' : 'Update Blog'}
                btnType="submit"
                isLoading={isLoading}
            />
        </Form>
    )
}

export default AddBlogForm
