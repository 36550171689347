import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import Box from '../Box'
import CKEditorWrapper from '../CkEditor/CkEditor'

const Input = ({
    name,
    control,
    rules,
    type,
    id,
    className,
    labelClass,
    defaultValue,
    helpText,
    placeholder,
    label,
    error,
    options,
    creatable,
    styles,
    isClearable,
    isMulti,
    ...props
}) => {
    const SelectElement = type === 'selectable' && creatable ? Creatable : Select
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
                <>
                    {!['checkbox', 'radio', 'switch'].includes(type) && label !== '' && (
                        <label className={['form-label', labelClass].join(' ')} htmlFor={id}>
                            {label}
                        </label>
                    )}

                    {['text', 'password', 'email', 'phone', 'tel', 'number'].includes(type) && (
                        <input
                            type={type}
                            id={id}
                            className={[className, 'form-control', `${error ? 'is-invalid' : ''}`].join(' ')}
                            placeholder={placeholder}
                            {...field}
                            {...props}
                        />
                    )}

                    {['file'].includes(type) && (
                        <input
                            onChange={(e) => field?.onChange(e.target.files)}
                            type="file"
                            id={id}
                            className={[className, 'form-control', `${error ? 'is-invalid' : ''}`].join(' ')}
                        />
                    )}

                    {['textarea'].includes(type) && (
                        <textarea
                            id={id}
                            placeholder={placeholder}
                            className={['form-control', className, `${error ? 'is-invalid' : ''}`].join(' ')}
                            {...field}
                            {...props}></textarea>
                    )}

                    {['select'].includes(type) && (
                        <select
                            className={['form-select', className, `${error ? 'is-invalid' : ''}`].join(' ')}
                            id={id}
                            {...field}
                            {...props}>
                            {options.map((opt) => (
                                <option key={`${opt.label}-${opt.value}`} value={opt.value}>
                                    {opt.label}
                                </option>
                            ))}
                        </select>
                    )}

                    {['ckeditor'].includes(type) && (
                        <CKEditorWrapper
                            onChange={(val) => field.onChange(val)}
                            placeholder={placeholder}
                            value={defaultValue}
                        />
                    )}

                    {['selectable'].includes(type) && (
                        <SelectElement
                            options={options}
                            defaultValue={field?.value ?? defaultValue}
                            styles={styles}
                            className={[className, `${error ? 'is-invalid' : ''}`].join(' ')}
                            onChange={(val) => field.onChange(val.value)}
                            isClearable={isClearable}
                            placeholder={placeholder}
                            isMulti={isMulti}
                            {...props}
                            {...field}
                        />
                    )}

                    {['checkbox', 'radio'].includes(type) && (
                        <>
                            <input
                                type={type}
                                id={id}
                                className={['form-check-input', className, `${error ? 'is-invalid' : ''}`].join(' ')}
                                {...field}
                                {...props}
                            />

                            <label className={['form-check-label ms-2', labelClass].join(' ')} htmlFor={id}>
                                {label}
                            </label>
                        </>
                    )}

                    {type === 'switch' && (
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                id={id}
                                className={['form-check-input', className, `${error ? 'is-invalid' : ''}`].join(' ')}
                                checked={field.value}
                                {...field}
                                {...props}
                            />

                            <label className={['form-check-label ms-2', labelClass].join(' ')} htmlFor={id}>
                                {label}
                            </label>
                        </div>
                    )}

                    {error && (
                        <Box Element="p" className="form-text text-danger">
                            {error.message}
                        </Box>
                    )}

                    {helpText && (
                        <Box Element="p" className="form-text">
                            {helpText}
                        </Box>
                    )}
                </>
            )}></Controller>
    )
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.object,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    rules: PropTypes.object,
    defaultValue: PropTypes.any,
    helpText: PropTypes.any,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.shape({ message: PropTypes.string }),
    options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })),
    type: PropTypes.oneOf([
        'text',
        'number',
        'password',
        'email',
        'phone',
        'tel',
        'select',
        'checkbox',
        'radio',
        'switch',
        'textarea',
        'selectable'
    ])
}

Input.defaultProps = {
    rules: {},
    type: 'text',
    defaultValue: '',
    className: '',
    labelClass: '',
    helpText: '',
    options: []
}

export default Input
