import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Constants from '../../../../../helpers/Constants'
import { Box } from '../../../../../components/vanilla'
import SubHRCard from './components/SubHRCard'
import { useSubHRList } from '../../../../../hooks/useAdmin'
import Pagination from '../../../../../components/Pagination'
import SubHRContractModal from './components/SubHRContractModal'
import Utils from '../../../../../helpers/Utils'
import { useNavigate } from 'react-router-dom'

function ListHR() {
    // Data Vars
    const [currentPage, setCurrentPage] = useState(1)
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [selectedSubHr, setSelectedSubHR] = useState([])

    const navigate = useNavigate()
    //get page no from URL page
    let search = window.location.search
    let params = new URLSearchParams(search)
    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    //call method on click on browser back and forward arrow button
    window.onpopstate = async () => {
        try {
            setCurrentPage(pageNo)
        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        setCurrentPage(pageNo > 0 ? pageNo : 1)
    }, [])

    const {
        data,
        isLoading: isInitLoading,
        isError: isInitError
    } = useSubHRList({ name: name, page: currentPage, phNumber: phoneNumber })

    /** Function to change page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigate({
                pathname: Constants.ROUTE_ADMIN_MANAGE_LIST_HR,
                search: '?page=' + pageNumber
            })
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    if (!isInitLoading && !isInitError && data) {
        Utils.scrollToTop()
        return (
            <Box className="p-4 mb-4">
                <Box className="mb-4 d-flex align-items-center justify-content-between">
                    <h3>Manage Sub-Admin HRs</h3>
                    <Link to={Constants.ROUTE_ADMIN_MANAGE_ADD_HR} className="btn-job btn-primary">
                        <i className="fas fa-user-plus"></i> Add New
                    </Link>
                </Box>

                <Box className="card mb-4 shadow-sm">
                    <Box className="card-body">
                        <h4>
                            <b>Search Pane</b>
                        </h4>

                        <Box className="row mb-3 ">
                            <div className="col-sm-6">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    className="form-control"
                                    placeholder="John Doe"
                                    id="name"
                                />
                            </div>
                            <div className="col-sm-6">
                                <label htmlFor="phNumber" className="form-label">
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    className="form-control"
                                    placeholder="0000000000 (Without country code)"
                                    id="phNumber"
                                />
                            </div>
                        </Box>
                    </Box>
                </Box>

                {data.subHrs.map((subHR) => (
                    <SubHRCard subHR={subHR} key={subHR.id} onSetSubHR={() => setSelectedSubHR(subHR)} />
                ))}

                <SubHRContractModal selectedSubHr={selectedSubHr} />

                {/* Pagination */}
                <Pagination
                    currentPage={currentPage}
                    totalPages={data.totalPages}
                    itemCount={data.count}
                    changePage={(page) => changePage(page)}
                />
            </Box>
        )
    }
    return null
}

export default ListHR
