import React from 'react'
import { useForm } from 'react-hook-form'
import { useAddSubscriptionStatus } from '../../../hooks/useAdmin'
import Utils from '../../../helpers/Utils'
import Constants from '../../../helpers/Constants'
import { Box, Button, Column, Row } from '../../vanilla'
import SubscriptionFields from '../../forms/employer/SubscriptionFields'

const SubscriptionStatusModal = ({ subscribedAgency, updateSubscriptionEnd }) => {
    const form = useForm()
    /** Function to close and reset modal and data */
    const closeAndReset = async () => {
        form.reset({})
        Utils.closeItem(`btn-close-update-subscription`)
    }

    const { mutateAsync: addSubscription, isLoading } = useAddSubscriptionStatus()
    const onSubmit = async (data) => {
        try {
            const res = await addSubscription({
                employerId: subscribedAgency.id,
                data
            })

            if (res.status === Constants.STATUS_SUCCESS) {
                updateSubscriptionEnd({ subscriptionEnd: res.data.subscriptionEnd, index: subscribedAgency.index })
                closeAndReset()
                // Showing success toast
                Utils.showToast(
                    subscribedAgency.subscribedUntil !== ''
                        ? Constants.MSG_SUCCESS_UPDATE_SUBSCRIPTION
                        : Constants.MSG_SUCCESS_ADD_SUBSCRIPTION,
                    Constants.TYPE_TOAST_SUCCESS
                )
            }
        } catch (e) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <Box className="back-modal" id="edit-modals">
            {/* Edit Profile Details Modal */}
            <Box
                className="modal fade"
                id="subscriptionModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <Box className="modal-dialog modal-dialog-centered">
                    <Box className="modal-content">
                        <Box Element="form" onSubmit={form.handleSubmit(onSubmit)}>
                            <Box className="modal-header">
                                {subscribedAgency.subscribedUntil !== '' ? (
                                    <h5 className="modal-title">
                                        Update Subscription for <b> {subscribedAgency.companyName}</b>
                                    </h5>
                                ) : (
                                    <h5 className="modal-title">
                                        Add Subscription for <b> {subscribedAgency.companyName}</b>
                                    </h5>
                                )}

                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </Box>
                            <Box className="modal-body">
                                <Row>
                                    <SubscriptionFields form={form} subscribedAgency={subscribedAgency} />
                                </Row>
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    id="btn-close-update-subscription"
                                    color="warning"
                                    className="px-5"
                                    style={{ fontSize: '14px' }}
                                    onClick={(e) => closeAndReset()}
                                    btnType="button"
                                    data-bs-dismiss="modal"
                                    label="Close"
                                />
                                <Row>
                                    <Column className="col-md-12">
                                        <Button
                                            btnType="submit"
                                            color="primary"
                                            className="px-5"
                                            style={{ fontSize: '14px' }}
                                            label={subscribedAgency.subscribedUntil !== '' ? 'Update' : 'Add'}
                                            disabled={isLoading}
                                        />
                                    </Column>
                                </Row>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SubscriptionStatusModal
