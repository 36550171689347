import { createSlice } from '@reduxjs/toolkit'

// Creating the slice
export const slice = createSlice({
    name: 'loader',
    initialState: {
        loading: false
    },
    reducers: {
        setLoadingState: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const { setLoadingState } = slice.actions

export const getIsLoading = (state) => state.loader.loading

export default slice.reducer
