export const baseStyle = {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.03)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
}

export const messageStyle = {
    maxWidth: '21rem',
    textAlign: 'center'
}
