import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'fsquare-ckeditor'

const CKEditorWrapper = ({ placeholder = 'Enter your value', onChange, value = '' }) => {
    const config = {
        placeholder
    }

    return (
        <CKEditor
            editor={ClassicEditor}
            config={config}
            data={value}
            onChange={(_event, editor) => {
                onChange(editor.getData())
            }}
            onReady={(editor) => {
                editor.editing.view.change((writer) => {
                    writer.setStyle('min-height', '10rem', editor.editing.view.document.getRoot())
                })
            }}
        />
    )
}

export default CKEditorWrapper
