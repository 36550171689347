import React from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Footer = ({ children, className, ...props }) => {
    return (
        <Box className={['card', className].join(' ')} {...props}>
            {children}
        </Box>
    )
}

Footer.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

Footer.defaultProps = {
    className: ''
}

export default Footer
