import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import AddSitemapForm from '../../../components/forms/sitemap/Form'

function AddSitemap() {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Add New Sitemap</Heading>
            <AddSitemapForm />
        </Box>
    )
}
export default AddSitemap
