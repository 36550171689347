import React from 'react'
import Box from '../Box'

const Badge = ({ children, className, color, ...props }) => {
    return (
        <Box className={[className, 'ime-badge', `ime-badge-${color}`].join(' ')} {...props}>
            {children}
        </Box>
    )
}

export default Badge
