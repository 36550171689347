import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import AddForm from '../../../components/forms/communityPost/Form'

function AddCommunityPost() {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Add New Community Post</Heading>
            <AddForm />
        </Box>
    )
}
export default AddCommunityPost
