import React, { useState } from 'react'
import { Avatar, Badge, Box, Heading } from '../../../components/vanilla'
import CardBasic from '../../../components/Cards/CardBasic'
import Pagination from '../../../components/Pagination'
import { useNonVerifiedJobSeekers } from '../../../hooks/useAdmin'
import Utils from '../../../helpers/Utils'
import Constants from '../../../helpers/Constants'

const JobSeekerNonVerified = () => {
    // Data vars here
    const [currentPage, setCurrentPage] = useState(1)

    let { isLoading, data, isError } = useNonVerifiedJobSeekers({ page: currentPage })
    if (isError) Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)

    return (
        <Box className="p-4 mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">Non Verified Candidates List</Heading>
            </Box>

            {!isLoading && data && (
                <>
                    {data.jobSeekers.length > 0 ? (
                        <>
                            {data.jobSeekers.map((jobSeeker) => (
                                <CardBasic
                                    bodyClass="d-flex align-items-center"
                                    cardClass="shadow-sm mb-3"
                                    key={jobSeeker.uuId}>
                                    <Box>
                                        <Avatar size="xl" />
                                    </Box>
                                    <Box style={{ flex: 1 }} className="ps-3">
                                        <Heading>{`${jobSeeker.firstName} ${jobSeeker.middleName} ${jobSeeker.lastName}`}</Heading>
                                        <Box>{jobSeeker.phoneNumber}</Box>
                                    </Box>
                                    <Box>
                                        <Badge color="primary" style={{ fontSize: '1.3rem' }}>
                                            {'Not Verified'}
                                        </Badge>
                                    </Box>
                                </CardBasic>
                            ))}
                        </>
                    ) : (
                        <Box Element="p">No Non Verified Job Seeker!</Box>
                    )}

                    <Pagination
                        totalPages={data.totalPages}
                        currentPage={currentPage}
                        nextPage={() => setCurrentPage((_currentPage) => _currentPage + 1)}
                        prevPage={() => setCurrentPage((_currentPage) => _currentPage - 1)}
                        changePage={(page) => setCurrentPage(page)}
                    />
                </>
            )}
        </Box>
    )
}

export default JobSeekerNonVerified
