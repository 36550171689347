import React from 'react'
import JobList from '../../../components/elements/Job/JobList'

function ListJobs() {
    return (
        <div className="admin-container mb-4">
            <h3>Job List</h3>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="employer-job-list-content">
                            <JobList />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListJobs
