import React from 'react'
import { Badge, Flex } from '../../../../../../../../components/vanilla'

const BasicMessage = ({ message, color = 'light' }) => {
    return (
        <Flex className="mb-4 justify-content-center">
            <Badge color={color}>{message}</Badge>
        </Flex>
    )
}

export default BasicMessage
