import React from 'react'
import CardBasic from '../../../../../../components/Cards/CardBasic'
import { Avatar, Box, Button, Heading, Row } from '../../../../../../components/vanilla'
import Constants from '../../../../../../helpers/Constants'
import { FaBriefcase } from 'react-icons/fa'

function JobSeekerCard({ firstName, middleName, lastName, email, phoneNumber, id, profile }) {
    return (
        <Row style={{ textDecoration: 'none' }}>
            <CardBasic bodyClass="d-flex align-items-center" cardClass="shadow-sm mb-3" key={id}>
                <Box>
                    <Avatar size="xl" />
                </Box>
                <Box style={{ flex: 1 }} className="ps-3">
                    <Heading className="text-dark">{`${firstName} ${middleName} ${lastName}`}</Heading>
                    <Box className="text-muted">
                        <b>Email:</b> {email}
                    </Box>
                    <Box className="text-muted">
                        <b>Phone:</b> {phoneNumber}
                    </Box>
                    <Box className="text-muted">
                        <b>Total Experience:</b> {`${profile.tagExperience ? profile.tagExperience : 'N/A'}`}
                    </Box>
                    <Box className="text-muted">
                        <b>Major Skills: </b>
                        {profile.majorSkills.length !== 0 ? (
                            <>
                                {profile.majorSkills.map((skill, index) => (
                                    <label key={index}>{(index ? ', ' : '') + skill}</label>
                                ))}
                            </>
                        ) : (
                            <>No Skills</>
                        )}
                    </Box>
                </Box>

                <Box>
                    <Button
                        type="link"
                        to={Constants.ROUTE_ADMIN_MANAGE_JOB_SEEKER_APPLICATIONS(id)}
                        className="w-100"
                        label="Applications"
                        icon={<FaBriefcase />}
                        color="warning"
                        btnType="button"
                    />
                </Box>
            </CardBasic>
        </Row>
    )
}

export default JobSeekerCard
