import React from 'react'
import { FaEdit } from 'react-icons/fa'
import CardBasic from '../../../../../components/Cards/CardBasic'
import { Avatar, Badge, Box, Button, Heading } from '../../../../../components/vanilla'
import Constants from '../../../../../helpers/Constants'

function AdminCard({ admin: { id, firstName, middleName, lastName, email, phoneNumber, isSuperAdmin } }) {
    return (
        <CardBasic bodyClass="d-flex align-items-center" cardClass="shadow-sm mb-3">
            <Box>
                <Avatar size="xl" />
            </Box>
            <Box style={{ flex: 1 }} className="ps-3">
                <Heading className="text-dark">
                    {`${firstName} ${middleName} ${lastName}`}{' '}
                    {isSuperAdmin && <Badge color="success">Super Admin</Badge>}
                </Heading>
                <Box className="text-muted">{email}</Box>
                <Box className="text-muted">{phoneNumber}</Box>
            </Box>
            <Box>
                <Button
                    type="link"
                    to={Constants.ROUTE_ADMIN_EDIT_ADMIN({ adminId: id })}
                    className="w-100"
                    label="Edit"
                    icon={<FaEdit />}
                    color="primary"
                    btnType="button"
                />
            </Box>
        </CardBasic>
    )
}

export default AdminCard
