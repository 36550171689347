import { createSlice } from '@reduxjs/toolkit'

// Initial State
const initialState = {
    id: '',
    email: '',
    type: '',
    phoneNumber: '',
    firstName: '',
    middleName: '',
    lastName: '',
    avatarPath: '',
    isActive: '',
    isEmailVerified: '',
    isMobileVerified: '',
    adminType: '',
    isSuperAdmin: '',
    canViewDashboard: '',
    canTogglePool: '',
    employerType: '',
    companyName: '',
    isVendor: '',
    vendorType: '',
    referralCode: '',

    accessToken: '',
    refreshToken: ''
}

// Creating slice here
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserProfile: (state, action) => {
            state.id = action.payload.id
            state.email = action.payload.email
            state.type = action.payload.type
            state.phoneNumber = action.payload.phoneNumber
            state.firstName = action.payload.firstName
            state.middleName = action.payload.middleName
            state.lastName = action.payload.lastName
            state.avatarPath = action.payload.avatarPath
            state.isActive = action.payload.isActive
            state.isEmailVerified = action.payload.isEmailVerified
            state.isMobileVerified = action.payload.isMobileVerified
            state.adminType = action.payload.adminType
            state.isSuperAdmin = action.payload.isSuperAdmin
            state.canViewDashboard = action.payload.canViewDashboard
            state.canTogglePool = action.payload.canTogglePool
            state.employerType = action.payload.employerType
            state.companyName = action.payload.companyName
            state.isVendor = action.payload.isVendor
            state.vendorType = action.payload.vendorType
            state.referralCode = action.payload.referralCode

            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },

        resetUserProfile: (state) => {
            state.id = null
            state.email = null
            state.type = null
            state.phoneNumber = null
            state.firstName = null
            state.middleName = null
            state.lastName = null
            state.avatarPath = null
            state.isActive = null
            state.isEmailVerified = null
            state.isMobileVerified = null
            state.adminType = null
            state.isSuperAdmin = null
            state.canViewDashboard = null
            state.canTogglePool = null
            state.employerType = null
            state.companyName = null
            state.isVendor = null
            state.vendorType = null
            state.referralCode = null

            state.accessToken = null
            state.refreshToken = null
        }
    }
})

export const { setUserProfile, resetUserProfile } = userSlice.actions
export const getUserId = (state) => state.user.id
export const getUserEmail = (state) => state.user.email
export const getUserType = (state) => state.user.type
export const getUserPhoneNumber = (state) => state.user.phoneNumber
export const getUserFirstName = (state) => state.user.firstName
export const getUserMiddleName = (state) => state.user.middleName
export const getUserLastName = (state) => state.user.lastName
export const getUserAvatarPath = (state) => state.user.avatarPath
export const getUserIsActive = (state) => state.user.isActive
export const getUserIsEmailVerified = (state) => state.user.isEmailVerified
export const getUserIsMobileVerified = (state) => state.user.isMobileVerified
export const getAccessToken = (state) => state.user.accessToken
export const getRefreshToken = (state) => state.user.refreshToken
export const getExpiresIn = (state) => state.user.expiresIn
export const getAdminType = (state) => state.user.adminType
export const getIsSuperAdmin = (state) => state.user.isSuperAdmin
export const getCanViewDashboard = (state) => state.user.canViewDashboard
export const getCanTogglePool = (state) => state.user.canTogglePool
export const getEmployerType = (state) => state.user.employerType
export const getCompanyName = (state) => state.user.companyName
export const getIsVendor = (state) => state.user.isVendor
export const getVendorType = (state) => state.user.vendorType
export const getReferralCode = (state) => state.user.referralCode

// Function to check if user is logged in
export const isLoggedIn = (state) => state.user.accessToken !== '' && state.user.accessToken != null

export default userSlice.reducer
