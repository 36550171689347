import React from 'react'

function AddEdit({ questionnaires = [], callback }) {
    /** Function to handle questionnaire input */
    const handleQuestionnaireInput = (index, event) => {
        let values = [...questionnaires]

        values[index][event.target.name] = event.target.name == 'mustHave' ? event.target.checked : event.target.value

        callback(values)
    }

    /** Function to add new question */
    const addNewQuestion = (e) => {
        e.preventDefault()

        // Adding new question template
        callback([
            ...questionnaires,
            {
                question: '',
                responseType: 'Yes/No',
                idealResponse: 'Yes',
                mustHave: true
            }
        ])
    }

    /** Function to delete a question */
    const deleteQuestion = (index) => {
        let values = [...questionnaires]
        values.splice(index, 1)
        callback(values)
    }

    return (
        <form>
            <h3 className="mb-3">
                Add Questionnaires <span className="fst-italic">(Optional)</span>
            </h3>

            {/* Question item */}
            {questionnaires.map((questionnaire, index) => (
                <div className="mb-4 p-4 border" key={index}>
                    <div>
                        <label htmlFor={'screeningQuestion' + index} className="col-form-label">
                            Write a Screening question
                        </label>
                    </div>
                    <div>
                        <textarea
                            value={questionnaire.question}
                            name="question"
                            className="form-control"
                            id={'screeningQuestion' + index}
                            placeholder="Do you have ..."
                            rows={1}
                            onChange={(e) => handleQuestionnaireInput(index, e)}></textarea>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="me-5">
                                <label htmlFor={'responseType' + index} className="col-form-label">
                                    Response Type
                                </label>
                                <div>
                                    <select
                                        onChange={(e) => handleQuestionnaireInput(index, e)}
                                        value={questionnaire.responseType}
                                        name="responseType"
                                        className="form-select"
                                        id={'responseType' + index}>
                                        <option value={'Yes/No'}>Yes/No</option>
                                        <option value={'Numeric'}>Numeric</option>
                                        <option value={'Descriptive'}>Descriptive</option>
                                    </select>
                                </div>
                            </div>
                            <div className="me-5">
                                <label htmlFor={'idealResponse' + index} className="col-form-label">
                                    Ideal Response
                                </label>
                                <div>
                                    {questionnaire.responseType == 'Yes/No' ? (
                                        <>
                                            <select
                                                onChange={(e) => handleQuestionnaireInput(index, e)}
                                                value={questionnaire.idealResponse}
                                                name="idealResponse"
                                                className="form-select"
                                                id={'idealResponse' + index}>
                                                <option value={'Yes'}>Yes</option>
                                                <option value={'No'}>No</option>
                                            </select>
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                name="idealResponse"
                                                onChange={(e) => handleQuestionnaireInput(index, e)}
                                                className="form-control"
                                                value={questionnaire.idealResponse}
                                                placeholder="Enter ideal answer"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className={['me-5', questionnaire.responseType === 'Descriptive' ? 'd-none' : ''].join(
                                    ' '
                                )}>
                                <div className="form-check d-flex align-items-center">
                                    <input
                                        onChange={(e) => handleQuestionnaireInput(index, e)}
                                        name="mustHave"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={questionnaire.mustHave}
                                        id={'mustHave' + index}
                                    />
                                    <label className="ms-3" htmlFor={'mustHave' + index}>
                                        Must-have qualification
                                    </label>
                                </div>
                            </div>
                        </div>
                        {index != 0 && (
                            <>
                                <button
                                    type="button"
                                    onClick={() => deleteQuestion(index)}
                                    className="btn btn-outline-danger">
                                    Remove
                                </button>
                            </>
                        )}
                    </div>
                </div>
            ))}

            {/* End Question item */}

            <button onClick={(e) => addNewQuestion(e)} className="btn btn-outline-success float-end">
                Add Question
            </button>
        </form>
    )
}

export default AddEdit
