import React from 'react'
import Constants from '../../helpers/Constants'
import { Button } from '../vanilla'

function featuredJobs({ jobs }) {
    return (
        <div
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-touch="true"
            id="featured-jobs-carousel"
            data-bs-interval="3000">
            <div className="carousel-inner">
                {jobs.map((job, index) => (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={job.id}>
                        <div>
                            <h1 className="fw-light carousel_white_text">{job.positionName}</h1>
                            <p className="lead text-muted carousel_white_text">{job.description.substring(0, 350)}</p>
                            <p>
                                <Button
                                    color="success"
                                    type="link"
                                    to={Constants.ROUTE_CLIENT_JOB_SEEKER_APPLY_JOB(job.id)}
                                    className="my-2 px-5"
                                    label="APPLY NOW"
                                />
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default featuredJobs
