import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Constants from '../../helpers/Constants'
import { getUserId, getUserType } from '../../features/user/userSlice'
import CreatableSelect from '../elements/CreatableSelect'
import SingleSelect from '../elements/SingleSelect'
import ValidationError from '../../errors/ValidationError'
import UnauthorizedError from '../../errors/UnauthorizedError'
import BadRequestError from '../../errors/BadRequestError'
import Utils from '../../helpers/Utils'
import { getCities } from '../../api/Common'
import { getAllEmployers } from '../../api/Admin'
import { setLoadingState } from '../../features/loader/LoaderSlice'
import AddEdit from '../elements/questionnaire/AddEdit'
import { addNewJob, getJobById, editJob, getSampleJobData } from '../../api/Jobs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../vanilla'

function AddForm({ type = Constants.TYPE_ANON_ADD }) {
    // Getting current user type
    const userType = useSelector(getUserType)
    const userId = useSelector(getUserId)
    const formType = type
    const params = useParams()
    let jobId
    const location = useLocation()

    /** If the form type is edit */
    if (formType == Constants.TYPE_ANON_EDIT) {
        // Setting job Id
        jobId = params.jobId
    }

    // Data vars
    const [technicalSkills, setTechnicalSkills] = useState([])
    const [employerId, setEmployerId] = useState('')
    const [positionName, setPositionName] = useState('')
    const [minExp, setMinExp] = useState(0)
    const [maxExp, setMaxExp] = useState(0)
    const [minSalary, setMinSalary] = useState(0)
    const [maxSalary, setMaxSalary] = useState(0)
    const [qualification, setQualification] = useState('')
    const [locationId, setLocationId] = useState([])
    const [remote, setRemote] = useState(false)
    const [isContract, setIsContract] = useState(false)
    const [jobDescription, setJobDescription] = useState('')
    const [feature, setFeature] = useState(false)
    const [active, setActive] = useState(false)
    const [questionnaires, setQuestionnaires] = useState([])
    const [jobType, setJobType] = useState('')
    const [totalHiring, setTotalHiring] = useState(1)
    const [maxNoticePeriod, setMaxNoticePeriod] = useState(0)

    // Edit Specific vars
    const [employerName, setEmployerName] = useState('')

    const [employers, setEmployers] = useState([])
    const [cities, setCities] = useState([])

    const [step, setStep] = useState(1)

    // Loader vars
    const [addLoading, setAddLoading] = useState(false)

    // Init Dispatch here
    const dispatch = useDispatch()
    // Init Navigate here
    const navigate = useNavigate()

    /** Function to update questionnaires by callback */
    const updateQuest = (questionnairesArr) => {
        setQuestionnaires(questionnairesArr)
    }

    // Callback to get technical skills from child
    const updateTechnicalSkills = (skills) => {
        setTechnicalSkills(skills)
    }

    // Callback to get employer details
    const updateEmployer = (employerDetail) => {
        setEmployerId(employerDetail.value)
    }

    /** Function to get all the available cities */
    const PopulateInitData = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the api to get city here
            const citiesResponse = await getCities('', '')
            let citiesArr = []
            citiesResponse.forEach((city) => {
                citiesArr.push({ label: city.name, value: city.id })
            })
            setCities(citiesArr)

            /** Fetching admin required data */
            if (userType == Constants.TYPE_USER_ADMIN) {
                // Calling the api to get employers here
                const employersResponse = await getAllEmployers('', '', Constants.TYPE_USER_EMPLOYER_COMPANY)

                // Setting states here
                let employers = []
                employersResponse.forEach((employee) => {
                    employers.push({ label: employee.companyName, value: employee.id })
                })
                setEmployers(employers)
            }

            /** Fetching edit data */
            if (formType == Constants.TYPE_ANON_EDIT) {
                if (Constants.TYPE_ANON_SAMPLE_JOBS_ARR.includes(jobId)) {
                    await getJobDetails(Constants.TYPE_JOB_SAMPLE)
                } else {
                    await getJobDetails('')
                }
            }

            // Getting the show variable (to mark questionnaire & other vars)
            const { show } = location.state || ''
            // If show is present changing step here
            if (show && show === 'questionnaire') {
                setStep(2) // Switching to next page
            }

            // Setting the loading here
            dispatch(setLoadingState(false))
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to handle ad job */
    const handleAddJob = async (e) => {
        try {
            e.preventDefault()

            // Setting loading here
            setAddLoading(true)

            let questionnaireArr = []
            let techSkillsArr = []
            // checking questionnaires if any
            if (questionnaires.length > 0 && questionnaires[0].question !== '') {
                questionnaireArr = [...questionnaires]
            }

            // Looping through tech skills
            technicalSkills.forEach((techSkill) => techSkillsArr.push(techSkill.value))

            // Preparing the data
            let data = {
                positionName,
                minExp,
                maxExp,
                minSal: minSalary,
                maxSal: maxSalary,
                qualification,
                cityId: locationId[0].value,
                isRemote: remote,
                isContract: isContract,
                techSkill: techSkillsArr,
                description: jobDescription,
                isFeatured: feature,
                isActive: active,
                questionaire: questionnaireArr,
                totalHiring: totalHiring,
                maxNoticePeriod
            }

            if (userType === Constants.TYPE_USER_ADMIN) data.employerId = employerId

            // Calling API here
            if (jobType == Constants.TYPE_JOB_SAMPLE || formType == Constants.TYPE_ANON_ADD)
                await addNewJob(data, userType)
            else await editJob(jobId, userType, data)

            // Showing success toast
            const toastMsg =
                formType === Constants.TYPE_ANON_ADD
                    ? Constants.MSG_ADD_JOB_SUCCESS_ADMIN
                    : Constants.MSG_EDIT_JOB_SUCCESS_ADMIN
            Utils.showToast(toastMsg, Constants.TYPE_TOAST_SUCCESS)

            // Setting loading here
            setAddLoading(false)

            const navigateURL =
                userType === Constants.TYPE_USER_ADMIN
                    ? Constants.ROUTE_ADMIN_JOBS_LIST
                    : Constants.ROUTE_CLIENT_EMPLOYER_JOB_LIST
            navigate(navigateURL)
        } catch (error) {
            // Setting loading here
            setAddLoading(false)
            if (error instanceof ValidationError || error instanceof BadRequestError)
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Function to validate job data */
    const validateJobData = async (e) => {
        try {
            e.preventDefault()

            // Admin Validations
            if (userType === Constants.TYPE_USER_ADMIN) {
                if (employerId === '') throw new ValidationError(Constants.MSG_EMPTY_EMPLOYER)
            }
            // Normal Validations
            if (positionName.length < 2) throw new ValidationError(Constants.MSG_EMPTY_POSITION_NAME)
            if (!maxNoticePeriod || parseInt(maxNoticePeriod) < 1)
                throw new ValidationError(Constants.MSG_GREATER_THAN_ZERO_MAXIMUM_NOTICE_PERIOD)
            if (parseFloat(minExp) > parseFloat(maxExp) || parseFloat(maxExp) < parseFloat(minExp))
                throw new ValidationError(Constants.MSG_INVALID_EXP)
            if (minSalary < 1 || maxSalary < 1) throw new ValidationError(Constants.MSG_LESS_SALARY)
            if (parseInt(minSalary) > parseInt(maxSalary) || parseInt(maxSalary) < parseInt(minSalary))
                throw new ValidationError(Constants.MSG_INVALID_SALARY)
            //validation for minimum total hiring should be 1
            if (parseInt(totalHiring) < 1) throw new ValidationError(Constants.MINIMUM_TOTAL_HIRING)
            if (qualification === '') throw new ValidationError(Constants.MSG_EMPTY_QUALIFICATION)
            if (locationId.length === 0) throw new ValidationError(Constants.MSG_EMPTY_LOCATION)
            if (technicalSkills.length === 0) throw new ValidationError(Constants.MSG_EMPTY_TECH_SKILLS)

            // Increasing the step
            setStep(2)
        } catch (error) {
            if (
                error instanceof ValidationError ||
                error instanceof UnauthorizedError ||
                error instanceof BadRequestError
            )
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    /** Function to get Job details by id */
    const getJobDetails = async (call) => {
        try {
            let response
            if (call == Constants.TYPE_JOB_SAMPLE) {
                setJobType(Constants.TYPE_JOB_SAMPLE)
                response = await getSampleJobData(userId, true, jobId)
            } else response = await getJobById(jobId, userType)

            let skillArr = []
            response.techSkill.forEach((skill) => {
                skillArr.push({ label: skill, value: skill })
            })

            // Setting data here
            setEmployerId(response.employer.id)
            setEmployerName(response.employer.companyName)
            setPositionName(response.positionName)
            setMinExp(response.minExp)
            setMaxExp(response.maxExp)
            setMinSalary(response.minSal)
            setMaxSalary(response.maxSal)
            setQualification(response.qualification)
            setLocationId([
                {
                    label: response.city.name,
                    value: response.city.id
                }
            ])
            setRemote(response.isRemote)
            setIsContract(response.isContract)
            setTechnicalSkills(skillArr)
            setJobDescription(response.description)
            setFeature(response.isFeatured)
            setActive(response.isActive)
            setQuestionnaires(response.questionaire)
            setTotalHiring(response.totalHiring)
            setMaxNoticePeriod(response.maxNoticePeriod)
        } catch (error) {
            return error
        }
    }

    useEffect(() => {
        // Populating initial data for the page
        PopulateInitData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {step === 1 && (
                <>
                    <form onSubmit={(e) => validateJobData(e)}>
                        {userType === Constants.TYPE_USER_ADMIN && (
                            <>
                                <div className="row mb-3">
                                    <label htmlFor="employer" className="col-sm-4 col-form-label">
                                        Select Employer <span className="red-text">*</span>
                                    </label>
                                    <div className="col-sm-8">
                                        {formType == Constants.TYPE_ANON_ADD ? (
                                            <SingleSelect
                                                callback={updateEmployer}
                                                options={employers}
                                                placeholder={'Start typing Employer Name ... '}
                                                noOptionsMessage="No Employer Found"
                                            />
                                        ) : (
                                            <>
                                                <input
                                                    type="text"
                                                    value={employerName}
                                                    name="employerName"
                                                    className="form-control"
                                                    readOnly
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}

                        {/* Position Name */}
                        <div className="row mb-3">
                            <label htmlFor="positionName" className="col-sm-4 col-form-label">
                                Position Name <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-8">
                                <input
                                    onChange={(e) => setPositionName(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    id="positionName"
                                    placeholder="Position Name "
                                    value={positionName}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="maxNoticePeriod" className="col-sm-4 col-form-label">
                                Maximum Notice Period <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-4 col-6">
                                <input
                                    onChange={(e) => setMaxNoticePeriod(e.target.value)}
                                    value={maxNoticePeriod}
                                    type="number"
                                    className="form-control"
                                    id="maxNoticePeriod"
                                    placeholder="Max notice period"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="experience" className="col-sm-4 col-form-label">
                                Experience (YEARS) <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-4 col-6 ">
                                <input
                                    onChange={(e) => setMinExp(e.target.value)}
                                    value={minExp}
                                    type="number"
                                    className="form-control"
                                    id="experience"
                                    placeholder="MIN"
                                />
                            </div>
                            <div className="col-sm-4 col-6 ">
                                <input
                                    onChange={(e) => setMaxExp(e.target.value)}
                                    value={maxExp}
                                    type="number"
                                    className="form-control"
                                    id="experienceMax"
                                    placeholder="MAX"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="salary" className="col-sm-4 col-form-label">
                                Annual Salary (INR) <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-4 col-6 ">
                                <input
                                    onChange={(e) => setMinSalary(e.target.value)}
                                    value={minSalary}
                                    type="number"
                                    className="form-control"
                                    id="salary"
                                    placeholder="MIN"
                                />
                            </div>
                            <div className="col-sm-4 col-6 ">
                                <input
                                    onChange={(e) => setMaxSalary(e.target.value)}
                                    value={maxSalary}
                                    type="number"
                                    className="form-control"
                                    id="salaryMax"
                                    placeholder="MAX"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="salary" className="col-sm-4 col-form-label">
                                Total Hiring <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-4 col-6 ">
                                <input
                                    onChange={(e) => setTotalHiring(e.target.value)}
                                    value={totalHiring}
                                    type="number"
                                    className="form-control"
                                    id="totalHiring"
                                    placeholder="Minimum Hiring Count"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">
                                Qualification <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-8 ">
                                <select
                                    value={qualification}
                                    onChange={(e) => setQualification(e.target.value)}
                                    className="form-select"
                                    aria-label=".form-select example">
                                    {Constants.QUALIFICATIONS.map((qualification, key) => {
                                        return (
                                            <option key={key} value={qualification.value}>
                                                {qualification.name}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">
                                Location <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-4 col-8">
                                <SingleSelect
                                    callback={(values) => setLocationId(values)}
                                    options={cities}
                                    isMulti
                                    placeholder={'Select City'}
                                    noOptionsMessage="No City Found"
                                    initialValues={locationId}
                                />
                            </div>
                            <div className="col-sm-1 col-1">
                                <div className="form-check pt-2">
                                    <input
                                        checked={remote}
                                        onChange={(e) => setRemote(e.target.checked)}
                                        type="checkbox"
                                        className="form-check-input"
                                        id="save-info"
                                    />
                                    <label className="form-check-label" htmlFor="save-info">
                                        Remote
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-1 col-1">
                                <div className="form-check pt-2">
                                    <input
                                        checked={isContract}
                                        onChange={(e) => setIsContract(e.target.checked)}
                                        type="checkbox"
                                        className="form-check-input"
                                        id="save-info"
                                    />
                                    <label className="form-check-label" htmlFor="save-info">
                                        Contract?
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="inputPassword3" className="col-sm-4 col-form-label">
                                Technical Skills <span className="red-text">*</span>
                            </label>
                            <div className="col-sm-8">
                                <CreatableSelect
                                    initialValues={technicalSkills}
                                    options={Constants.LIST_DEFAULT_TECH_SKILLS}
                                    callback={updateTechnicalSkills}
                                    placeholder="Search for a Skill and click to add"
                                />
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="jobDescription" className="col-sm-4 col-form-label">
                                Job Description <span className="text-muted fst-italic">(Optional)</span>
                            </label>
                            <div className="col-sm-8">
                                <textarea
                                    onChange={(e) => setJobDescription(e.target.value)}
                                    className="form-control"
                                    id="jobDescription"
                                    placeholder="We are looking for ..."
                                    rows="3"
                                    value={jobDescription}></textarea>
                            </div>
                        </div>

                        {userType === Constants.TYPE_USER_ADMIN && (
                            <>
                                <div className="row mb-3">
                                    <label className="col-sm-4 col-form-label" htmlFor="feature">
                                        Feature?
                                    </label>
                                    <div className="col-sm-8 ">
                                        <div className="form-check form-switch">
                                            <input
                                                checked={feature}
                                                onChange={(e) => setFeature(e.target.checked)}
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="feature"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label className="col-sm-4 col-form-label" htmlFor="active">
                                        Active?
                                    </label>
                                    <div className="col-sm-8 ">
                                        <div className="form-check form-switch">
                                            <input
                                                checked={active}
                                                onChange={(e) => setActive(e.target.checked)}
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="active"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="row mb-3">
                            <div className="col-sm-8 offset-sm-4">
                                <Button label="Next" className="px-5" btnType="submit" />
                            </div>
                        </div>
                    </form>
                </>
            )}

            {step === 2 && (
                <>
                    <AddEdit questionnaires={questionnaires} callback={updateQuest} />
                    <Button
                        label={
                            addLoading ? (
                                <>
                                    <i className="fas fa-circle-notch fa-spin"></i> Loading...
                                </>
                            ) : (
                                <>Save</>
                            )
                        }
                        className="px-5"
                        onClick={(e) => handleAddJob(e)}
                        disabled={addLoading}
                    />
                </>
            )}
        </div>
    )
}

export default AddForm
