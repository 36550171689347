import axios from 'axios'
import Constants from '../helpers/Constants'
import BadRequestError from '../errors/BadRequestError'
import UnauthorizedError from '../errors/UnauthorizedError'
import ValidationError from '../errors/ValidationError'
import moment from 'moment'
import NotFoundError from '../errors/NotFoundError'

/** Function to login user */
export const loginUser = async (userData) => {
    return new Promise(async (resolve, reject) => {
        try {
            /** Calling the api */
            const response = await axios.post(Constants.API_ROUTE_LOGIN, {
                email: userData.email,
                password: userData.password,
                type: userData.type
            })

            resolve(response.data)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNAUTHORIZED)
                reject(
                    new UnauthorizedError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            else if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            else if (error.response.status == Constants.STATUS_BAD_REQUEST)
                reject(
                    new BadRequestError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            else reject(error)
        }
    })
}

/** Function to logout a user */
export const logoutUser = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting user data from local storage
            const userData = getUserDataLocalStorage()

            // Calling the api
            const response = await axios.delete(Constants.API_ROUTE_LOGOUT, {
                data: { refreshToken: userData.refreshToken }
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to register a user */
export const registerUser = async (userData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const userType = userData.type

            // Default vars
            let apiURL = ''
            let requestBody = {}

            if (userType == Constants.TYPE_USER_EMPLOYER) {
                /** Employer registration */
                // Setting the api URL
                apiURL = Constants.API_ROUTE_REGISTER_EMPLOYER

                // Setting the request body
                requestBody = {
                    email: userData.companyEmail,
                    password: userData.password,
                    companyName: userData.companyName,
                    phoneNumber: userData.phoneNumber
                }
            } else {
                /** Job Seeker Registration */
                // Setting the api URL
                apiURL = Constants.API_ROUTE_REGISTER_JOB_SEEKER

                // Setting the request body
                requestBody = {
                    email: userData.email,
                    password: userData.password,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    middleName: userData.middleName,
                    phoneNumber: userData.phoneNumber,
                    referralCode: userData.referralCode,
                    majorSkills: userData.majorSkills,
                    totalExperience: userData.totalExperience,
                    tagLocation: userData.tagLocation
                }
            }

            // Calling the api
            await axios.post(apiURL, requestBody)
            resolve()
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_CONFLICT
            )
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to directly register a user */
export const directRegisterUser = async (userData) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the api
            const response = await axios.post(Constants.API_ROUTE_DIRECT_REGISTER, userData)
            resolve(response.data.user)
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_CONFLICT
            )
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to verify mobile otp */
export const verifyRegisterOTP = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.post(Constants.API_ROUTE_REGISTER_VERIFY_OTP, {
                phoneNumber: data.phoneNumber,
                otp: data.otp,
                type: data.type
            })
            resolve(response.data)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to verify mobile otp */
export const validateIdpToken = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.post(Constants.API_ROUTE_VALIDATE_ADP_TOKEN, {
                token: data.idpToken,
                type: data.type
            })
            resolve(response.data)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to send forgot password request to user/employer */
export const sendForgotPasswordRequest = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API here
            await axios.post(Constants.API_FORGOT_PASSWORD_REQUEST, data)
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_NOT_FOUND)
                reject(new NotFoundError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to very forgot password otp */
export const verifyForgotPwdOTP = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API here
            await axios.post(Constants.API_FORGOT_PWD_OTP, data)
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to change password by FP */
export const changePasswordFP = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API here
            await axios.patch(Constants.API_FORGOT_PWD_CHANGE, data)
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(new ValidationError(error.response.data.error.message))
            reject(error)
        }
    })
}

/** Function to set user profile in store */
// export const setUserDataToStore = (userObj) => {
// 	return new Promise((resolve, reject)=>{
// 		try {
// 			// Updating the user data in store
// 			store.dispatch(
// 				setUserProfile({
// 					user: userData.user,
// 				})
// 			)
// 			resolve()
// 		} catch (error) {
// 			reject(error)
// 		}
// 	})
// }

/** Function to set User profile in local storage */
export const setUserDataToLocalStorage = (userObj) => {
    return new Promise((resolve, reject) => {
        try {
            // Setting in LS
            localStorage.setItem(Constants.KEY_LS_USER, JSON.stringify(userObj.user))
            localStorage.setItem(Constants.KEY_LS_ACCESS_TOKEN, userObj.accessToken)
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to reset user data from local storage */
export const resetUserDataLocalStorage = () => {
    // Resetting the data
    localStorage.removeItem(Constants.KEY_LS_USER)
    localStorage.removeItem(Constants.KEY_LS_ACCESS_TOKEN)
    localStorage.removeItem(Constants.KEY_LS_EXPIRES_IN)
}

/** Function to check if user is logged in */
export const isAuthenticated = () =>
    localStorage.getItem(Constants.KEY_LS_ACCESS_TOKEN) != '' &&
    localStorage.getItem(Constants.KEY_LS_ACCESS_TOKEN) != null

/** Function to get user data from Local storage */
export const getUserDataLocalStorage = () => {
    return {
        accessToken: localStorage.getItem(Constants.KEY_LS_ACCESS_TOKEN),
        expiresIn: localStorage.getItem(Constants.KEY_LS_EXPIRES_IN),
        user: JSON.parse(localStorage.getItem(Constants.KEY_LS_USER))
    }
}

/** Function to get access token of user (regenerate if expired) */
export const getAccessToken = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const userData = getUserDataLocalStorage()
            const accessToken = userData.accessToken
            resolve(accessToken)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNAUTHORIZED) reject(new UnauthorizedError())
            reject(error)
        }
    })
}

/** Function to add a new panelist to employer */
export const addNewPanelist = async (userType, panelData, employerId = '') => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_EMPLOYER_ADD_PANELIST_ADMIN(employerId)
                    : Constants.API_ROUTE_EMPLOYER_ADD_PANELIST
            await axios.post(apiURL, panelData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            resolve()
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_CONFLICT
            )
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to get all panelists */
export const getPanelists = async (userType, pageNumber = 0, searchName = '') => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            const apiURL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_EMPLOYER_GET_PANELISTS_ADMIN
                    : Constants.API_ROUTE_EMPLOYER_GET_PANELISTS
            const response = await axios.get(
                `${apiURL}${pageNumber != 0 ? '?page=' + pageNumber : ''}${
                    searchName != '' ? '&name=' + searchName : ''
                }`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            resolve(response.data)
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_CONFLICT
            )
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to get job-seeker profile */
export const getJobSeekerProfile = async (userType, jobSeekerId = '') => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            const URL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_JOB_SEEKER_PROFILE_ADMIN(jobSeekerId)
                    : Constants.API_ROUTE_JOB_SEEKER_PROFILE
            const response = await axios.get(URL, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to update user profile (Job-Seeker/Employer) */
export const updateUserProfile = async (userType, updateObj, jobSeekerId = '') => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            const URL =
                userType == Constants.TYPE_USER_ADMIN
                    ? Constants.API_ROUTE_UPDATE_JOB_SEEKER_PROFILE_ADMIN(jobSeekerId)
                    : Constants.API_ROUTE_UPDATE_JOB_SEEKER_PROFILE
            await axios.post(URL, updateObj, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all the interviews for user (Job Seeker, Company, HR, Interviewer, Admin) */
export const getInterviews = async ({
    userType = Constants.TYPE_USER_JOB_SEEKER,
    page,
    positionName = '',
    companyName = '',
    jobSeekerName = '',
    isVendor = false
}) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            const response = await axios.get(
                `${Constants.API_GET_INTERVIEWS}?page=${page}&type=${userType}&positionName=${positionName}&companyName=${companyName}&candidateName=${jobSeekerName}&isVendor=${isVendor}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all the documents for job seeker */
export const getDocuments = async (jobSeekerUuId, userType = Constants.TYPE_USER_JOB_SEEKER, applicationId = 0) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            const URL =
                userType == Constants.TYPE_USER_JOB_SEEKER
                    ? Constants.API_GET_JOB_SEEKER_DOCS(jobSeekerUuId)
                    : Constants.API_GET_JOB_SEEKER_DOCS_OTHERS(applicationId, jobSeekerUuId, userType)
            const response = await axios.get(URL, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to update Employer access to user docs */
export const updateEmployerDocAccess = async (applicationId, canAccessUserDocs) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here

            await axios.patch(
                Constants.API_EMPLOYER_USER_DOC_ACCESS(applicationId),
                { canAccessUserDocs },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to update user password after login */
export const updateUserAuthPassword = async (passwordUpdateObj) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            await axios.patch(Constants.API_UPDATE_USER_AUTH_PWD, passwordUpdateObj, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            resolve()
        } catch (error) {
            if (
                error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status == Constants.STATUS_CONFLICT
            )
                reject(
                    new ValidationError(
                        error.response?.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/**
 * Function to update interview feedback by interviewer/HR
 */
export const updateInterviewFeedback = async ({ feedback, interviewId }) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            await axios.patch(
                Constants.API_PANELIST_UPDATE_FEEDBACK(interviewId),
                { feedback },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * Function to cancel an interview by employer/Panelist
 */
export const cancelInterview = async ({ interviewId, cancelReason }) => {
    return new Promise(async (resolve, reject) => {
        try {
            console.log(interviewId)
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            await axios.patch(
                Constants.API_CANCEL_INTERVIEW({ interviewId }),
                { cancelReason },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function send OTP to external users profile */
export const sendOtpToPoolUser = async ({ phoneNumber, isCampusRegistration = false }) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.post(Constants.API_OTP_SEND_POOL_USER, {
                phoneNumber: phoneNumber,
                isCampusRegistration
            })
            resolve(response.data)
        } catch (error) {
            if (error.response.status == Constants.STATUS_CONFLICT)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}
/** method to verify pool user otp **/
export const verifyPoolUserOTP = async ({ otp, phoneNumber, isCampusRegistration = false }) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.post(Constants.API_VERIFY_OTP_POOL_USER, {
                phoneNumber,
                otp,
                isCampusRegistration
            })
            resolve(response.data)
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to register a pool user (external form) */
export const registerPoolUser = async (userData) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the api
            const response = await axios.post(Constants.API_REGISTER_POOL_USER, {
                email: userData.email,
                password: userData.password,
                firstName: userData.firstName,
                lastName: userData.lastName,
                middleName: userData.middleName,
                phoneNumber: userData.phoneNumber,
                referralCode: userData.referralCode,
                majorSkills: userData.majorSkills,
                totalExperience: userData.totalExperience,
                tagLocation: userData.tagLocation,
                isCampusRegistration: userData.isCampusRegistration ?? false
            })
            resolve(response.data)
        } catch (error) {
            if (
                error.response.status === Constants.STATUS_UNPROCESSABLE_ENTITY ||
                error.response.status === Constants.STATUS_CONFLICT
            )
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}
