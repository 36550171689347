import { useQuery, useMutation } from 'react-query'
import {
    createNewAdmin,
    editAdmin,
    getAdmin,
    getAllAdmins,
    getAllSidebarData,
    getApplicantsByStatus,
    getNonVerifiedJobSeekers,
    getSidebarData,
    getSubHrApplicants,
    getSubHRs,
    mapSubHRToJs,
    UpdateJobSeekerNoticePeriod,
    updateSubHRStatus,
    updateJobSeekerIsPoolStatus,
    updateEmployerAccessToJobSeeker,
    updateEmployerStatus,
    updateJobSeekerStatus,
    getEmailLogs,
    getEmailLogById,
    getBlogList,
    getBlog,
    addBlog,
    updateBlog,
    getSitemapList,
    getSitemap,
    addSitemap,
    updateSitemap,
    getFooterList,
    getFooter,
    addFooter,
    updateFooter,
    getAllSitemaps,
    updateCompanyHighlights,
    getAdminApplicationTimeline,
    addAdminMsgToTimeline,
    getTermsConditions,
    updateTermsConditions,
    addSubscriptionStatus,
    getCommunityPostList,
    addCommunityPost,
    updateCommunityPost,
    getCommunityPost,
    getAdvertisementsList,
    addAdvertisement,
    updateAdvertisement,
    getAdvertisement,
    addMediaFile,
    getCommunityChatsList,
    getChatMessages
} from './util'

export const useApplicantsByStatus = ({ status, page }) => {
    return useQuery(['getApplicants', status, page], () => getApplicantsByStatus({ status, page }), {
        keepPreviousData: true
    })
}

export const useGetJSApplicationTimeline = ({ id }) =>
    useQuery(['getJobApplicationAdminTimeline', id], () => getAdminApplicationTimeline(id), {
        retry: false,
        refetchOnWindowFocus: false
    })

export const useAddJSMsgToTimeline = () => useMutation(addAdminMsgToTimeline)

/**
 * useQuery to return all applicants for a sub HR
 */
export const useSubHrApplicants = ({
    subHRId,
    page,
    name,
    mobileNumber,
    currentLocation,
    minCTC,
    maxCTC,
    minNoticePeriod,
    maxNoticePeriod,
    skillIsMandatory,
    skills,
    tagId,
    isPool = false
}) => {
    return useQuery(
        [
            'getSubHrApplicants',
            subHRId,
            page,
            name,
            mobileNumber,
            currentLocation,
            minCTC,
            maxCTC,
            minNoticePeriod,
            maxNoticePeriod,
            skillIsMandatory,
            skills,
            tagId,
            isPool
        ],
        () =>
            getSubHrApplicants({
                subHRId,
                page,
                name,
                mobileNumber,
                currentLocation,
                minCTC,
                maxCTC,
                minNoticePeriod,
                maxNoticePeriod,
                skillIsMandatory,
                skills,
                tagId,
                isPool
            }),
        {
            keepPreviousData: true
        }
    )
}

/**
 * useMutation to update jobseeker serve notice period toggle
 */

export const useToggleServeNoticePeriod = () => {
    return useMutation(UpdateJobSeekerNoticePeriod)
}

export const useSubHRList = ({ page, name, phNumber }) => {
    return useQuery(['getSubHrs', name, phNumber, page], () => getSubHRs({ page, name, phNumber }), {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })
}

export const useSubHRMapToJs = () => {
    return useMutation(mapSubHRToJs)
}

export const useSubHRStatusUpdate = ({ id, status }) => {
    return useMutation(['updateSubHRStatus', id, status], () => updateSubHRStatus({ id, status }))
}

export const useJobSeekerIsPoolStatus = ({ id, isPool }) => {
    return useMutation(['updateJobSeekerIsPoolStatus', id, isPool], () => updateJobSeekerIsPoolStatus({ id, isPool }))
}

export const useJobSeekerStatus = () => {
    return useMutation(updateJobSeekerStatus)
}

export const useToggleEmployerAccessToJobSeeker = () => {
    return useMutation(updateEmployerAccessToJobSeeker)
}

export const useToggleEmployerStatus = () => {
    return useMutation(updateEmployerStatus)
}

/**
 *
 * @param {*} param0
 * @returns All non-verified job seeker
 */
export const useNonVerifiedJobSeekers = ({ page }) => {
    return useQuery(['getNonVerifiedJobSeekers', page], () => getNonVerifiedJobSeekers({ page }), {
        keepPreviousData: true
    })
}

// Function to get all email logs
export const useEmailLogs = ({ page }) => {
    return useQuery(['getEmailLogs', page], () => getEmailLogs({ page }), {
        keepPreviousData: true
    })
}

// Function to get email log by id
export const useEmailLogById = ({ id }) => {
    return useQuery(['getEmailLogById', id], () => getEmailLogById({ id }), {
        keepPreviousData: true
    })
}

export const useCreateAdmin = () => {
    return useMutation(createNewAdmin)
}

export const useEditAdmin = () => {
    return useMutation(editAdmin)
}

export const useGetSidebar = ({ enabled = false }) => {
    return useQuery('getSidebarData', () => getSidebarData({ fromEdit: false }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

export const useGetSidebarFromEdit = ({ adminId, enabled }) => {
    return useQuery(['useGetSidebarFromEdit', adminId], () => getSidebarData({ adminId, fromEdit: true }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

export const useGetAllSidebars = () => {
    return useQuery('getAllSidebarData', () => getAllSidebarData(), {
        refetchOnWindowFocus: false
    })
}

export const useGetAllAdmins = ({ page }) => {
    return useQuery(['getAllAdmins', page], () => getAllAdmins({ page }), {
        refetchOnWindowFocus: false
    })
}

export const useGetAdmin = ({ adminId, enabled }) => {
    return useQuery(['getAdmin', adminId], () => getAdmin({ adminId }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

/**
 *Search blogs
 */
export const useBlogList = ({ title, page }) => {
    return useQuery(
        ['getBlogList', title, page],
        () =>
            getBlogList({
                page,
                title
            }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )
}

/**
 * Get blog by ID
 */
export const useGetBlog = ({ blogId, enabled }) => {
    return useQuery(['getBlog', blogId], () => getBlog({ blogId }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

/**
 *Function to create new blog
 */
export const useAddBlog = () => {
    return useMutation(addBlog)
}

export const useEditBlog = () => {
    return useMutation(updateBlog)
}

/**
 *Search sitemaps
 */
export const useSitemapList = ({ name, page }) => {
    return useQuery(
        ['getSitemapList', name, page],
        () =>
            getSitemapList({
                page,
                name
            }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )
}

/**
 * Get sitemap by ID
 */
export const useGetSitemap = ({ sitemapId, enabled }) => {
    return useQuery(['getSitemap', sitemapId], () => getSitemap({ sitemapId }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

/**
 *Function to create new sitemap
 */
export const useAddSitemap = () => {
    return useMutation(addSitemap)
}

//update sitemap
export const useEditSitemap = () => {
    return useMutation(updateSitemap)
}

/**method to update company highlights */
export const useUpdateCompanyHighlights = () => {
    return useMutation(updateCompanyHighlights)
}

/**method to update subscription status for staffing agency */
export const useAddSubscriptionStatus = () => {
    return useMutation(addSubscriptionStatus)
}

/**
 *Search footers
 */
export const useFooterList = ({ title, page }) => {
    return useQuery(
        ['getFooterList', title, page],
        () =>
            getFooterList({
                page,
                title
            }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )
}

/**
 * Get footer by ID
 */
export const useGetFooter = ({ footerId, enabled }) => {
    return useQuery(['getFooter', footerId], () => getFooter({ footerId }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

/**
 * Get all sitemaps to select in footer add/update
 */
export const useGetAllSitemaps = () => {
    return useQuery(['getAllSitemaps'], () => getAllSitemaps(), {
        refetchOnWindowFocus: false
    })
}

/**
 *Function to create new footer group
 */
export const useAddFooter = () => {
    return useMutation(addFooter)
}

//update Footer group
export const useEditFooter = () => {
    return useMutation(updateFooter)
}

// Function to get terms conditions
export const useGetTermsConditions = () => {
    return useQuery(['getTermsConditions'], () => getTermsConditions(), {
        keepPreviousData: true
    })
}

/**method to update terms and conditions */
export const useUpdateTermsConditions = () => {
    return useMutation(updateTermsConditions)
}

export const useCommunityPostList = ({ title, page }) => {
    return useQuery(
        ['getCommunityPostList', title, page],
        () =>
            getCommunityPostList({
                page,
                title
            }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )
}

/**
 *Function to create new community post
 */
export const useAddCommunityPost = () => {
    return useMutation(addCommunityPost)
}

/**
 *Function to create update community post
 */
export const useEditCommunityPost = () => {
    return useMutation(updateCommunityPost)
}

/**
 * Get community post by ID
 */
export const useGetCommunityPost = ({ postId, enabled }) => {
    return useQuery(['getCommunityPost', postId], () => getCommunityPost({ postId }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

export const useCommunityChatsList = ({ page }) => {
    return useQuery(
        ['getCommunityChatsList', page],
        () =>
            getCommunityChatsList({
                page
            }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )
}

/**
 *Method to return ads list based on title and pagination
 */
export const useAdvertisementsList = ({ companyName, page }) => {
    return useQuery(
        ['getAdvertisementsList', companyName, page],
        () =>
            getAdvertisementsList({
                page,
                companyName
            }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false
        }
    )
}

/**
 *Function to create new advertisement
 */
export const useAddAdvertisement = () => {
    return useMutation(addAdvertisement)
}

/**
 *Function to create update advertisement
 */
export const useEditAdvertisements = () => {
    return useMutation(updateAdvertisement)
}

//Function to add media file and return data
export const useAddMediaFile = () => {
    return useMutation(addMediaFile)
}

/**
 * Get advertisement by ID
 */
export const useGetAdvertisement = ({ adsId, enabled }) => {
    return useQuery(['getAdvertisement', adsId], () => getAdvertisement({ adsId }), {
        refetchOnWindowFocus: false,
        enabled
    })
}

/**
 * Get community chat messages by chatId
 */
export const useGetChatMessages = ({ chatId }) => {
    return useQuery(['getChatMessages', chatId], () => getChatMessages({ chatId }), {
        keepPreviousData: false,
        refetchOnWindowFocus: false
    })
}
