import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import AddBlogForm from '../../../components/forms/blog/Form'

function AddBlog() {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Add New Blog</Heading>
            <AddBlogForm />
        </Box>
    )
}
export default AddBlog
