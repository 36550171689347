export default function useFormFields({
    form: {
        control,
        formState: { errors }
    }
}) {
    return {
        message: {
            name: 'message',
            id: 'message',
            label: '',
            placeholder: 'Type a message here',
            type: 'textarea',
            rules: {
                required: 'Please enter your message'
            },
            isRequired: true,
            error: errors.message,
            control
        }
    }
}
