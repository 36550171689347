import React from 'react'
import Constants from '../../../helpers/Constants'
import { useGetBlog } from '../../../hooks/useAdmin'
import { Box, Column, Input, Row } from '../../vanilla'
import useFormFields from './useFormFields'

function FormFields({ form, blogId, formType = Constants.TYPE_ADD }) {
    const { data: blogData } = useGetBlog({ blogId, enabled: blogId !== undefined })
    const { title, body, thumbnailURL, isActive, isFeatured } = useFormFields({
        form,
        blog: blogData?.data,
        formType
    })

    return (
        <Box className="mb-3">
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...title} />
                </Column>
                <Column className="col-md-6">
                    <Input {...thumbnailURL} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...isActive} />
                </Column>
                <Column className="col-md-6">
                    <Input {...isFeatured} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-12">
                    <Input {...body} defaultValue={blogData?.data?.body} />
                </Column>
            </Row>
        </Box>
    )
}

export default FormFields
