import React from 'react'
import PropTypes from 'prop-types'

const Box = ({ Element, children, className, style, ...props }) => {
    return (
        <Element {...props} className={className} style={style}>
            {children}
        </Element>
    )
}

/**
 * Prop types
 */
Box.propTypes = {
    Element: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any
}

Box.defaultProps = {
    Element: 'div',
    className: '',
    style: {}
}

export default Box
