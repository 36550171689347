import React from 'react'
import { Box, Column, Input, Row, Flex } from '../../vanilla'
import useFormFields from './useFormFields'
import { useGetTermsConditions } from '../../../hooks/useAdmin'
import SimpleLoader from '../../vanilla/loaders/SimpleLoader/SImpleLoader'

function FormFields({ form }) {
    const { data, isLoading } = useGetTermsConditions()

    const { body } = useFormFields({ form, termsConditions: data?.data })

    if (isLoading) {
        return (
            <Flex className="align-items-center justify-content-center flex-column">
                <SimpleLoader size="50px" />
                <Box className="mt-3">Please wait, we are processing your data. This might take a few moments!</Box>
            </Flex>
        )
    }
    return (
        <Box className="mb-3">
            <Row className="mt-4">
                <Column className="col-md-12">
                    <Input {...body} defaultValue={data?.data?.body} />
                </Column>
            </Row>
        </Box>
    )
}

export default FormFields
