export default function useCompanyHighlightFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    selectedEmployer
}) {
    const fields = {
        workLifeBalance: {
            name: 'workLifeBalance',
            id: 'workLifeBalance',
            label: 'Work life balance',
            placeholder: 'Enter work life balance value',
            type: 'number',
            defaultValue: '0',
            rules: {
                required: 'Please enter work life balance'
            },
            error: errors['workLifeBalance'],
            control
        },
        salaryBenefits: {
            name: 'salaryBenefits',
            id: 'salaryBenefits',
            label: 'Salary Benefits',
            placeholder: 'Enter Salary Benefits value',
            type: 'number',
            defaultValue: '0',
            rules: {
                required: 'Please enter Salary Benefits'
            },
            error: errors['salaryBenefits'],
            control
        },
        companyCulture: {
            name: 'companyCulture',
            id: 'companyCulture',
            label: 'Company Culture',
            placeholder: 'Enter Company Cultures value',
            type: 'number',
            defaultValue: '0',
            rules: {
                required: 'Please enter Company Culture'
            },
            error: errors['companyCulture'],
            control
        }
    }

    // In case of selected employer not empty
    if (selectedEmployer) {
        setValue('workLifeBalance', selectedEmployer.workLifeBalance)
        setValue('salaryBenefits', selectedEmployer.salaryBenefits)
        setValue('companyCulture', selectedEmployer.companyCulture)
    }
    return fields
}
