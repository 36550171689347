/**
 * All the Misc api calls will be written here
 */
import axios from 'axios'
import Constants from '../helpers/Constants'
import ValidationError from '../errors/ValidationError'
import { getAccessToken } from './Auth'

/** Function to get the list of Cities */
export const getCities = async (id, nameString) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            const response = await axios.get(Constants.API_ROUTE_GET_CITIES + '?id=' + id + '&name=' + nameString)
            resolve(response.data.cities)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to send offer response */
export const sendOfferResponse = async (applicationId, response) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            await axios.patch(Constants.API_RESPOND_OFFER(applicationId, response))
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to send interview response */
export const sendInterviewResponse = async (applicationId, response, interviewType, interviewId) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            await axios.patch(Constants.API_RESPOND_INTERVIEW(applicationId, response, interviewType, interviewId))
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/** Function to verify user email */
export const verifyUserEmail = async (token, userType) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Calling the API
            await axios.post(Constants.API_VERIFY_USER_EMAIL, {
                emailToken: token,
                type: userType
            })
            resolve()
        } catch (error) {
            if (error.response.status == Constants.STATUS_UNPROCESSABLE_ENTITY)
                reject(
                    new ValidationError(
                        error.response.data ? error.response.data.error.message : Constants.MSG_UNEXPECTED_ERROR
                    )
                )
            reject(error)
        }
    })
}

/**
 * Function to change access of panelist for accept/reject candidate
 */
export const changePanelistResultAccess = async (panelId, accessType) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()

            // Calling the api here
            await axios.patch(
                `${Constants.API_MODIFY_PANELIST_RESULT_ACCESS(panelId, accessType)}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )

            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to update (accept/reject) post status */
export const approveRejectCommunityPost = async (postId, response) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Getting access token
            const accessToken = await getAccessToken()
            await axios.patch(
                Constants.API_ADMIN_CHANGE_COMMUNITY_POST_STATUS(postId),
                {
                    type: response
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` }
                }
            )
            resolve()
        } catch (error) {
            console.log(error.response)
            reject(error)
        }
    })
}
