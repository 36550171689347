export default function useSearchFields({ form: { control } }) {
    return {
        name: {
            name: 'name',
            id: 'name',
            placeholder: 'Search sitemap name',
            defaultValue: '',
            type: 'text',
            control
        }
    }
}
