import React from 'react'
import { Column, Input } from '../../../../../components/vanilla'
import useCRMMailFields from './useCRMMailFields'
const CRMMailFields = ({ form }) => {
    const { subject, message } = useCRMMailFields({ form })
    return (
        <>
            <Column className="col-sm-12 mb-4">
                <Input {...subject} />
            </Column>
            <Column className="col-sm-12 mb-4">
                <Input {...message} />
            </Column>
        </>
    )
}

export default CRMMailFields
