import React from 'react'
import { useSelector } from 'react-redux'
import ChatIndex from '../../../../components/chat/Index'
import { getEmployerType, getUserType, getIsVendor } from '../../../../features/user/userSlice'

function ListInterviewsEmployer() {
    // Getting the user type & employer type
    const userType = useSelector(getUserType)
    const employerType = useSelector(getEmployerType)
    const isVendor = useSelector(getIsVendor)

    return (
        <div>
            <section className="py-5 text-center hero-inner">
                <div className="row mrl-0 py-lg-5 pt-0">
                    <div className="col-lg-12 col-md-12 mx-auto">
                        <h1> Interviews </h1>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="employer-job-list-content">
                                <div className="text-dark">
                                    <h2 className="pb-0">All Scheduled Interviews</h2>
                                </div>

                                <ChatIndex userType={userType} employerType={employerType} isVendor={isVendor} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ListInterviewsEmployer
