import React from 'react'
import { Flex, Box, Avatar } from '../../../components/vanilla'
import moment from 'moment'

const SingleMessage = ({ firstUser, chat }) => {
    return (
        <Flex className={`mb-10 justify-content-${firstUser !== chat.user.id ? 'end' : 'start'}`}>
            <Flex className={`flex-column align-items-${firstUser !== chat.user.id ? 'end' : 'start'}`}>
                <Flex className="mb-2 align-items-center">
                    <Avatar
                        type={chat.user.avatarPath ? 'image' : 'text'}
                        size="lg"
                        imagePath={chat.user.avatarPath}
                        text={chat.user.firstName}
                    />
                    <Box className="ms-3">
                        <span className="fs-5 fw-bold text-gray-900 me-2">
                            {`${chat.user.firstName} ${chat.user.middleName} ${chat.user.lastName}`}
                        </span>
                        <span className="text-muted mb-1">
                            {moment(chat.createdAt).format('ddd MMM Do YYYY, h:mm:ss a')}
                        </span>
                    </Box>
                </Flex>
                <Box
                    className={[
                        'p-3 my-3 rounded text-dark fw-semibold mw-lg-400px',
                        firstUser !== chat.user.id ? 'text-end bg-light-chat-primary' : 'text-start bg-light-chat-info'
                    ].join(' ')}
                    dangerouslySetInnerHTML={{ __html: chat.data.message }}
                />
            </Flex>
        </Flex>
    )
}

export default SingleMessage
