export default function useCRMMailFields({
    form: {
        control,
        formState: { errors }
    }
}) {
    return {
        subject: {
            name: 'subject',
            id: 'subject',
            label: 'Email Subject',
            placeholder: 'Provide Email Subject.',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter email subject'
            },
            error: errors['subject'],
            control
        },
        message: {
            name: 'message',
            id: 'message',
            label: 'Email Body',
            placeholder: 'Provide email message',
            defaultValue: '',
            type: 'textarea',
            rules: {
                required: 'Please enter email message '
            },
            error: errors['message'],
            control
        }
    }
}
