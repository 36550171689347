import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import { useCreateAdmin, useEditAdmin } from '../../../hooks/useAdmin'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'

function AddAdminForm({ formType = Constants.TYPE_ANON_ADD, adminId }) {
    const form = useForm()
    const { mutateAsync: addAdmin, isLoading, isError, error } = useCreateAdmin()
    const { mutateAsync: editAdmin, isError: isEditError, error: editError } = useEditAdmin()
    // Init Navigate here
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        // Creating the access data
        let accesses = []
        // eslint-disable-next-line array-callback-return
        Object.keys(data).map((key) => {
            if (key.includes(Constants.MISC_ADMIN_ACCESS_SEPARATOR)) {
                // Pushing if the item is checked
                if (data[key]) accesses.push(parseInt(key.split(Constants.MISC_ADMIN_ACCESS_SEPARATOR)[1]))
                // Deleting the key from data as
                // its not required in final value
                delete data[key]
            }
        })
        // Checking that the admin have at-least 1 access
        // if the admin is not super admin
        if (accesses.length === 0 && !data.isSuperAdmin) {
            Utils.showToast('Please give at-least 1 access to the admin!', Constants.TYPE_TOAST_DANGER)
            return
        }
        // Appending the access to main object
        data.accesses = accesses
        // Creating/Editing the new admin/superAdmin user
        if (formType === Constants.TYPE_ANON_ADD) await addAdmin(data)
        else await editAdmin({ adminId, data })

        // Navigating to the Admins list
        navigate(Constants.ROUTE_ADMIN_LIST_ADMIN)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            {isEditError && (
                <Alert color="danger">
                    {editError?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}
                </Alert>
            )}
            <FormFields form={form} adminId={adminId} formType={formType} />

            <Button
                className="mb-0"
                color="primary"
                label={formType === Constants.TYPE_ANON_ADD ? 'Create Admin' : 'Update Admin'}
                btnType="submit"
                isLoading={isLoading}
            />
        </Form>
    )
}

export default AddAdminForm
