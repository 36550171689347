import React from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Column, Row } from '../../../../../../components/vanilla'
import Constants from '../../../../../../helpers/Constants'
import Utils from '../../../../../../helpers/Utils'
import { useSubHrContactForm } from '../../../../../../hooks/useEmail'
import SubHrContractFields from '../partials/SubHrContractFields'

const SubHRContractModal = ({ selectedSubHr }) => {
    const form = useForm()
    const btnStyle = { fontSize: '15px' }

    /** Function to close and reset modal and data */
    const closeAndReset = async () => {
        form.reset({})
        Utils.closeItem(`btn-close-subhr-contract`)
    }

    const { mutateAsync, isLoading } = useSubHrContactForm()
    const onSubmit = async (data) => {
        try {
            const res = await mutateAsync({
                ...data,
                email: selectedSubHr.email,
                name: `${selectedSubHr.firstName} ${selectedSubHr.lastName}`
            })
            res.init = true
            if (res.status === Constants.STATUS_SUCCESS) {
                //if status is success then reset form data, close modal and then show tooltip with success message
                closeAndReset()
                // Showing success toast
                Utils.showToast(Constants.MSG_SEND_SUB_HR_CONTRACT_EMAIL, Constants.TYPE_TOAST_SUCCESS)
            }
        } catch (e) {
            Utils.showToast(Constants.MSG_NOT_SEND_SUB_HR_CONTRACT_EMAIL, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <Box className="back-modal" id="edit-modals">
            {/* Edit Profile Details Modal */}
            <Box
                className="modal fade"
                id="subHrModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <Box className="modal-dialog modal-dialog-centered">
                    <Box className="modal-content">
                        <Box Element="form" onSubmit={form.handleSubmit(onSubmit)}>
                            <Box className="modal-header">
                                <h5 className="modal-title">
                                    Send Email to <b> {selectedSubHr.email}</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </Box>
                            <Box className="modal-body">
                                <Row>
                                    <SubHrContractFields form={form} subHr={selectedSubHr} />
                                </Row>
                            </Box>
                            <Box className="modal-footer">
                                <Button
                                    id="btn-close-subhr-contract"
                                    color="warning"
                                    className="px-5"
                                    style={btnStyle}
                                    onClick={(e) => closeAndReset()}
                                    btnType="button"
                                    data-bs-dismiss="modal"
                                    label="Close"
                                />
                                <Row>
                                    <Column className="col-md-12">
                                        <Button
                                            btnType="submit"
                                            color="primary"
                                            className="px-5"
                                            style={{ fontSize: '14px' }}
                                            label={isLoading ? 'Sending...' : 'Send'}
                                            disabled={isLoading}
                                        />
                                    </Column>
                                </Row>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SubHRContractModal
