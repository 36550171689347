import React from 'react'
import Constants from '../../helpers/Constants'
import { Box } from '../vanilla'

function LeftBar({ pageType = Constants.TYPE_USER_JOB_SEEKER }) {
    return (
        <div className="regis-text">
            <h2 className="yellow-text text-center">Why Us?</h2>

            {pageType === Constants.TYPE_USER_EMPLOYER ? (
                <Box style={{ fontSize: '1.4rem' }}>
                    <Box>
                        We have our own Job Portal IME JOBS and we have a dedicated team who looks into the sourcing of
                        candidates
                    </Box>
                    <ul>
                        <li>IMEJOB helps in sourcing right talent for the Employer who can join immediately.</li>
                        <li>
                            We have a pool of recruiters who works dedicatedly to find the candidates who is suitable
                            and can join immediately.
                        </li>
                        <li>Post unlimited Job for free and we will source candidate for your business at low cost.</li>
                        <li>Our dedicated recruiters will only source candidates based on the JD.</li>
                        <li>
                            Will provide resumes after the candidates qualify Pre Test or Screening round from our end.
                        </li>
                        <li>Unlimited Resume Browsing for free.</li>
                        <li>No Cost for Downloading Resumes with candidates contact details.</li>
                        <li>Payment only after conversion and once the candidate has joined.</li>
                        <li>
                            Shortlist, Schedule Interview Hire or Reject Candidates easily through our all-in-one ATS
                            for free.
                        </li>
                        <li>
                            Direct Option to chat with the candidates with interview panel once interview is scheduled.
                        </li>
                    </ul>
                    <Box>You get Pre-Screened Qualified candidates from our Screening Methods which include</Box>
                    <ul>
                        <li>HR Generic Round and</li>
                        <li>Basic Tech/Ops Round</li>
                    </ul>
                </Box>
            ) : (
                <p>
                    To improve your chances when you’re looking for a new job, make sure your resume is polished and
                    professional. Your resume profile should contain skillset keywords and project details. While
                    applying for a job please take the application filter question correctly. Based on your answers our
                    AI engine filters the right talent for the employer. The Job seeker and Employer can use our enabled
                    interview chat option to chat with your panel or candidate or IME admin in case of any issues or can
                    use the chat for interview purposes. The employer can use our ATS system from Shortlisting to
                    Joining Job seeker and all the notifications will be sent via Mail or SMS so that everyone is well
                    informed of all the steps. IMEjob team is always available on your service 24/7 and we are working
                    continuously to provide a seamless user experience.
                </p>
            )}
        </div>
    )
}

export default LeftBar
