import React from 'react'
import { Box, Flex, Heading, Image } from '../../../../components/vanilla'
import Constants from '../../../../helpers/Constants'
import moment from 'moment'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import Utils from '../../../../helpers/Utils'
import { useDispatch } from 'react-redux'
import ValidationError from '../../../../errors/ValidationError'
import { deletePostCommentById } from '../../../../api/Admin'
import { confirmAlert } from 'react-confirm-alert'

function PostComments({ comment, comments, index, onDelete }) {
    const dispatch = useDispatch()

    const deleteComment = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the API to delete community post comment
            await deletePostCommentById({ id: comment.id })

            // Deleting the comment from comment arr
            let values = [...comments]
            values.splice(index, 1)
            onDelete(values)
            Utils.showToast(Constants.MSG_COMMENT_DELETE_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }
    return (
        <div className="card mb-4 shadow-sm" style={{ cursor: 'pointer' }}>
            <div className="card-header">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault()
                        confirmAlert({
                            title: Constants.MSG_GEN_ALERT_UNASSIGN,
                            message: Constants.MSG_DELETE_COMMENT_APPROVE_CONFIRM,
                            buttons: [
                                {
                                    label: 'Confirm',
                                    onClick: () => deleteComment()
                                },
                                {
                                    label: 'Go Back'
                                }
                            ]
                        })
                    }}
                    className="btn btn-outline-danger rounded-circle float-end p-1"
                    style={{ minWidth: '30px', height: '30px' }}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
            <Flex className="m-2 align-items-center">
                <Box className="avatar avatar-xl">
                    <Image
                        src={`${
                            comment.user.avatarPath != null ? comment.user.avatarPath : Constants.DEFAULT_PATH_NO_IMAGE
                        }`}
                        className="avatar-img rounded-circle"
                        alt="Avatar Image"
                    />
                </Box>
                <Box className="ms-3">
                    <span className="fs-5 fw-bold text-gray-900 me-2">{`${comment.user.firstName} ${comment.user.lastName}`}</span>
                    <span className="text-muted mb-1">
                        {moment(comment.createdAt).format('ddd MMM Do YYYY, h:mm:ss a')}
                    </span>
                </Box>
            </Flex>
            <Heading className="ms-2 align-items-center">Comment:</Heading>
            <Box
                className={'m-2 rounded text-dark fw-semibold'}
                dangerouslySetInnerHTML={{ __html: comment.comment }}
            />
        </div>
    )
}

export default PostComments
