import React, { useState, useEffect } from 'react'
import { Box, Flex, Row, Heading } from '../../../components/vanilla'
import { useCommunityPostList } from '../../../hooks/useAdmin'
import Pagination from '../../../components/Pagination'
import { useNavigate, Link } from 'react-router-dom'
import UnAuthError from '../../../errors/UnAuthError'
import SearchForm from '../../../components/forms/communityPost/SearchForm'
import SimpleLoader from '../../../components/vanilla/loaders/SimpleLoader/SImpleLoader'
import Constants from '../../../helpers/Constants'
import SinglePost from '../../../components/forms/communityPost/SinglePost'

function CommunityPostList() {
    const [currentPage, setCurrentPage] = useState(1)
    const [searchData, setSearchData] = useState({
        title: ''
    })
    const navigate = useNavigate()
    let search = window.location.search
    let params = new URLSearchParams(search)

    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    useEffect(() => {
        setCurrentPage(pageNo > 0 ? pageNo : 1)
    }, [pageNo])

    const {
        data: postsData,
        isLoading: isInitLoading,
        isError: isInitError
    } = useCommunityPostList({ ...searchData, page: currentPage })

    const navigateUrl = (pageNumber) => {
        navigate({
            pathname: '/admin/community-post/list',
            search: '?page=' + pageNumber
        })
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigateUrl(pageNumber)
        } catch (error) {
            throw error
        }
    }

    /**
     * Function to set search form data
     */
    const setSearchFormData = (modifiedSearchData) => {
        setSearchData(modifiedSearchData)
        navigateUrl(1)
    }

    if (isInitError) {
        return <UnAuthError />
    }

    return (
        <Box className="admin-container mb-4">
            <Row className="p-4 mb-4">
                <Box className="mb-4 d-flex align-items-center justify-content-between">
                    <Heading size="lg">Manage Community Posts</Heading>
                    <Link to={Constants.ROUTE_ADMIN_ADD_COMMUNITY_POSTS} className="btn-job btn-primary">
                        <i className="fas fa-user-plus"></i> Add New
                    </Link>
                </Box>
                <SearchForm
                    isInitLoading={isInitLoading}
                    oldSearchData={searchData}
                    setSearchData={setSearchFormData}
                />
                {!isInitLoading ? (
                    <>
                        {postsData.data.length > 0 ? (
                            <>
                                {postsData.data.map((post, index) => (
                                    <Box className="card mb-4" key={post.id}>
                                        <SinglePost post={post} />
                                    </Box>
                                ))}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={postsData.meta.pagination.pageCount}
                                    changePage={(page) => changePage(page)}
                                    itemCount={postsData.meta.pagination.total}
                                    pageSize={postsData.meta.pagination.pageSize}
                                />
                            </>
                        ) : (
                            <>No Posts Found</>
                        )}
                    </>
                ) : (
                    <Flex className="align-items-center justify-content-center flex-column">
                        <SimpleLoader size="50px" />
                        <Box className="mt-3">
                            Please wait, we are processing your data. This might take a few moments!
                        </Box>
                    </Flex>
                )}
            </Row>
        </Box>
    )
}
export default CommunityPostList
