import React from 'react'
import { useParams } from 'react-router-dom'
import { Box, Heading } from '../../../components/vanilla'
import { useEmailLogById } from '../../../hooks/useAdmin'

const EmailLog = () => {
    const params = useParams()
    const { data: emailLogResponse } = useEmailLogById({ id: params.logId })

    return (
        <Box className="p-4 mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">Email Body</Heading>
            </Box>

            {emailLogResponse && (
                <>
                    <iframe
                        style={{ width: '50%', height: '45rem' }}
                        title="Email Log"
                        srcDoc={emailLogResponse.data.body}></iframe>
                </>
            )}
        </Box>
    )
}

export default EmailLog
