/* eslint-disable no-unused-vars */
import axios from 'axios'
import Constants from './Constants'
import { getAccessToken } from '../api/Auth'
import Utils from './Utils'

// create client to call axios request
const client = axios.create({ baseURL: Constants.API_URL + '/api/v' + Constants.API_VERSION })

// redirect URL to SSO login
const redirectSsoLogin = ({ resolvedUrl }) => {
    window.location.href = `${Constants.SSO_URL}?callback=${encodeURIComponent(
        `${Constants.APP_URL}${Constants.ROUTE_CLIENT_AUTH_CALLBACK}?continueUri=${resolvedUrl}`
    )}&requestType=admin`
}

/**Common axios method to call the API */
export const request = async ({ ...options }) => {
    if (options.requiresAuth) {
        // Getting access token
        const accessToken = await getAccessToken()
        client.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        client.defaults.headers.common['Content-Type'] = 'application/json'
    }
    delete options.requiresAuth

    const onSuccess = (response) => response
    const onError = (error) => {
        switch (error.response.status) {
            case Constants.STATUS_UNAUTHORIZED:
                redirectSsoLogin({ resolvedUrl: Constants.ROUTE_ADMIN_HOME })
                break
            default:
                return error
        }
        throw error
    }

    return client(options).then(onSuccess).catch(onError)
}
