export default function useFormFields({
    form: {
        control,
        formState: { errors }
    },
    options
}) {
    return {
        hrList: {
            name: 'subHR',
            id: 'subHR',
            label: 'Select HR',
            placeholder: 'Select an HR',
            defaultValue: '',
            type: 'selectable',
            options,
            rules: {
                required: 'Please Select an HR'
            },
            error: errors.subHR,
            control
        }
    }
}
