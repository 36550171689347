import React from 'react'
import { useParams } from 'react-router-dom'
import AddPanelistForm from '../../../../../components/elements/panelist/Add'

function AddEmployerMember() {
    const { employerId } = useParams()
    return (
        <div className="mb-4">
            <h3 className="p-4">Add New Panelist</h3>
            <AddPanelistForm employerId={employerId} />
        </div>
    )
}

export default AddEmployerMember
