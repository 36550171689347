export default function useSearchFields({ form: { control } }) {
    return {
        title: {
            name: 'title',
            id: 'title',
            placeholder: 'Search Footer Title',
            defaultValue: '',
            type: 'text',
            control
        }
    }
}
