import React from 'react'
import CardBasic from '../../Cards/CardBasic'
import { Box, Column, Row } from '../../vanilla'
import Actions from './partials/Actions'
import CandidateDetails from './partials/CandidateDetails'
import Details from './partials/Details'

const Applicant = ({
    applicant,
    index,
    onToggleBestFit,
    onAssign,
    onUnassign,
    onShortlist,
    onInterview,
    onHR,
    onJoined,
    onApplication,
    onDocs,
    onToggleDocAccess,
    onHold,
    onToggleServiceNoticePeriodStatus
}) => {
    const textStyle = {
        fontSize: '13px'
    }

    return (
        <Box className="col-md-12 my-4 p-0">
            <CardBasic>
                <Row className="align-items-center">
                    <Column className="col-md-3">
                        <CandidateDetails
                            jobSeeker={applicant.jobSeeker}
                            textStyle={textStyle}
                            isBestFit={applicant.isBestFit}
                            servingNoticePeriod={applicant.servingNoticePeriod}
                        />
                    </Column>
                    <Column className="col-md-5 border-start border-end">
                        <Actions
                            applicant={applicant}
                            index={index}
                            textStyle={textStyle}
                            onToggleBestFit={onToggleBestFit}
                            onAssign={onAssign}
                            onUnassign={onUnassign}
                            onShortlist={onShortlist}
                            onInterview={onInterview}
                            onHR={onHR}
                            onJoined={onJoined}
                            onApplication={onApplication}
                            onDocs={onDocs}
                            onToggleDocAccess={onToggleDocAccess}
                            onToggleServiceNoticePeriodStatus={onToggleServiceNoticePeriodStatus}
                        />
                    </Column>
                    <Column className="col-md-4">
                        <Details applicant={applicant} index={index} textStyle={textStyle} onHold={onHold} />
                    </Column>
                </Row>
            </CardBasic>
        </Box>
    )
}

export default Applicant
