import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import Constants from '../../../helpers/Constants'
import { useParams } from 'react-router-dom'
import AddBlogForm from '../../../components/forms/blog/Form'

function EditBlog() {
    const blogId = useParams().blogId
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Edit Blog</Heading>
            <AddBlogForm formType={Constants.TYPE_EDIT} blogId={blogId} />
        </Box>
    )
}
export default EditBlog
