import React, { useState } from 'react'
import { Box, Column, Row } from './vanilla'
import Constants from '../helpers/Constants'
import { useLocation, Link } from 'react-router-dom'
import { useSiteMap } from '../hooks/useCms'

function Footer() {
    const [footerSlug, setFooterSlug] = useState(Constants.PAGES_DISPLAY_SITEMAP_FOOTER)
    const location = useLocation()
    let { isLoading, data, isError } = useSiteMap({ isFooterURL: true })
    if (!footerSlug.includes(location.pathname) || isLoading || isError || !Object.values(data.data)) return null

    if (!isLoading && Object.values(data.data)) {
        return (
            <Box Element="footer">
                <Box className="slug_box">
                    <Row>
                        <Column className="col-md-4 slug_column">
                            {' '}
                            <Link className="ar-link" to={'sitemap/'}>
                                Sitemap
                            </Link>
                        </Column>
                        {Object.values(data.data).map(
                            ({ attributes: { name, slug, isExternal, isUrl, url, description }, id }) => (
                                <>
                                    <Column className="col-md-4 slug_column" key={id + url}>
                                        {isExternal && <a href={url}>{name}</a>}
                                        {!isExternal && isUrl && <Link to={'/' + url}>{name}</Link>}
                                        {!isExternal && !isUrl && <Link to={'sitemap/' + slug}>{name}</Link>}
                                    </Column>{' '}
                                </>
                            )
                        )}
                    </Row>
                </Box>
                <Box className="copyright">
                    <p>Copyright ©2022 IMEJOB | All Rights Reserved</p>
                </Box>
            </Box>
        )
    }
}
export default Footer
