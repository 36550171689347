import React from 'react'
import AddAdminForm from '../../../../components/forms/admin/Form'
import { Box, Heading } from '../../../../components/vanilla'

function AddAdmin() {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Add Admin User</Heading>
            <AddAdminForm />
        </Box>
    )
}

export default AddAdmin
