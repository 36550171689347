import React from 'react'
import { BasicMessage, GeneralMessage } from './components'

const Message = ({ type, data, user, createdAt, own, admin }) => {
    if (type === 'init') return <BasicMessage message="Job Seeker Applied to the job" />
    if (type === 'status') return <BasicMessage message={`Application status updated to ${data.message}`} />
    if (type === 'comment') {
        return <GeneralMessage message={data.message} own={own} user={user} createdAt={createdAt} admin={admin} />
    }
    if (type === 'interview') {
        let message = data.type === 'HR' ? `An ${data.type} ` : `A ${data.type} `
        message += 'interview has been scheduled! Details mentioned below: <br />'
        message += `<b>Date:</b> ${data.date} <br />`
        message += `<b>Time:</b> ${data.time} <br />`
        message += `<b>Meeting Link:</b> <a href="${data.meetingLink}" target="_blank">${data.meetingLink}</a>`
        return <GeneralMessage message={message} own={own} user={user} createdAt={createdAt} admin={admin} />
    }
    if (type === 'offer') {
        let message = `An offer has been made! Details mentioned below: <br />`
        message += `<b>CTC: </b>${data.ctc}<br />`
        message += `<b>Joining Date: </b>${data.joiningDate}<br />`
        message += `<b>Joining Location: </b>${data.joiningLocation}<br />`
        message += `<b>Offer Letter: </b><a href="${data.filePath}" target="_blank">Download</a>`
        return <GeneralMessage message={message} own={own} user={user} createdAt={createdAt} admin={admin} />
    }
    if (type === 'offerStatus') {
        let message = 'Job Seeker '
        message += data.type === 'accept' ? 'accepted ' : 'rejected '
        message += `the offer!`
        return <BasicMessage message={message} />
    }
    if (type === 'cancelInterview') {
        return <BasicMessage message="The previous interview has been cancelled!" />
    }

    return <div>Message</div>
}

export default Message
