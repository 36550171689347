import React from 'react'
import { Column, Input } from '../../../../../../components/vanilla'
import useSubHrContractFields from './useSubHrContractFields'
const SubHrContractFields = ({ form, subHr }) => {
    const { duration, doj, renumeration, commission } = useSubHrContractFields({ form })
    return (
        <>
            <Column className="col-sm-6 mb-4">
                <Input {...duration} />
            </Column>
            <Column className="col-sm-6 mb-4">
                <Input {...doj} />
            </Column>
            <Column className="col-sm-6 mb-4">
                <Input {...renumeration} />
            </Column>
            <Column className="col-sm-6 mb-4">
                <Input {...commission} />
            </Column>
        </>
    )
}

export default SubHrContractFields
