import React from 'react'
import { Column, Input } from '../../vanilla'
import useCompanyHighlightFields from './useCompanyHighlightFields'
const CompanyHighlightFields = ({ form, selectedEmployer }) => {
    const { workLifeBalance, salaryBenefits, companyCulture } = useCompanyHighlightFields({ form, selectedEmployer })
    return (
        <>
            <Column className="col-sm-12 mb-6">
                <Input {...workLifeBalance} />
            </Column>
            <Column className="col-sm-12 mb-6">
                <Input {...salaryBenefits} />
            </Column>
            <Column className="col-sm-12 mb-6">
                <Input {...companyCulture} />
            </Column>
        </>
    )
}

export default CompanyHighlightFields
