import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import TermsConditionsForm from '../../../components/forms/terms-conditions/Form'

const TermsConditions = () => {
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Update Terms & Conditions</Heading>
            <TermsConditionsForm />
        </Box>
    )
}

export default TermsConditions
