import React from 'react'
import Constants from '../../../helpers/Constants'
import { useGetSitemap, useFooterList } from '../../../hooks/useAdmin'
import { Box, Column, Input, Row, Flex } from '../../vanilla'
import useFormFields from './useFormFields'
import SimpleLoader from '../../vanilla/loaders/SimpleLoader/SImpleLoader'

function FormFields({ form, sitemapId, formType = Constants.TYPE_ADD }) {
    const optionsFooterList = [{ label: 'None', value: '0' }]

    const { data: sitemapData, isLoading: isSiteMapLoading } = useGetSitemap({
        sitemapId,
        enabled: sitemapId !== undefined
    })
    const { data: footerList, isLoading: isFooterListLoading } = useFooterList({ title: '' })

    if (footerList?.data && footerList?.data?.length > 0)
        footerList?.data?.map((footer) =>
            optionsFooterList.push({
                label: `${footer.title}`,
                value: `${footer.id}`
            })
        )

    // show loader until sitemapData or footer list hooks return data

    const { name, isExternal, isUrl, description, url, footerGroupId } = useFormFields({
        form,
        sitemap: sitemapData?.data,
        formType,
        options: optionsFooterList
    })

    // Styles for react-select
    const selectStyles = {
        option: (styles) => ({ ...styles, fontSize: '1.6rem' })
    }

    if (isSiteMapLoading || isFooterListLoading) {
        return (
            <Flex className="align-items-center justify-content-center flex-column">
                <SimpleLoader size="50px" />
                <Box className="mt-3">Please wait, we are processing your data. This might take a few moments!</Box>
            </Flex>
        )
    }

    return (
        <Box className="mb-3">
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...name} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...isExternal} />
                </Column>
                <Column className="col-md-6">
                    <Input {...isUrl} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...url} />
                </Column>
                <Column className="col-md-6">
                    <Input {...footerGroupId} styles={selectStyles} />
                </Column>
            </Row>
            <Row className="mt-4">
                <Column className="col-md-12">
                    <Input {...description} defaultValue={sitemapData?.data?.description} />
                </Column>
            </Row>
        </Box>
    )
}

export default FormFields
