import React from 'react'
import Constants from '../../../helpers/Constants'
import { Box, Button, Flex, Heading, Image, Row } from '../../../components/vanilla'
import { Link } from 'react-router-dom'

function AdminLogin() {
    const handleSSOLogin = async () => {
        window.location.href = `${Constants.SSO_URL}?callback=${encodeURIComponent(
            `${Constants.APP_URL}${Constants.ROUTE_CLIENT_AUTH_CALLBACK}?continueUri=${Constants.ROUTE_ADMIN_HOME}`
        )}&requestType=admin`
    }

    return (
        <Row className="align-items-center vh-100">
            <Flex
                className="col-md-6 h-100 ime-bg-light align-items-center justify-content-center flex-column"
                id="admin-login">
                <Image src="/assets/img/immediatejoblogo.png" width={200} />

                <Heading className="mt-3 display-6 fw-bold" size="xl">
                    IMEJob – We make the best fit available immediately.
                </Heading>

                <Box Element="p" className="text-center" style={{ padding: '10px 80px' }}>
                    To improve your chances when you’re looking for a new job, make sure your resume is polished and
                    professional. Your resume profile should contain skillset keywords and project details. While
                    applying for a job please take the application filter question correctly. Based on your answers our
                    AI engine filters the right talent for the employer. The Job seeker and Employer can use our enabled
                    interview chat option to chat with your panel or candidate or IME admin in case of any issues or can
                    use the chat for interview purposes. The employer can use our ATS system from Shortlisting to
                    Joining Job seeker and all the notifications will be sent via Mail or SMS so that everyone is well
                    informed of all the steps. IMEjob team is always available on your service 24/7 and we are working
                    continuously to provide a seamless user experience.
                </Box>
            </Flex>
            <Flex className="col-md-6 h-100 align-items-center justify-content-center flex-column">
                <Box>
                    <Heading className="display-6 fw-bold text-muted text-center mb-5" size="xl">
                        Sign In
                    </Heading>

                    <Button
                        onClick={(e) => handleSSOLogin()}
                        className="w-100 btn-lg"
                        label="Login"
                        color="primary"
                        soft
                    />
                </Box>
            </Flex>
        </Row>
    )
}

export default AdminLogin
