import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'

export default function useFormFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    accessList = [],
    accesses = [],
    admin,
    formType = Constants.TYPE_ANON_ADD
}) {
    const fields = {
        firstName: {
            name: 'firstName',
            id: 'firstName',
            label: 'First Name',
            placeholder: 'John',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter first name'
            },
            error: errors.firstName,
            control
        },
        middleName: {
            name: 'middleName',
            id: 'middleName',
            label: 'Middle Name',
            placeholder: 'karl',
            defaultValue: '',
            type: 'text',
            error: errors.middleName,
            control
        },
        lastName: {
            name: 'lastName',
            id: 'lastName',
            label: 'Last Name',
            placeholder: 'Doe',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter last name'
            },
            error: errors.lastName,
            control
        },
        email: {
            name: 'email',
            id: 'email',
            label: 'Email',
            placeholder: 'john@doe.com',
            defaultValue: '',
            type: 'email',
            rules: {
                required: 'Please enter the email',
                validate: {
                    email: (value) => Utils.validateEmail(value) || 'Please enter a valid email'
                }
            },
            error: errors.email,
            control
        },
        phoneNumber: {
            name: 'phoneNumber',
            id: 'phoneNumber',
            label: 'Phone Number',
            placeholder: '0000000000',
            defaultValue: '',
            type: 'tel',
            rules: {
                required: 'Please enter the Phone Number',
                validate: {
                    phoneNumber: (value) =>
                        Utils.validateMobileNumber(value) || 'Please enter a valid phone number (without country code)'
                }
            },
            error: errors.phoneNumber,
            control
        },
        isSuperAdmin: {
            name: 'isSuperAdmin',
            id: 'isSuperAdmin',
            label: 'Super Admin?',
            defaultValue: false,
            type: 'switch',
            error: errors.isSuperAdmin,
            control
        },
        canViewDashboard: {
            name: 'canViewDashboard',
            id: 'canViewDashboard',
            label: 'Can view Dashboard?',
            defaultValue: false,
            type: 'switch',
            error: errors.canViewDashboard,
            control
        },
        canTogglePool: {
            name: 'canTogglePool',
            id: 'canTogglePool',
            label: 'Can Toggle Pool users?',
            defaultValue: false,
            type: 'switch',
            error: errors.canTogglePool,
            control
        },
        accessList: []
    }

    // In case of edit setting all the default fields
    if (formType === Constants.TYPE_ANON_EDIT && admin) {
        let phoneNumber = admin.phoneNumber.slice(3)
        setValue('firstName', admin.firstName)
        setValue('middleName', admin.middleName)
        setValue('lastName', admin.lastName)
        setValue('email', admin.email)
        setValue('phoneNumber', phoneNumber)
        setValue('isSuperAdmin', admin.isSuperAdmin)
        setValue('canViewDashboard', admin.canViewDashboard)
        setValue('canTogglePool', admin.canTogglePool)
    }

    // Preparing the access fields
    if (Array.isArray(accessList) && accessList.length > 0) {
        accessList.forEach((access) => {
            const accessIdentifier = `${access.name.replace(/ /g, '')}${Constants.MISC_ADMIN_ACCESS_SEPARATOR}${
                access.id
            }`
            const accessObj = {
                name: accessIdentifier,
                id: accessIdentifier,
                label: access.name,
                url: access.url,
                defaultValue: false,
                type: 'switch',
                error: errors[accessIdentifier],
                control
            }

            // For form type as edit, setting the values for existing accesses
            if (formType === Constants.TYPE_ANON_EDIT && Array.isArray(accesses) && accesses.length > 0)
                setValue(accessIdentifier, accesses.filter((acc) => acc.id === access.id).length === 1)

            fields.accessList.push(accessObj)
        })
    }

    return fields
}
