import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { getUserType, isLoggedIn } from '../features/user/userSlice'
import Constants from '../helpers/Constants'
import Utils from '../helpers/Utils'
import { useGetSidebar } from '../hooks/useAdmin'
import { Box, Heading } from './vanilla'

function SideBar() {
    // Checking if user is logged in
    const isAuthenticated = useSelector(isLoggedIn)
    // Getting current user type
    const userType = useSelector(getUserType)
    // Init Location here
    const location = useLocation()
    let { isLoading, data: sidebarData, isError } = useGetSidebar({ enabled: isAuthenticated })

    // Not rendering if route is not admin
    if (!Utils.isAdminRoute(location.pathname)) return null
    if (!isAuthenticated) return null
    if (userType !== Constants.TYPE_USER_ADMIN) return null

    return (
        <Box
            className="offcanvas offcanvas-start p-4"
            tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel">
            <Box className="offcanvas-header">
                <Heading className="offcanvas-title" id="offcanvasExampleLabel">
                    Admin Menu
                </Heading>
                <button
                    id="side-bar-close"
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
            </Box>
            <Box className="offcanvas-body">
                {!isLoading ? (
                    <ul className="nav flex-column">
                        {sidebarData && (
                            <>
                                {Object.keys(sidebarData.sidebar).map((group) => (
                                    <Box key={group}>
                                        {/* For individual lists */}
                                        {group === 'individual' ? (
                                            <>
                                                {sidebarData.sidebar[group].items.map(({ id, url, name }) => (
                                                    <Link
                                                        key={id}
                                                        to={url}
                                                        className="dropdown-item"
                                                        onClick={() => Utils.closeItem('side-bar-close')}>
                                                        {name}
                                                    </Link>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <li className="nav-item dropdown">
                                                    <a
                                                        className="nav-link dropdown-toggle admin-side-bar-item"
                                                        data-bs-toggle="dropdown"
                                                        href="#"
                                                        role="button"
                                                        aria-expanded="false">
                                                        {group}
                                                    </a>
                                                    <ul className="dropdown-menu">
                                                        {sidebarData.sidebar[group].items.map(({ id, url, name }) => (
                                                            <li key={id}>
                                                                <Link
                                                                    to={url}
                                                                    className="dropdown-item"
                                                                    onClick={() => Utils.closeItem('side-bar-close')}>
                                                                    {name}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </li>
                                            </>
                                        )}
                                    </Box>
                                ))}
                            </>
                        )}
                    </ul>
                ) : (
                    <>Loading...</>
                )}

                {isError && <>Error while loading sidebar! Please reload the page</>}
            </Box>
        </Box>
    )
}

export default SideBar
