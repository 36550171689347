import React, { useState } from 'react'
import OTPInput, { ResendOTP } from 'otp-input-react'
import ValidationError from '../../../errors/ValidationError'
import BadRequestError from '../../../errors/BadRequestError'
import Constants from '../../../helpers/Constants'
import { verifyRegisterOTP, verifyForgotPwdOTP } from '../../../api/Auth'
import Utils from '../../../helpers/Utils'
import { useNavigate } from 'react-router-dom'

function VerifyOTP({
    mobileNumber = '',
    type,
    otpType = Constants.TYPE_VERIFY_OTP_REGISTER,
    callback = () => {},
    email = '',
    userType = ''
}) {
    // Data vars
    const [otp, setOtp] = useState('')

    // Init Navigate here
    const navigate = useNavigate()

    // Loading vars
    const [otpLoading, setOtpLoading] = useState(false)
    const [otpResendLoading, setOtpResendLoading] = useState(false)

    /** Function to handle otp verification */
    const handleOTPSubmit = async (e) => {
        e.preventDefault()
        try {
            // Setting the loading
            setOtpLoading(true)

            // Validating otp
            if (otp == '' || otp.length < 4) throw new ValidationError(Constants.MSG_INVALID_OTP)

            let response
            switch (otpType) {
                case Constants.TYPE_VERIFY_OTP_REGISTER:
                    // Calling the API
                    response = await verifyRegisterOTP({
                        phoneNumber: mobileNumber,
                        type,
                        otp
                    })

                    //after OTP verification, check if jobSeeker registration set step as 3 and jobseeker id in callback and return.
                    if (type == Constants.TYPE_USER_JOB_SEEKER) {
                        // Show success toast
                        Utils.showToast(Constants.MSG_OTP_VERIFIED_SUCCESS_UPLOAD_RESUME, Constants.TYPE_TOAST_SUCCESS)
                        callback(3, response.user.uuId)
                    } else {
                        // Show success toast
                        Utils.showToast(response.message, Constants.TYPE_TOAST_SUCCESS)

                        // Redirecting towards login
                        const redirectRoute =
                            type == Constants.TYPE_USER_EMPLOYER
                                ? Constants.ROUTE_CLIENT_LOGIN_EMPLOYER
                                : Constants.ROUTE_CLIENT_LOGIN_JOB_SEEKER
                        navigate(redirectRoute)
                    }

                    break
                case Constants.TYPE_VERIFY_OTP_EMAIL:
                    // Calling the API
                    response = await verifyForgotPwdOTP({
                        email,
                        otp,
                        type: userType
                    })
                    // Increasing parent step by callback & sending the original otp
                    callback(3, otp)
                    break
            }
        } catch (error) {
            if (error instanceof ValidationError || error instanceof BadRequestError)
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)

            // Setting the loading
            setOtpLoading(false)
        }
    }

    /** Function to render resend button & time clock */
    const renderButton = (buttonProps) => {
        return (
            <button className="resend-btn font-13" {...buttonProps}>
                {otpResendLoading ? (
                    <>
                        <i className="fas fa-circle-notch fa-spin"></i> Resending...
                    </>
                ) : (
                    <>Resend</>
                )}
            </button>
        )
    }
    const renderTime = (remainingTime) => {
        return <span className="font-13">{remainingTime != 0 && 'Please wait ' + remainingTime + ' seconds to'}</span>
    }

    return (
        <div>
            <OTPInput
                className="d-flex align-items-center justify-content-center mt-3 otp-div"
                value={otp}
                onChange={setOtp}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
            />
            <ResendOTP
                className="mt-3"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                onResendClick={() => console.log('Resend clicked')}
                renderButton={renderButton}
                renderTime={renderTime}
            />
            <div className="text-center mt-3">
                <button
                    className="btn btn-primary btn-sm px-5"
                    disabled={otpLoading}
                    onClick={(e) => handleOTPSubmit(e)}>
                    {otpLoading ? (
                        <>
                            <i className="fas fa-circle-notch fa-spin"></i> Verifying...
                        </>
                    ) : (
                        <>Verify &amp; Proceed</>
                    )}
                </button>
            </div>
        </div>
    )
}

export default VerifyOTP
