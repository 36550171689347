import React, { useState } from 'react'
import './conversation.css'
import Constants from '../../../helpers/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployerType, getUserType, getIsVendor } from '../../../features/user/userSlice'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { confirmAlert } from 'react-confirm-alert'
import { sendInterviewResponse } from '../../../api/Common'
import ValidationError from '../../../errors/ValidationError'
import Utils from '../../../helpers/Utils'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
import { removeInterviewAdmin } from '../../../api/Admin'
import { cancelInterview, updateInterviewFeedback } from '../../../api/Auth'
import InterviewCard from './InterviewCard'
import MeetingLinkModal from './modals/MeetingLinkModal'
import { Button } from '../../vanilla'

function Conversation({ interview, clickCallback, setInterviewsCallback, interviews, index }) {
    // Getting current user type
    const userType = useSelector(getUserType)
    // Getting current employer type
    const employerType = useSelector(getEmployerType)
    const isVendor = useSelector(getIsVendor)

    // Component Vars
    const [feedback, setFeedback] = useState('')
    const [cancelReason, setCancelReason] = useState('')

    // Loader vars
    const [updateFeedbackLoading, setUpdateFeedbackLoading] = useState(false)
    const [cancelInterviewLoading, setCancelInterviewLoading] = useState(false)

    // Init dispatch here
    const dispatch = useDispatch()

    /** Function to toggle interview status */
    const toggleInterviewStatus = async ({ interviewResponse, applicationId, interviewType, interviewId }) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the API to mark the interview result
            await sendInterviewResponse(
                applicationId,
                interviewResponse,
                interviewType == Constants.TYPE_INTERVIEW_TECHNICAL
                    ? Constants.STATUS_JOB_APP_INTERVIEW
                    : Constants.STATUS_JOB_APP_HR,
                interviewId
            )

            // Setting the values in interviews array
            let interviewsArr = [...interviews]
            interviewsArr.splice(index, 1)
            setInterviewsCallback(interviewsArr)

            // Showing success toast here
            Utils.showToast(Constants.MSG_INTERVIEW_STATUS_CHANGED, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /**
     * Function to check if the user can submit feedback
     */
    const canSubmitFeedback = () => {
        return userType === Constants.TYPE_USER_EMPLOYER && employerType !== Constants.TYPE_USER_EMPLOYER_COMPANY
    }

    /*
     * Function to remove interview from list
     */
    const handleRemoveInterview = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the API to remove interview
            await removeInterviewAdmin(interview.interviewId)

            // Removing the interview from View
            let interviewsArr = [...interviews]
            interviewsArr.splice(index, 1)
            setInterviewsCallback(interviewsArr)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    /** Function to cancel an interview */
    const handleCancelInterview = async () => {
        setCancelInterviewLoading(true)
        try {
            // Validating data
            if (cancelReason === '') throw new ValidationError(Constants.CANCEL_INTERVIEW_REASON_NULL)

            await cancelInterview({ cancelReason, interviewId: interview.interviewId })

            Utils.closeItem(`cancelModalClose${interview.interviewId}`)

            // Removing the interview from View
            let interviewsArr = [...interviews]
            interviewsArr.splice(index, 1)
            setInterviewsCallback(interviewsArr)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            setCancelInterviewLoading(false)
        }
    }

    /**
     * Function to update the feedback for the interview
     */
    const handleUpdateFeedback = async () => {
        try {
            setUpdateFeedbackLoading(true)
            // Validating the fields
            if (feedback === '') throw new ValidationError(Constants.FEEDBACK_NULL)

            // Updating the interview feedback here
            await updateInterviewFeedback({ feedback, interviewId: interview.interviewId })

            // Setting the feedback to local
            let interviewsArr = [...interviews]
            interviewsArr[index].feedback = feedback
            setInterviewsCallback(interviewsArr)
            setFeedback('')

            // Closing modal
            Utils.closeItem(`feedbackModalClose${interview.interviewId}`)
            // Showing success
            Utils.showToast(Constants.UPDATE_FEEDBACK_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            setUpdateFeedbackLoading(false)
        }
    }

    return (
        <>
            <InterviewCard
                interview={interview}
                onChat={(e) => clickCallback(e, interview)}
                userType={userType}
                isVendor={isVendor}
                employerType={employerType}
                onInterviewCleared={() =>
                    confirmAlert({
                        title: Constants.MSG_GEN_ALERT_UNASSIGN,
                        message: Constants.MSG_GEN_ALERT_INTERVIEW_CLEAR_DESC,
                        buttons: [
                            {
                                label: 'Confirm',
                                onClick: () =>
                                    toggleInterviewStatus({
                                        applicationId: interview.application.id,
                                        interviewId: interview.interviewId,
                                        interviewResponse: Constants.TYPE_OFFER_RESPONSE_ACCEPT,
                                        interviewType: interview.type
                                    })
                            },
                            {
                                label: 'Go Back'
                            }
                        ]
                    })
                }
                onInterviewReject={() =>
                    confirmAlert({
                        title: Constants.MSG_GEN_ALERT_UNASSIGN,
                        message: Constants.MSG_GEN_ALERT_INTERVIEW_REJECT_DESC,
                        buttons: [
                            {
                                label: 'Confirm',
                                onClick: () =>
                                    toggleInterviewStatus({
                                        interviewResponse: Constants.TYPE_OFFER_RESPONSE_REJECT,
                                        applicationId: interview.application.id,
                                        interviewType: interview.type,
                                        interviewId: interview.interviewId
                                    })
                            },
                            {
                                label: 'Go Back'
                            }
                        ]
                    })
                }
                onDelete={() => {
                    confirmAlert({
                        title: Constants.MSG_GEN_ALERT_UNASSIGN,
                        message: Constants.MSG_GEN_ALERT_INTERVIEW_DEL_ADMIN,
                        buttons: [
                            {
                                label: 'Confirm',
                                onClick: () => handleRemoveInterview()
                            },
                            {
                                label: 'Go Back'
                            }
                        ]
                    })
                }}
            />
            {/* Meeting link modal */}
            <MeetingLinkModal interview={interview} />

            {/* Feedback modal */}
            <div
                className="modal fade back-modal"
                id={`feedbackModal${interview.interviewId}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Feedback Section
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4">
                            {canSubmitFeedback() ? (
                                <>
                                    <textarea
                                        className="form-control"
                                        placeholder="Enter Feedback"
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}></textarea>
                                    {interview.feedback !== '' && (
                                        <div className="mt-3">
                                            <b>Previous Feedback:</b> {interview.feedback}
                                            <div className="alert alert-danger mt-2">
                                                Alert: Giving a new feedback will override the current available
                                                feedback!
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    {interview.feedback === '' ? (
                                        <p>No Feedback has been added yet!</p>
                                    ) : (
                                        <p>{interview.feedback}</p>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <Button
                                color="warning"
                                className="px-5"
                                data-bs-dismiss="modal"
                                id={`feedbackModalClose${interview.interviewId}`}
                                label="Close"
                            />

                            {canSubmitFeedback() && (
                                <Button
                                    color="primary"
                                    className="px-5"
                                    onClick={() => handleUpdateFeedback()}
                                    disabled={updateFeedbackLoading}
                                    label={
                                        updateFeedbackLoading ? (
                                            <>
                                                <i className="fas fa-circle-notch fa-spin"></i> Submitting....
                                            </>
                                        ) : (
                                            <>Submit Feedback</>
                                        )
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Cancel interview modal */}
            <div
                className="modal fade back-modal"
                id={`cancelModal${interview.interviewId}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header p-4">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Cancel Interview
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Reason for Cancellation?
                                </label>
                                <textarea
                                    className="form-control"
                                    value={cancelReason}
                                    onChange={(e) => setCancelReason(e.target.value)}
                                    placeholder="Enter reason for cancellation"></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                color="warning"
                                className="px-5"
                                data-bs-dismiss="modal"
                                id={`cancelModalClose${interview.interviewId}`}
                                label="Close"
                            />
                            <Button
                                color="danger"
                                className="px-5"
                                onClick={() => handleCancelInterview()}
                                disabled={cancelInterviewLoading}
                                label={
                                    cancelInterviewLoading ? (
                                        <>
                                            <i className="fas fa-circle-notch fa-spin"></i> Cancelling....
                                        </>
                                    ) : (
                                        <>Cancel Interview</>
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Conversation
