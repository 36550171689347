import React, { useState, useEffect } from 'react'
import { Box, Flex, Heading, Row, Column } from '../../../components/vanilla'
import { useParams } from 'react-router-dom'
import Styles from './Styles.module.css'
import PostDesc from './components/PostDesc'
import PostComments from './components/PostComments'
import PostLikes from './components/PostLikes'
import Utils from '../../../helpers/Utils'
import { getCommunityPost } from '../../../hooks/useAdmin/util'
import Constants from '../../../helpers/Constants'
import { useDispatch } from 'react-redux'
import { setLoadingState } from '../../../features/loader/LoaderSlice'

function CommunityPostDetails() {
    const postId = useParams().postId
    const [post, setPost] = useState('')
    const dispatch = useDispatch()

    //Function to get post details */
    const getPostDetails = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            const postDetails = await getCommunityPost({ postId: postId })
            setPost(postDetails)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }
    useEffect(() => {
        getPostDetails()
    }, [])

    /**
     * Function to reload comment data after delete
     */
    const reloadComments = (newCommentsArr) => {
        let values = { ...post }
        values.data.comments = newCommentsArr
        setPost(values)
    }

    return (
        <Flex className="flex-lg-row flex-column mt-5 mx-5">
            {post.data && (
                <>
                    <Box className="w-lg-300px w-xl-300px">
                        <Row className="h-50">
                            <Column className="col-12">
                                <PostDesc postData={post} />
                            </Column>
                        </Row>
                    </Box>
                    <Box className="flex-lg-row-fluid ms-lg-5 ms-xl-5 w-lg-500px w-xl-500px">
                        <Box className={['card', Styles.hero].join(' ')}>
                            <Heading size="xl" className={'p-2'}>
                                Post Likes
                            </Heading>{' '}
                            <hr />
                            <Box className="card-body overflow-auto">
                                {post.data.likes && post.data.likes.length > 0 ? (
                                    post.data.likes.map((like) => <PostLikes like={like} key={like.id} />)
                                ) : (
                                    <Heading size="md">No Likes Found !</Heading>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box className="flex-lg-row-fluid ms-lg-5 ms-xl-5 w-lg-500px w-xl-500px">
                        <Box className={['card', Styles.hero].join(' ')}>
                            <Heading size="xl" className={'p-2'}>
                                Post Comments
                            </Heading>{' '}
                            <hr />
                            <Box className="card-body overflow-auto">
                                {post.data.comments && post.data.comments.length > 0 ? (
                                    post.data.comments.map((comment, index) => (
                                        <PostComments
                                            comment={comment}
                                            comments={post.data.comments}
                                            key={comment.id}
                                            index={index}
                                            onDelete={reloadComments}
                                        />
                                    ))
                                ) : (
                                    <Heading size="md">No Comments Found !</Heading>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </Flex>
    )
}
export default CommunityPostDetails
