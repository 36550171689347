import React, { useState } from 'react'
import { Box, Row, Heading, Column, Badge, Button } from '../../../components/vanilla'
import Constants from '../../../helpers/Constants'
import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Utils from '../../../helpers/Utils'
import { useDispatch } from 'react-redux'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
import { approveRejectCommunityPost } from '../../../api/Common'
import { confirmAlert } from 'react-confirm-alert'

function SinglePost({ post }) {
    // Init Dispatch here
    const dispatch = useDispatch()
    const [isApproved, setIsApproved] = useState(post.isAdminApproved)
    const [isRejected, setIsRejected] = useState(post.isRejected)

    const handlePost = async (type) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            // Calling API to approve/reject post
            await approveRejectCommunityPost(post.id, type)
            if (type === 'approve') {
                setIsApproved(true)
                setIsRejected(false)
                Utils.showToast(Constants.MSG_STATUS_POST_APPROVED, Constants.TYPE_TOAST_SUCCESS)
            } else {
                setIsApproved(false)
                setIsRejected(true)
                Utils.showToast(Constants.MSG_STATUS_POST_REJECTED, Constants.TYPE_TOAST_DANGER)
            }
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    return (
        <Row className="card-body align-items-center">
            <Column className="col-md-1 mb-2 mb-md-0 text-center text-md-start">
                <img
                    alt="community_image"
                    src={`${post.media != null ? post.media : Constants.DEFAULT_PATH_NO_IMAGE}`}
                    className="avatar-img rounded-0"
                />
            </Column>
            <Column className="col-md-2 text-md-start">
                <Heading size="md">{post.title ? post.title : 'NO TITLE'}</Heading>
            </Column>

            <Column className="col-md-3 text-md-start">
                <Box
                    dangerouslySetInnerHTML={{
                        __html:
                            post.description.length < 250
                                ? `${post.description}`
                                : `${post.description.substring(0, 220)}...`
                    }}
                />
            </Column>
            <Column className="col-md-2 text-md-start">
                <Box>
                    <b>Likes Count: </b>
                    {post.likes.length}
                </Box>
                <Box>
                    <b>Comment Count: </b>
                    {post.comments.length}
                </Box>
            </Column>

            {isApproved && (
                <Column className="col-md-2">
                    <Badge color="success">Approved</Badge>
                </Column>
            )}
            {isRejected && (
                <Column className="col-md-2">
                    <Badge color="danger">Rejected</Badge>
                </Column>
            )}

            {!isRejected && !isApproved && (
                <>
                    <Column className="col-md-1">
                        <Button
                            color="success"
                            label="Approve"
                            onClick={(e) => {
                                e.preventDefault()
                                confirmAlert({
                                    title: Constants.MSG_GEN_ALERT_UNASSIGN,
                                    message: Constants.MSG_STATUS_POST_APPROVE_CONFIRM,
                                    buttons: [
                                        {
                                            label: 'Confirm',
                                            onClick: () => handlePost('approve')
                                        },
                                        {
                                            label: 'Go Back'
                                        }
                                    ]
                                })
                            }}
                            icon={<FaThumbsUp />}
                            className="w-100"
                        />
                    </Column>
                    <Column className="col-md-1">
                        <Button
                            color="warning"
                            label="Reject"
                            onClick={(e) => {
                                e.preventDefault()
                                confirmAlert({
                                    title: Constants.MSG_GEN_ALERT_UNASSIGN,
                                    message: Constants.MSG_STATUS_POST_REJECT_CONFIRM,
                                    buttons: [
                                        {
                                            label: 'Confirm',
                                            onClick: () => handlePost('reject')
                                        },
                                        {
                                            label: 'Go Back'
                                        }
                                    ]
                                })
                            }}
                            icon={<FaThumbsDown />}
                            className="w-100"
                        />
                    </Column>
                </>
            )}

            <Column className="col-md-1 text-md-end">
                <Link to={Constants.ROUTE_ADMIN_COMMUNITY_POST_DETAILS(post.id)} className="btn-job btn-info">
                    <i className="fas fa-info-circle"></i>&nbsp;Post Details
                </Link>
            </Column>
            <Column className="col-md-1 text-md-end">
                <Link to={Constants.ROUTE_ADMIN_COMMUNITY_POSTS_EDIT(post.id)} className="btn-job btn-primary">
                    <i className="fas fa-edit"></i>&nbsp;Edit
                </Link>
            </Column>
        </Row>
    )
}

export default SinglePost
