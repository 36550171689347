import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { useAddCommunityPost, useEditCommunityPost } from '../../../hooks/useAdmin'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'
function AddForm({ formType = Constants.TYPE_ADD, postId }) {
    const form = useForm()
    const { mutateAsync: addCommunityPost, isLoading, isError, error } = useAddCommunityPost()
    const { mutateAsync: editCommunityPost, isError: isEditError, error: editError } = useEditCommunityPost()
    // Init Navigate here
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        // Create multipart/formData
        const formData = new FormData()
        if (data.media[0] && Constants.ALLOWED_IMAGE_MIME_TYPE.indexOf(data.media[0].type) < 0) {
            form.setError('media', {
                type: 'filetype',
                message: 'Only image file is allowed.'
            })
            return
        }

        formData.append('description', data.description)
        formData.append('isActive', data.isActive)
        formData.append('media', data.media[0] ?? '')
        formData.append('title', data.title)

        // Creating/Editing the new community post
        if (formType === Constants.TYPE_ADD) await addCommunityPost(formData)
        else await editCommunityPost({ postId, data: formData })

        // Navigating to the community list
        navigate(Constants.ROUTE_ADMIN_COMMUNITY_POSTS_LIST)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            {isEditError && (
                <Alert color="danger">
                    {editError?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}
                </Alert>
            )}
            <FormFields form={form} postId={postId} formType={formType} />

            <Button
                className="mb-0"
                color="primary"
                label={formType === Constants.TYPE_ADD ? 'Add Community Post' : 'Update Community Post'}
                btnType="submit"
                isLoading={isLoading}
            />
        </Form>
    )
}

export default AddForm
