import React from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { getAdminType, getUserType } from '../../features/user/userSlice'
import Constants from '../../helpers/Constants'

const SubHRTooltip = ({ jobSeeker }) => {
    // Getting the user type
    const userType = useSelector(getUserType)
    const adminType = useSelector(getAdminType)

    /**
     * If the current logged in user is not admin / The job seeker is not created by admin(Sub HR)
     * then no need to show
     */
    if (userType !== Constants.TYPE_USER_ADMIN) return null
    if (adminType !== Constants.TYPE_USER_ADMIN_MAIN) return null
    if (!jobSeeker.isCreatedByAdmin) return null

    return (
        <span
            className="span_info_subhr"
            data-tip={`subHR Email: ${jobSeeker.subHR.subHREmail} <br /> subHR Name: ${jobSeeker.subHR.subHRFName} ${jobSeeker.subHR.subHRMName} ${jobSeeker.subHR.subHRLName} <br/> subHR Phone: ${jobSeeker.subHR.subHRPhone}  `}
            data-for="subhrTooltip">
            <FaInfoCircle />
        </span>
    )
}

export default SubHRTooltip
