import React from 'react'
import Constants from '../../../../helpers/Constants'
import { Avatar, Box, Heading, Flex } from '../../../vanilla'
import CardBasic from '../../../Cards/CardBasic'
import ReactTooltip from 'react-tooltip'
import SubHRTooltip from '../../../SubHRTooltip'

function CampusJobSeekerCard({ jobSeeker = {} }) {
    return (
        <CardBasic cardClass="mb-4">
            <Flex className="align-items-center">
                <Avatar
                    size="xxl"
                    src={jobSeeker.avatarPath !== '' ? jobSeeker.avatarPath : Constants.DEFAULT_PATH_JOB_SEEKER_AVATAR}
                />
                <Box className="ms-4">
                    <Heading className="text-purple mb-0 text-uppercase">
                        {`${jobSeeker.firstName} ${jobSeeker.middleName} ${jobSeeker.lastName}`}
                        <SubHRTooltip jobSeeker={jobSeeker} />
                    </Heading>

                    <ReactTooltip id="subhrTooltip" className="tooltip" html={true} />
                    <Box className="mb-0">{jobSeeker.email}</Box>
                    <Box className="mb-0">{jobSeeker.phoneNumber}</Box>
                </Box>
            </Flex>

            <Box className="mb-4 ps-3">
                <>
                    <Box>
                        <b>Experience:</b>{' '}
                        {`${jobSeeker.profile.tagExperience ? jobSeeker.profile.tagExperience + ' Years' : 'NA'}`}
                    </Box>
                    <Box>
                        <b>Current/Expected CTC:</b>{' '}
                        {`${jobSeeker.profile.currentCTC ? jobSeeker.profile.currentCTC : 'NA'}/${
                            jobSeeker.profile.ExpectedCTC ? jobSeeker.profile.ExpectedCTC : 'NA'
                        } LPA`}
                    </Box>
                    <Box>
                        <b>Location:</b> {jobSeeker.profile.tagLocation ? jobSeeker.profile.tagLocation : 'NA'}
                    </Box>
                    <Box>
                        <b>Notice Period:</b> {jobSeeker.profile.noticePeriod ? jobSeeker.profile.noticePeriod : 'NA'}{' '}
                        days
                    </Box>
                    <Box>
                        <b>Skills: </b>{' '}
                        {jobSeeker.profile.majorSkills.length !== 0 ? (
                            <>{jobSeeker.profile.majorSkills.join(', ')}</>
                        ) : (
                            <>No Skills available</>
                        )}
                    </Box>
                </>
            </Box>
        </CardBasic>
    )
}

export default CampusJobSeekerCard
