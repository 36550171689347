/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Box, Button } from '../../../../vanilla'
import FormFields from './map-subhr/FormFields'
import { useForm } from 'react-hook-form'
import { useSubHRList, useSubHRMapToJs } from '../../../../../hooks/useAdmin'

const MappedSubHR = ({ subHR, jobSeekerId, onMap }) => {
    const [isEdit, setIsEdit] = useState(false)
    const form = useForm()
    const { mutateAsync, isLoading: ismapLoading } = useSubHRMapToJs()
    const onSubmit = async (data) => {
        // Updating data
        await mutateAsync({
            jobSeekerId: jobSeekerId,
            subHRId: data.subHR.value
        })
        await onMap()
        setIsEdit(false)
    }

    const { data: subHRData, isLoading } = useSubHRList({ page: '', phNumber: '', name: '' })

    if (isLoading && !subHRData) return <>Please Wait...</>

    return (
        <Box className="col-sm-12 mt-2">
            <Box className="card p-3">
                <Box className="mb-0">
                    <b>Mapped HR</b> <br />
                    {!isEdit ? (
                        <>
                            {subHR?.subHREmail !== ''
                                ? `${subHR.subHRFName} ${subHR.subHRMName} ${subHR.subHRLName}`
                                : 'No HR Mapped yet'}{' '}
                            <a href="#" className="text-decoration-none" onClick={() => setIsEdit(true)}>
                                <i className="fas fa-edit"></i> Edit
                            </a>
                        </>
                    ) : (
                        <Box Element="form" onSubmit={form.handleSubmit(onSubmit)}>
                            <FormFields form={form} subHRs={subHRData.subHrs} />

                            <Button label="Update" btnType="submit" className="w-100" isLoading={ismapLoading} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default MappedSubHR
