/* eslint-disable import/no-anonymous-default-export */
import Constants from './Constants'
import { toast, Slide } from 'react-toastify'

export default {
    /** Function to validate Email address */
    validateEmail: (email) => {
        /* Regular expression for valid email */
        const regExp = Constants.REGEX_VALID_EMAIL
        return regExp.test(email)
    },

    /** Function to validate mobile number */
    validateMobileNumber: (number) => {
        /** Regular expression for valid mobile number */
        const regExp = Constants.REGEX_VALID_MOBILE_NUMBER
        return regExp.test(number)
    },

    /** Function to validate password */
    validatePassword: (password) => {
        var regExp = Constants.REGEX_VALID_PASSWORD
        return regExp.test(password)
    },

    /** Function to show toast */
    showToast: (description, type, position = Constants.POSITION_TOAST_TOP_RIGHT) => {
        const toastOptions = {
            position,
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: type,
            theme: 'colored',
            transition: Slide,
            style: { fontSize: '13px' }
        }

        // Showing the toast
        toast(description, toastOptions)
    },

    /** Function to check if a route is admin route */
    isAdminRoute: (routePath) => {
        return Constants.REGEX_ADMIN_ROUTE.test(routePath)
    },

    stripHTML: (html) => html.replace(/<\/?[^>]+(>|$)/g, ''),

    /** Function to get mobile number prefixes */
    getMobileNumPrefixes: () => {
        return ['91']
    },

    /** Function to get employer return company types */
    getEmployerCompanyTypes: () => {
        return [
            {
                label: 'Company - Hiring for own',
                value: Constants.TYPE_COMPANY_COMPANY
            },
            {
                label: 'Staffing Agency - Hiring for others',
                value: Constants.TYPE_COMPANY_HIRING_AGENCY
            }
        ]
    },
    /** Function to scroll to top of page */
    scrollToTop: () => {
        // Scrolling to Top of page
        window.scrollTo(0, 0)
    },

    /** Function to generate years based on given startYear */
    getYearsRange: (startYear) => {
        let currentYear = new Date().getFullYear(),
            years = []
        startYear = startYear || 1980
        while (startYear <= currentYear) {
            years.push(startYear++)
        }
        return years.reverse()
    },

    /** Function to auto close things (Modal, side bar, etc) by btn close id */
    closeItem: (btnId) => {
        let closeBtn = document.getElementById(btnId)
        if (closeBtn) closeBtn.click()
    },

    /** Function to check if string contain any number or not */
    validateName: (name) => {
        const containsOnlyNumbers = /^\d+$/.test(name)
        const containsSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(name)
        return !containsOnlyNumbers && !containsSpecialCharacters
    },

    /** Function to get current job application status display text */
    jobAppDisplayStatusText: ({
        status,
        interviewResult,
        hrResult,
        jobSchedulesTechnical,
        totalTechnicalCount,
        offerAccepted
    }) => {
        let displayText
        switch (status) {
            case Constants.STATUS_JOB_APP_APPLIED:
                displayText = 'APPLIED'
                break

            case Constants.STATUS_JOB_APP_ALLOTED:
                displayText = 'ALLOTED TO EMPLOYER'
                break

            case Constants.STATUS_JOB_APP_SHORTLISTED:
                displayText = 'SHORTLISTED'
                break

            case Constants.STATUS_JOB_APP_INTERVIEW:
                // Based on interview result showing the status
                switch (interviewResult) {
                    case Constants.TYPE_RESULT_PENDING:
                        // Getting the technical interviews
                        let technicalInterviews = jobSchedulesTechnical ? jobSchedulesTechnical : []

                        if (
                            Array.isArray(technicalInterviews) &&
                            technicalInterviews.length > 0 &&
                            technicalInterviews[0].isCancelled
                        ) {
                            displayText = `TECHNICAL INTERVIEW ${totalTechnicalCount - 1} CANCELLED`
                        } else if (totalTechnicalCount !== 0) {
                            displayText = `TECHNICAL INTERVIEW ${totalTechnicalCount - 1} SCHEDULED`
                        } else {
                            displayText = `TECHNICAL INTERVIEW SCHEDULED`
                        }
                        break
                    case Constants.TYPE_RESULT_PASSED:
                        displayText = `TECHNICAL INTERVIEW ${totalTechnicalCount - 1} CLEARED`
                        break
                    case Constants.TYPE_RESULT_FAILED:
                        displayText = `TECHNICAL INTERVIEW ${totalTechnicalCount - 1} FAILED`
                        break
                    default:
                }
                break

            case Constants.STATUS_JOB_APP_HR:
                // Based on interview result showing the status
                switch (hrResult) {
                    case Constants.TYPE_RESULT_PENDING:
                        displayText = `HR INTERVIEW SCHEDULED`
                        break
                    case Constants.TYPE_RESULT_PASSED:
                        displayText = `HR INTERVIEW CLEARED`
                        break
                    case Constants.TYPE_RESULT_FAILED:
                        displayText = `HR INTERVIEW FAILED`
                        break
                    default:
                }
                //displayText = 'HR INTERVIEW SCHEDULED'
                break

            case Constants.STATUS_JOB_APP_OFFERED:
                const offerStatus = offerAccepted
                switch (offerStatus) {
                    case Constants.TYPE_RESULT_PENDING:
                        displayText = 'OFFERED'
                        break
                    case Constants.TYPE_RESULT_ACCEPTED:
                        displayText = `OFFER ACCEPTED BY JOB-SEEKER`
                        break
                    case Constants.TYPE_RESULT_REJECTED:
                        displayText = `OFFER REJECTED BY JOB-SEEKER`
                        break
                    default:
                }

                break

            case Constants.STATUS_JOB_APP_JOINED:
                displayText = 'JOINED'
                break

            case Constants.STATUS_JOB_APP_REJECT:
                displayText = 'REJECTED'
                break

            case Constants.STATUS_JOB_APP_HOLD:
                displayText = 'ON HOLD'
                break

            case Constants.STATUS_JOB_APP_NO:
                displayText = 'NOT APPLIED YET'
                break

            default:
                break
        }
        return displayText
    }
}
