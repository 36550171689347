import React from 'react'
import { useParams } from 'react-router-dom'
import AddAdminForm from '../../../../components/forms/admin/Form'
import { Box, Heading } from '../../../../components/vanilla'
import Constants from '../../../../helpers/Constants'

function EditAdmin() {
    // Getting Admin id
    const adminId = useParams().adminId
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Edit Admin User</Heading>
            <AddAdminForm formType={Constants.TYPE_ANON_EDIT} adminId={adminId} />
        </Box>
    )
}

export default EditAdmin
