import React from 'react'
import { Input } from '../../../../../../components/vanilla'
import useFormFields from './useFormFields'

const FormFields = (props) => {
    const { form } = props
    const { message } = useFormFields({ form })
    return <Input {...message} className="mb-3 p-0" />
}

export default FormFields
