import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Box, Button, Column, Flex, Form, Heading, Image, Row } from '../../../../components/vanilla'
import FullPageLoader from '../../../../components/vanilla/loaders/FullPageLoader/FullPageLoader'
import SimpleLoader from '../../../../components/vanilla/loaders/SimpleLoader/SImpleLoader'
import Utils from '../../../../helpers/Utils'
import { useAddJSMsgToTimeline, useGetJSApplicationTimeline } from '../../../../hooks/useAdmin'
import { Message } from './components'
import FormFields from './components/ChatForm/FormFields'
import Styles from './Styles.module.css'
import { io } from 'socket.io-client'
import Constants from '../../../../helpers/Constants'

const Chat = () => {
    // Getting jobSeekerId Id
    const applicationId = useParams().applicationId
    const [socket, setSocket] = useState(null)
    // UseEffect to connect to socket on initial render
    useEffect(() => {
        setSocket(io(Constants.SOCKET_HOST))
    }, [])
    // UseEffect to join a room and disconnect from socket on unmount
    useEffect(() => {
        socket?.emit('join', { room: applicationId }, () => {})
        return () => {
            socket?.disconnect()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- As we don't want to resubscribe to the room
    }, [socket])
    const {
        data: timelineResponse,
        isLoading: isApplicationTimelineLoading,
        refetch: refetchTimeline,
        isRefetching: isTimelineRefetching
    } = useGetJSApplicationTimeline({
        id: applicationId
    })
    useEffect(() => {
        socket?.on('message', () => {
            // Just refetching the application data
            refetchTimeline()
        })
    }, [refetchTimeline, socket])
    const { mutateAsync: addMessage, isLoading: isAddMsgLoading } = useAddJSMsgToTimeline()
    const form = useForm()
    const onSubmit = async (data) => {
        // Adding the message to timeline
        await addMessage({
            id: applicationId,
            message: data.message
        })
        form.reset(['message'])
        // Emitting the message to the room
        socket?.emit('sendMessage', {}, () => {})
        // Refetching the application data
        refetchTimeline()
    }
    if (isApplicationTimelineLoading) return <FullPageLoader />
    return (
        <Flex className="flex-lg-row flex-column mt-5 mx-5">
            <Box className="w-100 w-lg-400px w-xl-500px">
                <Row className="h-48">
                    <Column className="col-12">
                        <Box className="card h-100">
                            <Box className="card-body">
                                {timelineResponse && (
                                    <Box>
                                        <Heading size="xl" className="mb-0">
                                            {timelineResponse.meta.job.positionName}
                                        </Heading>
                                        <Box>{timelineResponse.meta.job.employer.companyName}</Box>
                                        <Box className="mt-2 text-muted">
                                            {Utils.stripHTML(timelineResponse.meta.job.description).substring(0, 450)}{' '}
                                            ...
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Column>
                </Row>
                <Box className="h-4" />
                <Row className="h-48 d-lg-block d-none">
                    <Column colClass="col-12">
                        <Box className="card h-100">
                            <Box className="card-body">
                                <Image
                                    src="https://imejob.fra1.cdn.digitaloceanspaces.com/static/chat-avatar.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </Box>
                        </Box>
                    </Column>
                </Row>
            </Box>
            <Box className="flex-lg-row-fluid ms-lg-7 ms-xl-10 w-100">
                <Box className={['card', Styles.hero].join(' ')} id="kt_chat_messenger">
                    <Box className="card-body overflow-auto" id="kt_chat_messenger_body">
                        {isTimelineRefetching && <SimpleLoader size="50px" />}
                        {timelineResponse &&
                            timelineResponse.data &&
                            timelineResponse.data.length > 0 &&
                            timelineResponse.data.map((timeline) => <Message {...timeline} key={timeline.id} />)}
                    </Box>
                    <Box className="card-footer pt-4" id="kt_chat_messenger_footer">
                        <Form onSubmit={form.handleSubmit(onSubmit)}>
                            <FormFields form={form} />
                            <Flex className="flex-stack mt-4">
                                <Box />
                                <Button isLoading={isAddMsgLoading} btnType="submit" label="Send" />
                            </Flex>
                        </Form>
                    </Box>
                </Box>
            </Box>
        </Flex>
    )
}

export default Chat
