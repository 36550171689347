import React, { useState } from 'react'
import BadRequestError from '../../../../errors/BadRequestError'
import ValidationError from '../../../../errors/ValidationError'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import { addNewEmployer } from '../../../../api/Admin'
import { useNavigate } from 'react-router-dom'
import { Box, Column, Row } from '../../../../components/vanilla'

function AddNewEmployer() {
    // Data vars
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyDesignation, setCompanyDesignation] = useState('second')
    const [companyName, setCompanyName] = useState('')
    const [email, setEmail] = useState('')
    const [phPrefix, setPhPrefix] = useState('+91')
    const [phNumber, setPhNumber] = useState('')
    const [password, setPassword] = useState('')
    const [companyType, setCompanyType] = useState('')

    // Loader vars
    const [addLoading, setAddLoading] = useState(false)

    // Init navigate here
    const navigate = useNavigate()

    /** Function to handle addition of new employer */
    const handleSubmit = async (e) => {
        try {
            e.preventDefault()

            // Setting the loading here
            setAddLoading(true)

            // Validating the data here
            if (companyName == '') throw new ValidationError(Constants.MSG_EMPTY_COMPANY_NAME)
            if (firstName == '') throw new ValidationError(Constants.MSG_EMPTY_FIRST_NAME)
            if (lastName == '') throw new ValidationError(Constants.MSG_EMPTY_LAST_NAME)
            if (companyDesignation == '') throw new ValidationError(Constants.MSG_EMPTY_COMPANY_DESIGNATION)
            if (companyName.length > 20) throw new ValidationError(Constants.MSG_COMPANY_NAME_MAX_ALLOWED_CHAR)
            if (email == '' || !Utils.validateEmail(email)) throw new ValidationError(Constants.MSG_INVALID_EMAIL)
            if (phNumber == '' || !Utils.validateMobileNumber(phNumber))
                throw new ValidationError(Constants.MSG_INVALID_MOBILE_NUMBER)
            if (password == '' || !Utils.validatePassword(password))
                throw new ValidationError(Constants.MSG_INVALID_PASSWORD)
            if (companyType == '') throw new ValidationError(Constants.MSG_EMPTY_COMPANY_TYPE)
            // Calling the API here
            await addNewEmployer({
                firstName,
                middleName,
                lastName,
                companyDesignation,
                companyName,
                email,
                companyType,
                phoneNumber: `${phPrefix}${phNumber}`,
                password
            })

            // Setting the loading here
            setAddLoading(false)
            // Success toast here
            Utils.showToast(Constants.MSG_EMPLOYER_ADD_SUCCESS_ADMIN, Constants.TYPE_TOAST_SUCCESS)
            // Navigating to employers list page
            navigate(Constants.ROUTE_ADMIN_MANAGE_LIST_EMPLOYERS)
        } catch (error) {
            if (error instanceof ValidationError || error instanceof BadRequestError)
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
            // Setting the loading here
            setAddLoading(false)
        }
    }

    return (
        <div className="admin-container mb-4">
            <h3>Add New Employer</h3>

            <form onSubmit={(e) => handleSubmit(e)}>
                {/* Full Name */}
                <Row className="mb-3">
                    <Box element="label" htmlFor="companyName" className="col-sm-4 col-form-label">
                        Full Name <span className="red-text">*</span>
                    </Box>
                    <Column className="col-sm-8">
                        <Row>
                            <Column className="col-md-4 p-0">
                                <input
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="First Name"
                                />
                            </Column>
                            <Column className="col-md-4">
                                <input
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    value={middleName}
                                    type="text"
                                    className="form-control"
                                    id="middleName"
                                    placeholder="Middle Name (Optional)"
                                />
                            </Column>
                            <Column className="col-md-4 p-0">
                                <input
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    placeholder="Last Name"
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
                {/* End Full Name */}

                {/* Company Name */}
                <div className="row mb-3">
                    <label htmlFor="companyName" className="col-sm-4 col-form-label">
                        Company Name <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <input
                            onChange={(e) => setCompanyName(e.target.value)}
                            type="text"
                            className="form-control"
                            id="companyName"
                            placeholder="Enter name of Company"
                        />
                    </div>
                </div>
                {/* End Company Name */}

                {/* Company Designation */}
                <div className="row mb-3">
                    <label htmlFor="companyDesignation" className="col-sm-4 col-form-label">
                        Company Designation <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <input
                            onChange={(e) => setCompanyDesignation(e.target.value)}
                            type="text"
                            className="form-control"
                            id="companyDesignation"
                            placeholder="Enter Company Designation"
                        />
                    </div>
                </div>
                {/* End Company Designation */}

                {/* Company Email */}
                <div className="row mb-3">
                    <label htmlFor="companyEmail" className="col-sm-4 col-form-label">
                        Company Email <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="form-control"
                            id="companyEmail"
                            placeholder="Enter email address of Company"
                        />
                    </div>
                </div>
                {/* End Company Email */}

                {/* Company Type */}
                <div className="row mb-3">
                    <label htmlFor="companyEmail" className="col-sm-4 col-form-label">
                        Company Type <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <select
                            onChange={(e) => setCompanyType(e.target.value)}
                            className="form-select"
                            id="companyMobilePrefix"
                            aria-label=".form-select example">
                            <option value="" disabled selected hidden>
                                Select Company Type
                            </option>
                            {Utils.getEmployerCompanyTypes().map((type) => (
                                <option value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* End Company Type */}

                {/* Company Mobile Number */}
                <div className="row mb-3">
                    <label htmlFor="companyMobile" className="col-sm-4 col-form-label">
                        Company Mobile <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-3 col-4">
                        <select
                            onChange={(e) => setPhPrefix(e.target.value)}
                            className="form-select"
                            id="companyMobilePrefix"
                            aria-label=".form-select example">
                            {Utils.getMobileNumPrefixes().map((prefix, index) => (
                                <option key={index}>{prefix}</option>
                            ))}
                        </select>
                    </div>

                    <div className="col-sm-5 col-8 ">
                        <input
                            onChange={(e) => setPhNumber(e.target.value)}
                            type="text"
                            placeholder="xxxxxxxxxx"
                            className="form-control"
                            id="companyMobile"
                        />
                    </div>
                </div>
                {/* End Company Mobile Number */}

                {/* Password */}
                <div className="row mb-3">
                    <label htmlFor="password" className="col-sm-4 col-form-label">
                        Password <span className="red-text">*</span>
                    </label>
                    <div className="col-sm-8">
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="*************"
                        />
                    </div>
                </div>
                {/* End Password */}

                <div className="row mb-3">
                    <div className="col-sm-8 offset-sm-4">
                        <button type="submit" className="btn btn-primary" disabled={addLoading}>
                            {addLoading ? (
                                <>
                                    <i className="fas fa-circle-notch fa-spin"></i> Adding...
                                </>
                            ) : (
                                <>Add Employer</>
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default AddNewEmployer
