import React from 'react'
import AddForm from '../../../components/jobs/AddForm'

function AddJobs() {
    return (
        <div className="admin-container mb-4">
            <h3>Add New Job</h3>
            <AddForm />
        </div>
    )
}

export default AddJobs
