/**
 * All the only admin specific apis will be stored here
 */
import Constants from '../helpers/Constants'
import { request } from '../helpers/axios-utils'

/** Function to get all the employers (paginated + non-paginated) */
export const getAllEmployers = async (id, name, type, page = '') => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: `${Constants.API_ROUTE_GET_EMPLOYERS}?id=${id}&name=${name}&type=${type}&page=${page}`,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            if (page != '') resolve(response?.data)
            return resolve(response?.data?.employers)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all the members (panelists) of employer */
export const getAllEmployerMembers = async (name, page, employerId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: `${Constants.API_ROUTE_GET_EMPLOYER_MEMBERS(employerId)}?name=${name}&page=${page}`,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            return resolve(response?.data)
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * Method to retrieve all campus job-seekers based on search and pagination
 */
export const getAllCampusJobSeekerPaginated = async (
    page,
    {
        name = '',
        mobileNumber = '',
        currentLocation = '',
        minCTC = '',
        maxCTC = '',
        minNoticePeriod = '',
        maxNoticePeriod = '',
        skillIsMandatory = false,
        skills = '',
        tagId = ''
    }
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: `${
                    Constants.API_ADMIN_GET_CAMPUS_JOB_SEEKERS
                }?page=${page}&name=${name}&mobileNumber=${mobileNumber}&currentLocation=${currentLocation}&minCTC=${minCTC}&maxCTC=${maxCTC}&minNoticePeriod=${minNoticePeriod}&maxNoticePeriod=${maxNoticePeriod}&skillIsMandatory=${
                    skillIsMandatory ? 1 : 0
                }&skills=${skills}&tagId=${tagId}`,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            return resolve(response?.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get/search job seekers by page number */
export const getAllJobSeekersPaginated = async (
    page,
    {
        name,
        mobileNumber,
        currentLocation,
        minCTC,
        maxCTC,
        totalExperience,
        minNoticePeriod,
        maxNoticePeriod,
        skillIsMandatory,
        skills,
        tagId,
        isPool
    }
) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: `${
                    Constants.API_ADMIN_GET_JOB_SEEKERS
                }?page=${page}&name=${name}&mobileNumber=${mobileNumber}&currentLocation=${currentLocation}&minCTC=${minCTC}&maxCTC=${maxCTC}&minNoticePeriod=${minNoticePeriod}&maxNoticePeriod=${maxNoticePeriod}&skillIsMandatory=${
                    skillIsMandatory ? 1 : 0
                }&skills=${skills}&tagId=${tagId}&isPool=${isPool}&totalExperience=${totalExperience}`,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            return resolve(response?.data)
        } catch (error) {
            reject(error)
        }
    })
}

export const addNewEmployer = async (employerData) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ROUTE_ADD_EMPLOYER_BY_ADMIN,
                method: Constants.METHOD_POST,
                data: employerData,
                requiresAuth: true
            })
            return resolve()
        } catch (error) {
            reject(error)
        }
    })
}

export const addNewJobSeeker = async (jobSeekerData) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ROUTE_ADD_JOB_SEEKER_BY_ADMIN,
                method: Constants.METHOD_POST,
                data: jobSeekerData,
                requiresAuth: true
            })
            return resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to add new sub-hr */
export const addNewSubHR = async (subHRData) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ROUTE_ADD_SUB_HR_BY_ADMIN,
                method: Constants.METHOD_POST,
                data: subHRData,
                requiresAuth: true
            })
            return resolve()
        } catch (error) {
            reject(error)
        }
    })
}

export const sendTestLink = async (link, applicationId, applicantId, subject) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ROUTE_SEND_TEST_LINK(applicationId, applicantId),
                method: Constants.METHOD_POST,
                data: { link, subject },
                requiresAuth: true
            })
            return resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all the employers (paginated + non-paginated) */
export const getAllJobSeekerApplications = async (jobSeekerId, userType) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url:
                    userType == Constants.TYPE_USER_ADMIN
                        ? Constants.API_GET_JOB_SEEKER_APPLICATIONS(jobSeekerId)
                        : Constants.API_GET_MY_APPLICATIONS,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            return resolve(response.data.applications)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all admin dashboard statistics*/
export const getAdminDashboardStatistics = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: `${Constants.API_ROUTE_GET_ADMIN_DASHBOARD_STATS}`,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            return resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to update user profile rating */
export const updateUserRating = async (jobSeekerId, rating) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ROUTE_UPDATE_USER_RATING(jobSeekerId),
                method: Constants.METHOD_PATCH,
                data: { rating },
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to verify job-seeker mobile from admin panel */
export const verifyUserMobile = async (jobSeekerId) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ROUTE_VERIFY_JOB_SEEKER_MOB(jobSeekerId),
                method: Constants.METHOD_PATCH,
                data: {},
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to remove interview from interview list admin panel */
export const removeInterviewAdmin = async (interviewId) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_REMOVE_INTERVIEW_ADMIN(interviewId),
                method: Constants.METHOD_DELETE,
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to update applicant best fit flag */
export const updateIsBestFitFlag = async ({ applicationId, isBestFit }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ADMIN_JS_BEST_FIT(applicationId, isBestFit ? 1 : 0),
                method: Constants.METHOD_PATCH,
                data: {},
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to get all the tags */
export const getAllTags = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: Constants.API_ADMIN_GET_TAGS,
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            resolve(response.data.tags)
        } catch (error) {
            reject(error)
        }
    })
}

/** Function to add a new tag */
export const addNewTag = async ({ name }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: Constants.API_ADMIN_CREATE_TAG,
                method: Constants.METHOD_POST,
                data: { name },
                requiresAuth: true
            })
            resolve(response.data.tag)
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * Function to map or un map a job seeker with candidate
 */
export const jobSeekerMapping = async ({ jobSeekerId, tagId, mapValue }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ADMIN_CANDIDATE_TAG_MAPPING({ tagId, jobSeekerId, mapValue: mapValue ? 1 : 0 }),
                method: Constants.METHOD_POST,
                data: {},
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * Function delete a tag from db
 */
export const deleteTagById = async ({ id }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ADMIN_DELETE_TAG({ id }),
                method: Constants.METHOD_DELETE,
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * Function to get all the job Seekers for a tag
 */
export const getJobSeekersForTag = async ({ tagId }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await request({
                url: Constants.API_ADMIN_TAG_JOB_SEEKERS({ tagId }),
                method: Constants.METHOD_GET,
                requiresAuth: true
            })
            resolve(response.data.jobSeekers)
        } catch (error) {
            reject(error)
        }
    })
}

/**
 * Function delete a community post comment
 */
export const deletePostCommentById = async ({ id }) => {
    return new Promise(async (resolve, reject) => {
        try {
            await request({
                url: Constants.API_ADMIN_DELETE_POST_COMMENT({ id }),
                method: Constants.METHOD_DELETE,
                requiresAuth: true
            })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}
