import React, { useState, useEffect } from 'react'
import { Box, Flex, Row, Heading, Column } from '../../../components/vanilla'
import { useCommunityChatsList } from '../../../hooks/useAdmin'
import Pagination from '../../../components/Pagination'
import { useNavigate, Link } from 'react-router-dom'
import UnAuthError from '../../../errors/UnAuthError'
import SimpleLoader from '../../../components/vanilla/loaders/SimpleLoader/SImpleLoader'
import Constants from '../../../helpers/Constants'
import moment from 'moment'

function CommunityChatsList() {
    const [currentPage, setCurrentPage] = useState(1)

    const navigate = useNavigate()
    let search = window.location.search
    let params = new URLSearchParams(search)

    const pageNo = parseInt(params.get('page')) ? parseInt(params.get('page')) : 1

    useEffect(() => {
        setCurrentPage(pageNo > 0 ? pageNo : 1)
    }, [pageNo])

    const {
        data: chatsData,
        isLoading: isInitLoading,
        isError: isInitError
    } = useCommunityChatsList({ page: currentPage })

    const navigateUrl = (pageNumber) => {
        navigate({
            pathname: '/admin/community-chats/list',
            search: '?page=' + pageNumber
        })
    }

    /** Function to change Page */
    const changePage = async (pageNumber) => {
        try {
            setCurrentPage(pageNumber)
            navigateUrl(pageNumber)
        } catch (error) {
            throw error
        }
    }

    if (isInitError) {
        return <UnAuthError />
    }

    return (
        <Box className="admin-container mb-4">
            <Row className="p-4 mb-4">
                {!isInitLoading ? (
                    <>
                        {chatsData.data.length > 0 ? (
                            <>
                                {chatsData.data.map((chat) => (
                                    <Link
                                        key={chat.id}
                                        to={Constants.ROUTE_ADMIN_CHAT_MESSAGE({ chatId: chat.uuId })}
                                        style={{ textDecoration: 'none' }}>
                                        <Box className="card mb-4" key={chat.id}>
                                            <Row className="card-body">
                                                <Column className="col-md-5">
                                                    <Heading size="md">
                                                        {`${chat.user.firstName} ${chat.user.middleName} ${chat.user.lastName} (${chat.userTableType})`}
                                                    </Heading>
                                                </Column>

                                                <Column className="col-md-5">
                                                    <Heading size="md">
                                                        {`${chat.allowedUser.firstName} ${chat.allowedUser.middleName} ${chat.allowedUser.lastName} (${chat.allowedUserTableType})`}
                                                    </Heading>
                                                </Column>

                                                <Column className="col-md-2">
                                                    <Heading size="md">
                                                        <b>Date: </b>
                                                        {moment(chat.updatedAt).format('MMM Do YYYY')}
                                                    </Heading>
                                                </Column>
                                            </Row>
                                        </Box>
                                    </Link>
                                ))}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={chatsData.meta.pagination.pageCount}
                                    changePage={(page) => changePage(page)}
                                    itemCount={chatsData.meta.pagination.total}
                                    pageSize={chatsData.meta.pagination.pageSize}
                                />
                            </>
                        ) : (
                            <>No Chats Found</>
                        )}
                    </>
                ) : (
                    <Flex className="align-items-center justify-content-center flex-column">
                        <SimpleLoader size="50px" />
                        <Box className="mt-3">
                            Please wait, we are processing your data. This might take a few moments!
                        </Box>
                    </Flex>
                )}
            </Row>
        </Box>
    )
}
export default CommunityChatsList
