import Constants from '../../../helpers/Constants'

export default function useFormFields({
    form: {
        control,
        formState: { errors },
        setValue
    },
    communityPost,
    formType = Constants.TYPE_ADD
}) {
    const fields = {
        title: {
            name: 'title',
            id: 'title',
            label: 'Title',
            placeholder: 'Enter Title',
            defaultValue: '',
            type: 'text',
            error: errors.title,
            control
        },
        media: {
            name: 'media',
            id: 'media',
            label: 'Media Image',
            placeholder: 'Select Media Image',
            defaultValue: '',
            type: 'file',
            error: errors.media,
            control
        },
        description: {
            name: 'description',
            id: 'description',
            label: 'Post Description',
            placeholder: 'Enter Post Description',
            type: 'ckeditor',
            isRequired: true,
            defaultValue: '',
            floating: true,
            rules: {
                required: 'Please enter the Post Description'
            },
            error: errors.description,
            control
        },
        isActive: {
            name: 'isActive',
            id: 'isActive',
            label: 'Is Active?',
            defaultValue: true,
            type: 'switch',
            error: errors.isActive,
            control
        }
    }

    // In case of edit setting all the default fields
    if (formType === Constants.TYPE_EDIT && communityPost) {
        setValue('title', communityPost.title)
        setValue('body', communityPost.description)
        setValue('isActive', communityPost.isActive)
    }

    return fields
}
