import React from 'react'
import { Navigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import { isLoggedIn, getUserType, getAdminType } from '../../user/userSlice'
import { useSelector } from 'react-redux'

function AdminProtectedRoute({ children, type = Constants.TYPE_ADMIN_ROUTE_ALLOWED_ALL }) {
    // Checking if user is logged in
    const isAuthenticated = useSelector(isLoggedIn)
    // Getting current user type
    const userType = useSelector(getUserType)
    // Getting admin type
    const adminType = useSelector(getAdminType)

    // If user is not authenticated, redirecting to login
    if (!isAuthenticated) return <Navigate to={Constants.ROUTE_ADMIN_LOGIN} />
    if (userType != Constants.TYPE_USER_ADMIN) return <Navigate to={Constants.ROUTE_CLIENT_HOME} />
    if (type != Constants.TYPE_ADMIN_ROUTE_ALLOWED_ALL)
        if (type != adminType) return <Navigate to={Constants.ROUTE_CLIENT_ADMIN_404} />

    return children
}

export default AdminProtectedRoute
