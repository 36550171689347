import React from 'react'
import { Avatar, Box, Flex } from '../../../../../../../../components/vanilla'

const Header = (props) => {
    const { own = false, user, createdAt, admin } = props
    // Function to get the name of the user
    const getUserName = () => {
        // If the user's name is available, return the name
        if (user.firstName && user.lastName)
            return `${user.firstName} ${user.middleName} ${user.lastName} ${admin ? '-Admin' : ''}`
        return 'companyName' in user ? user.companyName : 'Unknown User'
    }
    // Function to get the name prefix of the user
    const getNamePrefix = () => {
        if (user.firstName && user.lastName) return user.firstName[0]
        return 'companyName' in user ? user.companyName[0] : 'U'
    }
    return (
        <Flex className="mb-2 align-items-center">
            {own && (
                <>
                    <Box className="ms-3">
                        <span className="text-muted fs-7 mb-1">{createdAt}</span>
                        <span className="fs-5 fw-bold text-gray-900 mx-2">You</span>
                    </Box>
                    <Avatar
                        color="success"
                        type={user.avatarPath ? 'image' : 'text'}
                        size="35"
                        imagePath={user.avatarPath}
                        text={getNamePrefix()}
                    />
                </>
            )}
            {!own && (
                <>
                    <Avatar
                        type={user.avatarPath ? 'image' : 'text'}
                        size="35"
                        imagePath={user.avatarPath}
                        text={getNamePrefix()}
                    />
                    <Box className="ms-3">
                        <span className="fs-5 fw-bold text-gray-900 me-2">{getUserName()}</span>
                        <span className="text-muted mb-1">{createdAt}</span>
                    </Box>
                </>
            )}
        </Flex>
    )
}

const GeneralMessage = (props) => {
    const { message, own = false } = props
    return (
        <Flex className={`mb-10 justify-content-${own ? 'end' : 'start'}`}>
            <Flex className={`flex-column align-items-${own ? 'end' : 'start'}`}>
                <Header {...props} />
                <Box
                    className={[
                        'p-3 my-3 rounded text-dark fw-semibold mw-lg-400px',
                        own ? 'text-end bg-light-chat-primary' : 'text-start bg-light-chat-info'
                    ].join(' ')}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </Flex>
        </Flex>
    )
}

export default GeneralMessage
