import React from 'react'
import { Column, Input } from '../../vanilla'
import useSubscriptionFields from './useSubscriptionFields'
const SubscriptionFields = ({ form }) => {
    const { subscribedDuration } = useSubscriptionFields({ form })
    return (
        <>
            <Column className="col-sm-12 mb-6">
                <Input {...subscribedDuration} />
            </Column>
        </>
    )
}

export default SubscriptionFields
