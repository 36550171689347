import React from 'react'
import CardBasic from '../../Cards/CardBasic'
import { Anchor, Box, Button, Column, Flex, Heading, Row } from '../../vanilla'
import { FaCloudDownloadAlt, FaRegCalendarAlt, FaRegClock, FaRegComments, FaStaylinked } from 'react-icons/fa'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import { FcCancel } from 'react-icons/fc'
import { BsTrash } from 'react-icons/bs'
import Constants from '../../../helpers/Constants'
import moment from 'moment'

const InterviewCard = ({
    interview,
    userType,
    employerType,
    onChat,
    onInterviewCleared,
    onInterviewReject,
    onDelete,
    isVendor = false
}) => {
    const btnStyle = { fontSize: '12px' }

    /**
     * Function to check if the user can see the interview result button
     */
    const canDecideInterviewResult = () => {
        // Admins/JobSeeker cannot decide the interview result
        if (userType === Constants.TYPE_USER_ADMIN || userType === Constants.TYPE_USER_JOB_SEEKER) return false
        // If the result is not already in pending state (Based on interview type)
        if (
            interview.type === Constants.TYPE_INTERVIEW_HR &&
            interview.application.hrResult !== Constants.TYPE_RESULT_PENDING
        )
            return false
        if (
            interview.type === Constants.TYPE_INTERVIEW_TECHNICAL &&
            interview.application.interviewResult !== Constants.TYPE_RESULT_PENDING
        )
            return false
        // If the user is from panel, checking the access
        return !(
            employerType !== Constants.TYPE_USER_EMPLOYER_COMPANY &&
            !interview.application.interviewer.canDecideInterviewResult
        )
    }

    /**
     * Function to check if interview can be deleted
     */
    const canDeleteInterview = () => {
        if (userType !== Constants.TYPE_USER_ADMIN) return false
        return !(!interview.isCompleted && !interview.isCancelled)
    }

    return (
        <CardBasic cardClass={['mb-3 shadow-sm'].join(' ')}>
            <Row className="align-items-center">
                <Column className="col-md-4">
                    <Heading className="text-uppercase">{`${interview.application.jobSeeker.firstName} ${interview.application.jobSeeker.middleName} ${interview.application.jobSeeker.lastName}`}</Heading>
                </Column>
                <Column className="col-md-8">
                    <Flex className="justify-content-md-end">
                        <Anchor
                            href={interview.application.resume.resumePath}
                            target="_blank"
                            rel="noreferrer"
                            className="pe-2 border-end interview-utils d-flex align-items-center ime-text-warning">
                            <FaCloudDownloadAlt className="me-1" /> Resume
                        </Anchor>
                        <Box className="px-2 border-end interview-utils d-flex align-items-center">
                            <FaRegCalendarAlt className="me-1" />{' '}
                            {moment(interview.scheduleDateTime).format('ddd, MMMM DD')}
                        </Box>
                        <Box className="ps-2 interview-utils d-flex align-items-center">
                            <FaRegClock className="me-1" /> {moment(interview.scheduleDateTime).format('hh:mm A')}
                        </Box>
                    </Flex>
                </Column>
            </Row>

            <Row>
                <Column className="col-md-12">
                    <Heading>
                        {interview.application.job.positionName}, {interview.application.employer.companyName} (
                        {interview.type === Constants.TYPE_INTERVIEW_TECHNICAL ? 'TECHNICAL ROUND' : 'HR ROUND'})
                    </Heading>
                </Column>
            </Row>

            <Row className="mt-2">
                {canDecideInterviewResult() && !isVendor && (
                    <>
                        <Column className="col-md-4 mb-2">
                            <Button
                                style={btnStyle}
                                className="w-100"
                                color="success"
                                label="Interview Cleared"
                                icon={<FiCheckCircle />}
                                onClick={onInterviewCleared}
                                soft
                            />
                        </Column>
                        <Column className="col-md-4 mb-2">
                            <Button
                                style={btnStyle}
                                className="w-100"
                                color="warning"
                                label="Interview Reject"
                                icon={<FiXCircle />}
                                onClick={onInterviewReject}
                                soft
                            />
                        </Column>
                    </>
                )}

                {userType !== Constants.TYPE_USER_ADMIN && userType !== Constants.TYPE_USER_JOB_SEEKER && !isVendor && (
                    <Column className="col-md-4 mb-2">
                        <Button
                            style={btnStyle}
                            className="w-100"
                            color="danger"
                            label="Cancel Interview"
                            data-bs-toggle="modal"
                            data-bs-target={`#cancelModal${interview.interviewId}`}
                            icon={<FcCancel />}
                            soft
                        />
                    </Column>
                )}

                {userType !== Constants.TYPE_USER_JOB_SEEKER && !isVendor && (
                    <Column className="col-md-4 mb-2">
                        <Button
                            style={btnStyle}
                            className="w-100"
                            color="primary"
                            label="Feedback"
                            data-bs-toggle="modal"
                            data-bs-target={`#feedbackModal${interview.interviewId}`}
                            icon={<FaRegComments />}
                            soft
                        />
                    </Column>
                )}

                <Column className="col-md-4 mb-2">
                    <Button
                        style={btnStyle}
                        className="w-100"
                        color="success"
                        label="Chat"
                        icon={<FaRegComments />}
                        onClick={(e) => onChat(e)}
                        soft
                    />
                </Column>
                {interview.meetingLink && !isVendor && (
                    <Column className="col-md-4 mb-2">
                        <Button
                            className="w-100"
                            data-bs-toggle="modal"
                            data-bs-target={`#meetingLinkModal${interview.interviewId}`}
                            color="warning"
                            icon={<FaStaylinked />}
                            label="Meeting Link"
                        />
                    </Column>
                )}
                {canDeleteInterview() && (
                    <Column className="col-md-4 mb-2">
                        <Button
                            style={btnStyle}
                            className="w-100"
                            color="danger"
                            label="Delete"
                            icon={<BsTrash />}
                            onClick={onDelete}
                            soft
                        />
                    </Column>
                )}
            </Row>
        </CardBasic>
    )
}

export default InterviewCard
