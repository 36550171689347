import React from 'react'
import { Flex, Avatar, Box, Heading, Badge } from '../../../vanilla'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getUserType } from '../../../../features/user/userSlice'
import Constants from '../../../../helpers/Constants'
import ReactTooltip from 'react-tooltip'
import SubHRTooltip from '../../../SubHRTooltip'
import { Link } from 'react-router-dom'
import { BsPencilSquare } from 'react-icons/bs'
import moment from 'moment'

const CandidateDetails = ({ textStyle, jobSeeker, isBestFit, servingNoticePeriod }) => {
    // Getting the user type
    const userType = useSelector(getUserType)

    return (
        <>
            <Flex className="align-items-center">
                <Avatar
                    size="xxl"
                    src={jobSeeker.avatarPath !== '' ? jobSeeker.avatarPath : '/assets/img/user/default-avatar.png'}
                />
                <Box className="ms-4">
                    <Heading className="text-purple mb-0 text-uppercase">
                        {`${jobSeeker.firstName} ${jobSeeker.middleName} ${jobSeeker.lastName}`}{' '}
                        {userType === Constants.TYPE_USER_ADMIN && (
                            <Link to={Constants.ROUTE_ADMIN_MANAGE_JOB_SEEKER_PROFILE(jobSeeker.id)}>
                                <BsPencilSquare size={15} />
                            </Link>
                        )}
                        <SubHRTooltip jobSeeker={jobSeeker} />
                    </Heading>
                    <ReactTooltip id="subhrTooltip" className="tooltip" html={true} />
                    <Box className="mb-0" style={textStyle}>
                        <b>Mobile:</b> {jobSeeker.phoneNumber}
                    </Box>
                    <Box className="mb-0" style={{ fontSize: '11px' }}>
                        <b>Email:</b> {jobSeeker.email}
                    </Box>
                    <Box className="mb-0" style={textStyle}>
                        <b>Exp:</b>{' '}
                        {jobSeeker.profile.tagExperience !== '' ? `${jobSeeker.profile.tagExperience} Yrs` : 'NA'}
                    </Box>
                </Box>
            </Flex>

            <Box className="m-1 mt-2">
                {userType !== Constants.TYPE_USER_ADMIN_MAIN && isBestFit && <Badge color="success">BEST FIT</Badge>}
                {userType === Constants.TYPE_USER_EMPLOYER && servingNoticePeriod ? (
                    <Badge color="danger ms-2">SERVING NOTICE PERIOD</Badge>
                ) : (
                    ''
                )}
            </Box>

            <Box className="mb-0 mt-2" style={textStyle}>
                <b>Current/Expected CTC:</b>{' '}
                {`${jobSeeker.profile.currentCTC ? jobSeeker.profile.currentCTC : 'NA'}/${
                    jobSeeker.profile.ExpectedCTC ? jobSeeker.profile.ExpectedCTC : 'NA'
                } LPA`}
            </Box>
            <Box className="mb-0" style={textStyle}>
                <b>Notice Period:</b> {jobSeeker.profile.noticePeriod ? `${jobSeeker.profile.noticePeriod} days` : 'NA'}
            </Box>
            <Box className="mb-0" style={textStyle}>
                <b>Current Location:</b> {jobSeeker?.profile?.tagLocation ? jobSeeker.profile.tagLocation : 'NA'}
            </Box>
            <Box className="mb-0" style={textStyle}>
                <b>LWD:</b>{' '}
                {jobSeeker.profile.lastWorkDay !== ''
                    ? moment(jobSeeker.profile.lastWorkDay).format('DD-MM-YYYY')
                    : 'NA'}
            </Box>
            <Box className="mb-0" style={textStyle}>
                <b>Skills:</b>{' '}
                {jobSeeker.profile.majorSkills.length !== 0 ? (
                    <>{jobSeeker.profile.majorSkills.join(', ')}</>
                ) : (
                    <>No Skills available</>
                )}
            </Box>
        </>
    )
}

CandidateDetails.propTypes = {
    textStyle: PropTypes.object
}

CandidateDetails.defaultProps = {
    textStyle: {}
}

export default CandidateDetails
