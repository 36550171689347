import Constants from '../../helpers/Constants'
import { request } from '../../helpers/axios-utils'

export const getApplicantsByStatus = async ({ status, page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_APPLICANTS_BY_STATUS}?status=${status}&page=${page}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getAdminApplicationTimeline = async (id) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_APPLICATION_TIMELINE({ id })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const addAdminMsgToTimeline = async (data) => {
    const { id } = data
    delete data.id
    await request({
        url: `${Constants.API_ADMIN_GET_APPLICATION_TIMELINE({ id })}`,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
}

export const getSubHrApplicants = async ({
    subHRId,
    page,
    name,
    mobileNumber,
    currentLocation,
    minCTC,
    maxCTC,
    minNoticePeriod,
    maxNoticePeriod,
    skillIsMandatory,
    skills,
    tagId,
    isPool = false
}) => {
    // Getting access token
    const response = await request({
        url: `${
            Constants.API_ADMIN_GET_JOB_SEEKERS
        }?subHRId=${subHRId}&page=${page}&name=${name}&mobileNumber=${mobileNumber}&currentLocation=${currentLocation}&minCTC=${minCTC}&maxCTC=${maxCTC}&minNoticePeriod=${minNoticePeriod}&maxNoticePeriod=${maxNoticePeriod}&skillIsMandatory=${
            skillIsMandatory ? 1 : 0
        }&skills=${skills}&tagId=${tagId}&isPool=${isPool}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const UpdateJobSeekerNoticePeriod = async ({ data }) => {
    return await request({
        url: Constants.API_JOB_SEEKER_SERVE_NOTICE_PERIOD(data.applicationId),
        method: Constants.METHOD_PATCH,
        data: { servingNoticePeriod: data.servingNoticePeriod },
        requiresAuth: true
    })
}

export const createNewAdmin = async (data) => {
    return await request({
        url: Constants.API_ADMIN_CREATE_ADMIN,
        method: Constants.METHOD_POST,
        data: {
            ...data,
            phoneNumber: `+91${data.phoneNumber}`
        },
        requiresAuth: true
    })
}

export const editAdmin = async ({ adminId, data }) => {
    return await request({
        url: Constants.API_ADMIN_EDIT_ADMIN({ adminId }),
        method: Constants.METHOD_PATCH,
        data: {
            ...data,
            phoneNumber: `+91${data.phoneNumber}`
        },
        requiresAuth: true
    })
}

/**
 * useQuery to get all the sub-hrs
 */
export const getSubHRs = async ({ page, name, phNumber }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_SUB_HRS}?page=${page}&name=${name}&phNumber=${phNumber}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

/**
 * useMutation to map sub-hr with job-seeker
 */
export const mapSubHRToJs = async (data) => {
    await request({
        url: `${Constants.API_ADMIN_MAP_SUB_HR_JS({ jobSeekerId: data.jobSeekerId, subHRId: data.subHRId })}`,
        method: Constants.METHOD_PATCH,
        requiresAuth: true
    })
}

/**
 * useMutation to update SubHR status
 */
export const updateSubHRStatus = async ({ id, status }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_UPDATE_SUB_HR_STATUS({ id })}`,
        method: Constants.METHOD_PATCH,
        data: {
            isActive: status
        },
        requiresAuth: true
    })

    return response?.data
}

/**
 * Use mutation to update employer access to jobSeeker status
 */
export const updateEmployerAccessToJobSeeker = async ({ data }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_UPDATE_EMPLOYER_ACCESS_TO_JOBSEEKER_STATUS({ employerId: data.employerId })}`,
        method: Constants.METHOD_PATCH,
        data: {
            canAccessJobSeekers: data.canAccessJobSeekers
        },
        requiresAuth: true
    })
    return response?.data
}

/**
 * Function to call API to change employer status as active or inactive
 */
export const updateEmployerStatus = async ({ data }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_UPDATE_EMPLOYER_STATUS({ employerId: data.employerId })}`,
        method: Constants.METHOD_PATCH,
        data: {
            isActive: data.isActive
        },
        requiresAuth: true
    })
    return response?.data
}

/**
 * @param page
 * @returns {Promise<any>}
 */
export const updateJobSeekerIsPoolStatus = async ({ id, isPool }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_UPDATE_JOB_SEEKER_IS_POOL_STATUS({ jobSeekerId: id })}`,
        method: Constants.METHOD_PATCH,
        data: {
            isPool: isPool
        },
        requiresAuth: true
    })
    return response?.data
}

/**update jobSeeker status */
export const updateJobSeekerStatus = async (data) => {
    const response = await request({
        url: `${Constants.API_ADMIN_UPDATE_JOB_SEEKER_STATUS({ jobSeekerId: data.id })}`,
        method: Constants.METHOD_PATCH,
        data: {
            isActive: data.isActive
        },
        requiresAuth: true
    })
    return response?.data
}

export const getNonVerifiedJobSeekers = async ({ page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_NON_VERIFIED_JOB_SEEKERS}?page=${page}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getEmailLogs = async ({ page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_EMAIL_LOGS}?page=${page}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getEmailLogById = async ({ id }) => {
    const response = await request({
        url: Constants.API_ADMIN_GET_EMAIL_LOG({ id }),
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getSidebarData = async ({ fromEdit = false, adminId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_SIDEBAR_DATA}${fromEdit ? `/${adminId}` : ''}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getAllSidebarData = async () => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_ALL_SIDEBAR_URLS}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getAllAdmins = async ({ page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_ALL_ADMINS}?page=${page}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

export const getAdmin = async ({ adminId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_ADMIN({ adminId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

/**
 * useQuery to get all the blogs
 */
export const getBlogList = async ({ title, page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_BLOGS}?page=${page}&title=${title}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const getBlog = async ({ blogId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_BLOG({ blogId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

// API call to add blog
export const addBlog = async (data) => {
    return await request({
        url: Constants.API_ADMIN_ADD_BLOG,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
}

// API call to update blog
export const updateBlog = async ({ blogId, data }) => {
    return await request({
        url: Constants.API_EMPLOYER_UPDATE_BLOG({ blogId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

/**
 * useQuery to get all the sitemaps
 */
export const getSitemapList = async ({ name, page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_SITEMAPS}?page=${page}&name=${name}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const getSitemap = async ({ sitemapId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_SITEMAP({ sitemapId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const getAllSitemaps = async () => {
    const response = await request({
        url: Constants.API_ADMIN_GET_ALL_SITEMAPS,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

// API call to add sitemap
export const addSitemap = async (data) => {
    return await request({
        url: Constants.API_ADMIN_ADD_SITEMAP,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
}

// API call to update sitemap
export const updateSitemap = async ({ sitemapId, data }) => {
    return await request({
        url: Constants.API_EMPLOYER_UPDATE_SITEMAP({ sitemapId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

/**
 * useQuery to get all the footer groups
 */
export const getFooterList = async ({ title, page, isActive = '' }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_FOOTERS}?page=${page}&title=${title}&isActive= ${isActive}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const getFooter = async ({ footerId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_FOOTER({ footerId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

// API call to add footer group
export const addFooter = async (data) => {
    return await request({
        url: Constants.API_ADMIN_ADD_FOOTER,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
}

// API call to update footer group
export const updateFooter = async ({ footerId, data }) => {
    return await request({
        url: Constants.API_EMPLOYER_UPDATE_FOOTER({ footerId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

// api call to update company highlights
export const updateCompanyHighlights = async ({ employerId, data }) => {
    return await request({
        url: Constants.API_ADMIN_UPDATE_COMPANY_HIGHLIGHTS({ employerId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

// API: Add subscription status for staffing agency
export const addSubscriptionStatus = async ({ employerId, data }) => {
    return await request({
        url: Constants.API_ADMIN_ADD_SUBSCRIPTION_STATUS({ employerId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

//API: Get terms and conditions
export const getTermsConditions = async () => {
    const response = await request({
        url: `${Constants.ROUTE_ADMIN_TERMS_CONDITIONS}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

//API: Update terms and conditions
export const updateTermsConditions = async ({ data }) => {
    return await request({
        url: `${Constants.ROUTE_ADMIN_TERMS_CONDITIONS}`,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
}

/**
 * useQuery to get all the community post based on search condition and pagination
 */
export const getCommunityPostList = async ({ title, page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_COMMUNITY_POSTS}?page=${page}&title=${title}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

// API to add community post
export const addCommunityPost = async (data) => {
    return await request({
        url: Constants.API_ADMIN_ADD_COMMUNITY_POST,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
}

// API call to update community post
export const updateCommunityPost = async ({ postId, data }) => {
    return await request({
        url: Constants.API_ADMIN_UPDATE_COMMUNITY_POST({ postId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

/** Function to get community post */
export const getCommunityPost = async ({ postId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_COMMUNITY_POST({ postId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

export const getCommunityChatsList = async ({ page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_COMMUNITY_CHATS}?page=${page}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}
/**
 * useQuery to get all the advertisement based on search condition and pagination
 */
export const getAdvertisementsList = async ({ companyName, page }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_ADVERTISEMENTS}?page=${page}&companyName=${companyName}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })

    return response?.data
}

// API to add advertisement
export const addAdvertisement = async (data) => {
    return await request({
        url: Constants.API_ADMIN_ADD_ADVERTISEMENT,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

//function to add media file and return data
export const addMediaFile = async (data) => {
    const response = await request({
        url: Constants.API_ADMIN_ADD_ADVERTISEMENT_MEDIA,
        method: Constants.METHOD_POST,
        data,
        requiresAuth: true
    })
    return response?.data
}

// API call to update advertisement
export const updateAdvertisement = async ({ adsId, data }) => {
    return await request({
        url: Constants.API_ADMIN_UPDATE_ADVERTISEMENT({ adsId }),
        method: Constants.METHOD_PATCH,
        data,
        requiresAuth: true
    })
}

/** Function to get advertisement */
export const getAdvertisement = async ({ adsId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_ADVERTISEMENT({ adsId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}

//API to get all chat messages by Chat ID
export const getChatMessages = async ({ chatId }) => {
    const response = await request({
        url: `${Constants.API_ADMIN_GET_CHAT_MESSAGES({ chatId })}`,
        method: Constants.METHOD_GET,
        requiresAuth: true
    })
    return response?.data
}
