import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { addNewJobSeeker } from '../../../../api/Admin'
import BadRequestError from '../../../../errors/BadRequestError'
import ValidationError from '../../../../errors/ValidationError'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import { Column, Row, Box } from '../../../../components/vanilla'
import CreatableSelect from '../../../../components/elements/CreatableSelect'

function AddNewJobSeeker({ isPool = false }) {
    // Data Vars
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [totalExperience, setTotalExperience] = useState('')
    const [tagLocation, setTagLocation] = useState('')
    const [phPrefix, setPhPrefix] = useState('+91')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [majorSkills, setMajorSkills] = useState([])
    const [resumeTitle, setResumeTitle] = useState('')
    const [resumeFile, setResumeFile] = useState('')

    // Loader vars
    const [addLoading, setAddLoading] = useState(false)

    // Initialize navigate here
    const navigate = useNavigate()

    /** Function to handle major skills callback */
    const majorSkillsCallback = (skills) => {
        setMajorSkills(skills)
    }

    const handleTypeSelect = (e) => {
        const copy = [...majorSkills]
        copy.push(e)
        setMajorSkills(copy)
    }

    const handleTypeRemove = (e) => {
        const copy = [...majorSkills]
        let index = copy.indexOf(e)
        copy.splice(index, 1)
        setMajorSkills(copy)
    }

    // Styles for react-select
    const selectStyles = {
        control: (styles) => ({ ...styles, fontSize: '1.6rem' }),
        option: (styles) => ({ ...styles, fontSize: '1.6rem' })
    }

    /** Function to handle submit */
    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            // Setting the loading here
            setAddLoading(true)

            //set technical skills to be added while create profile
            let majorSkillArr = []
            majorSkills.forEach((skill) => {
                majorSkillArr.push(skill.value)
            })

            // Validating the data here
            if (firstName == '') throw new ValidationError(Constants.MSG_EMPTY_FIRST_NAME)
            if (lastName == '') throw new ValidationError(Constants.MSG_EMPTY_LAST_NAME)
            if (email == '' || !Utils.validateEmail(email)) throw new ValidationError(Constants.MSG_INVALID_EMAIL)
            if (phoneNumber == '' || !Utils.validateMobileNumber(phoneNumber))
                throw new ValidationError(Constants.MSG_INVALID_MOBILE_NUMBER)

            if (!totalExperience) throw new ValidationError(Constants.MSG_EMPTY_TOTAL_EXP)
            if (!tagLocation) throw new ValidationError(Constants.MSG_REQUIRED__LOCATION)
            if (majorSkillArr.length === 0) throw new ValidationError(Constants.MSG_EMPTY_MAJOR_SKILLS)

            if (resumeTitle === '') throw new ValidationError(Constants.MSG_EMPTY_RESUME_TITLE)
            if (resumeFile === '') throw new ValidationError(Constants.MSG_EMPTY_RESUME)

            // Create multipart/formData
            const data = new FormData()
            data.append('resumeTitle', resumeTitle)
            data.append('resumeFile', resumeFile)
            data.append('firstName', firstName)
            data.append('middleName', middleName)
            data.append('lastName', lastName)
            data.append('email', email)
            data.append('totalExperience', totalExperience)
            data.append('tagLocation', tagLocation)
            data.append('majorSkills', JSON.stringify(majorSkillArr))
            data.append('phoneNumber', `${phPrefix}${phoneNumber}`)
            data.append('password', 'example@123')
            data.append('referralCode', '')
            data.append('isPool', isPool)

            // Calling the API here
            await addNewJobSeeker(data)

            // Setting the loading here
            setAddLoading(false)
            // Success toast here
            Utils.showToast(
                isPool ? Constants.MSG_POOL_USERS_ADD_SUCCESS_ADMIN : Constants.MSG_JOB_SEEKER_ADD_SUCCESS_ADMIN,
                Constants.TYPE_TOAST_SUCCESS
            )
            // Navigating to employers list page
            navigate(
                isPool ? Constants.ROUTE_ADMIN_MANAGE_LIST_POOL_USERS : Constants.ROUTE_ADMIN_MANAGE_LIST_JOB_SEEKERS
            )
        } catch (error) {
            // Setting the loading here
            setAddLoading(false)

            if (error instanceof ValidationError || error instanceof BadRequestError)
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        }
    }

    return (
        <Row className="admin-container mb-4">
            <h3>Add New {isPool ? 'Pool' : ''} Job-Seeker</h3>

            <form onSubmit={(e) => handleSubmit(e)}>
                {/* Full Name */}
                <Row className="mb-3">
                    <Box element="label" htmlFor="companyName" className="col-sm-4 col-form-label">
                        Name <span className="red-text">*</span>
                    </Box>
                    <Column className="col-sm-8">
                        <Row>
                            <Column className="col-md-4 p-0">
                                <input
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    placeholder="First Name"
                                />
                            </Column>
                            <Column className="col-md-4">
                                <input
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    value={middleName}
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    placeholder="Middle Name (Optional)"
                                />
                            </Column>
                            <Column className="col-md-4 p-0">
                                <input
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    placeholder="Last Name"
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
                {/* End Full Name */}

                {/* Email */}
                <Row className="mb-3 ">
                    <Box Element={'label'} htmlFor="companyEmail" className="col-sm-4 col-form-label">
                        Email <span className="red-text">*</span>
                    </Box>
                    <Column className="col-sm-8 col-4">
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            type="email"
                            className="form-control"
                            id="companyEmail"
                            placeholder="Enter email address"
                        />
                    </Column>
                </Row>
                {/* End Email */}

                {/* Mobile Number */}
                <Row className="mb-3">
                    <Box Element={'label'} htmlFor="companyMobile" className="col-sm-4 col-form-label">
                        Phone Number <span className="red-text">*</span>
                    </Box>
                    <Column className="col-sm-3 col-4">
                        <select
                            onChange={(e) => setPhPrefix(e.target.value)}
                            value={phPrefix}
                            className="form-select"
                            id="companyMobilePrefix"
                            aria-label=".form-select example">
                            {Utils.getMobileNumPrefixes().map((prefix, index) => (
                                <option key={index}>{prefix}</option>
                            ))}
                        </select>
                    </Column>

                    <Column className="col-sm-5 col-8 ">
                        <input
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            value={phoneNumber}
                            type="number"
                            placeholder="xxxxxxxxxx"
                            className="form-control"
                            id="companyMobile"
                        />
                    </Column>
                </Row>
                {/* End Mobile Number */}

                {/* total experience */}
                <Row className="row mb-3">
                    <Box Element={'label'} htmlFor="totalExperience" className="col-sm-4 col-form-label">
                        Total Experience (in years) <span className="red-text">*</span>
                    </Box>
                    <Column className="col-sm-8">
                        <input
                            onChange={(e) => setTotalExperience(e.target.value)}
                            value={totalExperience}
                            type="number"
                            className="form-control"
                            placeholder="Enter Total Experience"
                        />
                    </Column>
                </Row>

                {/* tag location of job seeker */}
                <Row className="row mb-3">
                    <Box Element={'label'} htmlFor="tagLocation" className="col-sm-4 col-form-label">
                        Location <span className="red-text">*</span>
                    </Box>
                    <Column className="col-sm-8">
                        <input
                            onChange={(e) => setTagLocation(e.target.value)}
                            value={tagLocation}
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"
                        />
                    </Column>
                </Row>

                {/* Technical Skills */}
                <Row className="mb-3">
                    <label htmlFor="majorSkills" className="col-sm-4 col-form-label">
                        Technical Skills <span className="red-text">*</span>
                    </label>
                    <Column className="col-sm-8">
                        <CreatableSelect
                            styles={selectStyles}
                            id="majorSkillsUpdate"
                            maxSelectCount={Constants.MAJOR_SKILLS_MAX_LENGTH}
                            callback={majorSkillsCallback}
                            initialValues={majorSkills}
                            options={Constants.LIST_DEFAULT_TECH_SKILLS}
                            placeholder="Please search for a skill and select to add"
                        />
                    </Column>
                </Row>

                <Row className="mb-3">
                    <Box Element="label" className="col-sm-4 col-form-label" htmlFor="upload_resume">
                        Upload Resume <span className="red-text">*</span>
                    </Box>
                    <Column className="col-md-3">
                        <input
                            type="text"
                            onChange={(e) => setResumeTitle(e.target.value)}
                            name="resumeTitle"
                            placeholder="Enter Resume Title"
                            className="form-control"
                        />
                    </Column>
                    <Column className="col-md-5">
                        <input
                            type="file"
                            onChange={(e) => setResumeFile(e.target.files[0])}
                            name="resume-file"
                            className="form-control"
                        />
                    </Column>
                </Row>

                <Row className=" mb-3">
                    <Column className="col-sm-8 offset-sm-4">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={(e) => handleSubmit(e)}
                            disabled={addLoading}>
                            {addLoading ? (
                                <>
                                    <i className="fas fa-circle-notch fa-spin"></i> Adding...
                                </>
                            ) : (
                                <>Add {isPool ? 'Pool' : ''} Job-Seeker</>
                            )}
                        </button>
                    </Column>
                </Row>
            </form>
        </Row>
    )
}

export default AddNewJobSeeker
