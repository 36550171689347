import React from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'

const Container = ({ children, className, style, isFluid, ...props }) => {
    // If the container is for fluid
    className += isFluid ? ' container-fluid' : ' container'

    return (
        <Box className={className} style={style} {...props}>
            {children}
        </Box>
    )
}

Container.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any.isRequired,
    isFluid: PropTypes.bool
}

Container.defaultProps = {
    className: '',
    isFluid: false
}

export default Container
