import React from 'react'
import { Link } from 'react-router-dom'
import Constants from '../helpers/Constants'

function NotFound() {
    return (
        <div className="container mt-5 d-flex flex-column align-items-center">
            <h2>404</h2>
            <p>Sorry, the page you're looking for cannot be found.</p>
            <div>
                <Link to={Constants.ROUTE_CLIENT_HOME} className="btn btn-primary me-2">
                    Go Home
                </Link>
                <Link to={Constants.ROUTE_CLIENT_HOME} className="btn btn-secondary ms-2">
                    See All Jobs
                </Link>
            </div>
        </div>
    )
}

export default NotFound
