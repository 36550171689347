import Constants from '../../helpers/Constants'
import axios from 'axios'

// CMS host
const host = process.env.REACT_APP_CMS_URL || 'http://localhost:1337/api'

export const getSiteMap = async ({ isFooterURL }) => {
    const response = await axios.get(`${host}${Constants.CMS_GET_SITEMAP}?isFooterURL=${isFooterURL}`)
    return response?.data
}

/**
 * useQuery to get all the slug page details
 */
export const getSlugPageData = async ({ slug }) => {
    const response = await axios.get(`${host}${Constants.CMS_GET_SITEMAP_SLUG_DATA({ slug })}`)
    return response?.data
}
