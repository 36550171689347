import Constants from '../../../../../../helpers/Constants'

export default function useAuthFields({
    form: {
        control,
        formState: { errors }
    }
}) {
    return {
        duration: {
            name: 'duration',
            id: 'duration',
            label: 'Duration',
            placeholder: 'Provide duration (in Months).',
            defaultValue: '',
            type: 'number',
            rules: {
                required: 'Please enter duration'
            },
            error: errors['duration'],
            control
        },
        doj: {
            name: 'doj',
            id: 'doj',
            label: 'Date Of Joining',
            placeholder: 'DOJ (in dd-mm-yyyy format)',
            defaultValue: '',
            type: 'text',
            rules: {
                required: 'Please enter Date of joining',
                pattern: {
                    value: Constants.REGEX_VALID_DATE,
                    message: 'Please enter a valid date'
                }
            },
            error: errors['doj'],
            control
        },
        renumeration: {
            name: 'renumeration',
            id: 'renumeration',
            label: 'Renumeration',
            placeholder: 'Provide renumeration  (in Rupees).',
            defaultValue: '',
            type: 'number',
            rules: {
                required: 'Please enter Renumeration'
            },
            error: errors['renumeration'],
            control
        },
        commission: {
            name: 'commission',
            id: 'commission',
            label: 'Commission',
            placeholder: 'Provide commision (in percentage).',
            defaultValue: '',
            type: 'number',
            rules: {
                required: 'Please enter Commission'
            },
            error: errors['commission'],
            control
        }
    }
}
