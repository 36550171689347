import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllJobSeekerApplications } from '../../../../api/Admin'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import { getUserType } from '../../../../features/user/userSlice'

function JobSeekersApplications() {
    // Init dispatch here
    const dispatch = useDispatch()
    const userType = useSelector(getUserType)

    // Getting the job-seeker id
    const jobSeekerId = useParams().jobSeekerId

    // Data Vars
    const [applications, setApplications] = useState([])

    useEffect(() => {
        /** Function to get all the job seeker applications */
        const getApplications = async () => {
            try {
                // Setting the loading here
                dispatch(setLoadingState(true))
                const response = await getAllJobSeekerApplications(jobSeekerId, userType)

                setApplications(response)
            } catch (error) {
                Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
            } finally {
                // Setting the loading here
                dispatch(setLoadingState(false))
            }
        }

        getApplications()
    }, [])
    return (
        <div className="container my-4">
            <h3 className="mb-4">Job Seeker Applications</h3>

            {applications.length > 0 ? (
                <>
                    <div className="row">
                        {applications.map((application) => (
                            <div className="col-md-3" key={application.job.id}>
                                <div className="card mb-4">
                                    <div className="card-body p-0">
                                        <div className="p-2 text-center bg-success text-white">
                                            <b>Current Status: </b>{' '}
                                            {Utils.jobAppDisplayStatusText({
                                                status: application.status,
                                                hrResult: application.hrResult,
                                                interviewResult: application.interviewResult,
                                                jobSchedulesTechnical: application.jobSchedulesTechnical,
                                                offerAccepted: application.offerAccepted,
                                                totalTechnicalCount: application.totalTechnicalCount
                                            })}
                                            {application.status === Constants.STATUS_JOB_APP_REJECT && (
                                                <>
                                                    <br />
                                                    <div>({application.rejectReason})</div>
                                                </>
                                            )}
                                        </div>
                                        <div className="p-3">
                                            <h4>
                                                <i className="fas fa-briefcase"></i> {application.job.positionName}
                                            </h4>
                                            {application.job.employer.companyName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <h5>No Applications yet!</h5>
            )}
        </div>
    )
}

export default JobSeekersApplications
