import Constants from '../../helpers/Constants'
import axios from 'axios'
import { getAccessToken } from '../../api/Auth'

export const sendBuyServiceContactForm = async (data) => {
    return await axios.post(Constants.API_BUY_SERVICE_CONTACT_FORM_ENQUIRY, data)
}

export const sendContactForm = async (data) => {
    return await axios.post(Constants.API_SUBMIT_CONTACT_ENQUIRY, data)
}

export const sendSubHrContactForm = async (data) => {
    const accessToken = await getAccessToken()
    return await axios.post(Constants.API_SUBMIT_SUB_HR_FORM, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
}

export const sendCRMMailForm = async (data) => {
    const accessToken = await getAccessToken()
    return await axios.post(Constants.API_SUBMIT_CRM_MAIL_FORM, data, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
}
