import React, { useEffect } from 'react'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
import { useDispatch } from 'react-redux'
import BadRequestError from '../../../errors/BadRequestError'
import UnauthorizedError from '../../../errors/UnauthorizedError'
import ValidationError from '../../../errors/ValidationError'
import { Box } from '../../../components/vanilla'
import { validateIdpToken, setUserDataToLocalStorage } from '../../../api/Auth'
import { useNavigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import { setUserProfile } from '../../../features/user/userSlice'

function AdminLoginCallback() {
    // Init dispatch here
    const dispatch = useDispatch()
    // Init navigate here
    const navigate = useNavigate()

    //get IDP token from URL search
    let search = window.location.search
    let params = new URLSearchParams(search)
    const idpToken = params.get('idpToken')
    const type = params.get('type')
    const continueUri = params.get('continueUri')

    useEffect(() => {
        handleAdminLogin()
    }, [])

    const handleAdminLogin = async () => {
        try {
            // Validate if empty then redirect to admin login page
            if (idpToken === '' || type === '') navigate(Constants.ROUTE_ADMIN_LOGIN)
            // Setting the loading here
            dispatch(setLoadingState(true))
            const userResponse = await validateIdpToken({
                idpToken,
                type
            })
            // setting redux details
            dispatch(
                setUserProfile({
                    id: userResponse.user.id,
                    email: userResponse.user.email,
                    type: Constants.TYPE_USER_ADMIN,
                    phoneNumber: userResponse.user.phoneNumber,
                    adminType: userResponse.user.adminType,
                    isSuperAdmin: userResponse.user.isSuperAdmin,
                    referralCode: userResponse.user.referralCode,
                    firstName: userResponse.user.firstName,
                    middleName: userResponse.user.middleName,
                    lastName: userResponse.user.lastName,
                    avatarPath: userResponse.user.avatarPath,
                    isActive: userResponse.user.isActive,
                    isEmailVerified: userResponse.user.isEmailVerified,
                    isMobileVerified: userResponse.user.isMobileVerified,
                    isVendor: Constants.TYPE_USER_EMPLOYER ? userResponse.user.isVendor : '',
                    vendorType: Constants.TYPE_USER_EMPLOYER ? userResponse.user.vendorType : '',
                    accessToken: userResponse.accessToken
                })
            )

            // Setting the user data to local storage
            await setUserDataToLocalStorage(userResponse)
            dispatch(setLoadingState(false))
            //redirect to continue URI page return from URL
            navigate(continueUri)
        } catch (error) {
            if (
                error instanceof ValidationError ||
                error instanceof UnauthorizedError ||
                error instanceof BadRequestError
            )
                Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
            // Setting the loading here
            dispatch(setLoadingState(false))
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }
    return <Box className="text-center">Loading...</Box>
}
export default AdminLoginCallback
