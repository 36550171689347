import React from 'react'
import Image from '../Image'
import PropTypes from 'prop-types'
import Box from '../Box'

const Avatar = ({ isLabelled, label, color, soft, size, src, className, ...props }) => {
    // Setting avatar size
    let avatarSize = size !== '' ? `avatar-${size}` : ''
    let textColor = soft ? `text-${color}` : 'text-white'

    return (
        <Box className={['avatar', avatarSize, className].join(' ')}>
            {isLabelled ? (
                <Box
                    className={['avatar-img', 'rounded-circle', `bg-${color}`, `${soft ? 'bg-opacity-10' : ''}`].join(
                        ' '
                    )}>
                    <span
                        className={['position-absolute top-50 start-50 translate-middle fw-bold', textColor].join(' ')}>
                        {label}
                    </span>
                </Box>
            ) : (
                <Image src={src} className="avatar-img rounded-circle" alt="Avatar Image" {...props} />
            )}
        </Box>
    )
}

Avatar.propTypes = {
    isLabelled: PropTypes.bool,
    label: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'danger', 'warning', 'success', 'info', 'dark']),
    soft: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', '', 'lg', 'xl', 'xxl', 'xxxl']),
    src: PropTypes.elementType,
    className: PropTypes.string
}

Avatar.defaultProps = {
    isLabelled: false,
    label: 'LB',
    color: 'primary',
    size: '',
    src: '/assets/img/user/default-avatar.png',
    className: '',
    soft: false
}

export default Avatar
