import React from 'react'
import './App.css'
import './assets/css/style_new.css'
import 'react-datepicker/dist/react-datepicker.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import { isAuthenticated, getUserDataLocalStorage } from './api/Auth'
import { useDispatch } from 'react-redux'
import { setUserProfile } from './features/user/userSlice'

import Header from './components/Header'
import Footer from './components/Footer'
// import JobSeekerLogin from './views/job-seeker/auth/Login'
// import EmployerLogin from './views/employer/auth/Login'
import Home from './views/Index'
import NotFound from './views/NotFound'
// import PostNewJob from './views/employer/dashboard/job/Add'
// import JobList from './views/employer/dashboard/job/List'
// import ProspectList from './views/employer/dashboard/prospect/List'
// import AddProspect from './views/employer/dashboard/prospect/Add'
// import PanelList from './views/employer/dashboard/panel/List'
// import AddPanelMember from './views/employer/dashboard/panel/Add'
// import AddVendor from './views/employer/dashboard/vendor/Add'
// import EditVendor from './views/employer/dashboard/vendor/Edit'
// import VendorList from './views/employer/dashboard/vendor/List'
// import AddVendorJobSeeker from './views/Vendor/dashboard/job-seeker/Add'
// import VendorJobSeekerList from './views/Vendor/dashboard/job-seeker/List'
// import VendorEmployerJobList from './views/Vendor/dashboard/job-seeker/Job/List'
// import Interviews from './views/job-seeker/dashboard/Interviews'
// import Documents from './views/job-seeker/dashboard/Documents'
// import Profile from './views/job-seeker/Profile'
// import ProtectedRoute from './features/auth/ProtectedRoute'
import Constants from './helpers/Constants'
// import AuthRedirectRoute from './features/auth/AuthRedirectRoute'
// import EmployerRegister from './views/employer/auth/Register'
// import JobSeekerRegister from './views/job-seeker/auth/Register'
// import EmployerForgotPassword from './views/employer/auth/ForgotPassword'
// import JobSeekerForgotPassword from './views/job-seeker/auth/ForgotPassword'
import AdminIndex from './views/admin/Index'
import AdminLogin from './views/admin/auth/Login'
import AdminLoginCallback from './views/admin/auth/Callback'
import AdminProtectedRoute from './features/auth/admin/AdminProtectedRoute'
import AdminAuthRedirectRoute from './features/auth/admin/AdminAuthRedirectRoute'
import SideBar from './components/SideBar'
import AddJobs from './views/admin/jobs/Add'
import Loader from './components/Loader'
import ListJobs from './views/admin/jobs/List'
import EditJob from './views/admin/jobs/Edit'
// import EditJobEmployer from './views/employer/dashboard/job/Edit'
import EmployerList from './views/admin/manage/employer/List'
import AddNewEmployer from './views/admin/manage/employer/Add'
// import ApplyJob from './views/job-seeker/dashboard/job/Apply'
import AssignJobToCandidate from './views/admin/assignment/candidate/Job'
// import OfferLetterResponse from './views/job-seeker/response/OfferLetter'
// import InterviewResponse from './views/employer/response/InterviewResponse'
// import VerifyEmail from './views/common/VerifyEmail'
import JobSeekerList from './views/admin/manage/job-seeker/List'
import CampusJobSeekerList from './views/admin/manage/job-seeker/CampusList'
import JobSeekerNonVerified from './views/admin/job-seeker/NonVerified'
import AddNewJobSeeker from './views/admin/manage/job-seeker/Add'
import AdminRedirectRoute from './features/auth/admin/AdminRedirectRoute'
import JobSeekerProfileAdmin from './views/admin/manage/job-seeker/Profile'
import ListEmployerMember from './views/admin/manage/employer/member/List'
import AddEmployerMember from './views/admin/manage/employer/member/Add'
import AddHR from './views/admin/manage/admin/hr/Add'
import ListHR from './views/admin/manage/admin/hr/List'
import ListHrJobSeekers from './views/admin/manage/admin/hr/job-seeker/List'
import ListInterviewsEmployer from './views/employer/dashboard/interviews/List'
// import EmployerProfile from './views/employer/dashboard/Profile/Profile'
// import ChangePasswordEmployer from './views/employer/auth/ChangePassword/ChangePassword'
// import ChangePasswordJobSeeker from './views/job-seeker/dashboard/ChangePassword/ChangePassword'
import ChangePasswordAdmin from './views/admin/ChangePassword/ChangePassword'
import JobSeekersApplications from './views/admin/manage/job-seeker/Applications'
import TagsIndex from './views/admin/Tags'
import TagJobSeekers from './views/admin/Tags/components/JobSeekers'
import ForgotPassword from './views/admin/auth/ForgotPassword'
// import AboutUs from './views/AboutUs'
// import ContactUs from './views/ContactUs'
// import BuyServices from './views/BuyServices'
// import HiringSolutions from './views/HiringSolutions'
// import SiteMap from './views/SiteMap'
// import SlugPage from './views/SiteMap/SlugPage'
// import PoolUserProfile from './views/PoolUserProfile'
// import CampusUserRegistration from './views/CampusUserRegistration'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// import ChatBot from './components/ChatBot'
// import Schedule from './views/employer/dashboard/Schedule'
// import SearchResumeList from './views/employer/dashboard/prospect/SearchResumeList'
// import EmployerDashboard from './views/employer/dashboard/job/Dashboard'
import Applicants from './views/admin/Applicants'
import AddAdmin from './views/admin/manage/admin/Add'
import AdminList from './views/admin/manage/admin/List'
import EditAdmin from './views/admin/manage/admin/Edit'
// import { JobSeekerProfile } from './views/employer/dashboard/prospect/Profile'
import EmailLogList from './views/admin/EmailLogs/List'
import EmailLog from './views/admin/EmailLogs/Log'
import BlogList from './views/admin/blog/List'
import AddBlog from './views/admin/blog/Add'
import EditBlog from './views/admin/blog/Edit'
import FooterList from './views/admin/footer/List'
import AddFooter from './views/admin/footer/Add'
import EditFooter from './views/admin/footer/Edit'
import SitemapList from './views/admin/sitemap/List'
import AddSitemap from './views/admin/sitemap/Add'
import EditSitemap from './views/admin/sitemap/Edit'
import { InterviewChat } from './views/admin/interviews/Chat'
import TermsConditions from './views/admin/termsConditions/Index'
import CommunityPostList from './views/admin/communityPost/List'
import AddCommunityPost from './views/admin/communityPost/Add'
import EditCommunityPost from './views/admin/communityPost/Edit'
import CommunityPostDetails from './views/admin/communityPost/Details'
import CommunityChatsList from './views/admin/communityChats/List'
import CommunityChatMessages from './views/admin/communityChats/Messages'

import AdvertisementsList from './views/admin/advertisements/List'
import AddAdvertisements from './views/admin/advertisements/Add'
import EditAdvertisements from './views/admin/advertisements/Edit'

function App() {
    const dispatch = useDispatch()

    /** Set auth data in redux during initialization */
    if (isAuthenticated()) {
        const userData = getUserDataLocalStorage()
        dispatch(
            setUserProfile({
                id: userData.user.id,
                email: userData.user.email,
                type: userData.user.type,
                adminType: userData.user.adminType,
                isSuperAdmin: userData.user.isSuperAdmin,
                canViewDashboard: userData.user.canViewDashboard,
                canTogglePool: userData.user.canTogglePool,
                referralCode: userData.user.referralCode,
                employerType: userData.user.userType,
                companyName: userData.user.companyName,
                phoneNumber: userData.user.phoneNumber,
                firstName: userData.user.firstName,
                middleName: userData.user.middleName,
                lastName: userData.user.lastName,
                avatarPath: userData.user.avatarPath,
                isActive: userData.user.isActive,
                isEmailVerified: userData.user.isEmailVerified,
                isMobileVerified: userData.user.isMobileVerified,
                accessToken: userData.accessToken,
                isVendor: Constants.TYPE_USER_EMPLOYER ? userData.user.isVendor : '',
                vendorType: Constants.TYPE_USER_EMPLOYER ? userData.user.vendorType : ''
            })
        )
    }

    // Setting axios default configs
    const apiURL = process.env.REACT_APP_API_URL || 'http://localhost:3000'
    const apiVersion = process.env.REACT_APP_API_VER || '1'
    axios.defaults.baseURL = apiURL + '/api/v' + apiVersion
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

    // Create a client
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <div className="App">
                <Loader />
                <Router>
                    <Header />
                    <ToastContainer />
                    {/* Admin Sidebar */}
                    <SideBar />
                    {/* Chat Bot */}
                    {/* <ChatBot /> */}
                    <Routes>
                        {/* Not Found */}
                        <Route path="*" element={<NotFound />} />

                        {/* Admin Routes */}
                        <Route path="admin">
                            {/* Auth Routes */}
                            <Route path="auth">
                                <Route
                                    path="login"
                                    element={
                                        <AdminAuthRedirectRoute>
                                            <AdminLogin />
                                        </AdminAuthRedirectRoute>
                                    }
                                />
                                <Route
                                    path="callback"
                                    element={
                                        <AdminAuthRedirectRoute>
                                            <AdminLoginCallback />
                                        </AdminAuthRedirectRoute>
                                    }
                                />
                                <Route
                                    path="forgot-password"
                                    element={
                                        <AdminAuthRedirectRoute>
                                            <ForgotPassword />
                                        </AdminAuthRedirectRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path="home"
                                element={
                                    <AdminProtectedRoute>
                                        <AdminIndex />
                                    </AdminProtectedRoute>
                                }
                            />
                            <Route
                                path="applicants/:status"
                                element={
                                    <AdminProtectedRoute>
                                        <Applicants />
                                    </AdminProtectedRoute>
                                }
                            />
                            <Route
                                path="job-seeker/non-verified"
                                element={
                                    <AdminProtectedRoute>
                                        <JobSeekerNonVerified />
                                    </AdminProtectedRoute>
                                }
                            />
                            <Route path="jobs">
                                <Route
                                    path="add"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AddJobs />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <ListJobs />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":jobId/edit"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <EditJob />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="manage">
                                <Route path="employer">
                                    <Route
                                        path="list"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <EmployerList />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <AddNewEmployer />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route path="member">
                                        <Route path=":employerId">
                                            <Route
                                                path="list"
                                                element={
                                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                        <ListEmployerMember />
                                                    </AdminProtectedRoute>
                                                }
                                            />
                                            <Route
                                                path="add"
                                                element={
                                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                        <AddEmployerMember />
                                                    </AdminProtectedRoute>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="job-seeker">
                                    <Route
                                        path="list"
                                        element={
                                            <AdminProtectedRoute>
                                                <JobSeekerList />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <AdminProtectedRoute>
                                                <AddNewJobSeeker />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route path=":jobSeekerId">
                                        <Route
                                            path="profile"
                                            element={
                                                <AdminProtectedRoute>
                                                    <JobSeekerProfileAdmin />
                                                </AdminProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="applications"
                                            element={
                                                <AdminProtectedRoute>
                                                    <JobSeekersApplications />
                                                </AdminProtectedRoute>
                                            }
                                        />
                                    </Route>
                                </Route>
                                <Route path="pool-users">
                                    <Route
                                        path="list"
                                        element={
                                            <AdminProtectedRoute>
                                                <JobSeekerList isPool={true} />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <AdminProtectedRoute>
                                                <AddNewJobSeeker isPool={true} />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route path="campus-users">
                                    <Route
                                        path="list"
                                        element={
                                            <AdminProtectedRoute>
                                                <CampusJobSeekerList />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route path="hr">
                                    <Route path="job-seeker">
                                        <Route path=":subHrId">
                                            <Route
                                                path="list"
                                                element={
                                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                        <ListHrJobSeekers />
                                                    </AdminProtectedRoute>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                    <Route path="pool-users">
                                        <Route path=":subHrId">
                                            <Route
                                                path="list"
                                                element={
                                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                        <ListHrJobSeekers isPool={true} />
                                                    </AdminProtectedRoute>
                                                }
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="admin">
                                    <Route path="hr">
                                        <Route
                                            path="add"
                                            element={
                                                <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                    <AddHR />
                                                </AdminProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="list"
                                            element={
                                                <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                    <ListHR />
                                                </AdminProtectedRoute>
                                            }
                                        />
                                    </Route>
                                    <Route
                                        path="list"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <AdminList />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <AddAdmin />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path=":adminId/edit"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <EditAdmin />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route path="blog">
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <BlogList />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AddBlog />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":blogId/edit"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <EditBlog />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="advertisements">
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AdvertisementsList />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AddAdvertisements />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":adsId/edit"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <EditAdvertisements />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="community-post">
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <CommunityPostList />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AddCommunityPost />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":postId/edit"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <EditCommunityPost />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":postId/details"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <CommunityPostDetails />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="community-chats">
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <CommunityChatsList />
                                        </AdminProtectedRoute>
                                    }
                                />

                                <Route
                                    path=":chatId/messages"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <CommunityChatMessages />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path="terms-conditions"
                                element={
                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                        <TermsConditions />
                                    </AdminProtectedRoute>
                                }
                            />
                            <Route path="footer">
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <FooterList />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AddFooter />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":footerId/edit"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <EditFooter />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="sitemap">
                                <Route
                                    path="list"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <SitemapList />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="add"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <AddSitemap />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":sitemapId/edit"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <EditSitemap />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="assignment">
                                <Route path="candidate">
                                    <Route
                                        path="job"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <AssignJobToCandidate />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                    {/* Same route as above with extra route params */}
                                    <Route
                                        path="job/:jobId/application/:applicationId"
                                        element={
                                            <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                                <AssignJobToCandidate />
                                            </AdminProtectedRoute>
                                        }
                                    />
                                </Route>
                            </Route>
                            <Route
                                path="interviews"
                                element={
                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                        <ListInterviewsEmployer />
                                    </AdminProtectedRoute>
                                }
                            />
                            <Route
                                path="interviews/:applicationId/chat"
                                element={
                                    <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                        <InterviewChat />
                                    </AdminProtectedRoute>
                                }
                            />
                            <Route path="email">
                                <Route
                                    path="logs"
                                    element={
                                        <AdminProtectedRoute>
                                            <EmailLogList />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path="logs/:logId"
                                    element={
                                        <AdminProtectedRoute>
                                            <EmailLog />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="tags">
                                <Route
                                    path=""
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <TagsIndex />
                                        </AdminProtectedRoute>
                                    }
                                />
                                <Route
                                    path=":tagId/job-seekers"
                                    element={
                                        <AdminProtectedRoute type={Constants.TYPE_USER_ADMIN_MAIN}>
                                            <TagJobSeekers />
                                        </AdminProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path="change-password"
                                element={
                                    <AdminProtectedRoute>
                                        <ChangePasswordAdmin />
                                    </AdminProtectedRoute>
                                }
                            />
                        </Route>

                        {/* Index */}
                        <Route
                            path={Constants.ROUTE_CLIENT_HOME}
                            element={
                                <AdminRedirectRoute>
                                    <Home />
                                </AdminRedirectRoute>
                            }
                        />
                        {/* <Route path={Constants.ROUTE_CLIENT_ABOUT_US} element={<AboutUs />} />
                        <Route path={Constants.ROUTE_CLIENT_CONTACT_US} element={<ContactUs />} />
                        <Route path={Constants.ROUTE_CLIENT_BUY_SERVICES} element={<BuyServices />} />
                        <Route path={Constants.ROUTE_CLIENT_HIRING_SOLUTIONS} element={<HiringSolutions />} />
                        <Route path={Constants.ROUTE_CLIENT_SITEMAP} element={<SiteMap />} />
                        <Route path={Constants.ROUTE_CLIENT_SITEMAP_SLUG_PAGE} element={<SlugPage />} /> */}

                        {/* <Route
                            path={Constants.ROUTE_POOL_USER_PROFILE}
                            element={
                                <AuthRedirectRoute>
                                    <PoolUserProfile />
                                </AuthRedirectRoute>
                            }
                        /> */}
                        {/* <Route
                            path={Constants.ROUTE_CAMPUS_USER_PROFILE}
                            element={
                                <AuthRedirectRoute>
                                    <CampusUserRegistration />
                                </AuthRedirectRoute>
                            }
                        />
                        <Route
                            path={Constants.ROUTE_POOL_USER_PROFILE_REFERRAL}
                            element={
                                <AuthRedirectRoute>
                                    <PoolUserProfile />
                                </AuthRedirectRoute>
                            }
                        /> */}

                        {/* Auth Routes */}
                        {/* <Route path="auth"> */}
                        {/* Login Routes */}
                        {/* <Route path="login">
                                <Route
                                    path="user"
                                    element={
                                        <AuthRedirectRoute>
                                            <JobSeekerLogin />
                                        </AuthRedirectRoute>
                                    }
                                />
                                <Route
                                    path="employer"
                                    element={
                                        <AuthRedirectRoute>
                                            <EmployerLogin />
                                        </AuthRedirectRoute>
                                    }
                                />
                            </Route> */}

                        {/* Register Routes */}
                        {/* <Route path="register">
                                <Route
                                    path="user"
                                    element={
                                        <AuthRedirectRoute>
                                            <JobSeekerRegister />
                                        </AuthRedirectRoute>
                                    }
                                />
                                <Route
                                    path="employer"
                                    element={
                                        <AuthRedirectRoute>
                                            <EmployerRegister />
                                        </AuthRedirectRoute>
                                    }
                                />
                            </Route> */}

                        {/* Forgot Password Routes */}
                        {/* <Route path="forgot-password">
                                <Route
                                    path="user"
                                    element={
                                        <AuthRedirectRoute>
                                            <JobSeekerForgotPassword />
                                        </AuthRedirectRoute>
                                    }
                                />
                                <Route
                                    path="employer"
                                    element={
                                        <AuthRedirectRoute>
                                            <EmployerForgotPassword />
                                        </AuthRedirectRoute>
                                    }
                                />
                            </Route> */}
                        {/* </Route> */}

                        {/* Employer Routes */}
                        {/* <Route path="employer"> */}
                        {/* Dashboard Routes */}
                        {/* <Route path="dashboard">
                                <Route
                                    path="job-seeker/:jobSeekerId/profile"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} allowPanelist={false}>
                                            <JobSeekerProfile />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path="job">
                                    <Route
                                        path="add"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <PostNewJob />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="list"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <JobList />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path=":jobId/edit"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <EditJobEmployer />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="dashboard"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <EmployerDashboard />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="job-seekers"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                            <SearchResumeList />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path="prospect">
                                    <Route
                                        path="list"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <ProspectList />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <AddProspect />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route path="panel">
                                    <Route
                                        path="list"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <PanelList />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <AddPanelMember />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route path="vendor">
                                    <Route
                                        path="list"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <VendorList />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <AddVendor />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path=":vendorId/edit"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                                <EditVendor />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="interviews"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} allowPanelist={true}>
                                            <ListInterviewsEmployer />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="schedule"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} allowPanelist={true}>
                                            <Schedule />
                                        </ProtectedRoute>
                                    }
                                />

                                <Route
                                    path="profile"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} allowPanelist={true}>
                                            <EmployerProfile />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="change-password"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} allowPanelist={true}>
                                            <ChangePasswordEmployer />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>

                            <Route path="response">
                                <Route path=":interviewType">
                                    <Route path=":interviewId">
                                        <Route path=":applicationId">
                                            <Route path=":response" element={<InterviewResponse />} />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route> */}
                        {/* </Route> */}

                        {/* Vendor Routes */}
                        {/* <Route path="vendor"> */}
                        {/* Dashboard Routes */}
                        {/* <Route path="dashboard">
                                <Route path="job-seeker">
                                    <Route
                                        path="list"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} isVendorRoute={true}>
                                                <VendorJobSeekerList />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path="add"
                                        element={
                                            <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} isVendorRoute={true}>
                                                <AddVendorJobSeeker />
                                            </ProtectedRoute>
                                        }
                                    />
                                </Route>
                                <Route
                                    path="interviews"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER} isVendorRoute={true}>
                                            <ListInterviewsEmployer />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="assignment">
                                <Route
                                    path="job-seeker/:jobSeekerId/job"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_EMPLOYER}>
                                            <VendorEmployerJobList />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route> */}
                        {/* </Route> */}

                        {/* Job Seeker Routes */}
                        {/* <Route path="user">
                            <Route path="dashboard">
                                <Route
                                    path="interviews"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_JOB_SEEKER}>
                                            <Interviews />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="jobs"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_JOB_SEEKER}>
                                            <Home />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="documents"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_JOB_SEEKER}>
                                            <Documents />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="applications"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_JOB_SEEKER}>
                                            <JobSeekersApplications />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="change-password"
                                    element={
                                        <ProtectedRoute type={Constants.TYPE_USER_JOB_SEEKER}>
                                            <ChangePasswordJobSeeker />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path="profile"
                                element={
                                    <ProtectedRoute type={Constants.TYPE_USER_JOB_SEEKER}>
                                        <Profile />
                                    </ProtectedRoute>
                                }
                            />
                            <Route path="job">
                                <Route path=":jobId/apply" element={<ApplyJob />} />
                                <Route path=":jobId/apply/:referralCode" element={<ApplyJob />} />
                            </Route>
                            <Route path="response">
                                <Route path="offer">
                                    <Route path=":applicationId/:response" element={<OfferLetterResponse />} />
                                </Route>
                            </Route>
                            <Route path="verify">
                                <Route path="email">
                                    <Route path=":token">
                                        <Route path=":userType" element={<VerifyEmail />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route> */}
                    </Routes>
                    <Footer />
                </Router>
            </div>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default App
