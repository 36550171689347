import React from 'react'
import { useForm } from 'react-hook-form'
import Constants from '../../../helpers/Constants'
import { Alert, Button, Form } from '../../vanilla'
import FormFields from './FormFields'
import { useUpdateTermsConditions } from '../../../hooks/useAdmin'
import Utils from '../../../helpers/Utils'

function TermsConditionsForm() {
    const form = useForm()
    const { mutateAsync: updateTermsConditions, isError, error, isLoading } = useUpdateTermsConditions()
    // Init Navigate here
    const onSubmit = async (data) => {
        await updateTermsConditions({ data })
        // show success message
        Utils.showToast(Constants.MSG_TERMS_UPDATED_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
    }

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            {isError && (
                <Alert color="danger">{error?.response?.data?.error?.message || Constants.MSG_UNEXPECTED_ERROR}</Alert>
            )}
            <FormFields form={form} />

            <Button className="mb-0" color="primary" label={'Update Terms'} btnType="submit" isLoading={isLoading} />
        </Form>
    )
}

export default TermsConditionsForm
