import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({ size, children, className, ...props }) => {
    let Element
    switch (size) {
        case 'xs':
            Element = 'h6'
            break
        case 'sm':
            Element = 'h5'
            break
        case 'md':
            Element = 'h4'
            break
        case 'lg':
            Element = 'h3'
            break
        case 'xl':
            Element = 'h2'
            break
        case 'xxl':
            Element = 'h1'
            break

        default:
            Element = 'h4'
            break
    }

    return (
        <Element className={className} {...props}>
            {children}
        </Element>
    )
}

Heading.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

Heading.defaultProps = {
    size: 'md',
    className: ''
}

export default Heading
