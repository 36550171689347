import React from 'react'
import { Navigate } from 'react-router-dom'
import Constants from '../../../helpers/Constants'

function AdminRedirectRoute({ children }) {
    const adminHost = process.env.REACT_APP_ADMIN_HOST || 'hr.imejob.com'
    const currentHost = window.location.host

    if (currentHost == adminHost) return <Navigate to={Constants.ROUTE_ADMIN_LOGIN} />
    return children
}

export default AdminRedirectRoute
