import React from 'react'
import Box from '../Box'
import PropTypes from 'prop-types'

const Body = ({ children, className, ...props }) => {
    return (
        <Box className={['card-body', className].join(' ')} {...props}>
            {children}
        </Box>
    )
}

Body.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string
}

Body.defaultProps = {
    className: ''
}

export default Body
