import React, { useState, useEffect } from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { ImUsers } from 'react-icons/im'
import { useDispatch } from 'react-redux'
import { Box, Button } from '../../../../../../components/vanilla'
import Constants from '../../../../../../helpers/Constants'
import { useSubHRStatusUpdate } from '../../../../../../hooks/useAdmin'
import { setLoadingState } from '../../../../../../features/loader/LoaderSlice'
import Utils from '../../../../../../helpers/Utils'

const SubHRCard = ({ subHR, onSetSubHR }) => {
    const [status, setStatus] = useState(false)
    const { mutateAsync } = useSubHRStatusUpdate({ id: subHR.id, status: !subHR.isActive })
    // Init Dispatch here
    const dispatch = useDispatch()

    const toggleStatus = async (e) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            const checkValue = e.target.checked
            // await mutateAsync()
            await mutateAsync({
                data: {
                    id: subHR.id,
                    status: checkValue
                }
            })
            setStatus(checkValue)
            Utils.showToast(Constants.MSG_SUB_HR_STATUS_CHANGE_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    const textStyle = {
        fontSize: '13px'
    }

    useEffect(() => {
        setStatus(subHR.isActive)
    }, [subHR])

    return (
        <Box className="card mb-4" key={subHR.id}>
            <Box className="card-body row align-items-center">
                <Box className="col-md-1 mb-2 mb-md-0 text-center text-md-start">
                    <img
                        alt=""
                        src={`${subHR.avatarPath !== '' ? subHR.avatarPath : Constants.DEFAULT_PATH_JOB_SEEKER_AVATAR}`}
                        className="avatar-img rounded-circle"
                    />
                </Box>
                <Box className="col-md-4 mb-4 mb-md-0 text-center text-md-start">
                    <h4>
                        {`${subHR.firstName} ${subHR.middleName} ${subHR.lastName}`}{' '}
                        <span className="pill pill-info">{subHR.referralCode}</span>
                    </h4>
                    <div>{subHR.email}</div>
                    <div>{subHR.phoneNumber}</div>
                </Box>
                <Box className="col-md-3 mb-4 mb-md-0 text-center text-md-start">
                    Job-Seekers sourced: {subHR.jobSeekerCount} <br />
                    Job-Seekers joined: {subHR.totalJoinedCount} <br />
                    Job-Seekers shortlisted: {subHR.totalShortlistedCount} <br />
                    Job-Seekers on hold: {subHR.totalHoldCount}
                    <br />
                    Pool Job-Seekers sourced: {subHR.poolJobSeekerCount}
                </Box>
                <Box className=" col-md-1 form-check form-switch">
                    <input
                        className="form-check-input me-2"
                        type="checkbox"
                        role="switch"
                        checked={status}
                        onChange={(e) => toggleStatus(e)}
                        style={{ marginTop: 0 }}
                    />
                    <Box Element="label" className="form-check mb-0 ps-0" style={{ width: '300px', ...textStyle }}>
                        Active?
                    </Box>
                </Box>

                <Box className="col-md-3 text-md-end text-center">
                    <Button
                        onClick={() => onSetSubHR()}
                        data-bs-toggle="modal"
                        data-bs-target="#subHrModal"
                        className="w-100 mb-2"
                        color="success"
                        label="Send Contract Email"
                        icon={<FaEnvelope />}
                    />
                    <Button
                        type="link"
                        color="primary"
                        label="Job Seekers"
                        className="w-100 mb-2"
                        icon={<ImUsers />}
                        to={Constants.ROUTE_ADMIN_MANAGE_SUBHR_JOBSEEKER_LIST(subHR.id)}
                    />
                    <Button
                        type="link"
                        color="warning"
                        label="Pool Job Seekers"
                        icon={<ImUsers />}
                        to={Constants.ROUTE_ADMIN_MANAGE_SUBHR_POOL_USERS_LIST(subHR.id)}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default SubHRCard
