import React from 'react'
import AssignmentList from '../../../../components/elements/assignment/List'

function AssignJobToCandidate() {
    return (
        <div>
            <AssignmentList />
        </div>
    )
}

export default AssignJobToCandidate
