import React from 'react'
import { Box, Heading } from '../../../components/vanilla'
import Constants from '../../../helpers/Constants'
import { useParams } from 'react-router-dom'
import AddForm from '../../../components/forms/communityPost/Form'

function EditCommunityPost() {
    const postId = useParams().postId
    return (
        <Box className="admin-container mb-4">
            <Heading size="lg">Edit Community Post</Heading>
            <AddForm formType={Constants.TYPE_EDIT} postId={postId} />
        </Box>
    )
}
export default EditCommunityPost
