import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ValidationError from '../../../errors/ValidationError'
import { getUserType } from '../../../features/user/userSlice'
import Constants from '../../../helpers/Constants'
import Utils from '../../../helpers/Utils'
import { updateUserAuthPassword } from '../../../api/Auth'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../vanilla'

function ChangePasswordForm() {
    // Getting the current user type
    const userType = useSelector(getUserType)
    /** Data vars */
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    /** Loader vars */
    const [updateLoading, setUpdateLoading] = useState(false)

    // Initialize navigate here
    const navigate = useNavigate()

    /** Function to handle update password */
    const handleUpdatePassword = async (e) => {
        e.preventDefault()
        try {
            // Setting the loading here
            setUpdateLoading(true)
            // Validating here
            if (currentPassword === '') throw new ValidationError(Constants.MSG_EMPTY_CURR_PWD)
            if (newPassword === '') throw new ValidationError(Constants.MSG_EMPTY_NEW_PWD)
            if (!Utils.validatePassword(newPassword)) throw new ValidationError(Constants.MSG_INVALID_PASSWORD)
            if (confirmPassword === '') throw new ValidationError(Constants.MSG_EMPTY_CONFIRM_PWD)
            if (newPassword !== confirmPassword) throw new ValidationError(Constants.MSG_PWD_NO_MATCH)

            // Calling the api to update password
            await updateUserAuthPassword({
                newPassword,
                currentPassword,
                type: userType
            })

            // Setting the loading here
            setUpdateLoading(false)

            Utils.showToast(Constants.MSG_AUTH_USER_PWD_CHANGED, Constants.TYPE_TOAST_SUCCESS)

            let navigateURL
            switch (userType) {
                case Constants.TYPE_USER_EMPLOYER:
                    navigateURL = Constants.ROUTE_CLIENT_EMPLOYER_PROFILE
                    break
                case Constants.TYPE_USER_ADMIN:
                    navigateURL = Constants.ROUTE_ADMIN_HOME
                    break
                case Constants.TYPE_USER_JOB_SEEKER:
                    navigateURL = Constants.ROUTE_CLIENT_JOB_SEEKER_PROFILE
                    break

                default:
                    navigateURL = Constants.ROUTE_CLIENT_HOME
            }

            navigate(navigateURL)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)

            // Setting the loading here
            setUpdateLoading(false)
        }
    }

    return (
        <div className="container my-4">
            <div className="card">
                <div className="card-body">
                    <h2>Change Password</h2>

                    <form onSubmit={(e) => handleUpdatePassword(e)}>
                        {/* Current Password */}
                        <div className="mb-3">
                            <label htmlFor="current-password" className="form-label">
                                Current Password
                            </label>
                            <input
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                className="form-control"
                                id="current-password"
                                placeholder="**************"
                            />
                        </div>

                        {/* New Password */}
                        <div className="mb-3">
                            <label htmlFor="new-password" className="form-label">
                                New Password
                            </label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="form-control"
                                id="new-password"
                                placeholder="**************"
                            />
                            <div id="passHelp" className="form-text">
                                Your password should be between 6-16 characters long and contain at-least a number and a
                                special character.
                            </div>
                        </div>

                        {/* Confirm New Password */}
                        <div className="mb-3">
                            <label htmlFor="confirm-password" className="form-label">
                                Confirm New Password
                            </label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="form-control"
                                id="confirm-password"
                                placeholder="**************"
                            />
                        </div>

                        <Button
                            btnType="submit"
                            style={{ fontSize: '13px' }}
                            disabled={updateLoading}
                            label={
                                updateLoading ? (
                                    <>
                                        <i className="fas fa-circle-notch fa-spin"></i> Processing...
                                    </>
                                ) : (
                                    <>Update Password</>
                                )
                            }
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordForm
