/* eslint-disable default-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUserType } from '../../../features/user/userSlice'
import Constants from '../../../helpers/Constants'
import { updateJobFlag } from '../../../api/Jobs'
import Utils from '../../../helpers/Utils'
import { setLoadingState } from '../../../features/loader/LoaderSlice'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { confirmAlert } from 'react-confirm-alert'
import { Box, Button, Column, Row } from '../../vanilla'
import { FaEdit, FaThumbsUp, FaTrashAlt, FaShareAlt, FaBriefcase } from 'react-icons/fa'

function SingleJob({ job, jobs, index, reRenderCallback = () => {}, isVendor = false, onApplication }) {
    // Getting current user type
    const userType = useSelector(getUserType)
    const jobValidity = moment(job.validity)
    const validDate = moment(new Date().setDate(new Date().getDate() - 1))

    // Init Dispatch here
    const dispatch = useDispatch()

    /** function to check if job is valid */
    const isJobValidityLeft = () => validDate > jobValidity
    /** Function to check if job is features */
    const isFeatured = () => job.isFeatured

    /** Function to approve/reject/delete/renew job */
    const handleJobUtil = async (type, val) => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))
            // Calling API to update Flag
            await updateJobFlag(job.id, type, val, userType)

            let newJobsArr = [...jobs]
            switch (type) {
                case Constants.TYPE_ANON_APPROVE:
                    newJobsArr[index].isAdminApproved = val
                    Utils.showToast(Constants.MSG_JOB_FLAG_SUCCESS_ADMIN, Constants.TYPE_TOAST_SUCCESS)
                    break

                case Constants.TYPE_ANON_DELETE:
                    newJobsArr.splice(index, 1)
                    Utils.showToast(Constants.MSG_JOB_FLAG_DELETE_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
                    break
            }

            reRenderCallback(newJobsArr)
        } catch (error) {
            Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    return (
        <Box className={`job-list job-list-div`}>
            <Box className="job-pos">
                <Box className="job-title">
                    <i className="fas fa-briefcase text-warning"></i> {job.positionName}{' '}
                    {Math.round(job.minExp * 100) / 100 + ' - ' + Math.round(job.maxExp * 100) / 100 + ' Yrs'}
                    {isFeatured() && <span className="fst-italic text-primary"> (Featured)</span>}
                    {userType === Constants.TYPE_USER_ADMIN ? (
                        <Box>{job.employer.companyName}</Box>
                    ) : (
                        <>
                            {job.type !== Constants.TYPE_JOB_SAMPLE && !job.isAdminApproved && (
                                <Box className="fst-italic text-danger pending-approval">(Pending Admin Approval)</Box>
                            )}
                        </>
                    )}
                    <Box>
                        <b>Created:</b> {job.createdAt}
                    </Box>
                    <Box>
                        <b>Updated:</b> {job.updatedAt}
                    </Box>
                </Box>
            </Box>

            {isVendor == true && (
                <Box className="job-act">
                    <Row className="justify-content-end">
                        <>
                            {job.jobApplications != '' ? (
                                <>
                                    <Box className="fst-italic h4">
                                        Current Status:{' '}
                                        {Utils.jobAppDisplayStatusText({
                                            status: job.jobApplications.status,
                                            hrResult: job.jobApplications.hrResult ?? '',
                                            interviewResult: job.jobApplications.interviewResult ?? '',
                                            jobSchedulesTechnical: job.jobApplications.jobSchedulesTechnical ?? '',
                                            offerAccepted: job.jobApplications.offerAccepted ?? '',
                                            totalTechnicalCount: job.jobApplications.totalTechnicalCount ?? 0
                                        })}
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Column className="col-md-3">
                                        <Button
                                            color="warning"
                                            label="Make Application"
                                            data-bs-toggle="modal"
                                            data-bs-target={`#applyModal${index}`}
                                            onClick={onApplication}
                                            icon={<FaBriefcase />}
                                            className="w-100"
                                        />
                                    </Column>
                                </>
                            )}
                        </>
                    </Row>
                </Box>
            )}

            {isVendor == false && (
                <>
                    <Box className="job-qus">
                        <Link
                            to={
                                userType === Constants.TYPE_USER_ADMIN
                                    ? Constants.ROUTE_ADMIN_JOB_EDIT(job.id)
                                    : Constants.ROUTE_CLIENT_EMPLOYER_JOB_EDIT(job.id)
                            }
                            state={{ show: 'questionnaire' }}
                            className="btn-job me-2 btn-link">
                            {job.type !== Constants.TYPE_JOB_SAMPLE && (
                                <>
                                    {job.questionaire.length > 0 ? (
                                        <>
                                            <i className="far fa-edit text-dark"></i> Edit Questionaire
                                        </>
                                    ) : (
                                        <>
                                            <i className="far fa-plus-square text-dark"></i> Add Questionaire
                                        </>
                                    )}
                                </>
                            )}
                        </Link>
                    </Box>
                    <Box className="job-act">
                        <Row className="justify-content-end">
                            <Column className="col-md-3">
                                <Button
                                    color="warning"
                                    label="Edit"
                                    type="link"
                                    icon={<FaEdit />}
                                    className="w-100"
                                    to={
                                        userType === Constants.TYPE_USER_ADMIN
                                            ? Constants.ROUTE_ADMIN_JOB_EDIT(job.id)
                                            : Constants.ROUTE_CLIENT_EMPLOYER_JOB_EDIT(job.id)
                                    }
                                />
                            </Column>
                            {userType === Constants.TYPE_USER_ADMIN && !job.isAdminApproved && (
                                <Column className="col-md-3">
                                    <Button
                                        color="success"
                                        label="Approve"
                                        onClick={() => handleJobUtil(Constants.TYPE_ANON_APPROVE, true)}
                                        icon={<FaThumbsUp />}
                                        className="w-100"
                                    />
                                </Column>
                            )}

                            {userType === Constants.TYPE_USER_ADMIN && (
                                <Column className="col-md-3">
                                    <Button
                                        color="danger"
                                        label="Delete"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            confirmAlert({
                                                title: Constants.MSG_GEN_ALERT_UNASSIGN,
                                                message: Constants.MSG_DELETE_JOB_CONFIRM,
                                                buttons: [
                                                    {
                                                        label: 'Confirm',
                                                        onClick: () => handleJobUtil(Constants.TYPE_ANON_DELETE, true)
                                                    },
                                                    {
                                                        label: 'Go Back'
                                                    }
                                                ]
                                            })
                                        }}
                                        icon={<FaTrashAlt />}
                                        className="w-100"
                                    />
                                </Column>
                            )}

                            {job.URL && (
                                <Column className="col-md-3">
                                    <CopyToClipboard
                                        text={job.URL}
                                        onCopy={() =>
                                            Utils.showToast(
                                                Constants.MSG_LINK_COPIED_CLIPBOARD,
                                                Constants.TYPE_TOAST_INFO,
                                                Constants.POSITION_TOAST_BOTTOM_CENTER
                                            )
                                        }>
                                        <Button color="success" label="Share" icon={<FaShareAlt />} className="w-100" />
                                    </CopyToClipboard>
                                </Column>
                            )}
                        </Row>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default SingleJob
