import React from 'react'
import { Link } from 'react-router-dom'
import { deleteTagById } from '../../../../api/Admin'
import ValidationError from '../../../../errors/ValidationError'
import Constants from '../../../../helpers/Constants'
import Utils from '../../../../helpers/Utils'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setLoadingState } from '../../../../features/loader/LoaderSlice'

function Tag({ tag, tags, onDelete, index }) {
    const dispatch = useDispatch()

    /**
     * Function to delete a tag by id
     */
    const deleteTag = async () => {
        try {
            // Setting the loading here
            dispatch(setLoadingState(true))

            // Calling the API to delete tag
            await deleteTagById({ id: tag.id })

            // Deleting the tag from tags arr
            let values = [...tags]
            values.splice(index, 1)
            onDelete(values)

            Utils.showToast(Constants.MSG_TAG_DELETE_SUCCESS, Constants.TYPE_TOAST_SUCCESS)
        } catch (error) {
            if (error instanceof ValidationError) Utils.showToast(error.message, Constants.TYPE_TOAST_DANGER)
            else Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)
        } finally {
            // Setting the loading here
            dispatch(setLoadingState(false))
        }
    }

    return (
        <div className="card mb-4 shadow-sm" style={{ cursor: 'pointer' }}>
            <div className="card-header">
                <button
                    onClick={() => deleteTag()}
                    type="button"
                    className="btn btn-outline-danger rounded-circle float-end p-1"
                    style={{ minWidth: '30px', height: '30px' }}>
                    <i className="fas fa-trash-alt"></i>
                </button>
            </div>
            <Link to={Constants.ROUTE_ADMIN_TAG_JOB_SEEKERS({ id: tag.id })} style={{ textDecoration: 'none' }}>
                <div
                    className="card-body d-flex align-items-center justify-content-center py-5"
                    style={{ fontSize: '13px' }}>
                    {tag.name}
                </div>
            </Link>
        </div>
    )
}

Tag.propTypes = {
    tag: PropTypes.object.isRequired,
    tags: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
}

Tag.defaultProps = {
    tag: {},
    tags: []
}

export default Tag
