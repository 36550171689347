import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Heading } from '../../../components/vanilla'
import Pagination from '../../../components/Pagination'
import { useApplicantsByStatus } from '../../../hooks/useAdmin'
import Utils from '../../../helpers/Utils'
import Constants from '../../../helpers/Constants'
import ApplicantCard from './partials/ApplicantCard'

const Applicants = () => {
    const status = useParams().status

    // Data vars here
    const [currentPage, setCurrentPage] = useState(1)

    let { isLoading, data, isError } = useApplicantsByStatus({ status, page: currentPage })
    if (isError) Utils.showToast(Constants.MSG_UNEXPECTED_ERROR, Constants.TYPE_TOAST_DANGER)

    return (
        <Box className="p-4 mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">Applicants ({status})</Heading>
            </Box>

            {!isLoading && data && (
                <>
                    {data.applicants.length > 0 ? (
                        <>
                            {data.applicants.map(
                                ({
                                    jobSeeker: { firstName, middleName, lastName, isPool },
                                    id,
                                    job: { positionName },
                                    employer: { companyName },
                                    jobUuId
                                }) => (
                                    <ApplicantCard
                                        firstName={firstName}
                                        middleName={middleName}
                                        lastName={lastName}
                                        id={id}
                                        positionName={positionName}
                                        companyName={companyName}
                                        key={id}
                                        jobId={jobUuId}
                                        isPool={isPool}
                                    />
                                )
                            )}
                        </>
                    ) : (
                        <Box Element="p">No Applicants Yet!</Box>
                    )}

                    <Pagination
                        totalPages={data.totalPages}
                        currentPage={currentPage}
                        changePage={(page) => setCurrentPage(page)}
                        itemCount={data.count}
                    />
                </>
            )}
        </Box>
    )
}

export default Applicants
