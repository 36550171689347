import React from 'react'
import Constants from '../../../helpers/Constants'
import { useGetFooter } from '../../../hooks/useAdmin'
import { Box, Column, Input, Row } from '../../vanilla'
import useFormFields from './useFormFields'

function FormFields({ form, footerId, formType = Constants.TYPE_ADD }) {
    const { data: footerData } = useGetFooter({ footerId, enabled: footerId !== undefined })

    const { title, isActive } = useFormFields({
        form,
        footer: footerData?.data,
        formType
    })

    return (
        <Box className="mb-3">
            <Row className="mt-4">
                <Column className="col-md-6">
                    <Input {...title} />
                </Column>
                <Column className="col-md-6" style={{ padding: '3.5rem 2rem 0 2rem' }}>
                    <Input {...isActive} />
                </Column>
            </Row>
        </Box>
    )
}

export default FormFields
