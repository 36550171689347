import React from 'react'
import AdminListComponent from './partials/AdminList'
import { HiUserAdd } from 'react-icons/hi'
import { Box, Button, Heading } from '../../../../components/vanilla'
import Constants from '../../../../helpers/Constants'

function AdminList() {
    return (
        <Box className="admin-container mb-4">
            <Box className="mb-4 d-flex align-items-center justify-content-between">
                <Heading size="lg">Admin List</Heading>
                <Button
                    type="link"
                    label="Add"
                    color="warning"
                    icon={<HiUserAdd />}
                    to={Constants.ROUTE_ADMIN_ADD_ADMIN}
                />
            </Box>

            <AdminListComponent />
        </Box>
    )
}

export default AdminList
