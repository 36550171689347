const subscriptionOptions = []
for (let i = 1; i <= 12; i++) {
    subscriptionOptions.push({
        label: `${i}`,
        value: `${i}`
    })
}
export default function useSubscriptionFields({
    form: {
        control,
        formState: { errors }
    }
}) {
    const fields = {
        subscribedDuration: {
            name: 'subscribedDuration',
            id: 'subscribedDuration',
            label: 'Duration (in months)',
            placeholder: 'Select duration in months',
            type: 'select',
            defaultValue: 1,
            options: subscriptionOptions,
            rules: {
                required: 'Please select subscription duration'
            },
            error: errors['subscribedDuration'],
            control
        }
    }

    return fields
}
